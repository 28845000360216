import { Box, Grid, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import DocUploader, { FileLocation } from '../../../../components/mediumComponents/uploadDoc/docUploader';
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import GuaranteeLevel from "../../../../components/enumTypes/GuaranteeLevel";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import { getDropdownList } from "../../../../services/dropdownService";
import './style/ContributionOption.css';
import { axiosInstance } from "../../../../axios";
import InputFileUpload from "../../../../components/mediumComponents/uploadDoc/FileUploader";
import { useRawFiles } from "../../../../contexts/FileContext";
import { uploadToBlob } from "../../../../services/blobService";

/**
 * @returns - Contribution Option main field component
 * @description - This component allows main applicant to fill void cheque info and upload void cheque document 
 */


export default function ContributionOption() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const { saveRawFile, rawFiles, setRawFiles } = useRawFiles();

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);
    const [institutions, setInstitutions] = useState([]);
    const [institutionCode, setInstitutionCode] = useState(applicant.institutionCode);
    const fetchInfo = async ()=>{
        const institutions = await getDropdownList("financialInstitutions");
        setInstitutions(institutions.map(x=>x['financialInstitution']));
        await fetchContributionOption();
    }
    useEffect(() => {
        fetchInfo();

    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleInstitutionChange = (attribute, value) => {
        dispatch(setApplicantInfo({attribute, value}));
        if(value.startsWith('Other')){
            
        }else{
            setInstitutionCode(value.substring(0, 3));
            dispatch(setApplicantInfo({attribute: "institutionCode", value: value.substring(0, 3)}));
        }
       
    }

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantJointSignatureChange = (attribute, value) => {
        // Clear joint email state if user selects no for multiple signature
        if (!value) {
            dispatch(setApplicantInfo({ attribute: 'jointAccountHolderEmail', value: "" }))
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    useEffect(() => {
        const fullName = (applicant.firstname + " " + applicant.lastname)
        if (applicant.accountHolder !== fullName) {
            handleApplicantChange("accountHolder", fullName)
        }
        // eslint-disable-next-line
    }, [applicant.firstname, applicant.lastname]);
    const itemSpace = 4;
    const docUploaderSpace = 6;

    const title = "Contribution Option & Banking Information";
    const guaranteeLevels = [
        GuaranteeLevel.LEVEL7575,
        GuaranteeLevel.LEVEL75100,
        GuaranteeLevel.LEVEL100100
    ];
    const checkboxQuestion = 'This account requires more than 1 signature to process transactions';
    const pageName = 'contribution';

    const fetchContributionOption = async () => {
        let info = await axiosInstance.get(`contribution/all/${application.applicationID}`);

        let fileURL = [];
        if(info.data){
            info = info.data;
            dispatch(setApplicantInfo({ attribute: 'guaranteeLevel', value: info.policyGuaranteeLevel === null? "75/75" : info.policyGuaranteeLevel }));
            dispatch(setApplicantInfo({ attribute: 'accountHolder', value: info.accountHolder }));
            dispatch(setApplicantInfo({ attribute: 'transitNumber', value: info.transitNo }));
            dispatch(setApplicantInfo({ attribute: 'institutionCode', value: info.bankCode }));
            dispatch(setApplicantInfo({ attribute: 'accountNumber', value: info.accountNo }));
            dispatch(setApplicantInfo({ attribute: 'requireMultipleSignature', value: info.requireMoreSignature === null? false : info.requireMoreSignature }));
            dispatch(setApplicantInfo({ attribute: 'jointAccountHolderEmail', value: info.jointAccountHolderEmail}));
            dispatch(setApplicantInfo({ attribute: 'jointAccountHolder', value: info.jointAccountHolder}));

            if (info.voidChequeLink) { fileURL.push(info.voidChequeLink) ;}
            dispatch(setApplicantInfo({ attribute: 'voidChequeLink', value: fileURL }));
            dispatch(setApplicantInfo({ attribute: 'institutionName', value: info.bankName }));
            fileURL = [];
        }

    }
    const saveFile = (attribute, value) => {
        saveRawFile(attribute, value);
    }

    
    const handleClick = async () => {
        await handleUploadToBlob(application.applicationID, 'Applicant', 'voidChequeLink');
    }

    const handleUploadToBlob = async (applicationID, applicantType, attribute) => {
        const file = rawFiles[attribute];
        if(!file){
            return;
        }
        const res = await uploadToBlob(applicationID, applicantType, attribute, file);
        if (res.success) {
            dispatch(setApplicantInfo({ attribute, value: res.url }));
        }
    }


    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 contribution-option-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Main Section - Void Cheque" value={0} />
                    <Tab style={{ fontSize: 16 }} label="Additional Info" value={1} />
                </Tabs>
            </Box>

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>
                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Account Holder' />
                        <TextField
                            setStateValue={value => handleApplicantChange("accountHolder", value)}
                            defaultValue={applicant.accountHolder}
                            type='name'
                            name='Applicant Account Holder'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            disabled={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Joint Account Holder' />
                        <TextField
                            setStateValue={value => handleApplicantChange("jointAccountHolder", value)}
                            defaultValue={applicant.jointAccountHolder}
                            type={applicant.requireMultipleSignature ? 'name' : 'optional'}
                            name='Applicant Joint Account Holder'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Bank / Insitition Name' />
                        {institutions?.length > 0 && (<DropdownList
                            options={institutions}
                            setStateValue={value => handleInstitutionChange("institutionName", value)}
                            defaultValue={applicant.institutionName}
                            type='mandatory'
                            name='Applicant Bank / Institution Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />)}
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Bank / Institution Code' />
                        <TextField
                            disabled={!applicant.institutionName?.includes("Other")} // Enable only if "Other" is part of institutionName
                            setStateValue={value => handleApplicantChange("institutionCode", value)}
                            defaultValue={applicant.institutionName?.includes("Other") ? applicant.institutionCode === null? applicant.institutionCode: ' ' : applicant.institutionCode}
                            type="optional"
                            name="Applicant Bank / Institution Code"
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Transit Number' />
                        <TextField
                            setStateValue={value => handleApplicantChange("transitNumber", value)}
                            defaultValue={applicant.transitNumber}
                            type='5digit'
                            name='Applicant Transit Number'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Account Number' />
                        <TextField
                            setStateValue={value => handleApplicantChange("accountNumber", value)}
                            defaultValue={applicant.accountNumber}
                            type='digits'
                            name='Applicant Account Number'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace * 2 - 0.5} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text={checkboxQuestion} largeBox={true} />
                        <RadioGroupBoolean
                            setStateValue={value => handleApplicantJointSignatureChange("requireMultipleSignature", value)}
                            defaultValue={applicant.requireMultipleSignature || false}
                            type='mandatory'
                            name='Applicant More than 1 Signature'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace + 0.5} style={{ display: applicant.requireMultipleSignature & tabValue === 0 ? 'block' : 'none' }}>
                        <TextFieldText text='Joint Account Holder Email' />
                        <TextField
                            setStateValue={value => handleApplicantChange("jointAccountHolderEmail", value)}
                            defaultValue={applicant.jointAccountHolderEmail}
                            type={applicant.requireMultipleSignature ? 'email' : 'optional'}
                            name='Applicant Joint Account Email'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            largeBox={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace * 3} display={tabValue === 1 ? 'block' : 'none'} >
                        <TextFieldText text='Policy guarantee level' />
                        <RadioGroup
                            options={guaranteeLevels}
                            setStateValue={value => handleApplicantChange("guaranteeLevel", value)}
                            defaultValue={applicant.guaranteeLevel}
                            type='mandatory'
                            name='Applicant Policy Guarantee Level'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>
            </Box>

            <Box className='mt-10 bg-mainfield-box rounded-2xl shadow-custom' >
                <Grid container spacing={2}>

                    <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <TextFieldText text='Upload Void Cheque' largeBox={true} />
                        <InputFileUpload setUploadedFile={value => saveFile("voidChequeLink", value)} />
                        
                    </Grid>

                </Grid>
            </Box>

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} handleClick={handleClick}/>
            </Box>

        </Box>
    );
}; 