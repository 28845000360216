import { Box, Tab, Tabs } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import { useState } from "react";
import { useParams } from 'react-router-dom';
import PrimaryBeneficiary from "./PrimaryBeneficiary";
import './style/Beneficiary.css'
import SecondaryBeneficiary from "./SecondaryBeneficiary";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import BeneficiaryType from "../../../../components/enumTypes/BeneficiaryType";
import { annuitantRelation } from '../../../../constants/lists';


/**
 * @returns - Beneficiary field component for the application page
 */


export default function Beneficiary() {
    const [isUpdate, setIsUpdate] = useState(false);
    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);

    const applicationType = useParams().applicationType;

    const hasSecondaryBeneficiary = ['regular', 'quick', 'transfer', 'nonreg', 'rrsp', 'tfsa'].includes(applicationType);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const beneficiaryType = [
        BeneficiaryType.REVOCABLE,
        BeneficiaryType.IRREVOCABLE
    ];

    const pageName = 'beneficiary';

    return (
        <Box className="box-overall">

            <Box>
                <TitleText text="Beneficiary" />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Primary" value={0} />
                    {hasSecondaryBeneficiary && (
                        <Tab style={{ fontSize: 16 }} label="Secondary" value={1} />
                    )}
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <PrimaryBeneficiary relationAnnuitant={annuitantRelation} beneficiaryType={beneficiaryType} />
            )}

            {tabValue === 1 && (
                <SecondaryBeneficiary relationAnnuitant={annuitantRelation} beneficiaryType={beneficiaryType} />
            )}

            <Box className="box-button">
                <PrevButton />
                <NextButton  checkbeneShare = {true} pageName={pageName} />
            </Box>

        </Box>
    );
};