import React from "react";
import { AdditionalInfoSummary } from "../../models/AdditionalInfoSummaryModel";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import InfoField from "../../components/mediumComponents/summary/InfoField";
import { postApplication } from "../../services/applicationService";
import { useNavigate } from "react-router-dom";
import NextButton from "../../components/smallComponents/button/nextButton/NextButton";
import PrevButton from "../../components/smallComponents/button/prevButton/PrevButton";

const AdditionalInfoSummaryPage = () => {
    const navigate = useNavigate();
    const dataObject = AdditionalInfoSummary();
    const additionalInfo = useSelector(state => state.additionalInfo);

    const handleSubmit = () => {
        // Send POST request
        // const requestBody = AdditionalInfoSummary({ additionalInfo });
        // postApplication(requestBody);

        // Route back to my-account
        navigate("/my-account");
    };

    const handleGoBack = () => {
        navigate(-1);
    };
    const pageName = "addtionalAppSubmit";
    const renderFields = (object) => {
        return (
            <div className="mx-4 grid grid-cols-2 gap-2">
                {Object.entries(object).map(([attr, obj]) => {
                    if ((attr === "Bank and Fund" || attr === "Referrers") && Array.isArray(obj)) {
                        return obj.map((itemObj, index) => (
                            <div key={index} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{`${attr} ${index + 1}`}</h3>
                                <div className="w-full grid grid-cols-2 gap-2">
                                    {Object.entries(itemObj).map(([key, valueObj]) => (
                                        <InfoField
                                            key={key}
                                            description={valueObj.description}
                                            value={valueObj.value}
                                        />
                                    ))}
                                </div>
                            </div>
                        ));
                    } else {
                        return (
                            <div key={attr} className="w-[50rem]">
                                <InfoField
                                    description={obj.description}
                                    value={obj.value}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <Box className="flex flex-col items-center justify-center">
            <div className="m-10 pb-8 border-2 border-gray-200">
                <h1 className="text-start m-8 font-bold text-4xl">Addtestitional Info Summary</h1>
                {Object.entries(dataObject).map(([attribute, object]) => (
                    <div key={attribute}>
                        <h2 className="min-w-[600px] text-start mt-10 mx-8 mb-4 font-bold text-3xl border-b border-gray-500">{attribute}</h2>
                        {renderFields(object)}
                    </div>
                ))}
            </div>

            <div className="mt-12 flex justify-center">
                <Button variant="contained" className="button-prev" onClick={handleGoBack}>
                    Back
                </Button>
                {/* <Button variant="contained" onClick={handleSubmit}>
                    Submit
                </Button> */}
                 <NextButton pageName={pageName} submit={false} addtionalsubmit={true}/>

            </div>
        </Box>
    );
};

export default AdditionalInfoSummaryPage;
