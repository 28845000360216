import React, { createContext, useContext, useState } from "react";

const FileContext = createContext();

export const useRawFiles = () => useContext(FileContext);

export const RawFileProvider = ({ children }) => {
  const [rawFiles, setRawFiles] = useState({});

  const saveRawFile = (attribute, file) => {
    setRawFiles((prev) => ({ ...prev, [attribute]: file }));
  };

  return (
    <FileContext.Provider value={{ rawFiles, saveRawFile }}>
      {children}
    </FileContext.Provider>
  );
};
