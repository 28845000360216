import { axiosInstance } from "../axios"

export const getDropdownList = async (listType) => {
    try {
      const response = await axiosInstance.get(`dropdown/${listType}`) //Ready, don't change
      return response.data
    } catch (error) {
      console.error(`GET dropdown list ${listType} failed, ${error}`);
      return null
    }
  }