import { Box } from "@mui/material";
import { useEffect, useState } from "react";

/**
 * @param {string[]} fieldNames - Array of strings that represent the names of the column fields
 * @param {Array[]} fieldValues - Array of arrays that represent the values of the column fields
 * @returns - Table component that displays the field names and values 
 */


export default function DisplayTable({ fieldNames, fieldValues }) {

    const [values, setValues] = useState([]);
    useEffect(()=>{
        fieldValues.map(fieldValue => {
            delete fieldValue.caseID;
            return fieldValue;
        });
        setValues(fieldValues);
    }, [fieldValues]);
    

    return (
        <Box className="overflow-x-auto w-full mt-8 max-h-[300px] overflow-y-auto">

            <table className="min-w-full divide-y divide-gray-300 border border-gray-300 bg-gray-200">

                <thead className="bg-gray-200">
                    <tr>
                        {fieldNames.map((fieldName, index) => (
                            <th
                                key={index}
                                className="px-4 py-4 text-center text-base font-extrabold text-gray-500 uppercase tracking-wider"
                            >
                                {fieldName}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-300">
                    {values?.map((value, rowIndex) => (
                        <tr key={rowIndex} className="hover:bg-gray-100">
                            {Object.keys(value).map((key, colIndex) => (
                                <td key={colIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                                    {value[key]}
                                </td>
                            ))}

                        </tr>
                    ))}
                </tbody>

            </table>

        </Box>
    );
};