import { Box, Grid } from "@mui/material";
import { useApplication } from "../../contexts/ApplicationContext";
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationInfo } from "../../reducers/applicationReducer";
import createApplicationID from "../../utilities/createApplicationID";
import CreateTimeStamp from "../../utilities/createTimeStamp";
import { fetchApplicationsInfo } from '../../services/fetchInfoService';
// Main field components
import Progressbar from "./progressbar/Progressbar";
import Sidebar from "./sidebar/Sidebar";
import General from "./mainField/general/General";
import ContactInformation from "./mainField/contactInformation/ContactInformation";
import FinancialAnalysis from "./mainField/financialAnalysis/FinancialAnalysis"
import Employment from "./mainField/Employment/Employment";
import Identification from "./mainField/Identification/Identification";
import Annuitant from "./mainField/annuitant/Annuitant";
import SuccessOwner from "./mainField/successOwner/SuccessOwner";
import InvestorProfile from "./mainField/investorProfile/InvestorProfile";
import SourceOfContribution from "./mainField/sourceOfContribution/SourceOfContribution";
import ContributionOption from "./mainField/contributionOption/ContributionOption";
import Verification from "./mainField/verification/Verification";
import CreditReport from "./mainField/creditReport/CreditReport";
import Disclosure from "./mainField/disclosure/Disclosure";
import Summary from "../SummaryPage/SummaryPage";
import Beneficiary from "./mainField/Beneficiary/Beneficiary";
import SpecialInstruction from "./mainField/specialInstruction/SpecialInstruction";
import ProductDetail from "./mainField/productDetail/ProductDetail";
import { infoToSave, saveInfo } from "../../services/saveInfoService";
import './ApplicationPageStyles.css';
import { fetchConfig } from "../../services/fetchConfigService";
/**
 * @returns - Application page component that renders the main field components
 * @description - This component renders different sets of main field components based on the application type
 */


export default function ApplicationPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const application = useSelector((state) => state.applicationInfo);
    const applicant = useSelector((state) => state.applicantInfo);
    const { progress, page } = useApplication();
    const [isHovered, setIsHovered] = useState(false);
    const [zIndexValue, setZIndexValue] = useState(0);
    const hasEffectRun = useRef(false);

    useEffect(() => {
        if (isHovered) {
            setZIndexValue(2);
            return;
        }

        if (!isHovered && zIndexValue === 2) {
            const timeoutId = setTimeout(() => setZIndexValue(0), 800);
            return () => clearTimeout(timeoutId);
        }
    }, [isHovered, zIndexValue, setZIndexValue]);

    const applicationType = useParams().applicationType;

    const pageNumber = {
        regular: 15,
        quick: 14,
        transfer: 13,
        nonreg: 13,
        resp: 12,
        rrsp: 11,
        tfsa: 12,
        // Define other pages
    };

    const totalPage = pageNumber[applicationType];

    const applicationConfig = {
        regular: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Financial Details', subOption: 'Financial Analysis', component: FinancialAnalysis },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Additional People', subOption: 'Success Owner', component: SuccessOwner },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Upload Documents', subOption: 'Credit Report', component: CreditReport },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        quick: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Financial Details', subOption: 'Financial Analysis', component: FinancialAnalysis },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Additional People', subOption: 'Success Owner', component: SuccessOwner },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Credit Report', component: CreditReport },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        transfer: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Additional People', subOption: 'Success Owner', component: SuccessOwner },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        nonreg: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Additional People', subOption: 'Success Owner', component: SuccessOwner },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        resp: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Product Details', component: ProductDetail },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        rrsp: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        tfsa: [
            { mainOption: 'Applicant Information', subOption: 'General', component: General },
            { mainOption: 'Applicant Information', subOption: 'Contact Information', component: ContactInformation },
            { mainOption: 'Applicant Information', subOption: 'Identification', component: Identification },
            { mainOption: 'Applicant Information', subOption: 'Employment Information', component: Employment },
            { mainOption: 'Applicant Information', subOption: 'Disclosure Information', component: Disclosure },
            { mainOption: 'Financial Details', subOption: 'Source of Contribution', component: SourceOfContribution },
            { mainOption: 'Financial Details', subOption: 'Contribution Option', component: ContributionOption },
            { mainOption: 'Additional People', subOption: 'Beneficiary', component: Beneficiary },
            { mainOption: 'Additional People', subOption: 'Annuitant', component: Annuitant },
            { mainOption: 'Investor Profile', subOption: 'Investor Profile', component: InvestorProfile },
            { mainOption: 'Upload Documents', subOption: 'Identify Verification', component: Verification },
            { mainOption: 'Special Instructions', subOption: 'Special Instructions', component: SpecialInstruction },
            { mainOption: 'Summary', subOption: 'Summary', component: Summary }
        ],
        // Define other types
    };

    // Define the pageName based on the current page in the applicationConfig
    const pageName = applicationConfig[applicationType]?.[page]?.subOption.toLowerCase().replace(/\s+/g, '') || '';

    // Use navigate to update the URL based on the current applicationType and pageName
    useEffect(() => {
        const applicationID = application.applicationID;
        if (pageName && applicationID) {
            navigate(`/application/${applicationType}/${pageName}/${applicationID}`);
        }

    }, [navigate, applicationType, pageName, application.applicationID]);
    

    const getSidebarOptions = (config) => {
        const mainOptionsMap = new Map();

        config.forEach(({ mainOption, subOption }, index) => {
            if (!mainOptionsMap.has(mainOption)) {
                mainOptionsMap.set(mainOption, { mainOption, subOptions: [] });
            }
            mainOptionsMap.get(mainOption).subOptions.push({ subOptionName: subOption, subOptionValue: index });
        });

        return Array.from(mainOptionsMap.values());
    };

    const sidebarOptions = getSidebarOptions(applicationConfig[applicationType]);
    const user = useSelector((state) => state.userInfo);
    const userID = user.userID;
    const [defaultAdvisorCode, setDefaultAdvisorCode] = useState('');
    
    const fetchApplicationInfo = async (applicationID)=>{
        const applicationResponse = await fetchApplicationsInfo(applicationID, page);
        if(applicationResponse.applicationID !== null){
            return;
        }else{
            const info = infoToSave('application', 
                {'fundType': application.fundType, 
                'investmentAccountType': application.investmentAccountType, 
                'requestType': application.requestType,
                'createdUserID': userID,
                'servingAdvisorCode': application.servingAdvisorCode || defaultAdvisorCode,
                'isSystemAssignAdvisor': application.isSystemAssignAdvisor,
                'hasCoApplicant': application.hasCoApplicant
            }, 
                null, applicationID);
            // checkOrCreateApplication(applicationID, application.fundType, application.investmentAccountType, application.requestType, timeStamp);
            await saveInfo('application', info);
            // to avoid foreign key contraint
            await saveInfo('source', {'amountApplied': applicant.loanAmountApplied || 200000,
                                'applicationID': applicationID
                                } );
            hasEffectRun.current = true; // Mark effect as run
           
        }
        
    }

    useEffect(() => {
            fetchConfig("Por_Application", "DefaultServingAdvisorCode").then((code) => {
                setDefaultAdvisorCode(code);
            }); 
    }, []);

    useEffect(() => {
        let applicationID = "";
        if(defaultAdvisorCode === "") {
            return;
        }
        if (hasEffectRun.current) return;
        applicationID = application.applicationID
        const params = new URLSearchParams(window.location.search);
        applicationID = params.get('applicationID');
        if(applicationID === null){
            applicationID = createApplicationID();
            //dispatch(setApplicationInfo({ attribute: 'applicationID', value: applicationID }));
        }
        dispatch(setApplicationInfo({ attribute: 'applicationID', value: applicationID }));
        //fetch application based on ID
       fetchApplicationInfo(applicationID);
      
        // eslint-disable-next-line
    }, [defaultAdvisorCode]);

    const renderComponent = (page, config) => {
        if (config[page]) {
            const Component = config[page].component;
            return Component ? <Component /> : null;
        }
        return null;
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = 'Are you sure you want to leave? Any unsaved changes will be lost.';
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>

                <Grid item xs={12} >
                    <Progressbar
                        applicationID={application.applicationID}
                        progress={Math.round(progress / totalPage * 100)}
                        buttonText1={page !== totalPage ? "Cancel" : ''}
                        buttonText2={page !== totalPage ? "My Account" : ''}
                    />
                </Grid>

                {page !== totalPage && (
                    <Grid item xs={12} style={{ position: 'absolute', zIndex: { zIndexValue }, marginTop: 120, height: '100%' }} >
                        <Sidebar
                            sidebarOptions={sidebarOptions}
                            isHovered={isHovered}
                            setIsHovered={setIsHovered}
                        />
                    </Grid>
                )}

                <Grid item xs={12} className="flex justify-center page-grid" style={{ paddingTop: 0, position: 'relative', zIndex: 1 }}>
                    {renderComponent(page, applicationConfig[applicationType])}
                </Grid>

            </Grid>
        </Box>
    );
};