import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Tabs, Tab, Snackbar, Alert } from "@mui/material";
import { AddBox, SearchOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import NumberField from "../../../../../../components/smallComponents/numberField/NumberField";
import NumberFieldMoney from "../../../../../../components/smallComponents/numberField/NumberFieldMoney";
import TextField from "../../../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../../../components/smallComponents/dateField/DateField";
import Table from "../../../../../../components/mediumComponents/table/Table";
import DocUploader from "../../../../../../components/mediumComponents/uploadDoc/docUploader";
import { answerAttributes, investorOptions } from "../../../../../../constants/lists";
import { getAllItems, addItem, deleteItemByID, updateItem } from "../../../../../../services/multiAddObjectService";
import CreateTimeStamp from "../../../../../../utilities/createTimeStamp";
import AddressAutoComplete from "../../../../../../components/smallComponents/addressAutoComplete/addressAutoComplete";
import Checkbox from "../../../../../../components/smallComponents/checkbox/Checkbox";
import { currAddr, prevAddr } from "../../../../../../constants/lists"
import Big from 'big.js';
import bigInt from 'big-integer';
import "./style/IncomeSubsection.css";
import ModifyTable from "../../../../../../components/mediumComponents/table/ModifyTable";
import { getDropdownList } from "../../../../../../services/dropdownService";
/**
 * @description - This component imports a table component to display the stored incomes and a field box to add an new income
 * @param {string} applicantType - Type of applicant (main or co)
 * @param {string} color - Background color of the income field
 * @returns - Income Subsection component for the Financial Analysis field component 
 */


export default function IncomeSubsection({ applicantType, color }) {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const applicationType = useParams().applicationType;
    const [isServiceStatusError, setIsServiceStatusError] = useState(false);
    const [isPrevServiceError, setIsPrevServiceError] = useState(false);
    const postCodePattern = /^[A-Za-z]\d[A-Za-z][ ]\d[A-Za-z]\d$/;

    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange, clickNextButton,mainIncomeManualInputCurrCheck, setMainIncomeManualInputCurrCheck,
         handleErrorMessage, } = useApplication();
    
    
    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const [income, setIncome] = useState({
        owner: '',
        type: '',
        annualAmount: '',
        employerName: '',
        employerAddrUnit: '',
        employerStreetNumber: '',
        employerStreetName: '',
        employerCity: '',
        employerProvince: '',
        employerPostalCode: '',
        industry: '',
        occupation: '',
        serviceSince: '',
        prevEmployerName: '',
        prevIndustry: '',
        prevOccupation: '',
        prevServiceSince: ''
    });
    const [industrys, setIndustrys] = useState([]);
    const [occupations, setOccupations] = useState([]);
    const [preOccupations, serPreOccupations] = useState([]);
    const [industrysJob, setIndustrysJob] = useState([]);
    
    const [incomeTypes, setIncomeTypes] = useState([]);
    const fetchIndustyJob = async ()=>{
        const industyJobs = await getDropdownList("IndustryOccupations");
        setIndustrysJob (industyJobs);
        setIndustrys([...new Set(industyJobs.map(x => x['industry']))]);
        
    }


    const fetchIncomeType = async ()=>{
        const incomeT = await getDropdownList("incomeTypes");
        setIncomeTypes(incomeT.map(x=>x['incomeType']));
    }

    const handleOccupationOption = (industy)=>{
        const matchedOccu = [];
        industrysJob.forEach(job => {
            
            if (job.industry === industy) {
              matchedOccu.push(job['occupation']);
            }
          });
          
          setOccupations([...new Set(matchedOccu)]);
    }

    const handlepPreccupationOption = (industy)=>{
        const matchedOccu = [];
        industrysJob.forEach(job => {
            
            if (job.industry === industy) {
              matchedOccu.push(job['occupation']);
            }
          });
          
          serPreOccupations([...new Set(matchedOccu)]);
    }
    const [currAddress, setCurrAddress]= useState(`${income.employerStreetNumber} ${income.employerStreetName} ${income.employerAddrUnit} ${income.employerCity} ${income.employerProvince} ${income.employerPostalCode}`);

    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const [isUpdate, setIsUpdate] = useState(false);


    const itemSpace = 3;
    const tabSpace = 12;
    const docUploaderSpace = 6;
    const workingMonthKey = 24;
    const specialItemSpace = 12;

    const pageName = 'financial';
    const itemType = 'incomes';

    // Question index and score range for the pre-fill question
    const questionIndex = 1;
    const questionScore = [1, 2, 5, 10, 20];

    const hasCoApplicant = applicant.anyCoapplicant;

    const applicantName = applicant.firstname + ' ' + applicant.lastname;
    const coApplicantName = hasCoApplicant ? coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const bothApplicantsName = hasCoApplicant ? applicant.firstname + ' ' + applicant.lastname + ' & ' + coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const owners = hasCoApplicant ? [applicantName, coApplicantName, bothApplicantsName] : [applicantName];
    
     //The rowIndex of the table that user clicks on
    const [rowIndex, setRowIndex] = useState(-1);

    // Array of strings that represent the names of the column fields in table component
    const names = ['owner', 'type', 'annualAmount', 'employerName', 'employerStreetNumber', 'employerStreetName', 'Unit','employerCity', 'employerProvince',
        'employerPostalCode', 'industry', 'occupation', 'serviceSince', 'prevEmployerName', 'prevIndustry', 'prevOccupation', 'prevServiceSince'];
    
    // Array of arrays that represent the values of the column fields in table component
    // Each individual array (global state) contains the specific attribute value from every income
    const values = applicantType === 'main' ?
        [
            applicant.incomeBelongTo,
            applicant.incomeType,
            applicant.annualIncome,
            applicant.employName,
            applicant.employerAddrStreetNumber,
            applicant.employerAddrStreetName,
            applicant.employerAddrUnit,
            applicant.employerAddrCity,
            applicant.employerAddrProvince,
            applicant.employerAddrPostalCode,
            applicant.industry,
            applicant.occupation,
            applicant.serviceSince,
            applicant.previousEmployerName,
            applicant.previousIndustry,
            applicant.previousOccupation,
            applicant.previousServiceSince
        ]
        :
        [
            coapplicant.incomeBelongTo,
            coapplicant.incomeType,
            coapplicant.annualIncome,
            coapplicant.employName,
            coapplicant.employerAddrStreetNumber,
            coapplicant.employerAddrStreetName,
            coapplicant.employerAddrUnit,
            coapplicant.employerAddrCity,
            coapplicant.employerAddrProvince,
            coapplicant.employerAddrPostalCode,
            coapplicant.industry,
            coapplicant.occupation,
            coapplicant.serviceSince,
            coapplicant.previousEmployerName,
            coapplicant.previousIndustry,
            coapplicant.previousOccupation,
            coapplicant.previousServiceSince
        ];
    
    const [updateIncomeIndex, setUpdateIncomeIndex] = useState(0);
   
    function clearCurrAddrOnChkBox(value) {
    setMainIncomeManualInputCurrCheck(value);

    handleAutoAddress();
    if (mainIncomeManualInputCurrCheck[0] === "Can't find your address?") {
        handleErrorMessage(pageName, 'add', `Applicant Current Address is mandatory`)
    }
    };
    
    const manualInputQuestion = "Can't find your address?";

    const handleAutoAddress = (addressObj) => {
        if (addressObj) {
           
            setIncome({
                ...income, employerStreetNumber:addressObj['streetNumber'], employerStreetName: addressObj['streetName'], employerStreetNumber:addressObj['streetNumber'],employerCity: addressObj['city'],
                employerProvince: addressObj['province'], employerPostalCode: addressObj['postalCode']
            });
            
            
            setMainIncomeManualInputCurrCheck([]);
            handleErrorMessage(pageName, 'remove', `Applicant Current Address is mandatory`)
        }
        else {
            handleErrorMessage(pageName, 'remove', "Applicant Current Address couldn't be retrieved please fill the address field manually")
            dispatch(setApplicantInfo({ attribute: 'streetNumber', value: '' }));
            dispatch(setApplicantInfo({ attribute: 'streetName', value: '' }));
            dispatch(setApplicantInfo({ attribute: 'city', value: '' }));
            dispatch(setApplicantInfo({ attribute: 'province', value: '' }));
            dispatch(setApplicantInfo({ attribute: 'postalCode', value: '' }));
            handleErrorMessage(pageName, 'remove', `Applicant Current Address is mandatory`)
        }
    };
    // Trigger the clearing of the input fields in child components after adding a new income
    // Reset the state to false after 0.1 seconds to resume input action in the child components
    const clearInputs = ()=>{
        setIsClear(true);
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);
        setCurrAddress("");
        // Reset the local state to empty strings after adding a new income
        setIncome({
            owner: '',
            type: '',
            annualAmount: '',
            employerName: '',
            employerStreetNumber: '',
            employerStreetName: '',
            employerCity: '',
            employerProvince: '',
            employerPostalCode: '',
            industry: '',
            occupation: '',
            serviceSince: '',
            prevEmployerName: '',
            prevIndustry: '',
            prevOccupation: '',
            prevServiceSince: ''
        });

    }

    // Pre-fill the income question and calculate investor profile score based on the total annual income
    const calculateIncome = (incomeChange) => {
        const totalAnnualIncome = incomeChange === '' ? 0 : parseFloat(incomeChange)
            +
            applicant.annualIncome
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            +
            coapplicant.annualIncome
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0);

        if (totalAnnualIncome <= 25000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[1], value: [1, investorOptions[1][0], 1] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[1], value: [1, investorOptions[1][0], 1] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[0]);
            handleCoInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (totalAnnualIncome <= 35000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[1], value: [2, investorOptions[1][1], 2] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[1], value: [2, investorOptions[1][1], 2] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[1]);
            handleCoInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (totalAnnualIncome <= 50000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[1], value: [3, investorOptions[1][2], 5] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[1], value: [3, investorOptions[1][2], 5] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[2]);
            handleCoInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (totalAnnualIncome <= 100000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[1], value: [4, investorOptions[1][3], 10] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[1], value: [4, investorOptions[1][3], 10] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[3]);
            handleCoInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else {
            dispatch(setApplicantInfo({ attribute: answerAttributes[1], value: [5, investorOptions[1][4], 20] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[1], value: [5, investorOptions[1][4], 20] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[4]);
            handleCoInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };


    // Provide input field validation before adding a new income
    const handleAddIncomeCheck = () => {
        const isFieldEmpty = (fields) => fields.some(field => field === '');

        const serviceSinceDate = dayjs(income.serviceSince);
        const prevServiceSinceDate = dayjs(income.prevServiceSince);
        const prevMonthsDifference = serviceSinceDate.diff(prevServiceSinceDate, 'month');
        const today = dayjs();
        const monthsDifference = today.diff(serviceSinceDate, 'month');
        
        setIsServiceStatusError(false);
        setIsPrevServiceError(false);

        if (isFieldEmpty([income.owner, income.type, income.annualAmount, income.serviceSince, income.annualAmount])) {
            setOpenError(true);
        }
        else if (parseFloat(income.annualAmount) < 0) {
            setOpenError(true);
        }
        else if (monthsDifference < 3) {  // New validation for serviceSince being more than 3 months
            setIsServiceStatusError(true);
            setOpenError(true);
        }        
        else if (dayjs().diff(dayjs(income.serviceSince), 'month') < workingMonthKey &&
            isFieldEmpty([income.prevEmployerName, income.prevIndustry, income.prevOccupation, income.prevServiceSince])) {
            setOpenError(true);
        }
        // Check if prevServiceSince exists and combined service + prevServiceSince < 2 years
        else if (income.prevServiceSince !== '' && (monthsDifference + prevMonthsDifference) < 24) {
            setIsPrevServiceError(true);
            setOpenError(true);
        }        
        else if ((income.type === 'Employment' || income.type === 'Self-Employment') &&
            isFieldEmpty([
                income.employerName, income.employerStreetNumber, income.employerStreetName, income.employerCity,
                income.employerProvince, income.employerPostalCode, income.industry, income.occupation
            ])) {
            setOpenError(true);
        }
        else  if((income.type === 'Employment' || income.type === 'Self-Employment') &&!postCodePattern.test(income.employerPostalCode)){
            setOpenError(true);
           
        }else {
            handleAddIncome();
            clearInputs();
            setOpenSuccess(true);
        }
    };

    const handleAddIncome = async () => {

        // Calculate the total annual income after adding a new income
        calculateIncome(income.annualAmount);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: [...applicant.incomeBelongTo, income.owner] }));
            dispatch(setApplicantInfo({ attribute: 'incomeType', value: [...applicant.incomeType, income.type] }));
            dispatch(setApplicantInfo({ attribute: 'annualIncome', value: [...applicant.annualIncome, income.annualAmount] }));
            dispatch(setApplicantInfo({ attribute: 'employName', value: [...applicant.employName, income.employerName] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: [...applicant.employerAddrStreetNumber, income.employerStreetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: [...applicant.employerAddrStreetName, income.employerStreetName] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: [...applicant.employerAddrUnit, income.employerAddrUnit] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: [...applicant.employerAddrCity, income.employerCity] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: [...applicant.employerAddrProvince, income.employerProvince] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: [...applicant.employerAddrPostalCode, income.employerPostalCode] }));
            dispatch(setApplicantInfo({ attribute: 'industry', value: [...applicant.industry, income.industry] }));
            dispatch(setApplicantInfo({ attribute: 'occupation', value: [...applicant.occupation, income.occupation] }));
            dispatch(setApplicantInfo({ attribute: 'serviceSince', value: [...applicant.serviceSince, income.serviceSince] }));
            dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: [...applicant.previousEmployerName, income.prevEmployerName] }));
            dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: [...applicant.previousIndustry, income.prevIndustry] }));
            dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: [...applicant.previousOccupation, income.prevOccupation] }));
            dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: [...applicant.previousServiceSince, income.prevServiceSince] }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'incomeBelongTo', value: [...coapplicant.incomeBelongTo,  income.owner] }));
            dispatch(setCoapplicantInfo({ attribute: 'incomeType', value: [...coapplicant.incomeType, income.type] }));
            dispatch(setCoapplicantInfo({ attribute: 'annualIncome', value: [...coapplicant.annualIncome, income.annualAmount] }));
            dispatch(setCoapplicantInfo({ attribute: 'employName', value: [...coapplicant.employName, income.employerName] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetNumber', value: [...coapplicant.employerAddrStreetNumber, income.employerStreetNumber] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetName', value: [...coapplicant.employerAddrStreetName, income.employerStreetName] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrUnit', value: [...coapplicant.employerAddrUnit, income.employerAddrUnit] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrCity', value: [...coapplicant.employerAddrCity, income.employerCity] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrProvince', value: [...coapplicant.employerAddrProvince, income.employerProvince] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrPostalCode', value: [...coapplicant.employerAddrPostalCode, income.employerPostalCode] }));
            dispatch(setCoapplicantInfo({ attribute: 'industry', value: [...coapplicant.industry, income.industry] }));
            dispatch(setCoapplicantInfo({ attribute: 'occupation', value: [...coapplicant.occupation, income.occupation] }));
            dispatch(setCoapplicantInfo({ attribute: 'serviceSince', value: [...coapplicant.serviceSince, income.serviceSince] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousEmployerName', value: [...coapplicant.previousEmployerName, income.prevEmployerName] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousIndustry', value: [...coapplicant.previousIndustry, income.prevIndustry] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousOccupation', value: [...coapplicant.previousOccupation, income.prevOccupation] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousServiceSince', value: [...coapplicant.previousServiceSince, income.prevServiceSince] }));
        }
        // Create a new income object to be added to the database
        const newIncome = {
            applicationID: application.applicationID,
            owner: income.owner,
            incomeType: income.type,
            annualIncome: new Big(income.annualAmount),
            employerName: income.employerName,
            employerAddressUnit: income.employerAddrUnit,
            employerAddressStreetNumber: income.employerStreetNumber,
            employerAddressStreetName: income.employerStreetName,
            employerAddressCity: income.employerCity,
            employerAddressProvince: income.employerProvince,
            employerAddressPostalCode: income.employerPostalCode,
            industry: income.industry,
            occupation: income.occupation,
            serviceSince: income.serviceSince,
            previousEmployerName: income.prevEmployerName,
            previousIndustry: income.prevIndustry,
            previousOccupation: income.prevOccupation,
            previousEmployerServingSince: income.prevServiceSince,
            previousEmployerServingEnd: null,
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new income to the database
        const addedIncome = await addItem(itemType, newIncome);

        // Push the DB generated incomeId from API response to the global state
        dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value: [...applicant.IncomeIDs, addedIncome.incomeID] }));

        clearInputs();
        setIsClear(true);
    };
    
    
    const handleEditIncome = (index) =>{

        setIncome({
            incomeID: applicant.IncomeIDs[index],
            owner: applicant.incomeBelongTo[index],
            type: applicant.incomeType[index],
            annualAmount: applicant.annualIncome[index],
            industry: applicant.industry[index],
            occupation: applicant.occupation[index],
            serviceSince: applicant.serviceSince[index],
            prevEmployerName: applicant.previousEmployerName[index],
            prevIndustry: applicant.previousIndustry[index],
            prevOccupation: applicant.previousOccupation[index],
            prevServiceSince: applicant.previousServiceSince[index],
            employerName: applicant.employName[index],
            employerAddrUnit: applicant.employerAddrUnit[index],
            employerStreetNumber: applicant.employerAddrStreetNumber[index],
            employerStreetName: applicant.employerAddrStreetName[index],
            employerCity: applicant.employerAddrCity[index],
            employerProvince: applicant.employerAddrProvince[index],
            employerPostalCode: applicant.employerAddrPostalCode[index]
        });
        const address = `${applicant.employerAddrStreetNumber[index]} ${applicant.employerAddrStreetName[index]} ${applicant.employerAddrCity[index]} ${applicant.employerAddrProvince[index]} ${applicant.employerAddrPostalCode[index]}`;

        setCurrAddress(address);
        handleDeleteIncomeNoDB(index);
        setIsUpdate(prevState => !prevState);
        setUpdateIncomeIndex(applicant.IncomeIDs[index]);
    }

    const sendEditIncome = async () => {
        if((income.type === 'Employment' || income.type === 'Self-Employment') &&!postCodePattern.test(income.employerPostalCode)){
            setOpenError(true);
            return;
        }
        // Calculate the total annual income after adding a new income
        calculateIncome(income.annualAmount);

        if (applicantType === 'main') { 
            dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value:[updateIncomeIndex]}));
            dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: [...applicant.incomeBelongTo,  income.owner] }));
            dispatch(setApplicantInfo({ attribute: 'incomeType', value: [...applicant.incomeType, income.type] }));
            dispatch(setApplicantInfo({ attribute: 'annualIncome', value: [...applicant.annualIncome, income.annualAmount] }));
            dispatch(setApplicantInfo({ attribute: 'employName', value: [...applicant.employName, income.employerName] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: [...applicant.employerAddrStreetNumber, income.employerStreetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: [...applicant.employerAddrStreetName, income.employerStreetName] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: [...coapplicant.employerAddrUnit, income.employerAddrUnit] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: [...applicant.employerAddrCity, income.employerCity] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: [...applicant.employerAddrProvince, income.employerProvince] }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: [...applicant.employerAddrPostalCode, income.employerPostalCode] }));
            dispatch(setApplicantInfo({ attribute: 'industry', value: [...applicant.industry, income.industry] }));
            dispatch(setApplicantInfo({ attribute: 'occupation', value: [...applicant.occupation, income.occupation] }));
            dispatch(setApplicantInfo({ attribute: 'serviceSince', value: [...applicant.serviceSince, income.serviceSince] }));
            dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: [...applicant.previousEmployerName, income.prevEmployerName] }));
            dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: [...applicant.previousIndustry, income.prevIndustry] }));
            dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: [...applicant.previousOccupation, income.prevOccupation] }));
            dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: [...applicant.previousServiceSince, income.prevServiceSince] }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'incomeBelongTo', value: [...coapplicant.incomeBelongTo,  income.owner] }));
            dispatch(setCoapplicantInfo({ attribute: 'incomeType', value: [...coapplicant.incomeType, income.type] }));
            dispatch(setCoapplicantInfo({ attribute: 'annualIncome', value: [...coapplicant.annualIncome, income.annualAmount] }));
            dispatch(setCoapplicantInfo({ attribute: 'employName', value: [...coapplicant.employName, income.employerName] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetNumber', value: [...coapplicant.employerAddrStreetNumber, income.employerStreetNumber] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetName', value: [...coapplicant.employerAddrStreetName, income.employerStreetName] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrUnit', value: [...coapplicant.employerAddrUnit, income.employerAddrUnit] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrCity', value: [...coapplicant.employerAddrCity, income.employerCity] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrProvince', value: [...coapplicant.employerAddrProvince, income.employerProvince] }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrPostalCode', value: [...coapplicant.employerAddrPostalCode, income.employerPostalCode] }));
            dispatch(setCoapplicantInfo({ attribute: 'industry', value: [...coapplicant.industry, income.industry] }));
            dispatch(setCoapplicantInfo({ attribute: 'occupation', value: [...coapplicant.occupation, income.occupation] }));
            dispatch(setCoapplicantInfo({ attribute: 'serviceSince', value: [...coapplicant.serviceSince, income.serviceSince] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousEmployerName', value: [...coapplicant.previousEmployerName, income.prevEmployerName] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousIndustry', value: [...coapplicant.previousIndustry, income.prevIndustry] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousOccupation', value: [...coapplicant.previousOccupation, income.prevOccupation] }));
            dispatch(setCoapplicantInfo({ attribute: 'previousServiceSince', value: [...coapplicant.previousServiceSince, income.prevServiceSince] }));
        }
        
        // Create a new income object to be added to the database
        const newIncome = {
            incomeID : updateIncomeIndex,
            applicationID: application.applicationID,
            owner: income.owner,
            incomeType: income.type,
            annualIncome: new Big(income.annualAmount),
            employerName: income.employerName,
            employerAddressStreetNumber: income.employerStreetNumber,
            employerAddressStreetName: income.employerStreetName,
            employerAddressUnit: income.employerAddrUnit,
            employerAddressCity: income.employerCity,
            employerAddressProvince: income.employerProvince,
            employerAddressPostalCode: income.employerPostalCode,
            industry: income.industry,
            occupation: income.occupation,
            serviceSince: income.serviceSince,
            previousEmployerName: income.prevEmployerName,
            previousIndustry: income.prevIndustry,
            previousOccupation: income.prevOccupation,
            previousEmployerServingSince: income.prevServiceSince,
            previousEmployerServingEnd: null,
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new income to the database
        const addedIncome = await updateItem(itemType, newIncome);

        setIsClear(true);
        setIsUpdate(prevState => !prevState);
        clearInputs();
        setOpenSuccess(true);

    };

    const handleDeleteIncomeNoDB = (index) => {

        // Calculate the total annual income after deleting an income
        calculateIncome(applicantType === 'main' ? -applicant.annualIncome[index] : -coapplicant.annualIncome[index]);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value: applicant.IncomeIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: applicant.incomeBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'incomeType', value: applicant.incomeType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'annualIncome', value: applicant.annualIncome.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employName', value: applicant.employName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: applicant.employerAddrStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: applicant.employerAddrUnit.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: applicant.employerAddrStreetName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: applicant.employerAddrCity.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: applicant.employerAddrProvince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: applicant.employerAddrPostalCode.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'industry', value: applicant.industry.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'occupation', value: applicant.occupation.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'serviceSince', value: applicant.serviceSince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: applicant.previousEmployerName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: applicant.previousIndustry.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: applicant.previousOccupation.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: applicant.previousServiceSince.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'incomeBelongTo', value: coapplicant.incomeBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'incomeType', value: coapplicant.incomeType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'annualIncome', value: coapplicant.annualIncome.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employName', value: coapplicant.employName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetNumber', value: coapplicant.employerAddrStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetName', value: coapplicant.employerAddrStreetName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrUnit', value: coapplicant.employerAddrUnit.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrCity', value: coapplicant.employerAddrCity.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrProvince', value: coapplicant.employerAddrProvince.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrPostalCode', value: coapplicant.employerAddrPostalCode.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'industry', value: coapplicant.industry.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'occupation', value: coapplicant.occupation.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'serviceSince', value: coapplicant.serviceSince.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousEmployerName', value: coapplicant.previousEmployerName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousIndustry', value: coapplicant.previousIndustry.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousOccupation', value: coapplicant.previousOccupation.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousServiceSince', value: coapplicant.previousServiceSince.filter((_, i) => i !== index) }));
        }
    };

    const handleDeleteIncome = async (index) => {

        // Calculate the total annual income after deleting an income
        calculateIncome(applicantType === 'main' ? -applicant.annualIncome[index] : -coapplicant.annualIncome[index]);

        // Delete the income from the database
        const incomeId = bigInt(applicant.IncomeIDs[index]);
        const deletedIncome = await deleteItemByID(itemType, incomeId);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value: applicant.IncomeIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: applicant.incomeBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'incomeType', value: applicant.incomeType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'annualIncome', value: applicant.annualIncome.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employName', value: applicant.employName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: applicant.employerAddrStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: applicant.employerAddrStreetName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: applicant.employerAddrUnit.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: applicant.employerAddrCity.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: applicant.employerAddrProvince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: applicant.employerAddrPostalCode.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'industry', value: applicant.industry.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'occupation', value: applicant.occupation.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'serviceSince', value: applicant.serviceSince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: applicant.previousEmployerName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: applicant.previousIndustry.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: applicant.previousOccupation.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: applicant.previousServiceSince.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'incomeBelongTo', value: coapplicant.incomeBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'incomeType', value: coapplicant.incomeType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'annualIncome', value: coapplicant.annualIncome.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employName', value: coapplicant.employName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetNumber', value: coapplicant.employerAddrStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrStreetName', value: coapplicant.employerAddrStreetName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrUnit', value: coapplicant.employerAddrUnit.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrCity', value: coapplicant.employerAddrCity.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrProvince', value: coapplicant.employerAddrProvince.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'employerAddrPostalCode', value: coapplicant.employerAddrPostalCode.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'industry', value: coapplicant.industry.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'occupation', value: coapplicant.occupation.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'serviceSince', value: coapplicant.serviceSince.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousEmployerName', value: coapplicant.previousEmployerName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousIndustry', value: coapplicant.previousIndustry.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousOccupation', value: coapplicant.previousOccupation.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'previousServiceSince', value: coapplicant.previousServiceSince.filter((_, i) => i !== index) }));
        }
    };

    const handleFetchIncomes = async () => {

        const incomes = await getAllItems(itemType, application.applicationID);
        // Temporary variables to store fetched incomes info
        let updatedIncomeIDs = [];
        let updatedIncomeBelongTo = [];
        let updatedIncomeType = [];
        let updatedAnnualIncome = [];
        let updatedEmployName = [];
        let updatedEmployerAddrStreetNumber = [];
        let updatedEmployerAddrStreetName = [];
        let updatedEmployerAddrUnit = [];
        let updatedEmployerAddrCity = [];
        let updatedEmployerAddrProvince = [];
        let updatedEmployerAddrPostalCode = [];
        let updatedIndustry = [];
        let updatedOccupation = [];
        let updatedServiceSince = [];
        let updatedPreviousEmployerName = [];
        let updatedPreviousIndustry = [];
        let updatedPreviousOccupation = [];
        let updatedPreviousServiceSince = [];
        let totoalIncome =0;
        for (const income of incomes) {
            updatedIncomeIDs.push(income['incomeID']);
            updatedIncomeBelongTo.push(income['owner']);
            updatedIncomeType.push(income['incomeType']);
            updatedAnnualIncome.push(income['annualIncome']);
            updatedEmployName.push(income['employerName']);
            updatedEmployerAddrStreetNumber.push(income['employerAddressStreetNumber']);
            updatedEmployerAddrStreetName.push(income['employerAddressStreetName']);
            updatedEmployerAddrCity.push(income['employerAddressCity']);
            updatedEmployerAddrProvince.push(income['employerAddressProvince']);
            updatedEmployerAddrPostalCode.push(income['employerAddressPostalCode']);
            updatedEmployerAddrUnit.push(income['employerAddressUnit']);
            updatedIndustry.push(income['industry']);
            updatedOccupation.push(income['occupation']);
            updatedServiceSince.push(income['serviceSince']);
            updatedPreviousEmployerName.push(income['previousEmployerName']);
            updatedPreviousIndustry.push(income['previousIndustry']);
            updatedPreviousOccupation.push(income['previousOccupation']);
            updatedPreviousServiceSince.push(income['previousEmployerServingSince']);
            totoalIncome += income['annualIncome'];
        }

        // Dispatch all updated incomes info to the global states
        dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value: updatedIncomeIDs }));
        dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: updatedIncomeBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'incomeType', value: updatedIncomeType }));
        dispatch(setApplicantInfo({ attribute: 'annualIncome', value: updatedAnnualIncome }));
        dispatch(setApplicantInfo({ attribute: 'employName', value: updatedEmployName }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: updatedEmployerAddrStreetNumber }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: updatedEmployerAddrStreetName }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: updatedEmployerAddrUnit }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: updatedEmployerAddrCity }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: updatedEmployerAddrProvince }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: updatedEmployerAddrPostalCode }));
        dispatch(setApplicantInfo({ attribute: 'industry', value: updatedIndustry }));
        dispatch(setApplicantInfo({ attribute: 'occupation', value: updatedOccupation }));
        dispatch(setApplicantInfo({ attribute: 'serviceSince', value: updatedServiceSince }));
        dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: updatedPreviousEmployerName }));
        dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: updatedPreviousIndustry }));
        dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: updatedPreviousOccupation }));
        dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: updatedPreviousServiceSince }));
    
        calculateIncome(totoalIncome);
        totoalIncome = 0;
    };

    const handleIncomeType = (value) => {
        // Clear the employer fields if the income type is not Employment or Self-Employment
        if (value !== 'Employment' && value !== 'Self-Employment') {
            setIncome({
                ...income, type: value, employerName: '', employerStreetNumber: '', employerStreetName: '', employerCity: '',
                employerProvince: '', employerPostalCode: '', industry: '', occupation: ''
            });
        }
        else {
            setIncome({ ...income, type: value });
        }
    };

    const handleIncomeServiceSince = (value) => {
        // Clear the previous employer fields if the service since date is equal to or greater than 24 months
        if (dayjs().diff(dayjs(value), 'month') >= workingMonthKey) {
            setIncome({ ...income, serviceSince: value, prevEmployerName: '', prevIndustry: '', prevOccupation: '', prevServiceSince: '' });
        }
        else {
            setIncome({ ...income, serviceSince: value });
        }
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        handleFetchIncomes();
        fetchIndustyJob();
        fetchIncomeType();
    }, []); 

    useEffect(() => {
        handleOccupationOption();
        handlepPreccupationOption();
    }, []); 

    const isManualInputAllowed = mainIncomeManualInputCurrCheck.includes(manualInputQuestion)
    return (
        <Box className="subsection-inco-box-overall">

            <Box>
                {/* <Table fieldNames={names} fieldValues={values} handleDelete={colIndex => handleDeleteIncome(colIndex)} /> */}
                <ModifyTable 
                    fieldNames={names} 
                    fieldValues={values} 
                    handleDelete={colIndex => handleDeleteIncome(colIndex)} 
                    handleEdit={colIndex => handleEditIncome(colIndex)}  
                    setRowIndex={setRowIndex}
                    disableEdit={false}  />
            </Box>

            <Box className="subsection-inco-box-field" bgcolor={color}>

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Owner" />
                        <DropdownList
                            options={owners}
                            defaultValue={income.owner}
                            setStateValue={value => setIncome({ ...income, owner: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Income Type" />
                        <DropdownList
                            options={incomeTypes}
                            defaultValue={income.type}
                            setStateValue={value => handleIncomeType(value)}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Annual Income" />
                        <NumberFieldMoney
                            defaultValue={income.annualAmount}
                            setStateValue={value => setIncome({ ...income, annualAmount: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                        <TextFieldText text="Employer Name" />
                        <TextField
                            defaultValue={income.employerName}
                            setStateValue={value => setIncome({ ...income, employerName: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                        <TextFieldText text='Current Employer Address' />
                        <AddressAutoComplete
                            setStateValue={value => handleAutoAddress(value)}
                            defaultValue={currAddress}
                            name='Current Employer Address'
                            mandatory={true}
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            isIncome= {true}
                            setIncome= {setIncome}
                            income={income}

                        />
                        
                    </Grid>
                    <Grid item xs={specialItemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                        <Checkbox
                            options={[manualInputQuestion]}
                            setStateValue={value => clearCurrAddrOnChkBox(value)}
                            defaultValue={mainIncomeManualInputCurrCheck}
                        />
                    </Grid>



                    {isManualInputAllowed && (
                         <Grid item xs={itemSpace}  style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment' ) ? 'block' : 'none' }} >
                            <TextFieldText text="Employer Street Number" />
                            <TextField
                                defaultValue={income.employerStreetNumber}
                                setStateValue={value => setIncome({ ...income, employerStreetNumber: value })}
                                isClear={isClear}
                                
                            />
                         </Grid>

                    )}

                    {isManualInputAllowed && (
                         <Grid item xs={itemSpace}  style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment' ) ? 'block' : 'none' }} >
                            <TextFieldText text="Employer Street Name" />
                            <TextField
                                defaultValue={income.employerStreetName}
                                setStateValue={value => setIncome({ ...income, employerStreetName: value })}
                                isClear={isClear}
                                
                            />
                         </Grid>

                    )}

                    {isManualInputAllowed && (
                         <Grid item xs={itemSpace}  style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment' ) ? 'block' : 'none' }} >
                            <TextFieldText text="Employer Address Unit" />
                            <TextField
                                defaultValue={income.employerAddrUnit}
                                setStateValue={value => setIncome({ ...income, employerAddrUnit: value })}
                                isClear={isClear}
                                
                            />
                         </Grid>

                    )}
            
                    
                    {isManualInputAllowed && (
                        <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                        <TextFieldText text="Employer City" />
                        <TextField
                            defaultValue={income.employerCity}
                            setStateValue={value => setIncome({ ...income, employerCity: value })}
                            isClear={isClear}
                        />
                    </Grid>
                    )}
               

                    {isManualInputAllowed && (
                         <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                         <TextFieldText text="Employer Province" />
                         <TextField
                             defaultValue={income.employerProvince}
                             setStateValue={value => setIncome({ ...income, employerProvince: value })}
                             isClear={isClear}
                         />
                     </Grid>
                    )}


                    
                
                    {isManualInputAllowed && (
                        <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                            <TextFieldText text="Employer Postal Code" />
                            <TextField
                                defaultValue={income.employerPostalCode}
                                setStateValue={value => setIncome({ ...income, employerPostalCode: value })}
                                isClear={isClear}
                                type={isManualInputAllowed ? 'postcode' : 'optional'}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>
                    )}
                   
                   
                   <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                            <TextFieldText text="Industry" />
                            <DropdownList
                                options={industrys}
                                defaultValue={income.industry}
                                setStateValue={value => {
                                    setIncome({ ...income, industry: value });
                                    handleOccupationOption(value);
                                  }}
                                isClear={isClear}
                            />
                    </Grid>
                   
                   <Grid item xs={itemSpace} style={{ display: (income.type === 'Employment' || income.type === 'Self-Employment') ? 'block' : 'none' }}>
                            <TextFieldText text="Occupation" />
                            <DropdownList
                                options={occupations}
                                defaultValue={income.occupation}
                                setStateValue={value => setIncome({ ...income, occupation: value })}
                                isClear={isClear}
                            />
                    </Grid>
                   
                   <Grid item xs={itemSpace}>
                            <TextFieldText text="Service Since" />
                            <DateField
                                defaultValue={income.serviceSince}
                                setStateValue={value => handleIncomeServiceSince(value)}
                                isClear={isClear}
                            />
                    </Grid>
                    

                    
                   <Grid item xs={itemSpace} style={{ display: income.serviceSince !== '' && dayjs().diff(dayjs(income.serviceSince), 'month') < workingMonthKey ? 'block' : 'none' }}>
                        <TextFieldText text="Previous Employer Name" />
                        <TextField
                            defaultValue={income.prevEmployerName}
                            setStateValue={value => setIncome({ ...income, prevEmployerName: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: income.serviceSince !== '' && dayjs().diff(dayjs(income.serviceSince), 'month') < workingMonthKey ? 'block' : 'none' }}>
                        <TextFieldText text="Previous Industry" />
                        <DropdownList
                            options={industrys}
                            defaultValue={income.prevIndustry}
                            setStateValue={value => {
                                setIncome({ ...income, prevIndustry: value });
                                handlepPreccupationOption(value);
                            }}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: income.serviceSince !== '' && dayjs().diff(dayjs(income.serviceSince), 'month') < workingMonthKey ? 'block' : 'none' }}>
                        <TextFieldText text="Previous Occupation" />
                        <DropdownList
                            options={preOccupations}
                            defaultValue={income.prevOccupation}
                            setStateValue={value => setIncome({ ...income, prevOccupation: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: income.serviceSince !== '' && dayjs().diff(dayjs(income.serviceSince), 'month') < workingMonthKey ? 'block' : 'none' }}>
                        <TextFieldText text="Previous Service Since" />
                        <DateField
                            defaultValue={income.prevServiceSince}
                            setStateValue={value => setIncome({ ...income, prevServiceSince: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    

                    <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                        
                        {!isUpdate && ( 
                            <IconButton onClick={handleAddIncomeCheck} >
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Income</span>
                            </IconButton>
                        )}
                        {isUpdate && ( 
                             <IconButton onClick={sendEditIncome} >
                             <AddBox sx={{ fontSize: 45 }} />
                             <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Income</span>
                            </IconButton>
                        )}
                       
                    </Grid>

                   

                    {/* <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                        <IconButton onClick={handleFetchIncomes} >
                            <SearchOutlined sx={{ fontSize: 45 }} />
                            <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Fetch Incomes</span>
                        </IconButton>
                    </Grid> */}

                </Grid>

            </Box>

            <Box className='mt-10 bg-mainfield-box rounded-2xl shadow-custom' >

                {['regular'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        {applicant.anyCoapplicant && (
                            <Grid item xs={tabSpace}>
                                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} className="ml-5 mr-5" >
                                    <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                                    <Tab style={{ fontSize: 20 }} label="Co-Applicant" value={1} />
                                </Tabs>
                            </Grid>
                        )}

                        <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Last year of NOA' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantUrlsLastYearNOA", value)}
                                defaultValue={applicant.applicantUrlsLastYearNOA}
                                type='mandatory'
                                name='Applicant NOA'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Last year of T1' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantUrlsLastYearT1", value)}
                                defaultValue={applicant.applicantUrlsLastYearT1}
                                type='mandatory'
                                name='Applicant T1'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Last 2 years of T4s' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantUrlsLast2YearsT4s", value)}
                                defaultValue={applicant.applicantUrlsLast2YearsT4s}
                                type='mandatory'
                                name='Applicant T4s'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Pay Stubs for the last 2 months' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantUrlsPayStubsLast2Months", value)}
                                defaultValue={applicant.applicantUrlsPayStubsLast2Months}
                                type='mandatory'
                                name='Applicant Pay Stubs'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                            <TextFieldText text='Investment Statement' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantUrlsInvestmentStatement", value)}
                                defaultValue={applicant.applicantUrlsInvestmentStatement}
                                type='mandatory'
                                name='Applicant Investment Statement'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Last year of NOA' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleCoapplicantChange("coApplicantUrlsLastYearNOA", value)}
                                defaultValue={coapplicant.coApplicantUrlsLastYearNOA}
                                type={hasCoApplicant ? 'mandatory' : 'optional'}
                                name='Co-Applicant NOA'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Last year of T1' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleCoapplicantChange("coApplicantUrlsLastYearT1", value)}
                                defaultValue={coapplicant.coApplicantUrlsLastYearT1}
                                type={hasCoApplicant ? 'mandatory' : 'optional'}
                                name='Co-Applicant T1'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Last 2 years of T4s' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleCoapplicantChange("coApplicantUrlsLast2YearsT4s", value)}
                                defaultValue={coapplicant.coApplicantUrlsLast2YearsT4s}
                                type={hasCoApplicant ? 'mandatory' : 'optional'}
                                name='Co-Applicant T4s'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Pay Stubs for the last 2 months' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleCoapplicantChange("coApplicantUrlsPayStubsLast2Months", value)}
                                defaultValue={coapplicant.coApplicantUrlsPayStubsLast2Months}
                                type={hasCoApplicant ? 'mandatory' : 'optional'}
                                name='Co-Applicant Pay Stubs'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                        <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                            <TextFieldText text='Investment Statement' largeBox={true} />
                            <DocUploader
                                setStateValue={value => handleCoapplicantChange("coApplicantUrlsInvestmentStatement", value)}
                                defaultValue={coapplicant.coApplicantUrlsInvestmentStatement}
                                type={hasCoApplicant ? 'mandatory' : 'optional'}
                                name='Co-Applicant Investment Statement'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Income'
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleErrorClose}>
                <Alert
                    onClose={handleErrorClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {isServiceStatusError ? "Current employment must be more than 3 months" :
                     isPrevServiceError ? "Total employment duration (current + previous) must be more than 2 years" : 
                     !postCodePattern.test(income.employerPostalCode) ? "Please fill valid postal code for Employer Postal Code":
                     "Please fill all required fields before adding"}
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}>
                <Alert
                    onClose={handleSuccessClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Added Income Successfully
                </Alert>
            </Snackbar>

        </Box>
    );
};