import { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { setAuthToken, clearAuthToken } from '../axios.js';

const useIdToken = () => {
    const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState(null);
    const [error, setError] = useState(null);

    const acquireToken = useCallback(() => {
        if (!instance) {
            return { error: new Error("MSAL instance not available") };
        }

        if (accounts.length === 0) {
            return { error: new Error("No active account") };
        }

        const currentAccount = accounts[0];
        if (!currentAccount.idToken) {
            return { error: new Error("ID token not available") };
        }

        return { idToken: currentAccount.idToken };
    }, [accounts, instance]);


    useEffect(() => {
        const { idToken, error } = acquireToken();
        if (idToken) {
            setIdToken(idToken);
            setError(null);
        }
        if (error) {
            setIdToken(null);
            setError(error);
        }
    }, [acquireToken]);

    useEffect(() => {
        if(error) {
            clearAuthToken();
        }

        if (idToken) {
            setAuthToken(idToken);
        } 
    }, [idToken, error]);

    return { idToken, error };
};
  

export default useIdToken;
