import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useApplication } from "../../../contexts/ApplicationContext";
import "./style/RadioGroupBoolean.css";


/**
 * @param {function} setStateValue - The function to be called when an option is selected
 * @param {boolean} defaultValue - The default value of the radio group
 * @param {boolean} disabled - The disabled state of the radio group
 * @param {string} type - The type of radio group boolean component
 * @param {string} name - The name of the radio group boolean component
 * @param {string} pageName - The name of the page the radio group boolean component is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @returns - A radio group boolean component that only allows user to select true of false 
 */


export default function RadioGroupBoolean({ setStateValue, defaultValue, disabled, type = 'optional', name = '', pageName = '', buttonCheck = false }) {

    const { handleErrorMessage } = useApplication();

    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [error, setError] = useState('');

    const handleRadioChange = (event) => {
        const userSelectedValue = event.target.value === "true";
        setSelectedValue(userSelectedValue);
        setStateValue(userSelectedValue);
        // Provide validation after the user has selected an option
        handleRadioCheck(userSelectedValue);
    };

    const handleRadioCheck = (selection) => {
        switch (type) {
            case 'mandatory':
                if (selection === null || selection === '') {
                    setError('mandatory');
                    handleErrorMessage(pageName, 'add', `Please select an option for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if (error === 'mandatory') {
            return 'Select Option Required';
        }
    };

    useEffect(() => {
        if (defaultValue !== selectedValue) {
            setSelectedValue(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleRadioCheck(defaultValue);
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className="radioboolean-box">
            <FormControl>
                <RadioGroup value={selectedValue === null ? '' : selectedValue.toString()} onChange={handleRadioChange}>
                    <FormControlLabel  value="true"
                        control={<Radio />}
                        label="YES"
                        disabled={disabled}
                    />
                    <FormControlLabel value="false"
                        control={<Radio />}
                        label="NO"
                        disabled={disabled}
                    />
                </RadioGroup>
            </FormControl>
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'left', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};