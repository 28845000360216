import { useDispatch } from 'react-redux';
import { setApplicantInfo } from "../reducers/applicantReducer";
import { setCoapplicantInfo } from "../reducers/coapplicantReducer";
import { useApplication } from '../contexts/ApplicationContext';

export function useAddressAutoFailInfo(){
    
    const dispatch = useDispatch();
    const {setMainManualInputCurrCheck,setMainIncomeManualInputCurrCheck, setCoManualInputCurrCheck, setMainManualInputPrevCheck, setCoManualInputPrevCheck, setCantFindPrevAddr, setCantFindCurrAddr} = useApplication();

    const handleFail = (type, applicantType, number, name, city, province, code, isIncome, setIncome, income) =>{
        if(isIncome){
            setMainIncomeManualInputCurrCheck(["Can't find your address?"]);
            setIncome({
                ...income, employerStreetNumber:number, employerStreetName: name, employerCity: city,
                employerProvince: province, employerPostalCode: code
            });

        }else{
            if(type ==='curr'){
                if(applicantType === 'Applicant'){
                    setCantFindCurrAddr(true);
                    dispatch(setApplicantInfo({attribute: 'streetNumber', value: number}));
                    dispatch(setApplicantInfo({attribute: 'streetName', value: name}));
                    dispatch(setApplicantInfo({attribute: 'city', value: city}));
                    dispatch(setApplicantInfo({attribute: 'province', value: province}));
                    dispatch(setApplicantInfo({attribute: 'postalCode', value: code}));
                } else if(applicantType ==='Co-Applicant'){
                    setCoManualInputCurrCheck(["Can't find your address?"]);
                    dispatch(setCoapplicantInfo({attribute: 'streetNumber', value: number}));
                    dispatch(setCoapplicantInfo({attribute: 'streetName', value: name}));
                    dispatch(setCoapplicantInfo({attribute: 'city', value: city}));
                    dispatch(setCoapplicantInfo({attribute: 'province', value: province}));
                    dispatch(setCoapplicantInfo({attribute: 'postalCode', value: code}));
                }
        } else if(type ==='prev'){
            if(applicantType === 'Applicant'){
                setCantFindPrevAddr(true);
                dispatch(setApplicantInfo({attribute: 'prevStreetNumber', value: number}));
                dispatch(setApplicantInfo({attribute: 'prevStreetName', value: name}));
                dispatch(setApplicantInfo({attribute: 'prevCity', value: city}));
                dispatch(setApplicantInfo({attribute: 'prevProvince', value: province}));
                dispatch(setApplicantInfo({attribute: 'prevPostalCode', value: code}));
            } else if(applicantType === 'Co-Applicant'){
                setCoManualInputPrevCheck(["Can't find your address?"]);
                dispatch(setCoapplicantInfo({attribute: 'prevStreetNumber', value: number}));
                dispatch(setCoapplicantInfo({attribute: 'prevStreetName', value: name}));
                dispatch(setCoapplicantInfo({attribute: 'prevCity', value: city}));
                dispatch(setCoapplicantInfo({attribute: 'prevProvince', value: province}));
                dispatch(setCoapplicantInfo({attribute: 'prevPostalCode', value: code}));
            }
        }
        }
        
    }

    return {handleFail};
}