
export const twoPhoneNumbersVerified = (attribute, value, applicant) => {
    let cellPhone = applicant.cellPhone === "" ? '1' : applicant.cellPhone;
    let workPhone = applicant.workPhone === "" ? '1' : applicant.workPhone;
    let homePhone = applicant.homePhone === "" ? '1' : applicant.homePhone;
    //console.log(applicant);
    if(attribute === 'homePhone'){
        homePhone = value; 
    } else if (attribute === 'cellPhone'){
        cellPhone = value;
    } else if (attribute === 'workPhone'){
        workPhone = value;
    }
    if (
        (homePhone === '1' && cellPhone !== workPhone) ||
        (cellPhone === '1' && workPhone !== homePhone) ||
        (workPhone === '1'  && cellPhone !== homePhone)
       ) {
        return true;
       }else if(homePhone !== '1' && cellPhone !== '1' && workPhone !== '1'){
            if(cellPhone !== workPhone || workPhone !== homePhone || cellPhone !== homePhone){
                return true;
            }else {
                return false;
            }
       }
      
    
}
