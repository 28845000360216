import React, { useEffect, useState } from "react";
import TextFieldText from "../text/textFieldText/TextFieldText";
import DateField from "../dateField/DateField";
import { Button, Grid, TextField } from "@mui/material";
import { addComment, getComments, updateComment, deleteComment } from "../../../services/caseCommentService";
import ModifyTable from "../../mediumComponents/table/ModifyTable";
import { USER_ROLE } from "../../../constants/UserRoleMap";

/**
 * 
 * @param {boolean} isOpen - For displaying comment block
 * @param {boolean} isOpen - For closing the comment block
 * @returns CommentBlock component as part of the additionalTableItem for adding and displaying comments
 */

const CommentBlock = ({caseID, isOpen, onclose, userInfo }) =>{

    const [date, setDate] = useState(new Date());
    const [comment, setComment] = useState("");
    const commentName = ['Index', 'Comment', 'Event Date', 'User Name', 'Timestamp'];
    const fieldKeys = ['index', 'commentDescription', 'eventDate', 'userName', 'timeStamp'];
    const [allComments, setAllComments] = useState([]);
    const [transformedComments, setTransformedComments] = useState([]);
    const [commentIDBeingEdited, setCommentIDBeingEdited] = useState(null);
    const disableEdit = userInfo.userInfos.every(({userType}) => userType !== USER_ROLE.OPERATOR);

    const fetchComments = async ()=>{
        try {
            const comments = await getComments(caseID);
            let res = comments.comments
                        .map(({commentID, commentDescription, eventDate, userName, timeStamp}) => 
                        ({commentID, commentDescription, eventDate, userName, timeStamp}))
            setAllComments(res); 
            setTransformedComments(res.map((comment, index) => ({
                ...comment,
                index: index + 1, // Add 1-based index
            })))
        } catch (error) {
            console.error(`Fetch Comments error: ${error}`);
        }
    } 

    //Fetch comments
    useEffect(()=>{
        fetchComments();
    },[]);

    const handleAddComment = async () => {
        if (!comment.trim()) {
            return;
        }
        const req = {
            'caseID': caseID,
            'commentDescription': comment,
            'eventDate': date,
            'userName': userInfo.userName,
            'userID': userInfo.userID
        }
        if(commentIDBeingEdited){
            const updatedComment = await updateComment(commentIDBeingEdited, {...req, commentID: commentIDBeingEdited});
            req.commentID = commentIDBeingEdited;
            setAllComments(prev => [...prev, updatedComment]);
        } else{
            const addedComment = await addComment(req);
            setCommentIDBeingEdited(null);
            setAllComments(prev => [...prev, addedComment]);
        }
        setComment('');
    }

    useEffect (()=> {
        setTransformedComments(
            allComments
                .sort((a, b) => new Date(a.timeStamp) - new Date(b.timeStamp))
                .map((comment, index) => ({
                    ...comment,
                    index: index + 1, // Add 1-based index
        })))
    }, [allComments]);

    const handleEdit = (info) => {
        setComment(info.commentDescription);
        setDate(info.eventDate);
        const comment = transformedComments.find(comment => comment.index === info.index); 
        setCommentIDBeingEdited(comment.commentID);
        setAllComments(comments => comments.filter(comment => comment.commentID != info.commentID))
    }

    const handleDelete = (info) => {
        const comment = transformedComments.find(comment => comment.index === info.index);
        deleteComment(comment.commentID);
        setAllComments(comments => comments.filter(comment => comment.commentID != info.commentID))
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
    }

    return(
        isOpen && (<div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-opacity-50 bg-gray-900 flex justify-center items-center">
            <div className="bg-white rounded-lg px-6 z-50 mx-auto max-w-screen-xl">
                <div className="text-center m-4">
                    <h1 className="text-start my-10 font-bold text-3xl text-sky-600">Case Comments</h1>
                    {/* comment and date */}
                    { userInfo.userInfos.some(({userType}) => userType === USER_ROLE.OPERATOR) && (
                        <Grid container >
                            <Grid item>
                                <div className="flex flex-row">
                                    <TextFieldText text="Event Date:"/>
                                    <DateField 
                                        defaultValue={date}
                                        setStateValue={handleDateChange}
                                        type='date'/>
                                </div>
                            </Grid>
                            <Grid item>
                                <div className="flex flex-row w-full justify-end">
                                    <TextFieldText text="Comment:" />
                                    <TextField 
                                        label="Type Comment" 
                                        variant="outlined" 
                                        sx={{ width: '500px' }}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)} />
                                    <Button 
                                        variant="contained" 
                                        sx={{ height: '38px', ml: 2, mt: 1}}
                                        onClick={handleAddComment}>
                                            Add
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    )}
                    {/* comments list */}
                    {transformedComments 
                        && <ModifyTable 
                                disableEdit={disableEdit}
                                editKeyName={'commentDescription'}
                                displayHorizonally={true}
                                fieldNames={commentName} 
                                fieldKeys={fieldKeys}
                                fieldValues={transformedComments}
                                handleEdit={handleEdit}
                                handleDelete={colIndex => handleDelete(colIndex)}
                                />}
                    <div className="my-8">
                        <Button variant="outlined" onClick={() => {onclose()}}>Close</Button>
                    </div>
                </div>
            </div>
        </div>)

    );
}

export default CommentBlock;