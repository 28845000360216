/**
 * Renders the BeforeApp component.
 * 
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.typeBeforeApp - The type of the BeforeApp component.
 * @returns {JSX.Element} The rendered BeforeApp component.
 */
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './BeforeApp.css';
import { useDispatch, useSelector } from 'react-redux';
import TextFieldText from '../../../components/smallComponents/text/textFieldText/TextFieldText';
import { setApplicationInfo } from '../../../reducers/applicationReducer';
import TextField from "../../../components/smallComponents/textField/TextField";
import { getDropdownList } from '../../../services/dropdownService';
import { useState } from 'react';

const BeforeApp = ({ typeBeforeApp }) => {
    const { setSelectedComponent } = useApplication();
    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicationInfo);
    // change the applicant properties
    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicationInfo({ attribute, value }));
    };
    const [advisors, setAdvisors] = useState([]);
    const fetchAdvisors = async ()=> {
        const advisors = await getDropdownList("advisors");
        console.log(advisors);
        setAdvisors(advisors.map(x=>x.advisorCode.toUpperCase()));
    };
    useEffect(()=>{
        fetchAdvisors();
    }, []);
    const [advisorFound, setAdvisorFound] = useState(true);

    //reset the applicant properties accordingly when user select back
    const handleApplicationChangeBack = (back) => {
        handleApplicantChange('fundType', '');
        handleApplicantChange('requestType', '');
        handleApplicantChange('loanType', '');
        handleApplicantChange('investmentAccountType', '');
        setSelectedComponent(back)
    }
    const handleAdvisorCodeChange = (value) => {
        if(!value){
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', true);
            handleApplicantChange('servingAdvisorCode', '');
        } else if (advisors.includes(value.toUpperCase())){
            setAdvisorFound(true);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('servingAdvisorCode', value.toUpperCase());
        } else {
            setAdvisorFound(false);
            handleApplicantChange('isSystemAssignAdvisor', false);
            handleApplicantChange('servingAdvisorCode', value.toUpperCase());
        }
    }

    useEffect(() => {
        // update the applicant properties accordingly based on the previous page user visited
        switch (typeBeforeApp) {
            case 'TFSA':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Own Fund' }));
                dispatch(setApplicationInfo({ attribute: 'investmentAccountType', value: 'TFSA' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                break;
            case 'RESP':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Own Fund' }));
                dispatch(setApplicationInfo({ attribute: 'investmentAccountType', value: 'RESP' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                break;
            case 'RRSP':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Own Fund' }));
                dispatch(setApplicationInfo({ attribute: 'investmentAccountType', value: 'RRSP' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                break;
            case 'NonReg':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Own Fund' }));
                dispatch(setApplicationInfo({ attribute: 'investmentAccountType', value: 'Non-Reg' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                break;
            case 'LoanTransfer':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Bank Loan' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Transfer' }));
                dispatch(setApplicationInfo({ attribute: 'loanType', value: 'Transfer' }));
                break;
            case 'QuickNext':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Bank Loan' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                dispatch(setApplicationInfo({ attribute: 'loanType', value: 'Quick' }));
                dispatch(setApplicationInfo({ attribute: 'investmentAccountType', value: 'Non-Reg' }));
                break;
            case 'Regular':
                dispatch(setApplicationInfo({ attribute: 'fundType', value: 'Bank Loan' }));
                dispatch(setApplicationInfo({ attribute: 'requestType', value: 'New Case' }));
                dispatch(setApplicationInfo({ attribute: 'loanType', value: 'Regular' }));
                break;
            default:
                break;
        }
    }, [typeBeforeApp, dispatch]);
    
    const backNavigationMap = {
        'TFSA': 'OwnFund',
        'RESP': 'OwnFund',
        'RRSP': 'OwnFund',
        'NonReg': 'OwnFund',
        'LoanTransfer': 'LoanTransfer',
        'QuickNext': 'Quick',
        'Regular': 'Loan',
    };

    const backNavigation = backNavigationMap[typeBeforeApp] || 'Home';
    const nextNavigation = typeBeforeApp ? `${typeBeforeApp}Next` : 'Home';

    return (
        <Box>
            <HeaderBox text='Do you have a preferred advisor?' />
            <div className="textPageBeforeApp">
                <div>
                    <TextField 
                        label="Preferred Advisor"
                        type={applicant.servingAdvisorCode.length > 0? '7chars': 'optional'}
                        name='Preferred Advisor'
                        defaultValue={applicant.servingAdvisorCode || ""}
                        buttonCheck={true}
                        err={advisorFound? "": "Advisor not found"}
                        setStateValue={value => handleAdvisorCodeChange(value)}
                        largeBox={true}
                        requiredProp={false}
                    />
                </div>

            </div>
            <div className='button-AreaBefore'>
                        <NavLink onClick={() => handleApplicationChangeBack(backNavigation)} >
                            <button className="bigButton">Back</button>
                        </NavLink>
                        {advisorFound && <NavLink onClick={() => setSelectedComponent(nextNavigation)} >
                            <button className="bigButton">Next</button>
                        </NavLink>}
                    </div>
        </Box>
    );
};

export default BeforeApp;