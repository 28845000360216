import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useParams } from "react-router-dom";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import PhoneField from "../../../../components/smallComponents/phoneField/PhoneField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import Gender from "../../../../components/enumTypes/Gender";
import { languages, annuitantRelation } from "../../../../constants/lists";
import { useEffect, useState } from "react";
import './style/Annuitant.css';
import { fetchApplicationsAnnuitant } from '../../../../services/fetchInfoService';
/**
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.relationAnnuitant - The options for the relation to annuitant dropdown list.
 * @param {string} props.beneficiaryType - The type of beneficiary.
 * @returns {JSX.Element} The primary beneficiary component.
 */


export default function Annuitant() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const { clickNextButton } = useApplication();
    const [isClear, setIsClear] = useState(false);
    const handleApplicantHasAnnuitantChange = (attribute, value) => {
        // Clear annuitant information if user selects no annuitant
        if (!value) {
            dispatch(setApplicantInfo({ attribute: 'annuitantLanguagePreference', value: "English" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantFirstName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantLastName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantDateOfBirth', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantGender', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantSIN', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'annuitantHomePhone', value: "" }))
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantHasSuccessorAnnuitantChange = (attribute, value) => {
        // Clear successor annuitant information if user selects no successor annuitant
        if (!value) {
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantLanguagePreference', value: "English" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantFirstName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantLastName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantDateOfBirth', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantGender', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantSIN', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'successorAnnuitantHomePhone', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'relationToAnnuitant', value: "" }))
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const sinRequirement = isLoanType ? 'sinforloan' : 'sin';

    const itemSpace = 3;

    const title = "Successor Annuitant";
    const genders = [Gender.MALE, Gender.FEMALE];
    const pageName = 'annuitant';

    const hasAnnuitant = applicant.isApplicantOtherThanAnnuitant;
    const conditionalDisplayAnnuitant = hasAnnuitant ? 'block' : 'none';

    const hasSuccessorAnnuitant = applicant.designatedSuccessorAnnuitant;
    const conditionalDisplaySuccessorAnnuitant = hasSuccessorAnnuitant ? 'block' : 'none';

    const fetchApplcantInfoAnnuitant = async ()=>{
    
        const applicantInfo = await fetchApplicationsAnnuitant(application.applicationID);
        if(applicantInfo !== undefined){
            if(applicantInfo.firstName !== null){
                dispatch(setApplicantInfo({ attribute: 'designatedSuccessorAnnuitant', value: true }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantFirstName', value: applicantInfo.firstName }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantLastName', value: applicantInfo.lastName }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantDateOfBirth', value: applicantInfo.dateOfBirth }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantGender', value: applicantInfo.gender }));          
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantSIN', value: applicantInfo.sin }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantHomePhone', value: applicantInfo.homePhone }));
                dispatch(setApplicantInfo({ attribute: 'relationToAnnuitant', value: applicantInfo.relationToAnnuitant }));

            }
           
        }
      
    }

    useEffect(() => {
        fetchApplcantInfoAnnuitant();
    }, []);


    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 annuitant-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            {/* Annuitant */}

            <Box className='bg-mainfield-box rounded-2xl shadow-custom'>

                {applicationType === 'nonreg' && (
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace * 4} >
                            <TextFieldText text='The Applicant is other than the Annuitant?' largeBox={true} />
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantHasAnnuitantChange("isApplicantOtherThanAnnuitant", value)}
                                defaultValue={applicant.isApplicantOtherThanAnnuitant}
                                type='mandatory'
                                name='Other Than Annuitant'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='First Name' />
                            <TextField
                                setStateValue={value => handleApplicantChange("annuitantFirstName", value)}
                                defaultValue={applicant.annuitantFirstName}
                                type={hasAnnuitant ? 'name' : 'optional'}
                                name='Annuitant First Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Last Name' />
                            <TextField
                                setStateValue={value => handleApplicantChange("annuitantLastName", value)}
                                defaultValue={applicant.annuitantLastName}
                                type={hasAnnuitant ? 'name' : 'optional'}
                                name='Annuitant Last Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Date of Birth' />
                            <DateField
                                setStateValue={value => handleApplicantChange("annuitantDateOfBirth", value)}
                                defaultValue={applicant.annuitantDateOfBirth}
                                type={hasAnnuitant ? 'date' : 'optional'}
                                name='Annuitant DOB'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Gender' />
                            <RadioGroup
                                options={genders}
                                setStateValue={value => handleApplicantChange("annuitantGender", value)}
                                defaultValue={applicant.annuitantGender}
                                type={hasAnnuitant ? 'mandatory' : 'optional'}
                                name='Annuitant Gender'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Social Insurance Number (SIN)' />
                            <TextField
                                setStateValue={value => handleApplicantChange("annuitantSIN", value)}
                                defaultValue={applicant.annuitantSIN}
                                type={hasAnnuitant ? 'sin' : 'optional'}
                                name='Annuitant SIN'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Language Preference' />
                            <DropdownList
                                options={languages}
                                setStateValue={value => handleApplicantChange("annuitantLanguagePreference", value)}
                                defaultValue={applicant.annuitantLanguagePreference}
                                type={hasAnnuitant ? 'mandatory' : 'optional'}
                                name='Annuitant Language Preference'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={conditionalDisplayAnnuitant} >
                            <TextFieldText text='Home Phone' />
                            <PhoneField
                                setStateValue={value => handleApplicantChange("annuitantHomePhone", value)}
                                defaultValue={applicant.annuitantHomePhone}
                                type={hasAnnuitant ? 'phone' : 'optional'}
                                name='Annuitant Home Phone'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

            {/* Successor Annuitant */}

            <Box className='mt-4 bg-mainfield-box rounded-2xl shadow-custom'>

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace * 4} >
                        <TextFieldText text='Do you want to designate a Successor Annuitant?' largeBox={true} />
                        <RadioGroupBoolean
                            setStateValue={value => handleApplicantHasSuccessorAnnuitantChange("designatedSuccessorAnnuitant", value)}
                            defaultValue={applicant.designatedSuccessorAnnuitant}
                            type='mandatory'
                            name='Has Successor Annuitant'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='First Name' />
                        <TextField
                            setStateValue={value => handleApplicantChange("successorAnnuitantFirstName", value)}
                            defaultValue={applicant.successorAnnuitantFirstName}
                            type={hasSuccessorAnnuitant ? 'name' : 'optional'}
                            name='Successor Annuitant First Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Last Name' />
                        <TextField
                            setStateValue={value => handleApplicantChange("successorAnnuitantLastName", value)}
                            defaultValue={applicant.successorAnnuitantLastName}
                            type={hasSuccessorAnnuitant ? 'name' : 'optional'}
                            name='Successor Annuitant Last Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Date of Birth' />
                        <DateField
                            setStateValue={value => handleApplicantChange("successorAnnuitantDateOfBirth", value)}
                            defaultValue={applicant.successorAnnuitantDateOfBirth}
                            type={hasSuccessorAnnuitant ? 'date' : 'optional'}
                            name='Successor Annuitant DOB'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Gender' />
                        <RadioGroup
                            options={genders}
                            setStateValue={value => handleApplicantChange("successorAnnuitantGender", value)}
                            defaultValue={applicant.successorAnnuitantGender}
                            type={hasSuccessorAnnuitant ? 'mandatory' : 'optional'}
                            name='Successor Annuitant Gender'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Social Insurance Number (SIN)' />
                        <TextField
                            setStateValue={value => handleApplicantChange("successorAnnuitantSIN", value)}
                            defaultValue={applicant.successorAnnuitantSIN}
                            type={hasSuccessorAnnuitant ? 'mandatory' : 'optional'}
                            name='Successor Annuitant SIN'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Language Preference' />
                        <DropdownList
                            options={languages}
                            setStateValue={value => handleApplicantChange("successorAnnuitantLanguagePreference", value)}
                            defaultValue={applicant.successorAnnuitantLanguagePreference}
                            type={hasSuccessorAnnuitant ? 'mandatory' : 'optional'}
                            name='Successor Annuitant Language Preference'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Home Phone' />
                        <PhoneField
                            setStateValue={value => handleApplicantChange("successorAnnuitantHomePhone", value)}
                            defaultValue={applicant.successorAnnuitantHomePhone}
                            type={hasSuccessorAnnuitant ? 'phone' : 'optional'}
                            name='Successor Annuitant Home Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} display={conditionalDisplaySuccessorAnnuitant} >
                        <TextFieldText text='Relation to Successor Annuitant' />
                        {/* <TextField
                            setStateValue={value => handleApplicantChange("relationToAnnuitant", value)}
                            defaultValue={applicant.relationToAnnuitant}
                            type={hasSuccessorAnnuitant ? 'mandatory' : 'optional'}
                            name='Applicant Relation to Successor Annuitant'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        /> */}
             
                       <DropdownList
                            options={annuitantRelation}
                            setStateValue={value => handleApplicantChange("relationToAnnuitant", value)}
                            defaultValue={applicant.relationToAnnuitant}
                            isClear={isClear}
                            type={hasSuccessorAnnuitant ? 'mandatory' : 'optional'}
                            name='Applicant Relation to Successor Annuitant'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>
            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>

        </Box>
    );
};