import { Box, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { useApplication } from '../../../contexts/ApplicationContext';
import { useParams } from 'react-router-dom';
import './style/TextField.css';
import { isTempSIN, isValidSIN } from '../../../utilities/sinNumVerification';

/**
 * @param {function} setStateValue - A function that sets the state value
 * @param {string} defaultValue - The default value of the text field
 * @param {boolean} disabled - A boolean value that determines if the text field is disabled
 * @param {boolean} isClear - A boolean value that determines if the text field needs to be cleared
 * @param {string} type - The type of validation to be performed on the text field with default value as 'optional'
 * @param {string} name - The name of the text field
 * @param {string} pageName - The name of the page where the text field is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @param {boolean} largeBox - A boolean value that determines if the text field is large
 * @param {boolean} longBox - A boolean value that determines if the text field is longer than usual
 * @returns - A text field component
 */


export default function TextFieldComponent({ setStateValue, defaultValue, disabled, isClear = false, type = 'optional', name = '', pageName = '', buttonCheck = false, largeBox = false, longBox = false, err='', label = "", requiredProp = undefined, placeholder = "" }) {

    const { errorMessages,  handleErrorMessage } = useApplication();

    const [inputValue, setInputValue] = useState(defaultValue);
    const [error, setError] = useState('');

    const applicationType = useParams().applicationType;

    const handleTextFieldChange = (event) => {
        const userTypedValue = event.target.value
        setInputValue(userTypedValue);
        setStateValue(userTypedValue);
        handleTextFieldCheck();
    };

    // Provied validation when the text field is blurred
    const handleTextFieldCheck = () => {

        if(err){
            setError(err);
        } else{
            setError("");
        }

        const namePattern = /^[A-Za-z\s\-']+$/;
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const threeDigitPattern = /^[0-9]{3}$/;
        const fiveDigitPattern = /^[0-9]{5}$/;
        const sevenLetterPattern = /^[A-Za-z]{7}$/;
        const numberPattern = /^[0-9]+$/;
        const postCodePattern = /^[A-Za-z]\d[A-Za-z][ ]\d[A-Za-z]\d$/;

        switch (type) {
            case 'postcode':
                if(!postCodePattern.test(defaultValue)){
                    setError('postCode');
                    handleErrorMessage(pageName, 'add', `Please validate postal code format for ${name}`);
                }
                else{
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate postal code format for ${name}`);
                }
                break; 
            case 'mandatory': 
                if (!defaultValue || defaultValue.trim() === '') { 
                    setError('mandatory');
                    handleErrorMessage(pageName, 'add', `${name} is mandatory`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                }
                break;
            case 'name':
                if (!namePattern.test(defaultValue)) {
                    setError('name');
                    handleErrorMessage(pageName, 'add', `Please validate name format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate name format for ${name}`);
                }
                break;
            case 'email':
                if (!emailPattern.test(defaultValue)) {
                    setError('email');
                    handleErrorMessage(pageName, 'add', `Please validate email format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate email format for ${name}`);
                }
                break;
            case 'sinforloan':
                if (['regular', 'quick'].includes(applicationType) && isTempSIN(defaultValue)) {
                        setError('tempSin');
                        handleErrorMessage(pageName, 'remove', `Please validate SIN format for ${name}`);
                        handleErrorMessage(pageName, 'add', `Your SIN is not eligible for investment loan. Please check ${name}`);
                }
                else if (!isValidSIN(defaultValue)) {
                    setError('sin');
                    handleErrorMessage(pageName, 'remove', `Your SIN is not eligible for investment loan. Please check ${name}`);
                    handleErrorMessage(pageName, 'add', `Please validate SIN format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Your SIN is not eligible for investment loan. Please check ${name}`);
                    handleErrorMessage(pageName, 'remove', `Please validate SIN format for ${name}`);
                }
                break;
            case 'sin':
                if (!isValidSIN(defaultValue)) {
                    setError('sin');
                    handleErrorMessage(pageName, 'add', `Please validate SIN format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate SIN format for ${name}`);
                }
                break;
            case '3digit':
                if (!threeDigitPattern.test(defaultValue)) {
                    setError('3digit');
                    handleErrorMessage(pageName, 'add', `Please validate 3 digit format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate 3 digit format for ${name}`);
                }
                break;
            case '5digit':
                if (!fiveDigitPattern.test(defaultValue)) {
                    setError('5digit');
                    handleErrorMessage(pageName, 'add', `Please validate 5 digit format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate 5 digit format for ${name}`);
                }
                break;
            case '7chars': 
                if(!sevenLetterPattern.test(defaultValue)){
                    setError('7chars');
                }
                break;
            case 'digits':
                if (!numberPattern.test(defaultValue)) {
                    setError('digits');
                    handleErrorMessage(pageName, 'add', `Please validate digits format for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate digits format for ${name}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                handleErrorMessage(pageName, 'remove', `Please validate name format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate email format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Your SIN is not eligible for investment loan. Please check ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate SIN format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate 3 digit format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate 5 digit format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate digits format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate postal code format for ${name}`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if(error === 'postCode'){
            return 'Valid postal code required';
        }
        else if (error === 'mandatory') {
            return 'Input Required';
        }
        else if (error === 'name') {
            return 'Valid Name Required';
        }
        else if (error === 'email') {
            return 'Valid Email Required';
        }
        else if (error === 'tempSin') {
            return 'Ineligible SIN';
        }
        else if(error === 'invalidSin'){
            return 'SIN number invalid';
        }
        else if (error === 'sin') {
            return 'Valid SIN Required';
        }
        else if (error === '3digit') {
            return '3 digits Required';
        }
        else if (error === '5digit') {
            return '5 digits Required';
        }
        else if (error === '7chars'){
            return 'Valid format required';
        }
        else if (error === 'digits') {
            return 'Valid digits Required';
        }
        else if(error === 'Advisor not found'){
            return 'Advisor not found';
        }
    };

    useEffect(() => {
        if (isClear) {
            setInputValue('');
        }
    }, [isClear]);

    useEffect(() => {
        if (defaultValue !== inputValue) {
            setInputValue(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleTextFieldCheck();
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
            handleErrorMessage(pageName, 'remove', `Please validate name format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate email format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Your SIN is not eligible for investment loan. Please check ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate SIN format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate 3 digit format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate 5 digit format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate digits format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate postal code format for ${name}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    const boxStyle = largeBox ?
        'max-w-[480px] w-[20vw] min-h-[70px] bg-transparent mt-[5px] mb-[30px] mx-[25px]' :
        longBox ?
            'max-w-[1100px] w-[60vw] min-h-[70px] bg-transparent mt-[5px] mb-[30px] mx-[25px]' :
            'max-w-[240px] w-[10vw] min-h-[70px] bg-transparent mt-[5px] mb-[30px] mx-[25px]';

    const textFieldStyle = largeBox ?
        'max-w-[460px] w-[20vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded' :
        longBox ?
            'max-w-[1100px] w-[60vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded':
            'max-w-[230px] w-[10vw] min-h-[54px] mt-[6px] ml-[2px]' + ' bg-white rounded';

    return (
        <Box className={boxStyle}>
            <TextField className={textFieldStyle} variant="outlined"  inputProps={{ style: { fontSize: '22px' } }} label={label} margin="dense"
                value={inputValue}
                onChange={handleTextFieldChange}
                onBlur={handleTextFieldCheck}
                disabled={disabled}
                type={type}
                required={requiredProp === undefined? type !== 'optional': requiredProp}
                error={error !== ''}
                placeholder={placeholder}
            />
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};