import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, TextField } from "@mui/material";
import { setApplicationInfo } from "../../../../reducers/applicationReducer";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import TextFieldComponent from "../../../../components/smallComponents/textField/TextField";
import dayjs from "dayjs";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { getDropdownList } from "../../../../services/dropdownService";
import { fetchFELRate } from '../../../../services/fetchInfoService';
import { useParams } from "react-router-dom";
/**
 * @description - This component is used for inputing/adding loan and fund related info by Operators
 * @param {object} investFund - local state object to store investment fund and loan info
 * @param {function} setInvestFund - for setting the investFund
 * @param {string} pageName - The name of the page the doc uploader is located
 * @param {boolean} isClear - State to trigger the clearing of the input fields in child components
 * @param {function} firstLoanAndFund - To ensure the first 'Bank & Investment' Info is mandatory
 * @returns - OperatorSection component as the input area for BankInvestment subsection
 */

// Custom hook to track the previous value of a state variable
const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
};

const OperatorSection = ({ investFund, setInvestFund, pageName, isClear, firstLoanAndFund, portfolioOpt, fundType, portfoliosMap }) => {
    const { applicationId } = useParams();
    const dispatch = useDispatch();
    const { clickNextButton } = useApplication();
    const [hasFetched, setHasFetched] = useState(false);
    const additionalInfo = useSelector((state) => state.additionalInfo);
    const application = useSelector((state) => state.applicationInfo);

    const previousCaseStatus = usePrevious(additionalInfo.caseStatus);
    
    const salesChargeOpt = ["FEL", "CB5", "CB4", "CB3", "CB2"];
    //const portfolioOpt = ["test1", "test2"];
    const portfolioOptList = portfolioOpt;
    // const fetchPortfoliolist= async ()=>{

    //     fetchPortfolio(investFund.investFund.loanBanks, investFund.salesCharge)
    //             .then((portfolios) => {
    //                 setPortfolios(portfolios);
    //             })
    // }

    //const caseStatusOpt = ["\u00A0", "0 Operation Approved", "1 Form Generated"]
    // Set the application approvedDate once the first case gets the "0 Operation Approved" status.
    useEffect(() => {
        //set first approve date
        if (previousCaseStatus && additionalInfo.caseStatus.length > previousCaseStatus.length) {
            const newStatus = additionalInfo.caseStatus[additionalInfo.caseStatus.length - 1];
            if (newStatus === "0 Operation Approved" && application.approvedDate === "") {
                dispatch(setApplicationInfo({ attribute: 'approvedDate', value: dayjs().format('YYYY-MM-DD') }));
            }
        }

        //update applicationStatus to be approved when all cases are approved
        const allApproved = additionalInfo.caseStatus.every(status => status === "0 Operation Approved");
        if (allApproved) {
            dispatch(setApplicationInfo({ attribute: 'applicationStatus', value: "Operation Approved" }));
        }
    }, [additionalInfo.caseStatus]);
    
    const fetchFelRate= async ()=>{
        const felRate = await fetchFELRate(applicationId,investFund.salesCharge, investFund.appliedAmount, fundType) || [];
        const felRateUpdate = felRate;
        setInvestFund({ ...investFund, felRate: felRateUpdate})
        setHasFetched((prevValue) => !prevValue);
    }

    useEffect(() => {

    }, [investFund.felRate]);

    useEffect(() => {
        if (hasFetched) return;
        if (investFund.salesCharge !== null && investFund.appliedAmount !==  "") {
            fetchFelRate();
        }
    }, [investFund.appliedAmount, hasFetched]);


    useEffect(() => {
        setHasFetched((prevValue) => !prevValue);
    }, [investFund.appliedAmount]);

     // Update contributionAmount based on the calculation of completedAmount and felFee
     useEffect(() => {
        const calculatedContributionAmount = isNaN(investFund.completedAmount - investFund.felFee)
            ? 0
            : investFund.completedAmount - investFund.felFee;

            setInvestFund(prevState => ({
                ...prevState,
                contributionAmount: calculatedContributionAmount
            }));        
    }, [investFund.completedAmount, investFund.felFee]);

    useEffect(() => {
        const calculateFelFee = () => {
            // Replace this with your calculation logic
            const calculatedFee = investFund.felRate * investFund.completedAmount; // example calculation
            setInvestFund(prevState => ({ ...prevState, felFee: calculatedFee }));
        };

        calculateFelFee();
    }, [investFund.felRate, investFund.completedAmount]);

    const handleChangeContribution = (event) => {
        const value = event.target.value;
        setInvestFund(prevState => ({
            ...prevState,
            contributionAmount: value
        }));
    };          
    const [caseStatusOpt, setCaseStatusOpt] = useState([]);
    const fetchCaseStatus = async ()=>{
        const casesOpt = await getDropdownList("caseStatus");
        setCaseStatusOpt(casesOpt.map(x=>x['caseStatus']));
    }
    useEffect(() => {
        fetchCaseStatus()
    }, []);


    const itemSpace = 4;

    return (
        <Box>
            <Box className="pt-10">
                <h2 className="border-b border-dashed border-gray-400 text-xl mx-4 mb-5">Operator's Section:</h2>
            </Box>
            <Grid container spacing={2} className="pl-2">
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Sales Charge" />
                        <DropdownList
                            name={"Sales Charge"}
                            options={salesChargeOpt}
                            defaultValue={investFund.salesCharge}
                            setStateValue={value => setInvestFund({ ...investFund, salesCharge: value })}
                            type={firstLoanAndFund ? 'mandatory' : 'optional'}
                            isClear={isClear}
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}

                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Fund Portfolio" />
                        <Box display="flex" alignItems="center" gap={1}>
                            <DropdownList
                                name={"Fund Portfolio"}
                                options={portfolioOptList}
                                defaultValue={investFund.portfolio}
                                setStateValue={value => setInvestFund({ ...investFund, portfolio: value, portfolioNo: portfoliosMap.get(value) })}
                                type={firstLoanAndFund ? 'mandatory' : 'optional'}
                                isClear={isClear}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                            <Box sx={{ marginBottom: '60px', width: '0px' }}> {/* Wrapper for TextFieldComponent */}
                                <TextField
                                    value={investFund.policyGuaranteeLevel} // Set value from your state
                                    InputProps={{
                                        readOnly: true, // Make the input read-only
                                    }}
                                    variant="outlined" // You can use other variants like 'filled', 'standard'
                                    size="small" // Match the size to the DropdownList
                                    sx={{ width: '90px' }} // Set width or other styles as necessary
                                />
                            </Box>
                        </Box>
                        
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="FEL Rate" />
                        {/* change TextField once data is connected, fetch felRate and display */}
                        <TextFieldComponent
                            defaultValue={investFund.felRate}
                            setStateValue={value => setInvestFund({ ...investFund, felRate: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>

                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Case Status" />
                        <DropdownList
                            options={caseStatusOpt}
                            defaultValue={investFund.caseStatus}
                            setStateValue={value => setInvestFund({ ...investFund, caseStatus: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Completed Date" />
                        <DateField
                            defaultValue={investFund.completedDate}
                            setStateValue={value => setInvestFund({ ...investFund, completedDate: value })}
                            type='date'
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Completed Amount" />
                        <TextFieldComponent
                            defaultValue={investFund.completedAmount}
                            setStateValue={value => setInvestFund({ ...investFund, completedAmount: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Contract No." />
                        <TextFieldComponent
                            defaultValue={investFund.contractNo}
                            setStateValue={value => setInvestFund({ ...investFund, contractNo: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Loan Account No." />
                        <TextFieldComponent
                            defaultValue={investFund.loanAccountNo}
                            setStateValue={value => setInvestFund({ ...investFund, loanAccountNo: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Loan No." />
                        <TextFieldComponent
                            defaultValue={investFund.loanNo}
                            setStateValue={value => setInvestFund({ ...investFund, loanNo: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>


                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="FEL Fee" />
                        {/* change TextField once data is connected, fetch felFee and display */}
                        <TextFieldComponent
                            defaultValue={investFund.felFee}
                            setStateValue={value => setInvestFund({ ...investFund, felFee: value })}
                            isClear={isClear}
                            InputProps={{
                                readOnly: true, // Make the input read-only
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box className='flex flex-col items-center justify-center'>
                        <TextFieldText text="Contribution Amount:" />
                        <TextField value={`${isNaN(investFund.completedAmount - investFund.felFee) ? 0 : (investFund.completedAmount - investFund.felFee)}`}
                            onChange={handleChangeContribution}
                            variant="outlined"
                        />
                    </Box>
                </Grid>
            
               
                <Grid item xs={itemSpace} className='flex items-center justify-center'>
                    <Box>
                        <TextFieldText text="Prime Rate" />
                        <TextFieldComponent
                            defaultValue={investFund.primeRate}
                            setStateValue={value => setInvestFund({ ...investFund, primeRate: value })}
                            isClear={isClear}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default OperatorSection;