import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import PhoneField from "../../../../components/smallComponents/phoneField/PhoneField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import Gender from "../../../../components/enumTypes/Gender";
import { languages, annuitantRelation } from "../../../../constants/lists";
import { useApplication } from "../../../../contexts/ApplicationContext";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import { useEffect, useState } from "react";
import './style/SuccessOwner.css'
import { fetchApplicationsSuccessorOwner } from '../../../../services/fetchInfoService';
/**
 * @returns - SuccessOwner main field component for the application page
 */


export default function SuccessOwner() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const [isClear, setIsClear] = useState(false);
    const handleApplicantHasOwnerChange = (attribute, value) => {
        // Clear success owner information if user selects no success owner
        if (!value) {
            dispatch(setApplicantInfo({ attribute: 'ownerLanguagePreference', value: "English" }))
            dispatch(setApplicantInfo({ attribute: 'ownerFirstName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'ownerLastName', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'ownerDateOfBirth', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'ownerGender', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'ownerSIN', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'ownerHomePhone', value: "" }))
            dispatch(setApplicantInfo({ attribute: 'relationToOwner', value: "" }))
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const itemSpace = 3;

    const title = "Successor Owner";
    const genders = [Gender.MALE, Gender.FEMALE];

    const { clickNextButton } = useApplication();
    const pageName = 'owner';

    const hasSuccessorOwner = applicant.designatedSuccessorOwner;
    const conditionalDisplay = hasSuccessorOwner ? 'block' : 'none';


    const fetchApplcantInfoSuccessOwner = async ()=>{
    
        const applicantInfo = await fetchApplicationsSuccessorOwner(application.applicationID);
        if(applicantInfo !== undefined){
            if(applicantInfo.firstName !== null){
                dispatch(setApplicantInfo({ attribute: 'designatedSuccessorOwner', value: true }));
                dispatch(setApplicantInfo({ attribute: 'ownerFirstName', value: applicantInfo.firstName }));
                dispatch(setApplicantInfo({ attribute: 'ownerLastName', value: applicantInfo.lastName }));
                dispatch(setApplicantInfo({ attribute: 'ownerDateOfBirth', value: applicantInfo.dateOfBirth }));
                dispatch(setApplicantInfo({ attribute: 'ownerGender', value: applicantInfo.gender }));          
                dispatch(setApplicantInfo({ attribute: 'ownerSIN', value: applicantInfo.sin }));
                dispatch(setApplicantInfo({ attribute: 'ownerHomePhone', value: applicantInfo.homePhone }));
                dispatch(setApplicantInfo({ attribute: 'relationToOwner', value: applicantInfo.relationToOwner }));

            }
           
        }
      
    }

    useEffect(() => {
        fetchApplcantInfoSuccessOwner();
    }, []);

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 success-owner-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-4 bg-mainfield-box rounded-2xl shadow-custom'>
                <Grid container spacing={2}>
                    <Grid item xs={itemSpace * 4} >
                        <Box>
                            <TextFieldText text='Do you want to designate a successor Owner?' largeBox={true} />
                            <RadioGroupBoolean setStateValue={value => handleApplicantHasOwnerChange("designatedSuccessorOwner", value)} defaultValue={applicant.designatedSuccessorOwner}
                                type='mandatory' name='Has Successor Owner' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='First Name' />
                            <TextField setStateValue={value => handleApplicantChange("ownerFirstName", value)} defaultValue={applicant.ownerFirstName}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner First Name' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Last Name' />
                            <TextField setStateValue={value => handleApplicantChange("ownerLastName", value)} defaultValue={applicant.ownerLastName}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner Last Name' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Date of Birth' />
                            <DateField setStateValue={value => handleApplicantChange("ownerDateOfBirth", value)} defaultValue={applicant.ownerDateOfBirth}
                                type={hasSuccessorOwner ? 'date' : 'optional'} name='Successor Owner DOB' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Gender' />
                            <RadioGroup options={genders} setStateValue={value => handleApplicantChange("ownerGender", value)} defaultValue={applicant.ownerGender}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner Gender' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Social Insurance Number (SIN)' />
                            <TextField setStateValue={value => handleApplicantChange("ownerSIN", value)} defaultValue={applicant.ownerSIN}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner SIN' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Language Preference' />
                            <DropdownList options={languages} setStateValue={value => handleApplicantChange("ownerLanguagePreference", value)} defaultValue={applicant.ownerLanguagePreference}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner Language Preference' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }} >
                        <Box>
                            <TextFieldText text='Home Phone' />
                            <PhoneField setStateValue={value => handleApplicantChange("ownerHomePhone", value)} defaultValue={applicant.ownerHomePhone}
                                type={hasSuccessorOwner ? 'phone' : 'optional'} name='Successor Owner Home Phone' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                        </Box>
                    </Grid>
                    <Grid item xs={itemSpace} style={{ display: conditionalDisplay }}>
                        <Box>
                            <TextFieldText text='Relation to Owner' />
                            {/* <TextField setStateValue={value => handleApplicantChange("relationToOwner", value)} defaultValue={applicant.relationToOwner}
                                type={hasSuccessorOwner ? 'mandatory' : 'optional'} name='Successor Owner Relation To Owner' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                         */}
                        <DropdownList
                            options={annuitantRelation}
                            setStateValue={value => handleApplicantChange("relationToOwner", value)}
                            defaultValue={applicant.relationToOwner}
                            isClear={isClear}
                            type={hasSuccessorOwner ? 'mandatory' : 'optional'}
                            name='Successor Owner Relation To Owner' 
                            pageName={pageName} 
                            buttonCheck={clickNextButton[pageName]}
                        />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>

        </Box>
    );
};