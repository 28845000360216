import { Alert, Box, Grid, IconButton, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import Table from "../../../../components/mediumComponents/table/Table";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import NumberField from "../../../../components/smallComponents/numberField/NumberField";
import { AddBox } from "@mui/icons-material";
import TextField from "../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import './style/SecondaryBeneficiary.css'


/**
 * Represents the Secondary beneficiary component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.relationAnnuitant - The options for the relation to annuitant dropdown list.
 * @param {string} props.beneficiaryType - The type of beneficiary.
 * @returns {JSX.Element} The primary beneficiary component.
 */
export default function SecondaryBeneficiary({ relationAnnuitant, beneficiaryType }) {
    const itemSpace = 3;

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    const [hasSecBen, setSecBen] = useState(false);

    const handleApplicantHasSecBenChange = (value) => {
        // Clear annuitant information if user selects no annuitant
        if (!value) {
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryFirstName', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryLastName', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secRelationToAnnuitant', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryGender', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryType', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryDateOfBirth', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secBeneficiaryShare', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secTrusteeFirstName', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secTrusteeLastName', value: [] }))
            dispatch(setApplicantInfo({ attribute: 'secTrusteeRelationToBeneficiary', value: [] }))
        }
        setSecBen(value)
    };

    //counting the age for the date
    const [dob, setDob] = useState(null);


    function calculateAge(dob) {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Adjust age if birth date hasn't occurred yet this year
        if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    }

    const genders = [
        "Male",
        "Female"
    ]

    let age = 0;
    //to decide whether if the age is less than 18
    const [isLessThan18, setIsLessThan18] = useState(false);


    // object for storing multiple beneficiary temporary
    const [beneficiaryObj, setBeneficiaryObj] = useState(
        {
            fName: '',
            lName: '',
            annuitantRelation: '',
            gender: '',
            benType: '',
            dob: '',
            share: '',
            trusteeFname: '',
            trusteeLname: '',
            trusteeBeneficiaryRelation: ''
        }
    )

    //tables properties needed
    const tableHead = ["First Name", "Last Name", "Relation to Annuitant", "Gender", "Beneficiary Type", "Date of Birth",
        "Share", "Trustee - FirstName", "Trustee - LastName", "Trustee-Relation to Beneficiary"];


    const values = [
        applicant.secBeneficiaryFirstName,
        applicant.secBeneficiaryLastName,
        applicant.secRelationToAnnuitant,
        applicant.secBeneficiaryGender,
        applicant.secBeneficiaryType,
        applicant.secBeneficiaryDateOfBirth,
        applicant.secBeneficiaryShare,
        applicant.secTrusteeFirstName,
        applicant.secTrusteeLastName,
        applicant.secTrusteeRelationToBeneficiary
    ]

    //for adding the object/values to the global state

    function addValues(ageAbove18) {
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryFirstName', value: [...applicant.secBeneficiaryFirstName, beneficiaryObj.fName] }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryLastName', value: [...applicant.secBeneficiaryLastName, beneficiaryObj.lName] }));
        dispatch(setApplicantInfo({ attribute: 'secRelationToAnnuitant', value: [...applicant.secRelationToAnnuitant, beneficiaryObj.annuitantRelation] }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryGender', value: [...applicant.secBeneficiaryGender, beneficiaryObj.gender] }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryType', value: [...applicant.secBeneficiaryType, beneficiaryObj.benType || 'Revocable'] }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryDateOfBirth', value: [...applicant.secBeneficiaryDateOfBirth, beneficiaryObj.dob] }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryShare', value: [...applicant.secBeneficiaryShare, beneficiaryObj.share] }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeFirstName', value: [...applicant.secTrusteeFirstName, beneficiaryObj.trusteeFname || ageAbove18] }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeLastName', value: [...applicant.secTrusteeLastName, beneficiaryObj.trusteeLname || ageAbove18] }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeRelationToBeneficiary', value: [...applicant.secTrusteeRelationToBeneficiary, beneficiaryObj.trusteeBeneficiaryRelation || ageAbove18] }));
    }

    function reset() {
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        setIsClear(true);
        setTimeout(() => setIsClear(false), 100);

        setBeneficiaryObj({
            fName: '',
            lName: '',
            annuitantRelation: '',
            gender: '',
            benType: '',
            dob: '',
            share: '',
            trusteeFname: '',
            trusteeLname: '',
            trusteeBeneficiaryRelation: ''
        })
    }


    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpenSuccess(false)
    };
    const handleAddBeneficiary = () => {

        if (beneficiaryObj.fName.trim() !== "" && beneficiaryObj.lName.trim() !== "" && beneficiaryObj.annuitantRelation.trim() !== "" &&
            beneficiaryObj.gender.trim() !== "" && beneficiaryObj.dob.trim() !== "" && beneficiaryObj.share.trim() !== "") {
            if (isLessThan18 && beneficiaryObj.trusteeFname.trim() !== "" && beneficiaryObj.trusteeLname.trim() !== "" && beneficiaryObj.trusteeBeneficiaryRelation.trim() !== "") {
                addValues();
                reset();
                setOpenSuccess(true)

            }
            else if (isLessThan18 === false) {
                beneficiaryObj.trusteeFname = ''
                beneficiaryObj.trusteeLname = ''
                beneficiaryObj.trusteeBeneficiaryRelation = ''
                addValues("N/A");
                reset();
                setOpenSuccess(true)

            }
            else {
                setOpen(true)

            }


        }
        else {
            setOpen(true)

        }

    }

    const handleDeleteBeneficiary = (index) => {

        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryFirstName', value: applicant.secBeneficiaryFirstName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryLastName', value: applicant.secBeneficiaryLastName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secRelationToAnnuitant', value: applicant.secRelationToAnnuitant.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryGender', value: applicant.secBeneficiaryGender.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryType', value: applicant.secBeneficiaryType.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryDateOfBirth', value: applicant.secBeneficiaryDateOfBirth.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secBeneficiaryShare', value: applicant.secBeneficiaryShare.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeFirstName', value: applicant.secTrusteeFirstName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeLastName', value: applicant.secTrusteeLastName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'secTrusteeRelationToBeneficiary', value: applicant.secTrusteeRelationToBeneficiary.filter((_, i) => i !== index) }));
    };

    const [shareRemaining, setshareRemaining] = useState(100)

    useEffect(() => {
        const totalShare = applicant.secBeneficiaryShare.reduce((acc, share) => acc + share, 0);
        const remainingShare = 100 - totalShare;
        setshareRemaining(remainingShare);
    }, [applicant.secBeneficiaryShare]);
    return (
        <Box>
            <Box>
                <Table fieldNames={tableHead} fieldValues={values} handleDelete={colIndex => handleDeleteBeneficiary(colIndex)}></Table>
            </Box>
            <Box className="box-2-field">
                <Grid container spacing={2}>
                    <Grid item xs={itemSpace * 4} >
                        <Box>
                            <TextFieldText text='Do you want to add Secondary Beneficiary?' largeBox={true} />
                            <RadioGroupBoolean setStateValue={value => handleApplicantHasSecBenChange(value)} defaultValue={false} />
                        </Box>
                    </Grid>
                </Grid>

                {hasSecBen && (
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="First Name" />
                                    <TextField
                                        defaultValue=''
                                        isClear={isClear}
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, fName: value })}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Last Name" />
                                    <TextField
                                        defaultValue=''
                                        isClear={isClear}
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, lName: value })} />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Relation to Annuitant" />
                                    <DropdownList
                                        options={relationAnnuitant}
                                        defaultValue=''
                                        isClear={isClear}
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, annuitantRelation: value })} />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Gender" />
                                    <RadioGroup
                                        options={genders}
                                        defaultValue=''
                                        isClear={isClear}
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, gender: value })} />
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Beneficiary Type" />
                                    <DropdownList
                                        options={beneficiaryType}
                                        isClear={isClear}
                                        defaultValue="Revocable"
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, benType: value })} />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Date of Birth" />
                                    <DateField
                                        setStateValue={value => {
                                            setDob(value);
                                            age = calculateAge(value);
                                            setIsLessThan18(age < 18);
                                            setBeneficiaryObj({ ...beneficiaryObj, dob: value })
                                        }}
                                        isClear={isClear}
                                        defaultValue=''

                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Share" />
                                    <NumberField
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, share: value })}
                                        minValue={0}
                                        maxValue={shareRemaining}
                                        isClear={isClear}
                                        type={beneficiaryObj.share !== '' ? 'number' : 'optional'}

                                    />
                                </Box>
                            </Grid>

                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Trustee - FirstName " />
                                    <TextField
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeFname: value })}
                                        disabled={isLessThan18 === false}
                                        isClear={isClear}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Trustee - LastName " />
                                    <TextField
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeLname: value })}
                                        disabled={isLessThan18 === false}
                                        isClear={isClear}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Trustee-Relation to Beneficiary " />
                                    <DropdownList
                                        options={relationAnnuitant}
                                        defaultValue=''
                                        setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeBeneficiaryRelation: value })}
                                        disabled={isLessThan18 === false}
                                        isClear={isClear}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                                <IconButton onClick={handleAddBeneficiary} >
                                    <AddBox sx={{ fontSize: 45 }} />
                                    <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Beneficiary</span>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="warning"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        Please fill all relatable fields before adding
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        Success adding Beneficiary!
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    )
}