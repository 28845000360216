import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // bankAndInvestmentCompany
    InvestmentLoanBank: [],
    InvestmentFundCompany: [],
    appliedAmount: [],
    subInvestmentAccountType: [],
    SalesCharge: [],
    FundPortfolio: [],
    FundPortfolioNo: [],
    felRate: [],
    felFee: [],
    caseStatus: [],
    completedAmount: [],
    completedDate: [],
    loanAccountNo: [],
    loanNo: [],
    contractNo: [],
    contributionAmount: [],
    primeRate: [],
    caseID: [],
    applicationID: [],
    policyGuaranteeLevel: [],
    // advisorInfo
    supportingAdvisorCode: "",
    servingAdvisorCode: "",

    // idVerification
    verificationDate: "",
    verificationPlace: "",

    // acquisitionChannel
    AcquisitionChannelID: "",
    acquisitionChannel: "",
    acquisitionChannelDetail: "",
    existingClient: "",
    referrerCustomerID: [],
    referrerFirstName: [],
    referrerLastName: [],
    referrerPreferredName: [],
    referrerDateOfBirth: [],
    referrerGender: [],
    referrerHomePhone: [],
    // DB IDs
    CaseID: [],
    CurrentFundIDs: [],
}

const additionalInfoSlice = createSlice({
    name: "additionalInfo",
    initialState,
    reducers: {
        setAdditionalInfo: (state, action) => {
            const { attribute, value } = action.payload
            state[attribute] = value;
        },
        updateAdditionalInfoByIndex: (state, action) => {
            const { attribute, index, newValue } = action.payload;
      
            // Check if attribute exists in state and is an array
            if (Array.isArray(state[attribute]) && index >= 0 && index < state[attribute].length) {
              // Update the specific item at the provided index
              state[attribute][index] = newValue;
            }
          },
        
        resetAdditionalInfo: () => initialState,
    },
});

export const { setAdditionalInfo, resetAdditionalInfo, updateAdditionalInfoByIndex } = additionalInfoSlice.actions;
export default additionalInfoSlice.reducer;