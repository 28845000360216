import { useState, useEffect } from "react";



export function usePhoneTypes(applicant) {
    const [workPhoneType, setWorkPhoneType] = useState('phone');
    const [cellPhoneType, setCellPhoneType] = useState('phone');
    const [homePhoneType, setHomePhoneType] = useState('phone');

    useEffect(()=>{
        setWorkPhoneType('phone');
        setHomePhoneType('phone');
        
        // if(!applicant.cellPhone){
        //     setWorkPhoneType('phone');
        //     setHomePhoneType('phone');
        //     setCellPhoneType('optional');
        // } else if(!applicant.homePhone){
        //     setWorkPhoneType('phone');
        //     setCellPhoneType('phone');
        //     setHomePhoneType('optional');
        // } else if(!applicant.workPhone){
        //     setHomePhoneType('phone');
        //     setCellPhoneType('phone');
        //     setWorkPhoneType('optional');
        // } else{
        //     setHomePhoneType('phone');
        //     setCellPhoneType('phone');
        //     setWorkPhoneType('phone');
        // }
    }, [applicant.cellPhone, applicant.homePhone, applicant.workPhone]);

    return [workPhoneType, cellPhoneType, homePhoneType];
}