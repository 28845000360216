import { Alert, Box, Grid, IconButton, Snackbar } from "@mui/material";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import NumberField from "../../../../components/smallComponents/numberField/NumberField";
import PhoneField from "../../../../components/smallComponents/phoneField/PhoneField";
import Table from "../../../../components/mediumComponents/table/Table";
import { AddBox } from "@mui/icons-material";
import "./style/PrimaryBeneficiary.css"
import { useApplication } from "../../../../contexts/ApplicationContext";
import Gender from "../../../../components/enumTypes/Gender";
import { provinces } from "../../../../constants/lists";
import ModifyTable from "../../../../components/mediumComponents/table/ModifyTable";
import CreateTimeStamp from "../../../../utilities/createTimeStamp";
import Big from 'big.js';
import bigInt from 'big-integer';
import { getAllItems, addItem, deleteItemByID, updateItem } from "../../../../services/multiAddObjectService";
/**
 * Represents the primary beneficiary component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.relationAnnuitant - The options for the relation to annuitant dropdown list.
 * @param {string} props.beneficiaryType - The type of beneficiary.
 * @returns {JSX.Element} The primary beneficiary component.
 */


export default function PrimaryBeneficiary({ relationAnnuitant, beneficiaryType }) {

    const itemSpace = 3;

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const applicationType = useParams().applicationType;
    const [isUpdate, setIsUpdate] = useState(false);
    const [updateBeneficiaryIndex, setUpdateBeneficiaryIndex] = useState(0);
    const needTrustee = ['regular', 'quick', 'transfer', 'nonreg', 'rrsp', 'tfsa'].includes(applicationType);
    const needBeneficiaryPersonalInfo = ['resp'].includes(applicationType);
    const [rowIndex, setRowIndex] = useState(-1);
    const { setBeneShare } = useApplication();
    //counting the age for the date
    function calculateAge(dob) {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let age = currentDate.getFullYear() - birthDate.getFullYear();

        // Adjust age if birth date hasn't occurred yet this year
        if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const genders = [Gender.MALE, Gender.FEMALE];
    
    const hasCoApplicant = applicant.anyCoapplicant;

    const applicantName = applicant.firstname + ' ' + applicant.lastname;
    const coApplicantName = hasCoApplicant ? coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const bothApplicantsName = hasCoApplicant ? applicant.firstname + ' ' + applicant.lastname + ' & ' + coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const owners = hasCoApplicant ? [applicantName, coApplicantName, bothApplicantsName] : [applicantName];
    const itemType = 'beneficiaries';

    let age = 0;

    //to decide whether if the age is less than 18
    const [isLessThan18, setIsLessThan18] = useState(false);

    // object for storing multiple beneficiary temporary
    const [beneficiaryObj, setBeneficiaryObj] = useState(
        {
            fName: '',
            lName: '',
            annuitantRelation: '',
            gender: '',
            benType: 'REVOCABLE',
            dob: '',
            share: '',
            trusteeFname: '',
            trusteeLname: '',
            trusteeBeneficiaryRelation: ''
        }
    );

    const [beneficiaryInfo, setBeneficiaryInfo] = useState({
        sin: '',
        sameAddress: true,
        streetNumber: applicant.streetNumber,
        streetName: applicant.streetName,
        unit: applicant.unit,
        city: applicant.city,
        province: applicant.province,
        postalCode: applicant.postalCode,
        email: '',
        homePhone: '',
        workPhone: '',
        cellPhone: ''
    });

    //tables properties needed
    const tableHead = needBeneficiaryPersonalInfo ?
        ["First Name", "Last Name", "Relation to Annuitant", "Gender", "Beneficiary Type", "Date of Birth",
            "Share", "SIN", "Street Number", "Street Name", "Unit", "City", "Province", "Postal Code", "Email",
            "Home Phone", "Work Phone", "Cell Phone"]
        :
        ["First Name", "Last Name", "Relation to Annuitant", "Gender", "Beneficiary Type", "Date of Birth",
            "Share", "Trustee - FirstName", "Trustee - LastName", "Trustee-Relation to Beneficiary"];

    const values = needBeneficiaryPersonalInfo ?
        [
            applicant.beneficiaryFirstName,
            applicant.beneficiaryLastName,
            applicant.priRelationToAnnuitant,
            applicant.beneficiaryGender,
            applicant.beneficiaryType,
            applicant.beneficiaryDateOfBirth,
            applicant.beneficiaryShare,
            applicant.beneficiarySIN,
            applicant.beneficiaryStreetNumber,
            applicant.beneficiaryStreetName,
            applicant.beneficiaryUnit,
            applicant.beneficiaryCity,
            applicant.beneficiaryProvince,
            applicant.beneficiaryPostalCode,
            applicant.beneficiaryEmail,
            applicant.beneficiaryHomePhone,
            applicant.beneficiaryWorkPhone,
            applicant.beneficiaryCellPhone
        ]
        :
        [
            applicant.beneficiaryFirstName,
            applicant.beneficiaryLastName,
            applicant.priRelationToAnnuitant,
            applicant.beneficiaryGender,
            applicant.beneficiaryType,
            applicant.beneficiaryDateOfBirth,
            applicant.beneficiaryShare,
            applicant.trusteeFirstName,
            applicant.trusteeLastName,
            applicant.trusteeRelationToBeneficiary
        ];

    const handleSameAddressChange = (value) => {
        setBeneficiaryInfo(prevState => ({
            ...prevState,
            sameAddress: value,
            streetNumber: value ? applicant.streetNumber : '',
            streetName: value ? applicant.streetName : '',
            unit: value ? applicant.unit : '',
            city: value ? applicant.city : '',
            province: value ? applicant.province : '',
            postalCode: value ? applicant.postalCode : '',
            homePhone: value ? '' : '1',
            workPhone: value ? '' : '1'
        }));
    };

    const handleEditBeneficiary = (index) =>{
        
        setBeneficiaryObj({
            beneficiaryTrusteeID: applicant.PrimBeneficiaryIDs[index],
            fName: applicant.beneficiaryFirstName[index],
            lName: applicant.beneficiaryLastName[index],
            annuitantRelation: applicant.priRelationToAnnuitant[index],
            gender: applicant.beneficiaryGender[index],
            benType: applicant.beneficiaryType[index],
            dob: applicant.beneficiaryDateOfBirth[index],
            share: applicant.beneficiaryShare[index],
            trusteeFname: applicant.trusteeFirstName[index],
            trusteeLname: applicant.trusteeLastName[index],
            trusteeBeneficiaryRelation: applicant.trusteeRelationToBeneficiary[index],
        });
        age = calculateAge(applicant.beneficiaryDateOfBirth[index]);
        setIsLessThan18(prevState => !prevState);

        handleDeleteBeneficiaryNODB(index);
        setIsUpdate(prevState => !prevState);
        setUpdateBeneficiaryIndex(applicant.PrimBeneficiaryIDs[index]);
        if(applicant.trusteeFirstName[index] !== ''){
            setIsLessThan18(prevState => !prevState);
        }

    };

    const handleDeleteBeneficiaryNODB = (index) => {
        
        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs', value: applicant.PrimBeneficiaryIDs.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: applicant.beneficiaryFirstName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: applicant.beneficiaryLastName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: applicant.priRelationToAnnuitant.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: applicant.beneficiaryGender.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: applicant.beneficiaryType.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: applicant.beneficiaryDateOfBirth.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: applicant.beneficiaryShare.filter((_, i) => i !== index) }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: applicant.trusteeFirstName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: applicant.trusteeLastName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: applicant.trusteeRelationToBeneficiary.filter((_, i) => i !== index) }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: applicant.beneficiarySIN.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: applicant.beneficiarySameAddressAsApplicant.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: applicant.beneficiaryStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: applicant.beneficiaryStreetName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: applicant.beneficiaryUnit.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: applicant.beneficiaryCity.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: applicant.beneficiaryProvince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: applicant.beneficiaryPostalCode.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: applicant.beneficiaryEmail.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: applicant.beneficiaryHomePhone.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: applicant.beneficiaryWorkPhone.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: applicant.beneficiaryCellPhone.filter((_, i) => i !== index) }));
        }
    };


    //for adding the object/values to the global state
    const addValues = async () => {
        
        const index = owners.indexOf(beneficiaryInfo.owner);

        let ownerName;
        switch (index) {
            case 0:
                ownerName = 'Applicant';
                break;
            case 1:
                ownerName = 'Co-Applicant';
                break;
            case 2:
                ownerName = 'Both Applicants';
                break;
            default:
                break;
        };


        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: [...applicant.beneficiaryFirstName, beneficiaryObj.fName] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: [...applicant.beneficiaryLastName, beneficiaryObj.lName] }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: [...applicant.priRelationToAnnuitant, beneficiaryObj.annuitantRelation] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: [...applicant.beneficiaryGender, beneficiaryObj.gender] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: [...applicant.beneficiaryType, beneficiaryObj.benType] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: [...applicant.beneficiaryDateOfBirth, beneficiaryObj.dob] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: [...applicant.beneficiaryShare, beneficiaryObj.share] }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: [...applicant.trusteeFirstName, beneficiaryObj.trusteeFname] }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: [...applicant.trusteeLastName, beneficiaryObj.trusteeLname] }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: [...applicant.trusteeRelationToBeneficiary, beneficiaryObj.trusteeBeneficiaryRelation] }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: [...applicant.beneficiarySIN, beneficiaryInfo.sin] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: [...applicant.beneficiarySameAddressAsApplicant, beneficiaryInfo.sameAddress] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: [...applicant.beneficiaryStreetNumber, beneficiaryInfo.streetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: [...applicant.beneficiaryStreetName, beneficiaryInfo.streetName] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: [...applicant.beneficiaryUnit, beneficiaryInfo.unit] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: [...applicant.beneficiaryCity, beneficiaryInfo.city] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: [...applicant.beneficiaryProvince, beneficiaryInfo.province] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: [...applicant.beneficiaryPostalCode, beneficiaryInfo.postalCode] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: [...applicant.beneficiaryEmail, beneficiaryInfo.email] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: [...applicant.beneficiaryHomePhone, beneficiaryInfo.homePhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: [...applicant.beneficiaryWorkPhone, beneficiaryInfo.workPhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: [...applicant.beneficiaryCellPhone, beneficiaryInfo.cellPhone] }));
        }

        // Create a new benefiacry object to be added to the database
        const newBenficiary = {
            applicationID: application.applicationID,
            beneficiaryOrder: 'Primary',
            beneficiaryType: beneficiaryObj.benType,
            firstName: beneficiaryObj.fName,
            lastName: beneficiaryObj.lName,
            dateOfBirth: beneficiaryObj.dob,
            gender: beneficiaryObj.gender,
            sin: beneficiaryInfo.sin,
            sharePercent: new Big(beneficiaryObj.share),
            relationToAnnuitant: beneficiaryObj.annuitantRelation,
            trusteeFirstname: beneficiaryObj.trusteeFname,
            trusteeLastname: beneficiaryObj.trusteeLname,
            trusteeRelationToBeneficiary: beneficiaryObj.trusteeBeneficiaryRelation,
            streetNumber: beneficiaryInfo.streetNumber,
            streetName: beneficiaryInfo.streetName,
            unit: beneficiaryInfo.unit,
            city: beneficiaryInfo.city,
            province: beneficiaryInfo.province,
            postalCode: beneficiaryInfo.postalCode,
            email: beneficiaryInfo.email,
            homePhone: beneficiaryInfo.homePhone,
            workPhone: beneficiaryInfo.homePhone,
            cellPhone: beneficiaryInfo.cellPhone,
            customerID: beneficiaryInfo.customerID,
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new liability to the database
        const addBeneficiary = await addItem(itemType, newBenficiary);
        // Push the DB generated liabilityID from API response to the global state
        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs', value: [...applicant.PrimBeneficiaryIDs, addBeneficiary.beneficiaryTrusteeID] }));

        reset();
        setIsClear(true);
    };

    const handleFetchBeneficiary = async () => {
        const beneficiaries = await getAllItems(itemType, application.applicationID);
        let updatedPrimBeneficiaryIDs = [];
	    let updatedbeneficiaryOrder = [];
        let updatedbeneficiaryType = [];
        let updatedfirstName = [];
        let updatedlastName= [];
        let updateddateOfBirth = [];
        let updatedgender = [];
        let updatedsharePercent= [];
        let updatedrelationToAnnuitant = [];
        let updatedtrustee_Firstname = [];
        let updatedtrustee_Lastname = [];
        let updatedtrustee_RelationToBeneficiary = [];


        for (const beneficiary of beneficiaries) {
            updatedPrimBeneficiaryIDs.push(beneficiary['beneficiaryTrusteeID']);
            updatedbeneficiaryOrder.push(beneficiary['beneficiaryOrder']);
            updatedbeneficiaryType.push(beneficiary['beneficiaryType']);
            updatedfirstName.push(beneficiary['firstName']);
            updatedlastName.push(beneficiary['lastName']);
            updateddateOfBirth.push(beneficiary['dateOfBirth']);
            updatedgender.push(beneficiary['gender']);
            updatedrelationToAnnuitant.push(beneficiary['relationToAnnuitant']);
            updatedsharePercent.push(beneficiary['sharePercent']);
            updatedtrustee_Firstname.push(beneficiary['trusteeFirstname']);
            updatedtrustee_Lastname.push(beneficiary['trusteeLastname']);
            updatedtrustee_RelationToBeneficiary.push(beneficiary['trusteeRelationToBeneficiary']);

            
        }

        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs',value: updatedPrimBeneficiaryIDs }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: updatedfirstName }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: updatedlastName }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: updatedrelationToAnnuitant }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: updatedgender }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: updatedbeneficiaryType }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: updateddateOfBirth }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: updatedsharePercent }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: updatedtrustee_Firstname }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: updatedtrustee_Lastname }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: updatedtrustee_RelationToBeneficiary }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: [...applicant.beneficiarySIN, beneficiaryInfo.sin] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: [...applicant.beneficiarySameAddressAsApplicant, beneficiaryInfo.sameAddress] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: [...applicant.beneficiaryStreetNumber, beneficiaryInfo.streetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: [...applicant.beneficiaryStreetName, beneficiaryInfo.streetName] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: [...applicant.beneficiaryUnit, beneficiaryInfo.unit] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: [...applicant.beneficiaryCity, beneficiaryInfo.city] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: [...applicant.beneficiaryProvince, beneficiaryInfo.province] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: [...applicant.beneficiaryPostalCode, beneficiaryInfo.postalCode] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: [...applicant.beneficiaryEmail, beneficiaryInfo.email] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: [...applicant.beneficiaryHomePhone, beneficiaryInfo.homePhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: [...applicant.beneficiaryWorkPhone, beneficiaryInfo.workPhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: [...applicant.beneficiaryCellPhone, beneficiaryInfo.cellPhone] }));
        }
    }
    function reset() {
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        setIsClear(true);
        setTimeout(() => setIsClear(false), 100);
        setBeneficiaryObj({
            beneficiaryTrusteeID: '',
            fName: '',
            lName: '',
            annuitantRelation: '',
            gender: '',
            benType: 'REVOCABLE',
            dob: '',
            share: '',
            trusteeFname: '',
            trusteeLname: '',
            trusteeBeneficiaryRelation: ''
        })
        setBeneficiaryInfo({
            sin: '',
            sameAddress: true,
            streetNumber: applicant.streetNumber,
            streetName: applicant.streetName,
            unit: applicant.unit,
            city: applicant.city,
            province: applicant.province,
            postalCode: applicant.postalCode,
            email: '',
            homePhone: '',
            workPhone: '',
            cellPhone: ''
        })
        setIsLessThan18(prevState => !prevState)
    };

    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleCloseSuccess = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false)
    };

    const handleAddBeneficiary = () => {

        const beneficiaryObjFieldsCheck =
            ['fName', 'lName', 'annuitantRelation', 'gender', 'dob', 'share'].every(field => beneficiaryObj[field].trim() !== "");

        const trusteeCheck =
            ['trusteeFname', 'trusteeLname', 'trusteeBeneficiaryRelation'].every(field => beneficiaryObj[field].trim() !== "");

        const beneficiaryInfoFieldsCheck = beneficiaryInfo.sameAddress ?
            ['sin'].every(field => beneficiaryInfo[field].trim() !== "")
            :
            ['sin', 'streetNumber', 'streetName', 'city', 'province', 'postalCode', 'email', 'homePhone', 'workPhone']
                .every(field => beneficiaryInfo[field].trim() !== "");

        const shareCheck = beneficiaryObj.share > 0 && beneficiaryObj.share <= 100;

        if (needBeneficiaryPersonalInfo) {
            if (beneficiaryObjFieldsCheck && beneficiaryInfoFieldsCheck && shareCheck) {
                addValues();
                reset();
                setOpenSuccess(true);
            }
            else {
                setOpenError(true);
            }
        }
        else if (needTrustee) {
            if (isLessThan18) {
                
                if (beneficiaryObjFieldsCheck && trusteeCheck && shareCheck) {
                    addValues();
                    reset();
                    setOpenSuccess(true);
                }
                else {
                    setOpenError(true);
                }
            }
            else {
                if (beneficiaryObjFieldsCheck && shareCheck) {
                    addValues();
                    reset();
                    setOpenSuccess(true);
                }
                else {
                    setOpenError(true);
                }
            }
        }
    };

    const handleEditBeneficiaryCheck = () => {
        const trusteeCheck =
            ['trusteeFname', 'trusteeLname', 'trusteeBeneficiaryRelation'].every(field => beneficiaryObj[field].trim() !== "");

       
        const shareCheck = beneficiaryObj.share > 0 && beneficiaryObj.share <= 100;

        if (needBeneficiaryPersonalInfo) {
            if (shareCheck) {
                sendEditBeneficiary();
                reset();
                setOpenSuccess(true);
            }
            else {
                setOpenError(true);
            }
        }
        else if (needTrustee) {
            if (isLessThan18) {
                
                if ( trusteeCheck && shareCheck) {
                    sendEditBeneficiary();
                    reset();
                    setOpenSuccess(true);
                }
                else {
                    setOpenError(true);
                }
            }
            else {
                if (shareCheck) {
                    sendEditBeneficiary();
                    reset();
                    setOpenSuccess(true);
                }
                else {
                    setOpenError(true);
                }
            }
        }
    };
  
    const sendEditBeneficiary = async () => {
        //need to check 
        
        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: [...applicant.beneficiaryFirstName, beneficiaryObj.fName] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: [...applicant.beneficiaryLastName, beneficiaryObj.lName] }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: [...applicant.priRelationToAnnuitant, beneficiaryObj.annuitantRelation] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: [...applicant.beneficiaryGender, beneficiaryObj.gender] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: [...applicant.beneficiaryType, beneficiaryObj.benType] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: [...applicant.beneficiaryDateOfBirth, beneficiaryObj.dob] }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: [...applicant.beneficiaryShare, beneficiaryObj.share] }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: [...applicant.trusteeFirstName, beneficiaryObj.trusteeFname] }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: [...applicant.trusteeLastName, beneficiaryObj.trusteeLname] }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: [...applicant.trusteeRelationToBeneficiary, beneficiaryObj.trusteeBeneficiaryRelation] }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: [...applicant.beneficiarySIN, beneficiaryInfo.sin] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: [...applicant.beneficiarySameAddressAsApplicant, beneficiaryInfo.sameAddress] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: [...applicant.beneficiaryStreetNumber, beneficiaryInfo.streetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: [...applicant.beneficiaryStreetName, beneficiaryInfo.streetName] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: [...applicant.beneficiaryUnit, beneficiaryInfo.unit] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: [...applicant.beneficiaryCity, beneficiaryInfo.city] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: [...applicant.beneficiaryProvince, beneficiaryInfo.province] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: [...applicant.beneficiaryPostalCode, beneficiaryInfo.postalCode] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: [...applicant.beneficiaryEmail, beneficiaryInfo.email] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: [...applicant.beneficiaryHomePhone, beneficiaryInfo.homePhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: [...applicant.beneficiaryWorkPhone, beneficiaryInfo.workPhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: [...applicant.beneficiaryCellPhone, beneficiaryInfo.cellPhone] }));
        }

        // Create a new liability object to be added to the database
        const newBenficiary = {
            beneficiaryTrusteeID: updateBeneficiaryIndex,
            applicationID: application.applicationID,
            beneficiaryOrder: 'Primary',
            beneficiaryType: beneficiaryObj.benType,
            firstName: beneficiaryObj.fName,
            lastName: beneficiaryObj.lName,
            dateOfBirth: beneficiaryObj.dob,
            gender: beneficiaryObj.gender,
            sin: beneficiaryInfo.sin,
            sharePercent: new Big(beneficiaryObj.share),
            relationToAnnuitant: beneficiaryObj.annuitantRelation,
            trusteeFirstname: beneficiaryObj.trusteeFname,
            trusteeLastname: beneficiaryObj.trusteeLname,
            trusteeRelationToBeneficiary: beneficiaryObj.trusteeBeneficiaryRelation,
            streetNumber: beneficiaryInfo.streetNumber,
            streetName: beneficiaryInfo.streetName,
            unit: beneficiaryInfo.unit,
            city: beneficiaryInfo.city,
            province: beneficiaryInfo.province,
            postalCode: beneficiaryInfo.postalCode,
            email: beneficiaryInfo.email,
            homePhone: beneficiaryInfo.homePhone,
            workPhone: beneficiaryInfo.homePhone,
            cellPhone: beneficiaryInfo.cellPhone,
            customerID: beneficiaryInfo.customerID,
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new liability to the database
        const addBeneficiary = await updateItem(itemType, newBenficiary);
        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs', value: [...applicant.PrimBeneficiaryIDs, addBeneficiary.beneficiaryTrusteeID] }));
        setIsUpdate(prevState => !prevState);
        reset();
        setIsClear(true);
    }

    const handleDeleteBeneficiary = async (index) => {
        
        const beneficiaryId = bigInt(applicant.PrimBeneficiaryIDs[index]);
        const deletedBeneficiary = await deleteItemByID(itemType, beneficiaryId);
        console.log('Deleted Beneficiary', deletedBeneficiary);
        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs', value: applicant.PrimBeneficiaryIDs.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: applicant.beneficiaryFirstName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: applicant.beneficiaryLastName.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: applicant.priRelationToAnnuitant.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: applicant.beneficiaryGender.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: applicant.beneficiaryType.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: applicant.beneficiaryDateOfBirth.filter((_, i) => i !== index) }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: applicant.beneficiaryShare.filter((_, i) => i !== index) }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: applicant.trusteeFirstName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: applicant.trusteeLastName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: applicant.trusteeRelationToBeneficiary.filter((_, i) => i !== index) }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: applicant.beneficiarySIN.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: applicant.beneficiarySameAddressAsApplicant.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: applicant.beneficiaryStreetNumber.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: applicant.beneficiaryStreetName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: applicant.beneficiaryUnit.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: applicant.beneficiaryCity.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: applicant.beneficiaryProvince.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: applicant.beneficiaryPostalCode.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: applicant.beneficiaryEmail.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: applicant.beneficiaryHomePhone.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: applicant.beneficiaryWorkPhone.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: applicant.beneficiaryCellPhone.filter((_, i) => i !== index) }));
        }
    };

    const pageName = 'beneficiary';

    const { clickNextButton, handleErrorMessage } = useApplication();

    //to ensure the fisrt beneficiary is mandatory, and other beneficiary will be optional
    const [firstBeneficiary, setFirstBeneficiary] = useState();

    useEffect(() => {
        if (applicant.beneficiaryFirstName.length > 0) {
            setFirstBeneficiary(false)
            handleErrorMessage(pageName, 'remove', 'Please add one Primary Beneficiary at least')
        }
        else {
            handleErrorMessage(pageName, 'add', 'Please add one Primary Beneficiary at least')
            setFirstBeneficiary(true)
        }
        // eslint-disable-next-line
    }, [applicant.beneficiaryFirstName]);

    const [shareRemaining, setshareRemaining] = useState(100);
    //to make sure cumulative share doesnt exceed 100
    useEffect(() => {
        let totalShare = applicant.beneficiaryShare.reduce((acc, share) => acc + (typeof share === 'number' ? share : Number(share)), 0);
        const remainingShare = 100 - totalShare;
        setshareRemaining(remainingShare);
        setBeneShare(remainingShare);
    }, [applicant.beneficiaryShare,]);
    
    useEffect(() => {
        handleFetchBeneficiary();
        
    }, []);

    useEffect(() => { 
    }, [beneficiaryObj.annuitantRelation, isLessThan18]);



    useEffect(() => {
   // Sync state with beneficiaryObj.dob when it changes
     if(beneficiaryObj.dob === ''){
        setIsLessThan18(false); 
     }
    }, [isLessThan18]);
    
    useEffect(() => {
        age = calculateAge(beneficiaryObj.dob);
        if(age < 18){
            setIsLessThan18(true); 
        }else{
            setIsLessThan18(false); 
        }
        // Sync state with beneficiaryObj.dob when it changes
    }, [beneficiaryObj.dob]);
    
    return (
        <Box>

            <Box>
            <ModifyTable 
                    fieldNames={tableHead} 
                    fieldValues={values} 
                    handleDelete={colIndex => handleDeleteBeneficiary(colIndex)} 
                    handleEdit={colIndex => handleEditBeneficiary(colIndex)}  
                    setRowIndex={setRowIndex}
                    disableEdit={false}  />
            </Box>

            <Box className="box-1-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="First Name" />
                        <TextField
                            defaultValue={beneficiaryObj.fName}
                            isClear={isClear}
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, fName: value })}
                            type={firstBeneficiary ? 'mandatory' : 'optional'}
                            name='Primary Beneficiary First Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Last Name" />
                        <TextField
                            defaultValue={beneficiaryObj.lName}
                            isClear={isClear}
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, lName: value })}
                            type={firstBeneficiary ? 'mandatory' : 'optional'}
                            name='Primary Beneficiary Last Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Relation to Annuitant" />
                        <DropdownList
                            options={relationAnnuitant}
                            isClear={isClear}
                            defaultValue={beneficiaryObj.annuitantRelation}
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, annuitantRelation: value })}
                            type={firstBeneficiary ? 'mandatory' : 'optional'}
                            name='Primary Beneficiary Relation To Annuitant'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Gender" />
                        <RadioGroup
                            options={genders}
                            isClear={isClear}
                            defaultValue={beneficiaryObj.gender}
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, gender: value })}
                            type={firstBeneficiary ? 'mandatory' : 'optional'}
                            name='Primary Beneficiary Gender'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Beneficiary Type" />
                        <DropdownList
                            options={beneficiaryType}
                            defaultValue={beneficiaryObj.benType}
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, benType: value })}
                            type={firstBeneficiary ? 'mandatory' : 'optional'}
                            name='Primary Beneficiary Beneficiary Type'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Date of Birth" />
                        <DateField
                            setStateValue={value => {
                                age = calculateAge(value);
                                setIsLessThan18(age < 18);
                                setBeneficiaryObj({ ...beneficiaryObj, dob: value })
                            }}
                            isClear={isClear}
                            defaultValue={beneficiaryObj.dob}
                            type={firstBeneficiary ? 'date' : 'optional'}
                            name='Primary Beneficiary DOB'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            key={beneficiaryObj.dob} 
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Share (1-100)" />
                        <NumberField
                            setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, share: value })}
                            minValue={0.001}
                            defaultValue={beneficiaryObj.share}
                            maxValue={shareRemaining}
                            isClear={isClear}
                            type={firstBeneficiary ? 'number' : 'optional'}
                            name='Primary Beneficiary Share'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    {needBeneficiaryPersonalInfo && (
                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Social Insurance Number' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, sin: value })}
                                defaultValue={beneficiaryInfo.sin}
                                isClear={isClear}
                                type={firstBeneficiary ? 'sin' : 'optional'}
                                name='Primary Beneficiary SIN'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>
                    )}

                </Grid>

                {needTrustee && (
                    <Grid container spacing={2} >

                        <Grid item xs={itemSpace} display={isLessThan18 ? 'block' : 'none'} >
                            <TextFieldText text="Trustee First Name" />
                            <TextField
                                setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeFname: value })}
                                defaultValue={beneficiaryObj.trusteeFname}
                                isClear={isClear}
                                type={isLessThan18 ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Trustee First Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={isLessThan18 ? 'block' : 'none'} >
                            <TextFieldText text="Trustee Last Name" />
                            <TextField
                                setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeLname: value })}
                                defaultValue={beneficiaryObj.trusteeLname}
                                isClear={isClear}
                                type={isLessThan18 ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Trustee Last Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} display={isLessThan18 ? 'block' : 'none'} >
                            <TextFieldText text="Trustee Relation to Primary Beneficiary" />
                            <DropdownList
                                options={relationAnnuitant}
                                setStateValue={value => setBeneficiaryObj({ ...beneficiaryObj, trusteeBeneficiaryRelation: value })}
                                defaultValue={beneficiaryObj.trusteeBeneficiaryRelation}
                                isClear={isClear}
                                type={isLessThan18 ? 'mandatory' : 'optional'}
                                name='Trustee Relation to Primary Beneficiary'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

                {needBeneficiaryPersonalInfo && (
                    <Grid container spacing={2}>

                        <Grid item xs={12} >
                            <TextFieldText text='Same address as Main Applicant?' largeBox={true} />
                            <RadioGroupBoolean
                                setStateValue={value => handleSameAddressChange(value)}
                                defaultValue={beneficiaryInfo.sameAddress}
                                isClear={isClear}
                                type={firstBeneficiary ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Same Address'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={6} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Email' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, email: value })}
                                defaultValue={beneficiaryInfo.email}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'email' : 'optional'}
                                name='Primary Beneficiary Email'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                largeBox={true}
                            />
                        </Grid>

                        <Grid item xs={6} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Home Phone' />
                            <PhoneField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, homePhone: value })}
                                defaultValue={beneficiaryInfo.homePhone}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'phone' : 'optional'}
                                name='Primary Beneficiary Home Phone'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={6} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Work Phone' />
                            <PhoneField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, workPhone: value })}
                                defaultValue={beneficiaryInfo.workPhone}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'phone' : 'optional'}
                                name='Primary Beneficiary Work Phone'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={6} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Cell Phone' />
                            <PhoneField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, cellPhone: value })}
                                defaultValue={beneficiaryInfo.cellPhone}
                                type='optional'
                                name='Primary Beneficiary Cell Phone'
                                pageName={pageName}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Street Number' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, streetNumber: value })}
                                defaultValue={beneficiaryInfo.streetNumber}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Street Number'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Street Name' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, streetName: value })}
                                defaultValue={beneficiaryInfo.streetName}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Street Name'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Unit' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, unit: value })}
                                defaultValue={beneficiaryInfo.unit}
                                isClear={isClear}
                                type='optional'
                                name='Primary Beneficiary Unit'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='City' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, city: value })}
                                defaultValue={beneficiaryInfo.city}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary City'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Province' />
                            <DropdownList
                                options={provinces}
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, province: value })}
                                defaultValue={beneficiaryInfo.province}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Province'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={4} display={!beneficiaryInfo.sameAddress ? 'block' : 'none'} >
                            <TextFieldText text='Postal Code' />
                            <TextField
                                setStateValue={value => setBeneficiaryInfo({ ...beneficiaryInfo, postalCode: value })}
                                defaultValue={beneficiaryInfo.postalCode}
                                isClear={isClear}
                                type={firstBeneficiary && !beneficiaryInfo.sameAddress ? 'mandatory' : 'optional'}
                                name='Primary Beneficiary Postal Code'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                        {/* <IconButton onClick={handleAddBeneficiary} >
                            <AddBox sx={{ fontSize: 45 }} />
                            <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Beneficiary</span>
                        </IconButton> */}
                        {!isUpdate && ( 
                             <IconButton onClick={handleAddBeneficiary} >
                             <AddBox sx={{ fontSize: 45 }} />
                             <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Beneficiary</span>
                            </IconButton>
                        )}

                       {isUpdate && ( 
                             <IconButton onClick={handleEditBeneficiaryCheck} >
                             <AddBox sx={{ fontSize: 45 }} />
                             <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Beneficiary</span>
                            </IconButton>
                        )}
                       
                    </Grid>

                    

                </Grid>

                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleCloseError}>
                    <Alert
                        onClose={handleCloseError}
                        severity="warning"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                         Please fill all relatable fields correctly before adding
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
                    <Alert
                        onClose={handleCloseSuccess}
                        severity="success"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        Success adding Beneficiary!
                    </Alert>
                </Snackbar>

            </Box>

        </Box>
    );
};