import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from '../../../../reducers/coapplicantReducer';
import { Box, Grid, IconButton } from '@mui/material';
import TitleText from '../../../../components/smallComponents/text/titleText/TitleText';
import TextFieldText from '../../../../components/smallComponents/text/textFieldText/TextFieldText';
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import "./style/IdentificationCoAppTaxStatus.css"
import RadioGroupBoolean from '../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean';
import { useApplication } from '../../../../contexts/ApplicationContext';
import { ArrowBack, ArrowForward } from '@mui/icons-material';


/**
 * Component for handling the Co-Applicant Tax Status.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.reasons - The list of reasons.
 * @returns {JSX.Element} The JSX element representing the Co-Applicant Tax Status component.
 */


export default function IdentificationCoAppTaxStatus({ reasons, enable }) {

    const { clickNextButton, handleErrorMessage } = useApplication()

    const dispatch = useDispatch();
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const applicant = useSelector((state) => state.applicantInfo);

    const pageName = 'identification';

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const handleCoApplicantTaxResidenceChangeUS = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        //making sure the value is being passed to the global variable
        //as the defaultValue wont change the global state variable
        if (value === true) {
            handleCoApplicantTinUS("haveTinUS", true)
        }
        else {
            handleCoApplicantTinUS("haveTinUS", null)
        }
    };

    const handleCoApplicantTinUS = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        if (value === false) {
            handleCoapplicantChange("tinUS", '')
        }
        else {
            handleCoapplicantChange("reasonOfNoTinUS", "")
        }
    };


    const handleCoApplicantOther = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        //making sure the value is being passed to the global variable
        //as the defaultValue wont change the global state variable
        if (value === true) {
            handleCoApplicantOutside("haveTinOther", true)
        }
        else {
            handleCoApplicantOutside("haveTinOther", null)
        }
    };

    const handleCoApplicantOutside = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        //setting the global value to default or empty if user changed certain field
        if (value === false) {
            handleCoapplicantChange("tinOther", '')
            handleCoapplicantChange("countryName", '')
        }
        else {
            handleCoapplicantChange("reasonOfNoTinOther", '')
        }
    };

    const residenceOfUS = coapplicant.residenceOfUnitedStates;

    const residenceOfOutside = coapplicant.otherResidence;

    const questions = [
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <TextFieldText text='Tax Residence of Canada' />
                        <RadioGroupBoolean
                            setStateValue={value => handleCoapplicantChange("residenceOfCanada", value)}
                            defaultValue={coapplicant.residenceOfCanada}
                            type={applicant.anyCoapplicant ? 'mandatory' : 'optional'} name='Co-Applicant Tax Residence of Canada' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>

                </Grid>
            </Grid>
        </Grid>,
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={residenceOfUS ? 4 : 12}>
                    <Box>
                        <TextFieldText text='Tax Residence of United States' />
                        <RadioGroupBoolean
                            setStateValue={value => handleCoApplicantTaxResidenceChangeUS("residenceOfUnitedStates", value)}
                            defaultValue={coapplicant.residenceOfUnitedStates}
                            type={applicant.anyCoapplicant ? 'mandatory' : 'optional'} name='Co-Applicant Tax Residence of United States' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text='Do you have Taxpayer identification number (TIN) from the U.S. ?' />
                        <RadioGroupBoolean
                            setStateValue={value => handleCoApplicantTinUS("haveTinUS", value)}
                            defaultValue={coapplicant.haveTinUS}
                            disabled={!coapplicant.residenceOfUnitedStates}
                            type={applicant.anyCoapplicant && coapplicant.residenceOfUnitedStates ? 'mandatory' : 'optional'} name='Co-Applicant Has US TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}

                        />
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS && coapplicant.haveTinUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text='TaxPayer Identification Number (TIN) ' />
                        <TextField
                            disabled={!coapplicant.residenceOfUnitedStates || !coapplicant.haveTinUS}
                            setStateValue={value => handleCoapplicantChange("tinUS", value)}
                            defaultValue={coapplicant.tinUS}
                            type={applicant.anyCoapplicant && (coapplicant.residenceOfUnitedStates && coapplicant.haveTinUS) ? 'mandatory' : 'optional'} name='Co-Applicant TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        ></TextField>
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS && !coapplicant.haveTinUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text="Explain why you don't have TIN" />
                        <DropdownList
                            options={reasons}
                            disabled={!coapplicant.residenceOfUnitedStates || coapplicant.haveTinUS}
                            setStateValue={value => handleCoapplicantChange("reasonOfNoTinUS", value)}
                            defaultValue={coapplicant.reasonOfNoTinUS}
                            type={applicant.anyCoapplicant && (coapplicant.residenceOfUnitedStates && !coapplicant.haveTinUS) ? 'mandatory' : 'optional'} name='Co-Applicant Reason for No TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        ></DropdownList>
                    </Box>
                </Grid>
            </Grid>
        </Grid>,
        <Box>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={residenceOfOutside ? 6 : 12}>
                        <Box>
                            <TextFieldText text='Tax residence of a jurisdiction other than Canada or the United States?' />
                            <RadioGroupBoolean
                                setStateValue={value => handleCoApplicantOther("otherResidence", value)}
                                defaultValue={coapplicant.otherResidence}
                                type={applicant.anyCoapplicant ? 'mandatory' : 'optional'} name='Co-Applicant Tax Residence of Other Jurisdiction' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={coapplicant.otherResidence && coapplicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text='If yes, what is the country name of the Taxpayer Identification Number (TIN) that you have? '
                            />
                            <TextField
                                disabled={!coapplicant.otherResidence || !coapplicant.haveTinOther}
                                setStateValue={value => handleCoapplicantChange("countryName", value)}
                                defaultValue={coapplicant.countryName}
                                type={applicant.anyCoapplicant && (coapplicant.otherResidence && coapplicant.haveTinOther) ? 'mandatory' : 'optional'} name='Co-Applicant TIN Country' pageName={pageName} buttonCheck={clickNextButton[pageName]}>
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={coapplicant.otherResidence && !coapplicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text="Explain why you don't have TIN" />
                            <DropdownList
                                options={reasons}
                                disabled={!coapplicant.otherResidence || coapplicant.haveTinOther}
                                setStateValue={value => handleCoapplicantChange("reasonOfNoTinOther", value)}
                                defaultValue={coapplicant.reasonOfNoTinOther}
                                type={applicant.anyCoapplicant && (coapplicant.otherResidence && !coapplicant.haveTinOther) ? 'mandatory' : 'optional'} name='Applicant Reason for No TIN Other' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            ></DropdownList>
                        </Box>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} display={coapplicant.otherResidence ? 'block' : 'none'}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box>
                            <TextFieldText text='Do you have Taxpayer identification number (TIN) outside the U.S. or Canada?' />
                            <RadioGroupBoolean
                                setStateValue={value => handleCoApplicantOutside("haveTinOther", value)}
                                defaultValue={coapplicant.haveTinOther}
                                disabled={!coapplicant.otherResidence}
                                type={applicant.anyCoapplicant && coapplicant.otherResidence ? 'mandatory' : 'optional'} name='Co-Applicant Has Other TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}

                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={coapplicant.otherResidence && coapplicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text='TaxPayer Identification Number (TIN) ' />
                            <TextField
                                disabled={!coapplicant.otherResidence || !coapplicant.haveTinOther}
                                setStateValue={value => handleCoapplicantChange("tinOther", value)}
                                defaultValue={coapplicant.tinOther}
                                type={applicant.anyCoapplicant && (coapplicant.otherResidence && coapplicant.haveTinOther) ? 'mandatory' : 'optional'} name='Applicant TIN Other' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            ></TextField>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    ];

    // questions tab prev and next function
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const goToPreviousQuestion = () => {
        setCurrentTabIndex(currentTabIndex - 1);
    };

    const goToNextQuestion = () => {
        setCurrentTabIndex(currentTabIndex + 1);
    };

    // useEffect(() => {
    //     if (currentTabIndex < 2 && applicant.anyCoapplicant) {
    //         handleErrorMessage(pageName, 'add', 'Please Review All Co-Applicant Tax Status Information')
    //     }
    //     else {
    //         handleErrorMessage(pageName, 'remove', 'Please Review All Co-Applicant Tax Status Information')
    //     }
    //     // eslint-disable-next-line
    // }, [currentTabIndex]);

    return (
        <Box className="section-co-box-field-CoApp" display={enable ? 'block' : 'none'} >

            <TitleText text="Co-Applicant Tax Status" />

            <Box sx={{ display: 'flex', justifyContent: 'center',position: 'relative', alignItems: 'center' }}>
                <IconButton onClick={goToPreviousQuestion} disabled={currentTabIndex === 0} sx={{position: 'absolute', left :'0', marginLeft: '20px'}}>
                    <ArrowBack sx={{ fontSize: 45 }} />
                </IconButton>
                <Box>
                    {questions[currentTabIndex]}
                </Box>
                <IconButton onClick={goToNextQuestion} disabled={currentTabIndex === questions.length - 1} sx={{position: 'absolute', right :'0', marginRight: '20px'}}>
                    <ArrowForward sx={{ fontSize: 45 }} />
                </IconButton>
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {currentTabIndex < 2 && applicant.anyCoapplicant ? 'please review until the end of the Tab' : ''}
            </Box>

        </Box>
    );
};