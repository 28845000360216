import { Box, Grid, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useApplication } from "../../../../contexts/ApplicationContext";
// import { useSelector } from "react-redux";
// import MainAppFinancialSection from "./section/MainAppFinancialSection";
// import CoAppFinancialSection from "./section/CoAppFinancialSection";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import LiabilitySubsection from "./section/subsection/LiabilitySubsection";
import AssetSubsection from "./section/subsection/AssetSubsection";
import IncomeSubsection from "./section/subsection/IncomeSubsection";
import RatioSubsection from "./section/subsection/RatioSubsection";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import "./style/FinancialAnalysis.css";


/**
 * @returns - Financial Analysis component for the application page
 * @description - Disable the CoAppFinancialSection component for now in order to save all the data in main applicant states
 */


export default function FinancialAnalysis() {

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // const applicant = useSelector((state) => state.applicantInfo);

    const itemSpace = 12;

    const title = "Financial Analysis";
    const color = "#E1E8EF";
    const applicantType = 'main';
    const pageName = 'financial';

    return (
        <Box className="financial-box-overall">

            {/* <Box>
                <MainAppFinancialSection />
                {applicant.anyCoapplicant && <CoAppFinancialSection />}
            </Box> */}

            <Box>
                <TitleText text={title} />
            </Box>

            <Box className="mt-10">
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className="mt-10">
                <RatioSubsection />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 20 }} label="Liability" value={0} />
                    <Tab style={{ fontSize: 20 }} label="Asset" value={1} />
                    <Tab style={{ fontSize: 20 }} label="Income" value={2} />
                </Tabs>
            </Box>

            <Box>
                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                        <LiabilitySubsection applicantType={applicantType} color={color} />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                        <AssetSubsection applicantType={applicantType} color={color} />
                    </Grid>

                    <Grid item xs={itemSpace} display={tabValue === 2 ? 'block' : 'none'} >
                        <IncomeSubsection applicantType={applicantType} color={color} />
                    </Grid>

                </Grid>
            </Box>
            <Box className="financial-box-button">
                <PrevButton />
                <NextButton checkIncome={true} checkWorth = {true} checkTdsr={true} pageName={pageName} />
            </Box>
        </Box>
    );
};