import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import applicantReducer from './reducers/applicantReducer';
import coapplicantReducer from './reducers/coapplicantReducer';
import applicationReducer from './reducers/applicationReducer';
import additionalInfoReducer from './reducers/additionalInfoReducer';
import userReducer from './reducers/userReducer';
import otherRequest from './reducers/otherRequest';

// MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const store = configureStore({
  reducer: {
    applicantInfo: applicantReducer,
    coapplicantInfo: coapplicantReducer,
    applicationInfo: applicationReducer,
    additionalInfo: additionalInfoReducer,
    userInfo: userReducer,
    otherRequestInfo: otherRequest
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <App instance={msalInstance}/>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
