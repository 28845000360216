import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userID: "",
  userName: "",
  // An element in userInfos are {userType: "", roleName: ""}
  userInfos: [],
  advisorCode: "",
  advisorID: "",
  email: "",
  cellPhone: "",
};

const userSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUserInfo: () => initialState,
  },
});

export const { setUserInfo, resetUserInfo } = userSlice.actions;

export default userSlice.reducer;
