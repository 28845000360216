import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCardHome from '../../../components/smallComponents/preAppCard/whiteCardHome'
import './Home.css'
import { Box } from '@mui/material';
import { useApplication } from '../../../contexts/ApplicationContext';

/**
 * Renders the Home component.
 *
 * @returns {JSX.Element} The rendered Home component.
 */
function ApplicationHome() {
    const { setSelectedComponent } = useApplication();

   
    return (
        <Box>
            <HeaderBox text='Welcome to AI Financial Online Portal' />
            <div className="pre-application-page">
                <WhiteCardHome 
                    size='small'
                    title="Application/Request"
                    url="RequestOpt"
                    list={null}
                    buttonName="Application/Request"
                />
                <WhiteCardHome
                    size='small'
                    title="My Account"
                    url="myAccount"
                    list={null}
                    buttonName="My Account"
                />
            </div>

        </Box>
    );
}

export default ApplicationHome;