import { LogLevel } from "@azure/msal-browser";

const tenantName = process.env.REACT_APP_B2C_TENANT_NAME;
const clientId = process.env.REACT_APP_B2C_CLIENT_ID;
const loginAuthority = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/B2C_1_login_custom_ui1`;
const signupAuthority = `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/B2C_1_signup_custom_ui1`;
/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId,
    authority: loginAuthority,
    knownAuthorities: [`${tenantName}.b2clogin.com`], // Mark your B2C tenant's domain as trusted.
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URL, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: process.env.REACT_APP_B2C_REDIRECT_URL, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const loginRequest = {
  authority: loginAuthority,
};

const signupRequest = {
  authority: signupAuthority,
};

export { loginRequest, signupRequest };
