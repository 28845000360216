import { useNavigate } from "react-router-dom";
import CaseTable from "./CaseTable";
import { useEffect, useState } from "react";
import { USER_ROLE } from "../../../constants/UserRoleMap";
import { useSelector, useDispatch } from 'react-redux';
import { updateApp } from "../../../services/appFieldUpdateService";
import { setApplicationInfo } from "../../../reducers/applicationReducer";
import { fetchCaseOverview } from "../../../services/fetchCaseService";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { allAppStatuses } from "../../../constants/lists";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { axiosInstance } from "../../../axios";
/**
 * @param {object} item - a data object that contains id, submitDate, verifiedDate, advisorName, status
 */

const ApplicationTableItem = ({ item, index, userInfo, checkAppStatus = () => { } }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showCases, setShowCases] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const [cases, setCases] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [originalAppStatus, setOriginalAppStatus] = useState(item.applicationStatus);
    const [newAppStatus, setNewAppStatus] = useState("");
    const handleViewClick = () => {
        let applicationType = 'quick';
        if(item.fundType === 'Bank Loan'){
            applicationType = 'quick'
        }
        navigate(`/details/${item.fundType.replace(' ', '-')}/${applicationType}/${item.applicationID}`);
        
    }

    const handleEditClick = () => {
        //navigate(`/details/${item.fundType.replace(' ', '-')}/${item.applicationID}`);
        dispatch(setApplicationInfo({ attribute: 'applicationID', value: item.applicationID }));
        const nextUrl = `/application/quick/general/${item.applicationID}`;
        window.open(`${nextUrl}?applicationID=${item.applicationID}`);

    }

    const handleShowCasesClick = () => {
        setIsRotated(prevState => !prevState);
        setShowCases(!showCases);
    }

    const handleSelectChange = (event) => {
        setNewAppStatus(event.target.value);
        setOpenDialog(true);
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    }

    const handleAppStatusChange = () => {
        updateApp(item.applicationID, { 'applicationStatus': newAppStatus });
        addAppStatusChangeHistory(item.applicationID, originalAppStatus, newAppStatus, userInfo.userName, userInfo.userID);

        item.applicationStatus = newAppStatus;
        setOriginalAppStatus(newAppStatus);
        setNewAppStatus("");

    }

    const addAppStatusChangeHistory = (applicationID, originalStatus, newStatus, userName, userID) => {
        axiosInstance.post(`app/status/history/add`, {
            applicationID: applicationID,
            originalStatus: originalStatus,
            newStatus: newStatus,
            userName: userName,
            userID: userID
        });
    }

    const handleDialogConfirm = () => {
        handleAppStatusChange();
        setOpenDialog(false);
    }



    const fetchAppCases = async (applicationID) => {
        const data = await fetchCaseOverview(applicationID);
        setCases(data);
        const allCompleted = data.every(caseItem => caseItem.caseStatus.startsWith('5'));
        checkAppStatus(applicationID, allCompleted);
    }

    useEffect(() => {
        if (showCases) {
            fetchAppCases(item.applicationID);
        } else {
            setCases([]);
        }
    }, [showCases]);

    //Determines the text color className based on the number of days since application first approved date.
    const getDaysSinceApplyColor = (days) => {
        if (days > 90) return 'bg-gray-300';
        if (days > 60) return 'bg-red-400';
        if (days > 30) return 'bg-orange-300';
        if (days > 15) return 'bg-yellow-300';
        return 'bg-green-400';
    }

    const userIsNotCustomer = userInfo.userInfos.every(({ userType }) => userType !== USER_ROLE.CUSTOMER)
    const userIsNotOperator = userInfo.userInfos.every(({ userType }) => userType !== USER_ROLE.OPERATOR)
    const userIsOperator = userInfo.userInfos.some(({ userType }) => userType === USER_ROLE.OPERATOR)

    return (
        <>
            <tr key={item.applicationID} className="h-12 hover:bg-gray-100">
                <td className="text-base font-bold p-2 text-end">{index + 1}</td>
                {/*  arrow button */}
                <td className="text-base font-semibold p-2 text-center flex flex-row">
                    <div className="flex items-center">
                        {userIsNotCustomer && originalAppStatus !== "Editing" && (<button
                            className={`px-3 text-xl font-extrabold text-lg text-cyan-500 transform transition-transform duration-300 ${isRotated ? 'rotate-90' : 'rotate-0'}`}
                            onClick={handleShowCasesClick}
                        >
                            &#62;
                        </button>)}
                        {item.applicationID}
                    </div>
                </td>
                <td className="text-base font-semibold p-2 text-center">{item.requestType}</td>
                <td className="text-base font-semibold p-2 text-center">{item.fundType}</td>
                <td className="text-base font-semibold p-2 text-center">{item.applicantName}</td>
                <td className="text-base font-semibold p-2 text-center">{item.servingAdvisorName}</td>
                <td className="text-base font-semibold p-2 text-center">{item.supportingAdvisorName}</td>
                <td className="text-base font-semibold p-2 text-center">{item.firstSubmittedDate}</td>
                <td className="text-base font-semibold p-2 text-center">{item.approvedDate}</td>
                <td className="text-base font-semibold p-2 text-center">
                    {userIsOperator ? (<FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <Select
                            labelId="app-status-label"
                            value={originalAppStatus}
                            onChange={handleSelectChange}
                            label={originalAppStatus}
                        >
                            {allAppStatuses.map((name) => (
                                <MenuItem key={name} value={name}> {name} </MenuItem>))}
                        </Select>
                    </FormControl>) :  originalAppStatus }
                </td>
                {userIsOperator && (<td className="text-base font-bold p-2 text-center flex justify-center">
                    <div className={`w-1/2 ${getDaysSinceApplyColor(item.daysSinceApply)}`}>
                        {item.daysSinceApply}
                    </div>
                </td>)}
                <td className="h-12">
                    <button
                        className={`rounded-lg w-1/2 text-white mx-4 px-4 flex justify-center ${item.applicationStatus === 'Editing' ? 'bg-custom-yellow' : 'bg-custom-blue'
                            }`}
                        onClick={item.applicationStatus !== 'Editing' ? handleViewClick : handleEditClick}
                    >
                        {item.applicationStatus !== 'Editing' ? 'View' : 'Edit'}
                    </button>
                </td>
                {/* non breaking space */}
                <td className="min-w-[100px]">&nbsp;</td>
                <td className="min-w-[100px]">&nbsp;</td>
                {/* Zero-Width Space */}
                {userIsNotOperator && (<td className="min-w-[100px]">&#8203;</td>)}
            </tr>

            {/* click arrow button and show cases data */}
            {showCases && <CaseTable cases={cases} userInfo={userInfo} fetchAppCases={fetchAppCases} />}

            <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Change Application Status
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to change application status?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>CANCEL</Button>
                    <Button onClick={handleDialogConfirm} autoFocus>
                        CONFIRM
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ApplicationTableItem;