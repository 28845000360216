import { Button } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import "./style/PrevButton.css"


/**
 * @returns - A button that when clicked will call the handlePrevButtonClick function from the ApplicationContext
 */


export default function PrevButton() {

    const { page, setPage } = useApplication();

    const handlePrevButtonClick = () => {
        // Set a timeout for calling the page change function to simulate a time lag
        // Enhance the user experience by standardizing the time lag between the next and prev buttons
        setTimeout(() => {
            handlePageChange();
        }, 200);
    };

    const handlePageChange = () => {
        // Reduce the page number by 1 if the current page is not the first page
        if (page > 0) {
            setPage(page - 1);
        }
    };

    return (
        <Button variant="contained" className="button-prev" onClick={handlePrevButtonClick}>Prev</Button>
    );
};