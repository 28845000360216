export default class MonthlyRepayment {
    
    static INTERESTONLY = "Interest Only";
    static PRINCIPALANDINTEREST = "Principal and Interest";

    constructor(option) {
        this.option = option;
    }

    toString() {
        return this.option;
    }
};