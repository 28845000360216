import React, { useEffect, useState } from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './Quick.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
import { useDispatch, useSelector } from 'react-redux';
import RadioGroup from '../../../components/smallComponents/radioGroup/RadioGroup'
import BadgeComponent from '../../../components/smallComponents/Badge/Badge';
import NumberField from '../../../components/smallComponents/numberField/NumberField';
import NumberFieldMoney from "../../../components/smallComponents/numberField/NumberFieldMoney";
import { setApplicantInfo } from '../../../reducers/applicantReducer';
/**
 * Quick component represents a page where users can select the type of loan they want to apply for.
 */
function Quick() {
    const dispatch = useDispatch();
    const { setSelectedComponent } = useApplication();
    const applicant = useSelector((state) => state.applicantInfo); 
    const [displayInputBox, setDisplayInputBox] = useState(false);
    const [allowNext, setAllowNext] = useState(true);
    const values = ['$200,000', '$100,000', 'other amount']    

    useEffect(()=>{
        dispatch(setApplicantInfo({attribute: "loanAmountApplied", value: '200000'}));
    }, []);

    /**
     * Handles the change of applicant information.
     * @param {string} attribute - The attribute to be updated.
     * @param {string} value - The new value for the attribute.
     */

    const handleLoanOptionChange = (value) => {
        if(value === 'other amount'){
            setDisplayInputBox(true);
        } else {
            value = value.replace(/\D/g, '');
            dispatch(setApplicantInfo({attribute: "loanAmountApplied", value: value}));
            setDisplayInputBox(false);
            setAllowNext(true);
        }
    }

    const handleLoanAmountChange = (value) => {
        dispatch(setApplicantInfo({attribute: "loanAmountApplied", value: value}));
        if(value === "" || parseInt(value) > 200000 || parseInt(value) < 10000){
            setAllowNext(false);
        } else{
            setAllowNext(true);
        }
    }

    return (
        <Box>
            <HeaderBox text='What is the loan amount you want to apply today?' />
            <div className="textPage">
                <BadgeComponent badgeContent={'Best option'} children={
                    <RadioGroup
                        options={values}
                        setStateValue={value => handleLoanOptionChange(value)}
                        defaultValue={values[0]}
                        type='mandatory'
                        name='Loan Amount Applied'
                        buttonCheck = {true}
                />} />
                <Box display={displayInputBox? 'inline': 'none'}>
                    <NumberFieldMoney 
                        type={displayInputBox? 'number': 'optional'}
                        name = 'Loan Amount Applied'
                        defaultValue={applicant.loanAmountApplied}
                        setStateValue={value => handleLoanAmountChange(value)}
                        minValue = {10000}
                        maxValue = {200000}
                        buttonCheck = {true}
                    />
                </Box>

            </div>
            <div className='button-Area'>
                    <NavLink onClick={() => setSelectedComponent('Loan')} className={'backButton'}>
                        <button className="bigButton">Back</button>
                    </NavLink>
                    {allowNext? <NavLink onClick={() => setSelectedComponent('QuickNext') } className={'nextButton'}>
                        <button className="bigButton">Next</button>
                    </NavLink> : ''}
                    
            </div>
        </Box>
    );
}

export default Quick;