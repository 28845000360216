import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Snackbar, Alert } from "@mui/material";
import { AddBox, SearchOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import NumberField from "../../../../../../components/smallComponents/numberField/NumberField";
import NumberFieldMoney from "../../../../../../components/smallComponents/numberField/NumberFieldMoney";
import DropdownList from "../../../../../../components/smallComponents/dropdownList/DropdownList";
import Table from "../../../../../../components/mediumComponents/table/Table";
import ModifyTable from "../../../../../../components/mediumComponents/table/ModifyTable";
import { answerAttributes, investorOptions } from "../../../../../../constants/lists";
import { getAllItems, addItem, deleteItemByID, updateItem} from "../../../../../../services/multiAddObjectService";
import CreateTimeStamp from "../../../../../../utilities/createTimeStamp";
import Big from 'big.js';
import bigInt from 'big-integer';
import "./style/LiabilitySubsection.css";
import { getDropdownList } from "../../../../../../services/dropdownService";
import { debounce } from 'lodash'; 
import TextField from '@mui/material/TextField';


/**
 * @description - This component imports a table component to display the stored liabilities and a field box to add an new liability
 * @param {string} applicantType - Type of applicant (main or co)
 * @param {string} color - Background color of the liability field
 * @returns - Liability Subsection component for the Financial Analysis field component 
 */


export default function LiabilitySubsection({ applicantType, color }) {

    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const [isUpdate, setIsUpdate] = useState(false);
    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange } = useApplication();

    //The rowIndex of the table that user clicks on
    const [rowIndex, setRowIndex] = useState(-1);

    const clearInputs = ()=>{
        
        // Trigger the clearing of the input fields in child components after adding a new liability
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        // Reset the local state to empty strings after adding a new liability
        setLiability({
            owner: '',
            type: '',
            creditor: '',
            balance: '',
            propertyTax: '',
            maintenanceFee: '',
            monthlyPayment: ''
        });
    }

    const [liability, setLiability] = useState(
        {
            owner: '',
            type: '',
            creditor: '',
            balance: '',
            propertyTax: '',
            maintenanceFee: '',
            monthlyPayment: ''
        }
    );
    const [updateLiabilityIndex, setUpdateLiabilityIndex] = useState(0);
   
    const [institutions, setInstitutions] = useState([]);
    const [liabilityType, setLiabilityType] = useState([]);
    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);


    const [inputValue, setInputValue] = useState('');

    const itemSpace = 3;

    const itemType = 'liabilities';

    // Question index and score range for the pre-fill question
    const questionIndex = 2;
    const questionScore = [1, 2, 5, 10, 20];

    const hasCoApplicant = applicant.anyCoapplicant;

    const applicantName = applicant.firstname + ' ' + applicant.lastname;
    const coApplicantName = hasCoApplicant ? coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const bothApplicantsName = hasCoApplicant ? applicant.firstname + ' ' + applicant.lastname + ' & ' + coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const owners = hasCoApplicant ? [applicantName, coApplicantName, bothApplicantsName] : [applicantName];

    // Array of strings that represent the names of the column fields in table component
    const names = ['owner', 'type', 'creditor', 'balance', 'propertyTax', 'maintenanceFee', 'monthlyPayment'];


   

    // const onInputChange = (e) => {
    //     handleInputChange(e.target.value);
    // };
    // Array of arrays that represent the values of the column fields in table component
    // Each individual array (global state) contains the specific attribute value from every liability
    const values = applicantType === 'main' ?
        [
            applicant.liabilityBelongTo,
            applicant.liabilityType,
            applicant.liabilityInstitutionName,
            applicant.liabilityBalance,
            applicant.annualPropertyTax,
            applicant.monthlyManagementMaintenanceFee,
            applicant.monthlyPayment
        ]
        :
        [
            coapplicant.liabilityBelongTo,
            coapplicant.liabilityType,
            coapplicant.liabilityInstitutionName,
            coapplicant.liabilityBalance,
            coapplicant.annualPropertyTax,
            coapplicant.monthlyManagementMaintenanceFee,
            coapplicant.monthlyPayment
        ];
    

    const fetchFinancials = async ()=>{
        const financials = await getDropdownList("financialInstitutions");
        setInstitutions(financials.map(x=>x['financialInstitution']));
        setFilteredInstitutions(financials.map(x=>x['financialInstitution']));
    }

    const fetchLiabilityType = async ()=>{
        const liabilityList = await getDropdownList("liabilityType");
        setLiabilityType(liabilityList.map(x=>x['liabilityType']));
    }

    const [filteredInstitutions, setFilteredInstitutions] = useState(institutions);
     // Handler for input change, with debouncing to limit excessive filtering
    //  const handleInputChange = debounce((value) => {
    //     setInputValue(value);
    //     if (value) {
    //         const filtered = institutions.filter(inst => 
    //             inst.toLowerCase().includes(value.toLowerCase())
    //         );
    //         setFilteredInstitutions(filtered);
    //     } else  if(value === ''){
    //         setFilteredInstitutions(institutions);
    //     }
    // }, 100);  // 300ms debounce delay
    
    // Pre-fill the net worth question and calculate investor profile score based on the calculated net worth
    const calculateNetWorth = (liabilityChange) => {

        const netWorth = liabilityChange === '' ? 0 : parseFloat(liabilityChange)
            +
            applicant.assetMarketValue
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            +
            coapplicant.assetMarketValue
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            -
            applicant.liabilityBalance
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            -
            coapplicant.liabilityBalance
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0);

        if (netWorth <= 25000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [1, investorOptions[2][0], 1] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [1, investorOptions[2][0], 1] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[0]);
            handleCoInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (netWorth <= 50000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [2, investorOptions[2][1], 2] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [2, investorOptions[2][1], 2] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[1]);
            handleCoInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (netWorth <= 100000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [3, investorOptions[2][2], 5] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [3, investorOptions[2][2], 5] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[2]);
            handleCoInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (netWorth <= 200000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [4, investorOptions[2][3], 10] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [4, investorOptions[2][3], 10] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[3]);
            handleCoInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [5, investorOptions[2][4], 20] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [5, investorOptions[2][4], 20] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[4]);
            handleCoInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleAddLiability = async () => {

        // Calculate the net worth when adding a new liability
        calculateNetWorth(-liability.balance);

        const ownerName =liability.owner

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: [...applicant.liabilityBelongTo, ownerName] }));
            dispatch(setApplicantInfo({ attribute: 'liabilityType', value: [...applicant.liabilityType, liability.type] }));
            dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: [...applicant.liabilityInstitutionName, liability.creditor] }));
            dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: [...applicant.liabilityBalance, liability.balance] }));
            dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: [...applicant.annualPropertyTax, liability.propertyTax] }));
            dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: [...applicant.monthlyManagementMaintenanceFee, liability.maintenanceFee] }));
            dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: [...applicant.monthlyPayment, liability.monthlyPayment] }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBelongTo', value: [...coapplicant.liabilityBelongTo, ownerName] }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityType', value: [...coapplicant.liabilityType, liability.type] }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityInstitutionName', value: [...coapplicant.liabilityInstitutionName, liability.creditor] }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBalance', value: [...coapplicant.liabilityBalance, liability.balance] }));
            dispatch(setCoapplicantInfo({ attribute: 'annualPropertyTax', value: [...coapplicant.annualPropertyTax, liability.propertyTax] }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: [...coapplicant.monthlyManagementMaintenanceFee, liability.maintenanceFee] }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyPayment', value: [...coapplicant.monthlyPayment, liability.monthlyPayment] }));
        }
        // Create a new liability object to be added to the database
        const newLiability = {
            applicationID: application.applicationID,
            owner: ownerName,
            liabilityType: liability.type,
            financialInstitution: liability.creditor,
            liabilityBalance: new Big(liability.balance),
            annualPropertyTax: liability.propertyTax === '' ? null : new Big(liability.propertyTax),
            condoFeeMonthlyPayment: liability.maintenanceFee === '' ? null : new Big(liability.maintenanceFee),
            liabilityMonthlyPayment: new Big(liability.monthlyPayment),
            propertyTaxMonthlyPayment: liability.propertyTax === '' ? null : new Big(liability.propertyTax).div(12),
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new liability to the database
        const addedLiability = await addItem(itemType, newLiability);

        // Push the DB generated liabilityID from API response to the global state
        dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value: [...applicant.LiabilityIDs, addedLiability.liabilityID] }));

    };

    // Provide input field validation before adding a new liability
    const handleAddLiabilityCheck = () => {

        const isFieldEmpty = (fields) => fields.some(field => field === '');
        const isNegativeAmount = (amounts) => amounts.some(amount => parseFloat(amount) < 0);

        if (isFieldEmpty([liability.owner, liability.type, liability.creditor, liability.balance, liability.monthlyPayment])) {
            setOpenError(true);
        }
        else if (isNegativeAmount([liability.balance, liability.monthlyPayment])) {
            setOpenError(true);
        }
        else if (liability.type.startsWith('Mortgage') &&
            (isFieldEmpty([liability.propertyTax, liability.maintenanceFee]) || isNegativeAmount([liability.propertyTax, liability.maintenanceFee]))) {
            setOpenError(true);
        }
        else {
            handleAddLiability();
            clearInputs();
            setOpenSuccess(true);
        }
    };

    const handleDeleteLiabilityNODB = async (index) => {

        // Calculate the net worth when deleting a liability
        calculateNetWorth(applicantType === 'main' ? applicant.liabilityBalance[index] : coapplicant.liabilityBalance[index]);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value: applicant.LiabilityIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: applicant.liabilityBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityType', value: applicant.liabilityType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: applicant.liabilityInstitutionName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: applicant.liabilityBalance.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: applicant.annualPropertyTax.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: applicant.monthlyManagementMaintenanceFee.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: applicant.monthlyPayment.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBelongTo', value: coapplicant.liabilityBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityType', value: coapplicant.liabilityType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityInstitutionName', value: coapplicant.liabilityInstitutionName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBalance', value: coapplicant.liabilityBalance.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'annualPropertyTax', value: coapplicant.annualPropertyTax.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: coapplicant.monthlyManagementMaintenanceFee.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyPayment', value: coapplicant.monthlyPayment.filter((_, i) => i !== index) }));
        }
    };

    const handleDeleteLiability = async (index) => {

        // Calculate the net worth when deleting a liability
        calculateNetWorth(applicantType === 'main' ? applicant.liabilityBalance[index] : coapplicant.liabilityBalance[index]);

        // Delete the liability from the database
        const liabilityId = bigInt(applicant.LiabilityIDs[index]);
        const deletedLiability = await deleteItemByID(itemType, liabilityId);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value: applicant.LiabilityIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: applicant.liabilityBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityType', value: applicant.liabilityType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: applicant.liabilityInstitutionName.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: applicant.liabilityBalance.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: applicant.annualPropertyTax.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: applicant.monthlyManagementMaintenanceFee.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: applicant.monthlyPayment.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBelongTo', value: coapplicant.liabilityBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityType', value: coapplicant.liabilityType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityInstitutionName', value: coapplicant.liabilityInstitutionName.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'liabilityBalance', value: coapplicant.liabilityBalance.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'annualPropertyTax', value: coapplicant.annualPropertyTax.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: coapplicant.monthlyManagementMaintenanceFee.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'monthlyPayment', value: coapplicant.monthlyPayment.filter((_, i) => i !== index) }));
        }
    };

    const handleEditLiability = (index) =>{

        let ownername = applicant.liabilityBelongTo[index];

        setLiability({
            owner: ownername,
            type: applicant.liabilityType[index],
            creditor: applicant.liabilityInstitutionName[index],
            balance: applicant.liabilityBalance[index],
            propertyTax: applicant.annualPropertyTax[index],
            maintenanceFee: applicant.monthlyManagementMaintenanceFee[index],
            monthlyPayment: applicant.monthlyPayment[index]
        })
        setIsUpdate(prevState => !prevState);
        handleDeleteLiabilityNODB(index);
        setUpdateLiabilityIndex(applicant.LiabilityIDs[index]);
    }

    const sendEditLiability = async () => {

          // Calculate the net worth when adding a new liability
          calculateNetWorth(-liability.balance);

          let ownerName = liability.owner;

          if (applicantType === 'main') {
              dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value:[updateLiabilityIndex]}));
              dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: [...applicant.liabilityBelongTo, ownerName] }));
              dispatch(setApplicantInfo({ attribute: 'liabilityType', value: [...applicant.liabilityType, liability.type] }));
              dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: [...applicant.liabilityInstitutionName, liability.creditor] }));
              dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: [...applicant.liabilityBalance, liability.balance] }));
              dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: [...applicant.annualPropertyTax, liability.propertyTax] }));
              dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: [...applicant.monthlyManagementMaintenanceFee, liability.maintenanceFee] }));
              dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: [...applicant.monthlyPayment, liability.monthlyPayment] }));
          }
          else if (applicantType === 'co') {
              dispatch(setCoapplicantInfo({ attribute: 'liabilityBelongTo', value: [...coapplicant.liabilityBelongTo, ownerName] }));
              dispatch(setCoapplicantInfo({ attribute: 'liabilityType', value: [...coapplicant.liabilityType, liability.type] }));
              dispatch(setCoapplicantInfo({ attribute: 'liabilityInstitutionName', value: [...coapplicant.liabilityInstitutionName, liability.creditor] }));
              dispatch(setCoapplicantInfo({ attribute: 'liabilityBalance', value: [...coapplicant.liabilityBalance, liability.balance] }));
              dispatch(setCoapplicantInfo({ attribute: 'annualPropertyTax', value: [...coapplicant.annualPropertyTax, liability.propertyTax] }));
              dispatch(setCoapplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: [...coapplicant.monthlyManagementMaintenanceFee, liability.maintenanceFee] }));
              dispatch(setCoapplicantInfo({ attribute: 'monthlyPayment', value: [...coapplicant.monthlyPayment, liability.monthlyPayment] }));
          }
  
          // Create a new liability object to be added to the database
          const newLiability = {
              liabilityID: updateLiabilityIndex,  
              applicationID: application.applicationID,
              owner: ownerName,
              liabilityType: liability.type,
              financialInstitution: liability.creditor,
              liabilityBalance: new Big(liability.balance),
              annualPropertyTax: liability.propertyTax === '' ? null : new Big(liability.propertyTax),
              condoFeeMonthlyPayment: liability.maintenanceFee === '' ? null : new Big(liability.maintenanceFee),
              liabilityMonthlyPayment: new Big(liability.monthlyPayment),
              propertyTaxMonthlyPayment: liability.propertyTax === '' ? null : new Big(liability.propertyTax).div(12),
              timeStamp: CreateTimeStamp({ needTime: true }),
              deleteFlag: false
          };
  
          // Call the service to add a new liability to the database
          const addedLiability= await updateItem(itemType, newLiability);
          // Push the DB generated liabilityID from API response to the global state
          setIsClear(true);
          setIsUpdate(prevState => !prevState);
          clearInputs();
          setOpenSuccess(true);

    }
    const handleFetchLiabilities = async () => {

        const liabilities = await getAllItems(itemType, application.applicationID);
        console.log('All Liabilities', liabilities);

        // Temporary variables to store fetched liabilities info
        let updatedLiabilityIDs = [];
        let updatedLiabilityBelongTo = [];
        let updatedLiabilityType = [];
        let updatedLiabilityInstitutionName = [];
        let updatedLiabilityBalance = [];
        let updatedAnnualPropertyTax = [];
        let updatedMonthlyManagementMaintenanceFee = [];
        let updatedMonthlyPayment = [];

        for (const liability of liabilities) {
            updatedLiabilityIDs.push(liability['liabilityID']);
            updatedLiabilityBelongTo.push(liability['owner']);
            updatedLiabilityType.push(liability['liabilityType']);
            updatedLiabilityInstitutionName.push(liability['financialInstitution']);
            updatedLiabilityBalance.push(liability['liabilityBalance']);
            updatedAnnualPropertyTax.push(liability['annualPropertyTax'] === null ? '' : liability['annualPropertyTax']);
            updatedMonthlyManagementMaintenanceFee.push(liability['condoFeeMonthlyPayment'] === null ? '' : liability['condoFeeMonthlyPayment']);
            updatedMonthlyPayment.push(liability['liabilityMonthlyPayment']);
        }

        // Dispatch all updated liabilities info to the global states
        dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value: updatedLiabilityIDs }));
        dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: updatedLiabilityBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'liabilityType', value: updatedLiabilityType }));
        dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: updatedLiabilityInstitutionName }));
        dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: updatedLiabilityBalance }));
        dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: updatedAnnualPropertyTax }));
        dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: updatedMonthlyManagementMaintenanceFee }));
        dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: updatedMonthlyPayment }));
    };

    const handleLiabilityType = (value) => {
        // Clear the property tax and maintenance fee fields if the liability type is not mortgage related
        if (!value.startsWith('Mortgage')) {
            setLiability({ ...liability, type: value, propertyTax: '', maintenanceFee: '' });
        }
        else {
            setLiability({ ...liability, type: value });
        }
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        fetchLiabilityType();
        fetchFinancials();
        handleFetchLiabilities();

    }, []); 

    return (
        <Box className="subsection-liab-box-overall">

            <Box>
            <ModifyTable
                    fieldNames={names}
                    fieldValues={values}
                    handleDelete={colIndex => handleDeleteLiability(colIndex)}
                    handleEdit={colIndex => handleEditLiability(colIndex)}
                    setRowIndex={setRowIndex}
                    disableEdit={false} />
            </Box>

            <Box className="subsection-liab-box-field" bgcolor={color}>

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Owner" />
                        <DropdownList
                            options={owners}
                            defaultValue={liability.owner}
                            setStateValue={value => setLiability({ ...liability, owner: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Liability Type" />
                        <DropdownList
                            options={liabilityType}
                            defaultValue={liability.type}
                            setStateValue={value => handleLiabilityType(value)}
                            isClear={isClear}
                        />
                    </Grid>

                    {/* <Grid item xs={itemSpace}>
                        <TextFieldText text="Financial Institution Name" />
                        <DropdownList
                            options={institutions}
                            defaultValue={liability.creditor}
                            setStateValue={value => setLiability({ ...liability, creditor: value })}
                            isClear={isClear}
                        />
                    </Grid> */}
                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Financial Institution Name" />
                       
                        <DropdownList
                            options={institutions}// Use the filtered list
                            defaultValue={liability.creditor}  // Default selected value
                            setStateValue={value => setLiability({ ...liability, creditor: value })}  // Update state
                            isClear={isClear}
                             // Clear if necessary
                        />

                        {/* 
                         <TextField item xs={itemSpace}
                            value={inputValue}
                            onChange={onInputChange}  // Capture user input
                            placeholder="Type to search institutions..."
                        /> */}
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Liability Balance" />
                        <NumberFieldMoney
                            defaultValue={liability.balance}
                            setStateValue={value => setLiability({ ...liability, balance: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: liability.type.startsWith('Mortgage') ? 'block' : 'none' }}>
                        <TextFieldText text="Annual Property Tax" />
                        <NumberFieldMoney
                            defaultValue={liability.propertyTax}
                            setStateValue={value => setLiability({ ...liability, propertyTax: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: liability.type.startsWith('Mortgage') ? 'block' : 'none' }}>
                        <TextFieldText text="Monthly Management Maintenance Fee" />
                        <NumberFieldMoney
                            defaultValue={liability.maintenanceFee}
                            setStateValue={value => setLiability({ ...liability, maintenanceFee: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Monthly Payment" />
                        <NumberFieldMoney
                            defaultValue={liability.monthlyPayment}
                            setStateValue={value => setLiability({ ...liability, monthlyPayment: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                         {!isUpdate && ( 
                            <IconButton onClick={handleAddLiabilityCheck} >
                            <AddBox sx={{ fontSize: 45 }} />
                            <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Liability</span>
                            </IconButton>
                        )}
                         {isUpdate && ( 
                             <IconButton onClick={sendEditLiability} >
                             <AddBox sx={{ fontSize: 45 }} />
                             <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Liability</span>
                            </IconButton>
                        )}
                        
                        
                    </Grid>

                </Grid>

            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleErrorClose}>
                <Alert
                    onClose={handleErrorClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Please fill all required fields before adding
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}>
                <Alert
                    onClose={handleSuccessClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Added Liability Successfully
                </Alert>
            </Snackbar>

        </Box>
    );
};