import { Box, Grid, Tabs, Tab } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import DocUploader from "../../../../../components/mediumComponents/uploadDoc/docUploader";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import { verifiedIDOptions, nameAddressOptions, nameDateOfBirthOptions, nameFinancialAccountOptions } from "../../../../../constants/lists";


/**
 * @param {boolean} enable - Enable the main applicant section to be displayed
 * @returns - Co-Applicant verification section  
 */


const CoappVerificationSection = ({ enable }) => {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const hasCoApplicant = applicant.anyCoapplicant;

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);
    const [currentIDVerifiedOption, setCurrentIDVerifiedOption] = useState([]);
    const [currentIDVerifiedOption_2, setCurrentIDVerifiedOption_2] = useState([]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const selectedIDVerified = coapplicant.coApplicantPersonalIDInfoVerified;
    const selectedIDVerified_2 = coapplicant.coApplicantPersonalIDInfoVerified_2;

    useEffect(() => {
        const getIDTypeOptions = () => {
            switch (selectedIDVerified) {
                case 'Name and address':
                    return nameAddressOptions;
                case 'Name and date of birth':
                    return nameDateOfBirthOptions;
                case 'Name and financial account':
                    return nameFinancialAccountOptions;
                default:
                    return [];
            }
        };
        setCurrentIDVerifiedOption(getIDTypeOptions());
    }, [selectedIDVerified]);

    useEffect(() => {
        const getIDTypeOptions_2 = () => {
            switch (selectedIDVerified_2) {
                case 'Name and address':
                    return nameAddressOptions;
                case 'Name and date of birth':
                    return nameDateOfBirthOptions;
                case 'Name and financial account':
                    return nameFinancialAccountOptions;
                default:
                    return [];
            }
        };
        setCurrentIDVerifiedOption_2(getIDTypeOptions_2());
    }, [selectedIDVerified_2]);

    const itemSpace = 4;

    const pageName = 'verification';

    return (
        <Box className='mt-12 bg-cofield-box rounded-2xl shadow-custom' display={enable ? 'block' : 'none'} >

            <Grid container spacing={2}>

                <Grid item xs={itemSpace * 3}>
                    <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} className="ml-5 mr-5" >
                        <Tab style={{ fontSize: 20 }} icon={<InsertDriveFileIcon />} iconPosition="start" label="First Verification Document" value={0} />
                        <Tab style={{ fontSize: 20 }} icon={<InsertDriveFileIcon />} iconPosition="start" label="Second Verification Document" value={1} />
                    </Tabs>
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='Upload First Verification Document' />
                    <DocUploader
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalID_1Urls", value)}
                        defaultValue={coapplicant.coApplicantPersonalID_1Urls}
                        type={hasCoApplicant && !coapplicant.verified ? 'mandatory' : 'optional'}
                        name='Co-Applicant First Verification Document'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        includeName={true}
                        categoryName='Verification'
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='First Verification Document Information Verified' />
                    <DropdownList
                        options={verifiedIDOptions}
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDInfoVerified", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDInfoVerified}
                        type={hasCoApplicant ? 'mandatory' : 'optional'}
                        name='Co-Applicant First Verification Document Information Verified'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='First Verification Document Issuing Authority' />
                    <TextField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDIssuingAuthority", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDIssuingAuthority}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='First Verification Document Type' />
                    <DropdownList
                        options={currentIDVerifiedOption}
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDType", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDType}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='First Verification Document Account/Reference number' />
                    <TextField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDAccountReference", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDAccountReference}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 0 ? 'block' : 'none'} >
                    <TextFieldText text='First Verification Document Date of information / Expiry date' />
                    <DateField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDExpiryDate", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDExpiryDate}
                        minYear={-30}
                        maxYear={0}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Upload Second Verification Document' />
                    <DocUploader
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalID_2Urls", value)}
                        defaultValue={coapplicant.coApplicantPersonalID_2Urls}
                        type={hasCoApplicant && !coapplicant.verified_2 ? 'mandatory' : 'optional'}
                        name='Co-Applicant Second Verification Document'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        includeName={true}
                        categoryName='Verification'
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Second Verification Document Information Verified' />
                    <DropdownList
                        options={verifiedIDOptions}
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDInfoVerified_2", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDInfoVerified_2}
                        type={hasCoApplicant ? 'mandatory' : 'optional'}
                        name='Co-Applicant Second Verification Document Information Verified'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Second Verification Document Issuing Authority' />
                    <TextField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDIssuingAuthority_2", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDIssuingAuthority_2}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Second Verification Document Type' />
                    <DropdownList
                        options={currentIDVerifiedOption_2}
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDType_2", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDType_2}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Second Verification Document Account/Reference number' />
                    <TextField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDAccountReference_2", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDAccountReference_2}
                    />
                </Grid>

                <Grid item xs={itemSpace} display={tabValue === 1 ? 'block' : 'none'} >
                    <TextFieldText text='Second Verification Document Date of information / Expiry date' />
                    <DateField
                        setStateValue={value => handleCoapplicantChange("coApplicantPersonalIDExpiryDate_2", value)}
                        defaultValue={coapplicant.coApplicantPersonalIDExpiryDate_2}
                        minYear={-30}
                        maxYear={0}
                    />
                </Grid>

            </Grid>

        </Box>
    );
};

export default CoappVerificationSection;