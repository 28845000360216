import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //Applicant Personal Information
    languagePreference: "English",
    firstname: "",
    lastname: "",
    preferredName: "",
    dateOfBirth: "",
    socialInsuranceNumber: "",
    gender: "",
    residentialStatus: "",
    citizenship: "",
    maritalStatus: "",
    countryOfBirth: "",
    provinceOfBirth: "",
    residentOfCanadaSince: "",
    anyCoapplicant: false, //only for frontend
    spousalRSP: null, //only for frontend

    //Applicant’s Residental Address
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    province: "",
    postalCode: "",
    livingSince: "",

    //Applicant’s Previous Residental Address 
    prevStreetNumber: "",
    prevStreetName: "",
    prevUnit: "",
    prevCity: "",
    prevProvince: "",
    prevPostalCode: "",
    prevLivingSince: "",

    //Applicant’s Contact Information
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",

    //Applicant's ID 1
    fullName: "",
    idType: "",
    idNumber: "",
    issueCountry: "",
    issueProvince: "",
    issueAuthority: "",
    issueDate: "",
    expireDate: "",
    verified: null,
    applicantID1Urls: [],

    //Applicant's ID 2
    fullName_2: "",
    idType_2: "",
    idNumber_2: "",
    issueCountry_2: "",
    issueProvince_2: "",
    issueAuthority_2: "",
    issueDate_2: "",
    expireDate_2: "",
    verified_2: null,
    applicantID2Urls: [],

    //Applicant Tax Status
    residenceOfCanada: true,
    residenceOfUnitedStates: false,
    haveTinUS: null,
    tinUS: "",
    reasonOfNoTinUS: "",
    otherResidence: false,
    countryName: "",
    haveTinOther: null,
    tinOther: "",
    reasonOfNoTinOther: "",

    //Applicant Employment
    employmentStatus: "",
    jobTitle: "",
    natureOfJobResponsibilities: "",
    employmentName: "",
    natureOfEmployersBusiness: "",

    //Applicant Disclosure for Investment Loan
    isPEP_HIO: "No",
    isCoSigner_Guarantor: "No",
    hasSuitsOrJudgements: "No",
    hasBankruptcyOrProposal: "No",
    isLoanBenefittingThirdParty: "No",
    isIncomeTaxOwed: "No",
    isMonthlySupportOrAlimony: "No",
    isSubjectToGarnishment: "No",
    hasPropertySeized: "No",
    isManulifeStaffMember: "No",
    // for own fund
    purposeOfInvestment: "",
    sourceOfFunds: "",

    //Product Details - RESP
    planTypeRESP: "Family Plan",
    primaryCaregiverRESP: "Applicant",
    parentOrGuardianRESP: "Applicant",

    // souce of contribution - Loan
    // Loan Details - new case
    loanAmountApplied: "",
    newLoan: true,
    loanRatio: "100%",
    monthlyRepaymentOption: "Interest Only",
    repaymentTerms: "",
    existingLoanAmount: "",
    existingSegFundInvestmentAccount: "",
    amountForCollateral: "",
    // Loan Details - Transfer
    loanAmountTransferred: "",
    loanFromBank: "618 - B2B BANK",
    bankAccountNumber: "",
    uploadLoanStatementUrls: [],
    currentOriginalInvestmentCompany: "",
    currentOriginalInvestmentAccountNumber: "",
    currentInvestmentMarketValue: "",
    currentInvestmentFundName: [],
    currentInvestmentFundCode: [],
    uploadInvestmentStatementUrls: [],

    // source of contribution - Own Fund
    sourceOfInitialContribution: "",
    // new case
    totalAmountForContribution: "",
    setUpRecurringContributionsRegularPAD: false,
    regularPADAmount: "",
    regularPADFrequency: "",
    firstRegularPADDate: "",
    // transfer
    relinquishingInstitution: "",
    relinquishingInstitutionStreetNumber: "",
    relinquishingInstitutionStreetName: "",
    relinquishingInstitutionUnit: "",
    relinquishingInstitutionCity: "",
    relinquishingInstitutionProvince: "",
    relinquishingInstitutionPostalCode: "",
    policyAccountNumber: "",
    ownFundTypeOfTransfer: "",
    investmentDescription: "",
    ownFundTransTypeOfAmount: "",
    ownFundTransAmount: "",
    isSameBeneficiary: null,
    uploadAccountStatementUrls: [],

    //Contribution Option
    guaranteeLevel: "75/75",
    accountHolder: "",
    jointAccountHolder: "",
    institutionCode: "",
    institutionName: "",
    transitNumber: "",
    accountNumber: "",
    requireMultipleSignature: false,
    jointAccountHolderEmail: "",

    //Liabilities (could be more than 1)
    liabilityBelongTo: [],
    liabilityType: [],
    liabilityInstitutionName: [],
    liabilityBalance: [],
    annualPropertyTax: [],
    monthlyManagementMaintenanceFee: [],
    monthlyPayment: [],

    //Assets (could be more than 1)
    assetOrderNumbers: [],
    assetBelongTo: [],
    assetType: [],
    assetMarketValue: [],
    assetInstitutionName: [],
    assetProofUrls: [], // each element is a list of urls

    //Income (could be more than 1)
    incomeBelongTo: [],
    incomeType: [],
    annualIncome: [],
    employName: [],
    employerAddrUnit: [],
    employerAddrStreetNumber: [],
    employerAddrStreetName: [],
    employerAddrCity: [],
    employerAddrProvince: [],
    employerAddrPostalCode: [],
    industry: [],
    occupation: [],
    serviceSince: [],
    previousEmployerName: [],
    previousIndustry: [],
    previousOccupation: [],
    previousServiceSince: [],
    incomeProofUrls: [], // each element is a list of urls

    //Primary Beneficiary (could be more than 1)
    beneficiaryFirstName: [],
    beneficiaryLastName: [],
    priRelationToAnnuitant: [],
    beneficiaryGender: [],
    beneficiaryType: [],
    beneficiaryDateOfBirth: [],
    beneficiaryShare: [],
    // Trustee
    trusteeFirstName: [],
    trusteeLastName: [],
    trusteeRelationToBeneficiary: [],
    // RESP
    beneficiarySIN: [],
    beneficiarySameAddressAsApplicant: [],
    beneficiaryStreetNumber: [],
    beneficiaryStreetName: [],
    beneficiaryUnit: [],
    beneficiaryCity: [],
    beneficiaryProvince: [],
    beneficiaryPostalCode: [],
    beneficiaryEmail: [],
    beneficiaryHomePhone: [],
    beneficiaryWorkPhone: [],
    beneficiaryCellPhone: [],

    //Secondary Beneficiary (could be more than 1)
    secBeneficiaryFirstName: [],
    secBeneficiaryLastName: [],
    secRelationToAnnuitant: [],
    secBeneficiaryGender: [],
    secBeneficiaryType: [],
    secBeneficiaryDateOfBirth: [],
    secBeneficiaryShare: [],
    secTrusteeFirstName: [],
    secTrusteeLastName: [],
    secTrusteeRelationToBeneficiary: [],

    // Annuitant - own fund only
    isApplicantOtherThanAnnuitant: false,
    annuitantLanguagePreference: "English",
    annuitantFirstName: "",
    annuitantLastName: "",
    annuitantDateOfBirth: "",
    annuitantGender: "",
    annuitantSIN: "",
    annuitantHomePhone: "",

    //Successor Annuitant
    designatedSuccessorAnnuitant: false,
    successorAnnuitantLanguagePreference: "English",
    successorAnnuitantFirstName: "",
    successorAnnuitantLastName: "",
    successorAnnuitantDateOfBirth: "",
    successorAnnuitantGender: "",
    successorAnnuitantSIN: "",
    successorAnnuitantHomePhone: "",
    relationToAnnuitant: "",

    //Success Owner
    designatedSuccessorOwner: false,
    ownerLanguagePreference: "English",
    ownerFirstName: "",
    ownerLastName: "",
    ownerDateOfBirth: "",
    ownerGender: "",
    ownerSIN: "",
    ownerHomePhone: "",
    relationToOwner: "",

    howHeard: "",
    heardDetail: "",
    additionalInformation: "",

    //Investor Profile
    q1_ans: [],
    q2_ans: [],
    q3_ans: [],
    q4_ans: [],
    q5_ans: [],
    q6_ans: [],
    q7_ans: [],
    q8_ans: [],
    q9_ans: [],
    q10_ans: [],
    q11_ans: [],
    q12_ans: [],

    //Investor Profile
    q_ques: [],
    q_quesCate: [],
    q_answer:[],
    q_score:[],

    //Upload Documents
    //Void Cheque
    voidChequeLink: [],

    //Income Proofs
    applicantUrlsLastYearNOA: [],
    applicantUrlsLastYearT1: [],
    applicantUrlsLast2YearsT4s: [],
    applicantUrlsPayStubsLast2Months: [],
    applicantUrlsInvestmentStatement: [],

    //Asset Proofs
    applicantUrlsRecentTaxBillResidence: {},
    applicantUrlsAccountsStatement: {},

    //Applicant Identity Verification
    applicantPersonalID_1Urls: [],
    applicantPersonalIDInfoVerified: "",
    applicantPersonalIDIssuingAuthority: "",
    applicantPersonalIDType: "",
    applicantPersonalIDAccountReference: "",
    applicantPersonalIDExpiryDate: "",
    applicantPersonalID_2Urls: [],
    applicantPersonalIDInfoVerified_2: "",
    applicantPersonalIDIssuingAuthority_2: "",
    applicantPersonalIDType_2: "",
    applicantPersonalIDAccountReference_2: "",
    applicantPersonalIDExpiryDate_2: "",

    //Applicant Credit Report
    applicantCreditReportUrls: [],

    //Additional Information
    acquisitionChannel: "",
    additionalInformation: "",

    // Fund Portfolio
    fundPortfolio: "",

    // DB IDs
    CustomerIDs: [],
    LiabilityIDs: [],
    AssetIDs: [],
    IncomeIDs: [],
    PrimBeneficiaryIDs: [],
    SecBeneficiaryIDs: [],
};

const applicantInfoSlice = createSlice({
    name: "applicantInfo",
    initialState,
    reducers: {
        setApplicantInfo: (state, action) => {
            const { attribute, value } = action.payload
            state[attribute] = value;
        },
        resetApplicantInfo: () => initialState,
    },
});

export const { applicationInfo, setApplicantInfo, resetApplicantInfo } = applicantInfoSlice.actions;
export default applicantInfoSlice.reducer;