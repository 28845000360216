import { Box } from "@mui/material"
import { ClientInfoSummary } from "../../models/clientInfoSummaryModel"
import { useApplication } from "../../contexts/ApplicationContext"
import { ClientInfoModel } from "../../models/clientInfoModel"
import { ApplicationInfoModel } from "../../models/applicationModel"
import { postApplication } from "../../services/applicationService"
import { getApplication } from "../../services/applicationService"
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationInfo } from '../../reducers/applicationReducer';
import InfoField from "../../components/mediumComponents/summary/InfoField"
import CommentSection from "../../components/mediumComponents/summary/CommentSection"
import PrevButton from "../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../components/smallComponents/button/nextButton/NextButton";
import { fetchConfig } from "../../services/fetchConfigService";
import {  useEffect } from "react";
/**
 * @returns - Summary page with all the information filled out by the user
 */


const SummaryPage = () => {

    const dataObject = ClientInfoSummary();
    const dispatch = useDispatch();

    const applicantInfo = useSelector(state => state.applicantInfo);
    const coapplicantInfo = useSelector(state => state.coapplicantInfo);
    const applicationInfo = useSelector(state => state.applicationInfo);

    const { page, setPage } = useApplication();
    const pageMapping = {
        'General': 1,
        'Contact Information': 2,
        'Identification': 3,
        'Employment Information': 4,
        'Disclosure Information': 5,
        'Source of Contribution': 6,
        'Contribution Option' : 7,
        'Financial Analysis' : 8,
        'Beneficiary' : 9,
        'Annuitant' : 10,
        'Success Owner' :11,
        'Investor Profile' : 12,
        'Void Cheque': 7,
        'Identity Verification': 3,
        'Credit Report': 13,
        'Special Instructions' : 14,
        'Investor Profile - Applicant' :12

    };
    const handleSubmit = () => {
        //send POST request
        dispatch(setApplicationInfo({ attribute: 'applicationStatus', value: 'Submitted' }));
        const requestBody = ApplicationInfoModel({     
            applicationInfo: {
                ...applicationInfo,
                applicationStatus: 'Submitted'
            },
            applicantInfo, 
            coapplicantInfo 
        });
        postApplication(requestBody);

        //route back to my-account
        // navigate("/my-account")
    };



    const handleBackButtonClick = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    };


    const handleNextButtonClick = (attributePage) => {
        const pages = pageMapping[attributePage];
        setPage(pages-1); // set the page number for each attribute
    };
    const pageName = "summary";


    useEffect(() => {
        fetchConfig("Por_Sys_Dro_ClientAcquisitionChannel", "DefaultPrimeRate").then((code) => {
            dispatch(setApplicationInfo({ attribute: 'defaultPrimeRate', value: code }));
        }); 
    }, []);
    
    const renderFields = (object) => {
        return (
            <div className="mx-4 grid grid-cols-2 gap-2">
                {Object.entries(object).map(([attr, obj]) => {
                    if ((attr === "Liabilities" || attr === "Assets" || attr === "Income" || attr === "Primary Beneficiary") && Array.isArray(obj)) {
                        return obj.map((itemObj, index) => (
                            <div key={index} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{`${attr} ${index + 1}`}</h3>
                                <div className="w-full grid grid-cols-2 gap-2">
                                    {Object.entries(itemObj).map(([key, valueObj]) => (
                                        <InfoField
                                            key={key}
                                            description={valueObj.description}
                                            value={valueObj.value}
                                        />
                                    ))}
                                </div>
                            </div>
                        ));
                    } 
                })}
            </div>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div class="grid grid-cols-1 h-fit m-4">
                <div class="col-span-1 m-4 pb-8 h-fit border-2 border-gray-200">
                    <h1 class="text-start m-8 font-bold text-3xl">Summary</h1>
                    <div>
                        {Object.entries(dataObject).map(([attribute, object], index) => (
                            <>
                                <h2 class="text-start m-8 font-bold text-2xl">{attribute}</h2>
                                {Object.entries(object).map(([key, object]) => (
                                    <>
                                        {/* <h2 class="text-start m-8 font-bold text-xl border-b border-gray-500">{key}</h2>
                                        <button
                                        onClick={() => handleNextButtonClick(key)}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    >
                                        Edit
                                        </button> */}
                                        <div class="flex items-center justify-between m-8 border-b border-gray-500">
                                            <h2 class="text-start font-bold text-xl">{key}</h2>
                                            <button
                                                onClick={() => handleNextButtonClick(key)}
                                                class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-2" 
                                            >
                                                Edit
                                            </button>
                                            
                                        </div>
                                        {renderFields(object)}
                                        {Object.entries(object).map(([key, object]) => {
                                            // Skip rendering for "Liabilities", "Assets", and "Income"
                                            if (!["Liabilities", "Assets", "Income", "Primary Beneficiary"].includes(key)) {
                                                return (
                                                    <><h2 class="text-start m-8 font-bold text-lg">{key}</h2>
                                                    <div className="mx-4 grid grid-cols-2 gap-2" key={key}>

                                                        {Object.entries(object).map(([innerKey, innerObject]) => (
                                                            <InfoField
                                                                key={innerKey}
                                                                description={innerObject.description}
                                                                value={innerObject.value} />
                                                        ))}
                                                    </div></>
                                                );
                                            }
                                            // Return null for excluded keys (no rendering)
                                            return null;
                                        })}
                                    </>
                                ))}
                            </>
                        ))}
                    </div>
                </div>
                {/* <CommentSection /> */}
                <div class="flex justify-center">
                    {/* <button
                        type="submit"
                        class="bg-gray-500 hover:bg-blue-700 text-white font-bold w-1/12 py-4 m-4 rounded-lg"
                        onClick={handleBackButtonClick}
                    >
                        Back
                    </button>
                    <button
                        type="submit"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/12 py-4 m-4 rounded-lg"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button> */}
                <Box className="mt-12 flex justify-center">
                    <PrevButton />
                    <NextButton pageName={pageName} submit={true}/>
                </Box>
                </div>

            </div>
        </Box>
    );
};

export default SummaryPage;