import { Box, Typography, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import "./style/Checkbox.css";


/**
 * 
 * @param {string[]} options - The options to be displayed in the checkbox
 * @param {function} setStateValue - The function to set the state value
 * @param {string[]} defaultValue - The default value of the checkbox
 * @returns - A checkbox component
 */


export default function CheckboxComponent({ options, setStateValue, defaultValue, disabled, pageName}) {

    const [checkedOptions, setCheckedOptions] = useState(defaultValue || []);
    const [inputValue, setInputValue] = useState(defaultValue);

    useEffect(() => {
        if (defaultValue !== inputValue) {
            setInputValue(defaultValue);
            setCheckedOptions(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);
    
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (pageName === 'report') {
            setCheckedOptions(prev => 
                checked ? [...prev, value] : prev.filter((item) => item !== value)
            );
            setStateValue(checkedOptions);
        } else {
            const updatedCheckedOptions = checked
            ? [...checkedOptions, value] // Add the value if checked
            : checkedOptions.filter((item) => item !== value); // Remove the value if unchecked

            setCheckedOptions(updatedCheckedOptions); // Update local state

            setStateValue(updatedCheckedOptions); 
        }
    };

    return (
        <Box className="checkbox-box">
            <FormGroup>
                {options.map((option, index) => (
                    <FormControlLabel className="checkbox-option" key={index} disabled = {disabled}
                        control={
                            <Checkbox disableRipple className="checkbox-option-box"
                                value={option}
                                checked={checkedOptions.includes(option)}
                                onChange={handleCheckboxChange} />}
                        label={<Typography className={`checkbox-option-text ${disabled ? 'checkbox-option-text-strikethrough' : ''}`}>{option}</Typography>} />
                ))}
            </FormGroup>
        </Box>
    )
};
