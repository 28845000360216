import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './TFSA.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
/**
 * Component for the RRSP page.
 * 
 * @returns {JSX.Element} The TFSA page component.
 */
function TFSA() {
    const { setSelectedComponent } = useApplication();
    return (
        <Box>
            <HeaderBox text='Requirements: TFSA' />
            <div className="textPage">
                <p>Eligible applicants are:</p>
                <ul className='ulQuick'>
                    <li>at-least 25-year-old</li>
                    <li>either Canadian Citizen or Canadian Permanent Resident</li>
                    <li>Canadian tax resident</li>
                    <li>not identified as <a href="https://fintrac-canafe.canada.ca/guidance-directives/client-clientele/pep/pep-eng">PEP/HIO</a></li>
                    <li>having credit score higher than 700 (Not sure? Check your credit score free <a href="https://www.consumer.equifax.ca/personal/education/credit-report/articles/-/learn/how-to-get-a-free-credit-report/#online">Here</a>)</li>
                    <li>having at least 2-year living and working experience in Canada</li>
                </ul>
                <br />
                <p>You will required to prepare and upload following documents during the application: </p>
                <ul className='ulQuick'>
                    <li>Two pieces of goverment-issued photo IDs</li>
                    <li>Two pieces personal identification information documents</li>
                    <li>Void Cheque</li>
                    <li>Credit Score Report</li>
                </ul>
                <br />
                <p>For each contribution of the investment, we will charge a one-time fee from your investment as service fee. The service fee is 5% of the total contribution</p>

            </div>
            <div className='button-Area'>
                <NavLink onClick={() => setSelectedComponent('OwnFund')} className={'backButton'}>
                    <button className="bigButton">Back</button>
                </NavLink>

                <NavLink onClick={() => setSelectedComponent('BeforeApp')} className={'nextButton'}>
                    <button className="bigButton">Next</button>
                </NavLink>

            </div>

        </Box>
    );
}

export default TFSA;