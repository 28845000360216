import { useState, useEffect } from "react";
import { ListItemButton, ListItemText, Collapse } from "@mui/material";
import { useApplication } from "../../../contexts/ApplicationContext";
import SubOption from "./SubOption";
import './style/MainOption.css';


/**
 * 
 * @param {string} mainOption - Name of the main option
 * @param {Array<{ subOptionName: string, subOptionValue: number }>} subOptions - Array of sub options
 * @returns - Main option component of the sidebar 
 */


export default function MainOption({ mainOption, subOptions }) {

    const [open, setOpen] = useState(false);
    const { page } = useApplication();

    // Set the open state for main option based on the page number
    // The main option will be open if the page number is within the range of pages represented by the sub options values 
    useEffect(() => {
        const count = subOptions.length;

        if (subOptions[0].subOptionValue <= page && subOptions[0].subOptionValue + count > page) {
            setOpen(true);
        } else {
            setOpen(false);
        };
    }, [page, subOptions]);

    // Handle click to open or close the sub options
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>
            <ListItemButton style={{ padding: 0, marginTop: 0 }} onClick={handleClick}>
                <ListItemText className='main-option-text' primary={mainOption} />
            </ListItemButton>
            <Collapse className='sub-options' in={open} timeout="auto" unmountOnExit>
                {subOptions.map((subOption, index) => (
                    <SubOption key={index} subOptionName={subOption.subOptionName} subOptionValue={subOption.subOptionValue} />
                ))}
            </Collapse>
        </>
    );
};