import { Box, Button } from "@mui/material";
import { AdditionalInfoSummary } from "../../models/AdditionalInfoSummaryModel";
import InfoField from "../../components/mediumComponents/summary/InfoField";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchCaseSummary } from "../../services/fetchCaseService";
import { formatConverter, digitConverter } from "../../services/backendResponseFormatService";
import { useSelector } from "react-redux";
import { USER_ROLE } from "../../constants/UserRoleMap";
import { updateApp } from "../../services/appFieldUpdateService";

const AdditionalDetailsPage = () => {

    const { applicationID} = useParams(); 
    const location = useLocation();
    const toBeConfirmed = location.state?.toBeConfirmed || false;
    const [dataObject, setDataObject] = useState(null);
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state.userInfo);
    const isOperator = userInfo.userInfos.some(({ userType }) => userType === USER_ROLE.OPERATOR);
    const isAdvisor = userInfo.userInfos.some(({ userType }) => userType === USER_ROLE.ADVISOR);    

    const handleGoBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        const fetchCase = async () => {
            const res = await fetchCaseSummary(applicationID);
            setDataObject(res);
        }
        
        fetchCase(applicationID);
      }, [applicationID]);

    

    const handleEditClick = ()=> {
        //dispatch and add in reducer

        //navigate
        navigate("/additionalInfo/" + applicationID); 
    }

    const renderFieldsTest =(object) => {
        Object.entries(object).map(([attr, obj]) => {
            console.log("attr, obj: ", attr, obj)
        });
    }

    const handleConfirmClick = () => {
        if(isOperator){
            updateApp(applicationID, { 'applicationStatus': 'Operator Processing' });
        } else if(isAdvisor){
            updateApp(applicationID, { 'applicationStatus': 'Advisor Reviewed' });
        }
        // redirect to my account page 
        navigate("/home/my-account");
    }

    const renderFields = (object) => {
        return (
            <div className="mx-4 grid grid-cols-2 gap-2">
                {typeof object === 'object' && Object.entries(object).map(([attr, obj]) => {
                    // render List 
                    if ((attr === "applicationCaseProfile" || attr === "acquisitionChannels")) {
                        if (Array.isArray(obj) && obj.length > 0) {
                            return obj.map((itemObj, index) => (
                                <div key={index} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                    <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{`${formatConverter(attr)} ${index + 1}`}</h3>
                                    <div className="w-full grid grid-cols-2 gap-2">
                                        {Object.entries(itemObj).map(([key, value]) => (
                                            <InfoField
                                                key={key}
                                                description={formatConverter(key)}
                                                value={digitConverter(value)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ));
                        } else {
                            // Render empty field with description
                            return (
                                <div key={attr} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                    <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{formatConverter(attr)}</h3>
                                    <div className="w-full grid grid-cols-2 gap-2">
                                        <InfoField
                                            key={`${formatConverter(attr)}-empty`}
                                            description={`No ${formatConverter(attr)} available`}
                                            value={""}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    } else {
                        
                        return (
                            <div key={attr} className="w-[50rem]">
                                <InfoField
                                    description={formatConverter(attr)}
                                    value={digitConverter(obj)}
                                />
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <Box className="flex flex-col items-center justify-center">
            <div className="m-10 pb-8 border-2 border-gray-200">
                <h1 className="text-start m-8 font-bold text-4xl">Additional Info Summary</h1>
                {dataObject && Object.entries(dataObject).map(([attribute, object]) => (
                    <div key={attribute}>
                        <h2 className="min-w-[600px] text-start mt-10 mx-8 mb-4 font-bold text-3xl border-b border-gray-500">{formatConverter(attribute)}</h2>
                        {renderFields(object)}
                    </div>
                ))}
            </div>

            <div className="flex justify-center gap-10">
                <Button variant="contained" onClick={handleGoBack}>
                    BACK
                </Button>
                {toBeConfirmed? 
                <Button variant="contained" onClick={handleConfirmClick}>
                    CONFIRM     
                </Button> :
                <Button variant="contained" onClick={handleEditClick}>
                    EDIT
                </Button>}                
            </div>
        </Box>
    );
};

export default AdditionalDetailsPage;
