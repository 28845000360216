import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; 
import './style/whiteCard.css';
import BadgeComponent from '../Badge/Badge';
import { useApplication } from '../../../contexts/ApplicationContext';

/**
 * @param {string} title - Title of the card
 * @param {string[]} description - Description of the card
 * @param {string} url - URL to other page
 * @param {string} size - Size of the Cards
 * @param {boolean} openInNewTab - Determines if the button opens a new tab
 * @returns - White card component
 */
const WhiteCardHome = ({ title, description, url, size, banner, list, openInNewTab, buttonName, isDisabled  }) => {
    const { setSelectedComponent } = useApplication();
    const navigate = useNavigate();
    const buttonText = isDisabled ? 'ComingSoon' : (buttonName || 'Apply');

    const handleClickButton = () => {
        setSelectedComponent(url);
    }

    const handleApplyClick = (e) => {
        e.preventDefault();

        if (openInNewTab) {          
            window.open(url, '_blank');
        } else {
            handleClickButton();
            const urlLowCase = url.charAt(0).toLowerCase() + url.slice(1);
            navigate(urlLowCase);
        }
    }

   return size === 'small' ? (
  banner ? (
    <BadgeComponent badgeContent={'Recommend'}>
      <div className="smallCard">
        <div className="bigGrid">
          <p className='smallText'>{title}</p>
          <div className="smallTextBody">{description}</div>
          <div style={{ textAlign: 'center' }}>
            {/* Conditionally render the button only if className is smallCard */}
            {size === 'small' && (
              <button className="smallButton" disabled={isDisabled}  onClick={handleApplyClick}>{buttonName || 'Apply'}</button>
            )}
          </div>
        </div>
      </div>
    </BadgeComponent>
  ) : (
    <div >
      {size === 'small' && (
            // <button className={isDisabled ? 'disabled-button' : 'smallButton'}  disabled={isDisabled} onClick={handleApplyClick}>{buttonName || 'Apply'}</button>
            <button 
                className={isDisabled ? 'disabled-button' : 'smallButton'} 
                disabled={isDisabled}  
                onClick={handleApplyClick}>
                {buttonText}
            </button>
          )}
    </div>
  )
) : (
        banner ? (
            <BadgeComponent badgeContent={'Recommend'} children={
                <div className="bigCard">
                    <div className="bigGrid">
                        <p className='bigText'>{title}</p>
                        <div className="bigTextBody">
                            <ul>
                                {description ? description.map((item, index) => (
                                    <li key={index}>{item}</li>
                                )) : ''}
                            </ul>
                            {list ? (
                                <ul className="list-disc mx-6">
                                    {list.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            ) : ''}
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button className="bigButton" onClick={handleApplyClick}>Apply</button>
                        </div>
                    </div>
                </div>
            } />
        ) : (
            <div className="bigCard">
                <div className="bigGrid">
                    <p className='bigText'>{title}</p>
                    <div className="bigTextBody">
                        <ul>
                            {description ? description.map((item, index) => (
                                <li key={index}>{item}</li>
                            )) : ''}
                        </ul>
                        {list ? (
                            <ul className="list-disc mx-6">
                                {list.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        ) : ''}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button className="bigButton" onClick={handleApplyClick}>Apply</button>
                    </div>
                </div>
            </div>
        )
    );
};

export default WhiteCardHome;
