import { Box } from "@mui/material"
import CommentSection from "../../components/mediumComponents/summary/CommentSection"
import { ClientInfoSummary } from "../../models/clientInfoSummaryModel"
import InfoField from "../../components/mediumComponents/summary/InfoField"
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { setApplicationInfo } from "../../reducers/applicationReducer";
import { useParams } from 'react-router-dom';
import { fetchCases } from '../../services/fetchCaseService';
import { setAdditionalInfo } from "../../reducers/additionalInfoReducer";
import { useState, useEffect } from "react";
import { fetchApplicationInfo, fetchApplicantcreditInfo, fetchApplicantspecialInfo, fetchApplicationsInvestorProfile, fetchApplicationsSuccessorOwner, fetchApplicationsAnnuitant, fetchApplicantInfo, fetchContact, fetchAddress, fetchApplicantidInfo, fetchApplicantidvInfo, fetchTaxStatus, fetchApplicantemploymentInfo, fetchApplicantsourceofConInfo } from '../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../reducers/applicantReducer";
import { getAllItems, addItem, deleteItemByID } from "../../services/multiAddObjectService";
import { useApplication } from "../../contexts/ApplicationContext";
import {answerAttributes } from "../../constants/lists";
import JSZip from "jszip";
import { axiosInstance } from '../../axios';
/**
 * @param {string} applicationId - ID of the application
 */

const ApplicationDetailsPage = () => {
    //sample data
    const { applicationId, fundType, applicationType} = useParams();
    const dataObject = ClientInfoSummary()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange, clickNextButton, assetOrderNumber, setAssetOrderNumber } = useApplication();

    const userTypes = useSelector((state) => state.userInfo.userInfos.map((info) => info.userType));
    const additionalInfoURL = `/additionalInfo/${fundType}/${applicationId}`;
    // const [applicationsSubCase, setApplicationSubCase] = useState([]);

    // const [applicationsPolicy, setApplicationsPolicy] = useState(null);
    const additionalInfo = useSelector((state) => state.additionalInfo);
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    let mainApplicant  = '';
    let cpApplicant  = '';
    const handleGoBack = () => {
        navigate(-1)
    };
    const [isDownloading, setIsDownloading] = useState(false); // Track download state

    
    const [error, setError] = useState('');
    const [beneficiaryInfo, setBeneficiaryInfo] = useState({
        sin: '',
        sameAddress: true,
        streetNumber: applicant.streetNumber,
        streetName: applicant.streetName,
        unit: applicant.unit,
        city: applicant.city,
        province: applicant.province,
        postalCode: applicant.postalCode,
        email: '',
        homePhone: '',
        workPhone: '',
        cellPhone: ''
    });


    //set application status to Advisor Reviewed
    const handleAdvisorReviewedClick = () => {
        //dispatch(setApplicationInfo({ attribute: 'applicantStatus', value: 'Advisor Reviewed'}));
    }
    const handleApplicantHasCoAppOrSpouseChange = (attribute, value) => {
        // Clear co-applicant information if main applicant selects no for co-applicant question or spouse question
        if (!value) {
            dispatch(resetCoapplicantInfo())
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };


    //fetch info for this application
    const fetchApplcantInfo = async ()=>{
        const applicantInfo = await fetchApplicantInfo(applicationId);
        if(applicantInfo.length >0){
           
            applicantInfo.map((applicant, index) => {
        
                if (index === 0) {
                    dispatch(setApplicantInfo({ attribute: 'languagePreference', value: applicant.preferredLanguage }));
                    dispatch(setApplicantInfo({ attribute: 'firstname', value: applicant.firstName }));
                    dispatch(setApplicantInfo({ attribute: 'lastname', value: applicant.lastName }));
                    dispatch(setApplicantInfo({ attribute: 'dateOfBirth', value: applicant.dateOfBirth })); 
                    dispatch(setApplicantInfo({ attribute: 'socialInsuranceNumber', value: applicant.sin }));
                    dispatch(setApplicantInfo({ attribute: 'gender', value: applicant.gender }));
                    dispatch(setApplicantInfo({ attribute: 'residentialStatus', value: applicant.residentialStatus }));
                    dispatch(setApplicantInfo({ attribute: 'citizenship', value: applicant.citizenship }));
                    dispatch(setApplicantInfo({ attribute: 'maritalStatus', value: applicant.maritalStatus }));
                    dispatch(setApplicantInfo({ attribute: 'countryOfBirth', value: applicant.countryOfBirth }));
                    dispatch(setApplicantInfo({ attribute: 'residentOfCanadaSince', value: applicant.residentOfCanadaSince }));
                    dispatch(setApplicantInfo({ attribute: 'provinceOfBirth', value: applicant.provinceOfBirth }));
                    mainApplicant = applicant.firstName + '_' + applicant.lastName;
                }else{
                    dispatch(setCoapplicantInfo({ attribute: 'languagePreference', value: applicant.preferredLanguage }));
                    dispatch(setCoapplicantInfo({ attribute: 'firstname', value: applicant.firstName }));
                    dispatch(setCoapplicantInfo({ attribute: 'lastname', value: applicant.lastName }));
                    dispatch(setCoapplicantInfo({ attribute: 'dateOfBirth', value: applicant.dateOfBirth }));  
                    dispatch(setCoapplicantInfo({ attribute: 'socialInsuranceNumber', value: applicant.sin }));
                    dispatch(setCoapplicantInfo({ attribute: 'gender', value: applicant.gender }));
                    dispatch(setCoapplicantInfo({ attribute: 'residentialStatus', value: applicant.residentialStatus }));
                    dispatch(setCoapplicantInfo({ attribute: 'citizenship', value: applicant.citizenship }));
                    dispatch(setCoapplicantInfo({ attribute: 'maritalStatus', value: applicant.maritalStatus }));
                    dispatch(setCoapplicantInfo({ attribute: 'countryOfBirth', value: applicant.countryOfBirth }));
                    dispatch(setCoapplicantInfo({ attribute: 'residentOfCanadaSince', value: applicant.residentOfCanadaSince }));
                    dispatch(setCoapplicantInfo({ attribute: 'provinceOfBirth', value: applicant.provinceOfBirth }));
                    cpApplicant = applicant.firstName + '_' + applicant.lastName;
                }
                
            });
        }
        
    }

    const fetchApplcantInfoContact = async ()=>{
    
        const applicantInfo = await fetchContact(applicationId);
        const applicantInfoAddess = await fetchAddress(applicationId);
        if(applicantInfo.length >0){            
           
            applicantInfo.map((applicant, index) => {
            
                if (index === 0) {
                    
                    dispatch(setApplicantInfo({ attribute: 'email', value: applicant.email }));
                    dispatch(setApplicantInfo({ attribute: 'homePhone', value: applicant.homePhone }));
                    dispatch(setApplicantInfo({ attribute: 'workPhone', value: applicant.workPhone }));
                    dispatch(setApplicantInfo({ attribute: 'cellPhone', value: applicant.cellPhone }));
                   
                    dispatch(setApplicantInfo({ attribute: 'streetNumber', value: applicantInfoAddess[index].streetNumberC }));
                    dispatch(setApplicantInfo({ attribute: 'streetName', value: applicantInfoAddess[index].streetNameC }));
                    dispatch(setApplicantInfo({ attribute: 'unit', value: applicantInfoAddess[index].unitC }));
                    dispatch(setApplicantInfo({ attribute: 'city', value: applicantInfoAddess[index].cityC }));
                    dispatch(setApplicantInfo({ attribute: 'province', value: applicantInfoAddess[index].provinceC }));
                    dispatch(setApplicantInfo({ attribute: 'postalCode', value: applicantInfoAddess[index].postCodeC }));
                    dispatch(setApplicantInfo({ attribute: 'livingSince', value: applicantInfoAddess[index].livingSinceC }));
                    dispatch(setApplicantInfo({ attribute: 'prevStreetNumber', value: applicantInfoAddess[index].streetNumberP }));
                    dispatch(setApplicantInfo({ attribute: 'prevStreetName', value: applicantInfoAddess[index].streetNameP }));
                    dispatch(setApplicantInfo({ attribute: 'prevUnit', value: applicantInfoAddess[index].unitP }));
                    dispatch(setApplicantInfo({ attribute: 'prevCity', value: applicantInfoAddess[index].cityP }));
                    dispatch(setApplicantInfo({ attribute: 'prevProvince', value: applicantInfoAddess[index].provinceP }));

                    dispatch(setApplicantInfo({ attribute: 'prevPostalCode', value: applicantInfoAddess[index].postCodeP }));
                    dispatch(setApplicantInfo({ attribute: 'prevLivingSince', value: applicantInfoAddess[index].livingSinceP }));

                }else{
                    if(applicantInfoAddess[index].streetNumberC === applicantInfoAddess[1].streetNumberC && applicantInfoAddess[index].postCodeC === applicantInfoAddess[1].postCodeC){
                        dispatch(setCoapplicantInfo({ attribute: 'sameAddressAsPrimaryApplicant', value: true }));
                    }else{
                        dispatch(setCoapplicantInfo({ attribute: 'sameAddressAsPrimaryApplicant', value: false }));
                    }

                
                    dispatch(setCoapplicantInfo({ attribute: 'email', value: applicant.email }));
                    dispatch(setCoapplicantInfo({ attribute: 'homePhone', value: applicant.homePhone }));
                    dispatch(setCoapplicantInfo({ attribute: 'workPhone', value: applicant.workPhone }));
                    dispatch(setCoapplicantInfo({ attribute: 'cellPhone', value: applicant.cellPhone }));
                    
                    dispatch(setCoapplicantInfo({ attribute: 'streetNumber', value: applicantInfoAddess[index].streetNumberC }));
                    dispatch(setCoapplicantInfo({ attribute: 'streetName', value: applicantInfoAddess[index].streetNameC }));
                    dispatch(setCoapplicantInfo({ attribute: 'unit', value: applicantInfoAddess[index].unitC }));
                    dispatch(setCoapplicantInfo({ attribute: 'city', value: applicantInfoAddess[index].cityC }));
                    dispatch(setCoapplicantInfo({ attribute: 'province', value: applicantInfoAddess[index].provinceC }));
                    dispatch(setCoapplicantInfo({ attribute: 'postalCode', value: applicantInfoAddess[index].postCodeC }));
                    dispatch(setCoapplicantInfo({ attribute: 'livingSince', value: applicantInfoAddess[index].livingSinceC }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevStreetNumber', value: applicantInfoAddess[index].streetNumberP }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevStreetName', value: applicantInfoAddess[index].streetNameP }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevUnit', value: applicantInfoAddess[index].unitP }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevCity', value: applicantInfoAddess[index].cityP }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevProvince', value: applicantInfoAddess[index].provinceP }));

                    dispatch(setCoapplicantInfo({ attribute: 'prevPostalCode', value: applicantInfoAddess[index].postCodeP }));
                    dispatch(setCoapplicantInfo({ attribute: 'prevLivingSince', value: applicantInfoAddess[index].livingSinceP }));
                }
                
            });
        }
      
    }

    const fetchApplcantIds = async ()=>{
        
        const applicantInfo = await fetchApplicantidInfo(applicationId);
        const applicantInfoidv = await fetchApplicantidvInfo(applicationId);
        const applicantInfoTaxStatus = await fetchTaxStatus(applicationId);
    
        if(applicantInfo.length >0 ){
            let isFritID = true;
            let coIsFirstID = true;
            applicantInfo.map((appid, index) => {
            
                let fileURL = [];
                if (appid.applicantType === "Applicant") {
                    if(isFritID){
                        dispatch(setApplicantInfo({ attribute: 'fullName', value: appid.fullNameOnID }));
                        dispatch(setApplicantInfo({ attribute: 'idType', value: appid.idType }));
                        dispatch(setApplicantInfo({ attribute: 'idNumber', value: appid.idNumber }));
                        dispatch(setApplicantInfo({ attribute: 'issueProvince', value: appid.issueProvince }));
                        dispatch(setApplicantInfo({ attribute: 'issueCountry', value: appid.issueCountry }));
                        dispatch(setApplicantInfo({ attribute: 'issueAuthority', value: appid.issueAuthority }));
                        dispatch(setApplicantInfo({ attribute: 'issueDate', value: appid.issueDate }));
                        dispatch(setApplicantInfo({ attribute: 'expireDate', value: appid.expiryDate === null? " ": appid.expiryDate}));
                        dispatch(setApplicantInfo({ attribute: 'verified', value: appid.verified }));
                        fileURL.push(appid.idLink);
                        dispatch(setApplicantInfo({ attribute: 'applicantID1Urls', value: fileURL }));
                        fileURL = [];
                        isFritID =false;
                        
                    }else {

                        dispatch(setApplicantInfo({ attribute: 'fullName_2', value: appid.fullNameOnID }));
                        dispatch(setApplicantInfo({ attribute: 'idType_2', value: appid.idType }));
                        dispatch(setApplicantInfo({ attribute: 'idNumber_2', value: appid.idNumber }));
                        dispatch(setApplicantInfo({ attribute: 'issueProvince_2', value: appid.issueProvince }));
                        dispatch(setApplicantInfo({ attribute: 'issueCountry_2', value: appid.issueCountry }));
                        dispatch(setApplicantInfo({ attribute: 'issueAuthority_2', value: appid.issueAuthority }));
                        dispatch(setApplicantInfo({ attribute: 'issueDate_2', value: appid.issueDate }));
                        dispatch(setApplicantInfo({ attribute: 'expireDate_2', value: appid.expiryDate === null? " ": appid.expiryDate}));
                        dispatch(setApplicantInfo({ attribute: 'verified_2', value: appid.verified }));
                        fileURL.push(appid.idLink);
                        dispatch(setApplicantInfo({ attribute: 'applicantID2Urls', value: fileURL }));
                        fileURL = [];
                        isFritID =true;
                    }
                    
                }else if(appid.applicantType === "Co-Applicant"){
                    if(coIsFirstID){
                        dispatch(setCoapplicantInfo({ attribute: 'fullName', value: appid.fullNameOnID }));
                        dispatch(setCoapplicantInfo({ attribute: 'idType', value: appid.idType }));
                        dispatch(setCoapplicantInfo({ attribute: 'idNumber', value: appid.idNumber }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueProvince', value: appid.issueProvince }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueCountry', value: appid.issueCountry }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueAuthority', value: appid.issueAuthority }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueDate', value: appid.issueDate }));
                        dispatch(setCoapplicantInfo({ attribute: 'expireDate', value: appid.expiryDate === null? " ": appid.expiryDate}));
                        dispatch(setCoapplicantInfo({ attribute: 'verified', value: appid.verified }));
                        fileURL.push(appid.idLink);
                        dispatch(setCoapplicantInfo({ attribute: 'coApplicantID1Urls', value: fileURL }));
                        fileURL = [];
                        coIsFirstID = false;
                    }else {
                        dispatch(setCoapplicantInfo({ attribute: 'fullName_2', value: appid.fullNameOnID }));
                        dispatch(setCoapplicantInfo({ attribute: 'idType_2', value: appid.idType }));
                        dispatch(setCoapplicantInfo({ attribute: 'idNumber_2', value: appid.idNumber }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueProvince_2', value: appid.issueProvince }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueCountry_2', value: appid.issueCountry }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueAuthority_2', value: appid.issueAuthority }));
                        dispatch(setCoapplicantInfo({ attribute: 'issueDate_2', value: appid.issueDate }));
                        dispatch(setCoapplicantInfo({ attribute: 'expireDate_2', value: appid.expiryDate === null? " ": appid.expiryDate}));
                        dispatch(setCoapplicantInfo({ attribute: 'verified_2', value: appid.verified }));
                        fileURL.push(appid.idLink);
                        dispatch(setCoapplicantInfo({ attribute: 'coApplicantID2Urls', value: fileURL }));
                        fileURL = [];
                        coIsFirstID = true;
                    }
                    
                }
                
            });
            
        }
        if(applicantInfoidv.length >0){
            applicantInfoidv.map((applicants, index) => {
                    
                let fileURL = [];
            
                if (applicants.applicantType === "Applicant") {
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDInfoVerified', value: applicants.informationVerified }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDIssuingAuthority', value: applicants.issueAuthority }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDType', value: applicants.documentType }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDAccountReference', value: applicants.accountReferenceNumber }));
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalIDExpiryDate', value: applicants.dateOfInformationExpiryDate }));
         
                    fileURL.push(applicants.verificationDocumentLink);
    
                    dispatch(setApplicantInfo({ attribute: 'applicantPersonalID_1Urls', value: fileURL }));
                    fileURL = [];
    
                }else if(applicants.applicantType === "Co-Applicant"){
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDInfoVerified', value: applicants.informationVerified }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDIssuingAuthority', value: applicants.issueAuthority }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDType', value: applicants.documentType }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDAccountReference', value: applicants.accountReferenceNumber }));
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalIDExpiryDate', value: applicants.dateOfInformationExpiryDate }));
         
                    fileURL.push(applicants.verificationDocumentLink);
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantPersonalID_1Urls', value: fileURL }));
                    fileURL = [];
                }
            });
        }

        if(applicantInfoTaxStatus.length >0 ){
            applicantInfoTaxStatus.map((applicanttax, index) => {
                if(applicanttax.applicantType === "Applicant"){
                    if(applicanttax.answer !== null && applicanttax.answer !== ""){
                        if(applicanttax.question === 'Tax Residence of Canada'){
                            dispatch(setApplicantInfo({ attribute: 'residenceOfCanada', value: applicanttax.answer}));
                        }else if(applicanttax.question === 'Tax Residence of United States'){
                            dispatch(setApplicantInfo({ attribute: 'residenceOfUnitedStates', value: applicanttax.answer}));
                        }else if(applicanttax.question === 'Tax residence of a jurisdiction other than Canada or the United States?'){
                            dispatch(setApplicantInfo({ attribute: 'otherResidence', value: applicanttax.answer}));
                        }
                    }
                   
                }else if(applicanttax.applicantType === "Co-Applicant"){
                    if(applicanttax.answer !== null && applicanttax.answer !== ""){
                        if(applicanttax.question === 'Tax Residence of Canada'){
                            dispatch(setCoapplicantInfo({ attribute: 'residenceOfCanada', value: applicanttax.answer}));
                        }else if(applicanttax.question === 'Tax Residence of United States'){
                            dispatch(setCoapplicantInfo({ attribute: 'residenceOfUnitedStates', value: applicanttax.answer}));
                        }else if(applicanttax.question === 'Tax residence of a jurisdiction other than Canada or the United States?'){
                            dispatch(setCoapplicantInfo({ attribute: 'otherResidence', value: applicanttax.answer}));
                        }
                    }
                }

            })


        }
    }


    const fetchApplcantInfoEmployment = async ()=>{
        
        const applicationInfo = await fetchApplicationInfo(applicationId);
        
        if(applicationInfo !== undefined && applicationInfo !== null){
            handleApplicantHasCoAppOrSpouseChange("anyCoapplicant", applicationInfo.hasCoApplicant);
        }

        const applicantInfo = await fetchApplicantemploymentInfo(applicationId);
        if(applicantInfo.length >0){
            applicantInfo.map((applicant, index) => {
                if(applicant.applicantType === 'Applicant'){
                    dispatch(setApplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setApplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setApplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setApplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC }));
                    dispatch(setApplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                
                }else if(applicant.applicantType === 'Co-Applicant'){
                    dispatch(setCoapplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setCoapplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setCoapplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                }
              
            });
        }
      
    }

    const fetchApplcantInfoSourceofCon = async ()=>{
    
        const applicantInfo = await fetchApplicantsourceofConInfo(applicationId);
        let fileURL = [];
        if(applicantInfo !== null ){
            dispatch(setApplicantInfo({ attribute: 'loanAmountApplied', value: applicantInfo.amountApplied === null ? 0 : applicantInfo.amountApplied }));
            dispatch(setApplicantInfo({ attribute: 'newLoan', value: applicantInfo.newLoanorNot === null? true : applicantInfo.newLoanorNot }));
            dispatch(setApplicantInfo({ attribute: 'loanRatio', value: applicantInfo.loanRatio === null? "100%" : applicantInfo.loanRatio }));
            dispatch(setApplicantInfo({ attribute: 'monthlyRepaymentOption', value: applicantInfo.monthlyRepaymentOption }));
            dispatch(setApplicantInfo({ attribute: 'existingLoanAmount', value: applicantInfo.existingLoanAmount  }));
            dispatch(setApplicantInfo({ attribute: 'guaranteeLevel', value: applicantInfo.policyGuaranteeLevel === null? "75/75" : applicantInfo.policyGuaranteeLevel }));
            dispatch(setApplicantInfo({ attribute: 'accountHolder', value: applicantInfo.accountHolder }));
            dispatch(setApplicantInfo({ attribute: 'transitNumber', value: applicantInfo.transitNo }));
            dispatch(setApplicantInfo({ attribute: 'institutionCode', value: applicantInfo.bankCode }));
            dispatch(setApplicantInfo({ attribute: 'accountNumber', value: applicantInfo.accountNo }));
            dispatch(setApplicantInfo({ attribute: 'requireMultipleSignature', value: applicantInfo.requireMoreSignature === null? false : applicantInfo.requireMoreSignature }));
            dispatch(setApplicantInfo({ attribute: 'jointAccountHolderEmail', value: applicantInfo.jointAccountHolderEmail}));
            
            fileURL.push(applicantInfo.voidChequeLink);
            dispatch(setApplicantInfo({ attribute: 'voidChequeLink', value: fileURL }));
            dispatch(setApplicantInfo({ attribute: 'institutionName', value: applicantInfo.bankName }));
            fileURL = [];
        }
      
    }

    const handleFetchLiabilities = async () => {
        const itemType = 'liabilities';
        const liabilities = await getAllItems(itemType, applicationId);
        
        // Temporary variables to store fetched liabilities info
        let updatedLiabilityIDs = [];
        let updatedLiabilityBelongTo = [];
        let updatedLiabilityType = [];
        let updatedLiabilityInstitutionName = [];
        let updatedLiabilityBalance = [];
        let updatedAnnualPropertyTax = [];
        let updatedMonthlyManagementMaintenanceFee = [];
        let updatedMonthlyPayment = [];

        for (const liability of liabilities) {
            updatedLiabilityIDs.push(liability['liabilityID']);
            updatedLiabilityBelongTo.push(liability['applicantType']);
            updatedLiabilityType.push(liability['liabilityType']);
            updatedLiabilityInstitutionName.push(liability['financialInstitution']);
            updatedLiabilityBalance.push(liability['liabilityBalance']);
            updatedAnnualPropertyTax.push(liability['annualPropertyTax'] === null ? '' : liability['annualPropertyTax']);
            updatedMonthlyManagementMaintenanceFee.push(liability['condoFeeMonthlyPayment'] === null ? '' : liability['condoFeeMonthlyPayment']);
            updatedMonthlyPayment.push(liability['liabilityMonthlyPayment']);
        }

        // Dispatch all updated liabilities info to the global states
        dispatch(setApplicantInfo({ attribute: 'LiabilityIDs', value: updatedLiabilityIDs }));
        dispatch(setApplicantInfo({ attribute: 'liabilityBelongTo', value: updatedLiabilityBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'liabilityType', value: updatedLiabilityType }));
        dispatch(setApplicantInfo({ attribute: 'liabilityInstitutionName', value: updatedLiabilityInstitutionName }));
        dispatch(setApplicantInfo({ attribute: 'liabilityBalance', value: updatedLiabilityBalance }));
        dispatch(setApplicantInfo({ attribute: 'annualPropertyTax', value: updatedAnnualPropertyTax }));
        dispatch(setApplicantInfo({ attribute: 'monthlyManagementMaintenanceFee', value: updatedMonthlyManagementMaintenanceFee }));
        dispatch(setApplicantInfo({ attribute: 'monthlyPayment', value: updatedMonthlyPayment }));
    };

    const handleFetchAssets = async () => {
        const itemType = 'assets';
        const assets = await getAllItems(itemType, applicationId);

        // Temporary variables to store fetched assets info
        let updatedAssetIDs = [];
        let updatedAssetOrderNumbers = [];
        let updatedAssetBelongTo = [];
        let updatedAssetType = [];
        let updatedAssetMarketValue = [];
        let updatedAssetInstitutionName = [];
        let updatedApplicantUrlsRecentTaxBillResidence = {};
        let updatedApplicantUrlsAccountsStatement = {};
        let updatedCoApplicantUrlsRecentTaxBillResidence = {};
        let updatedCoApplicantUrlsAccountsStatement = {};
        let lastOrderNum = 0;
        for (const asset of assets) {
            updatedAssetIDs.push(asset['assetID']);
            updatedAssetOrderNumbers.push(asset['orderNum']);
            updatedAssetBelongTo.push(asset['applicantType']);
            updatedAssetType.push(asset['assetType']);
            updatedAssetMarketValue.push(asset['assetMarketValue']);
            updatedAssetInstitutionName.push(asset['financialInstitution']);
        
            lastOrderNum = asset['orderNum'];

            if (asset['applicantType'] !== 'Co-Applicant') {
                if (asset['taxBillLink'] !== '') {
                    updatedApplicantUrlsRecentTaxBillResidence[asset['orderNum']] = asset['taxBillLink'];
                }
                if (asset['accountStatementLink'] !== '') {
                    updatedApplicantUrlsAccountsStatement[asset['orderNum']] = asset['accountStatementLink'];
                }
            }
            else {
                if (asset['taxBillLink'] !== '') {
                    updatedCoApplicantUrlsRecentTaxBillResidence[asset['orderNum']] = asset['taxBillLink'];
                }
                if (asset['accountStatementLink'] !== '') {
                    updatedCoApplicantUrlsAccountsStatement[asset['orderNum']] = asset['accountStatementLink'];
                }
            }
        }

        // Dispatch all updates at once
        dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: updatedAssetIDs }));
        dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: updatedAssetOrderNumbers }));
        dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: updatedAssetBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'assetType', value: updatedAssetType }));
        dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: updatedAssetMarketValue }));
        dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: updatedAssetInstitutionName }));
        dispatch(setApplicantInfo({ attribute: 'applicantUrlsRecentTaxBillResidence', value: updatedApplicantUrlsRecentTaxBillResidence }));
        dispatch(setApplicantInfo({ attribute: 'applicantUrlsAccountsStatement', value: updatedApplicantUrlsAccountsStatement }));
        dispatch(setCoapplicantInfo({ attribute: 'coApplicantUrlsRecentTaxBillResidence', value: updatedCoApplicantUrlsRecentTaxBillResidence }));
        dispatch(setCoapplicantInfo({ attribute: 'coApplicantUrlsAccountsStatement', value: updatedCoApplicantUrlsAccountsStatement }));

        setAssetOrderNumber(lastOrderNum + 1);
    };

    
    const handleFetchIncomes = async () => {
        const itemType = 'incomes';

        const incomes = await getAllItems(itemType, applicationId);
       
        // Temporary variables to store fetched incomes info
        let updatedIncomeIDs = [];
        let updatedIncomeBelongTo = [];
        let updatedIncomeType = [];
        let updatedAnnualIncome = [];
        let updatedEmployName = [];
        let updatedEmployerAddrStreetNumber = [];
        let updatedEmployerAddrStreetName = [];
        let updatedEmployerAddrUnit = [];
        let updatedEmployerAddrCity = [];
        let updatedEmployerAddrProvince = [];
        let updatedEmployerAddrPostalCode = [];
        let updatedIndustry = [];
        let updatedOccupation = [];
        let updatedServiceSince = [];
        let updatedPreviousEmployerName = [];
        let updatedPreviousIndustry = [];
        let updatedPreviousOccupation = [];
        let updatedPreviousServiceSince = [];
        for (const income of incomes) {
            updatedIncomeIDs.push(income['incomeID']);
            updatedIncomeBelongTo.push(income['applicantType']);
            updatedIncomeType.push(income['incomeType']);
            updatedAnnualIncome.push(income['annualIncome']);
            updatedEmployName.push(income['employerName']);
            updatedEmployerAddrStreetNumber.push(income['employerAddressStreetNumber']);
            updatedEmployerAddrStreetName.push(income['employerAddressStreetName']);
            updatedEmployerAddrCity.push(income['employerAddressCity']);
            updatedEmployerAddrProvince.push(income['employerAddressProvince']);
            updatedEmployerAddrPostalCode.push(income['employerAddressPostalCode']);
            updatedEmployerAddrUnit.push(income['employerAddressUnit']);
            updatedIndustry.push(income['industry']);
            updatedOccupation.push(income['occupation']);
            updatedServiceSince.push(income['serviceSince']);
            updatedPreviousEmployerName.push(income['previousEmployerName']);
            updatedPreviousIndustry.push(income['previousIndustry']);
            updatedPreviousOccupation.push(income['previousOccupation']);
            updatedPreviousServiceSince.push(income['previousEmployerServingSince']);
        
        }

        // Dispatch all updated incomes info to the global states
        dispatch(setApplicantInfo({ attribute: 'IncomeIDs', value: updatedIncomeIDs }));
        dispatch(setApplicantInfo({ attribute: 'incomeBelongTo', value: updatedIncomeBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'incomeType', value: updatedIncomeType }));
        dispatch(setApplicantInfo({ attribute: 'annualIncome', value: updatedAnnualIncome }));
        dispatch(setApplicantInfo({ attribute: 'employName', value: updatedEmployName }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrStreetNumber', value: updatedEmployerAddrStreetNumber }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrStreetName', value: updatedEmployerAddrStreetName }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrUnit', value: updatedEmployerAddrUnit }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrCity', value: updatedEmployerAddrCity }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrProvince', value: updatedEmployerAddrProvince }));
        dispatch(setApplicantInfo({ attribute: 'employerAddrPostalCode', value: updatedEmployerAddrPostalCode }));
        dispatch(setApplicantInfo({ attribute: 'industry', value: updatedIndustry }));
        dispatch(setApplicantInfo({ attribute: 'occupation', value: updatedOccupation }));
        dispatch(setApplicantInfo({ attribute: 'serviceSince', value: updatedServiceSince }));
        dispatch(setApplicantInfo({ attribute: 'previousEmployerName', value: updatedPreviousEmployerName }));
        dispatch(setApplicantInfo({ attribute: 'previousIndustry', value: updatedPreviousIndustry }));
        dispatch(setApplicantInfo({ attribute: 'previousOccupation', value: updatedPreviousOccupation }));
        dispatch(setApplicantInfo({ attribute: 'previousServiceSince', value: updatedPreviousServiceSince }));
    
    };

    const handleFetchBeneficiary = async () => {
        const needBeneficiaryPersonalInfo = ['resp'].includes(applicationType);
        const needTrustee = ['regular', 'quick', 'transfer', 'nonreg', 'rrsp', 'tfsa'].includes(applicationType);
       
        const itemType = 'beneficiaries';
        const beneficiaries = await getAllItems(itemType, applicationId);
        let updatedPrimBeneficiaryIDs = [];
	    let updatedbeneficiaryOrder = [];
        let updatedbeneficiaryType = [];
        let updatedfirstName = [];
        let updatedlastName= [];
        let updateddateOfBirth = [];
        let updatedgender = [];
        let updatedsharePercent= [];
        let updatedrelationToAnnuitant = [];
        let updatedtrustee_Firstname = [];
        let updatedtrustee_Lastname = [];
        let updatedtrustee_RelationToBeneficiary = [];


        for (const beneficiary of beneficiaries) {
            
            updatedPrimBeneficiaryIDs.push(beneficiary['beneficiaryTrusteeID']);
            updatedbeneficiaryOrder.push(beneficiary['beneficiaryOrder']);
            updatedbeneficiaryType.push(beneficiary['beneficiaryType']);
            updatedfirstName.push(beneficiary['firstName']);
            updatedlastName.push(beneficiary['lastName']);
            updateddateOfBirth.push(beneficiary['dateOfBirth']);
            updatedgender.push(beneficiary['gender']);
            updatedrelationToAnnuitant.push(beneficiary['relationToAnnuitant']);
            updatedsharePercent.push(beneficiary['sharePercent']);
            updatedtrustee_Firstname.push(beneficiary['trusteeFirstname']);
            updatedtrustee_Lastname.push(beneficiary['trusteeLastname']);
            updatedtrustee_RelationToBeneficiary.push(beneficiary['trusteeRelationToBeneficiary']);

            
        }

        dispatch(setApplicantInfo({ attribute: 'PrimBeneficiaryIDs',value: updatedPrimBeneficiaryIDs }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryFirstName', value: updatedfirstName }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryLastName', value: updatedlastName }));
        dispatch(setApplicantInfo({ attribute: 'priRelationToAnnuitant', value: updatedrelationToAnnuitant }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryGender', value: updatedgender }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryType', value: updatedbeneficiaryType }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryDateOfBirth', value: updateddateOfBirth }));
        dispatch(setApplicantInfo({ attribute: 'beneficiaryShare', value: updatedsharePercent }));

        if (needTrustee) {
            dispatch(setApplicantInfo({ attribute: 'trusteeFirstName', value: updatedtrustee_Firstname }));
            dispatch(setApplicantInfo({ attribute: 'trusteeLastName', value: updatedtrustee_Lastname }));
            dispatch(setApplicantInfo({ attribute: 'trusteeRelationToBeneficiary', value: updatedtrustee_RelationToBeneficiary }));
        }
        else if (needBeneficiaryPersonalInfo) {
            dispatch(setApplicantInfo({ attribute: 'beneficiarySIN', value: [...applicant.beneficiarySIN, beneficiaryInfo.sin] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiarySameAddressAsApplicant', value: [...applicant.beneficiarySameAddressAsApplicant, beneficiaryInfo.sameAddress] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetNumber', value: [...applicant.beneficiaryStreetNumber, beneficiaryInfo.streetNumber] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryStreetName', value: [...applicant.beneficiaryStreetName, beneficiaryInfo.streetName] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryUnit', value: [...applicant.beneficiaryUnit, beneficiaryInfo.unit] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCity', value: [...applicant.beneficiaryCity, beneficiaryInfo.city] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryProvince', value: [...applicant.beneficiaryProvince, beneficiaryInfo.province] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryPostalCode', value: [...applicant.beneficiaryPostalCode, beneficiaryInfo.postalCode] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryEmail', value: [...applicant.beneficiaryEmail, beneficiaryInfo.email] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryHomePhone', value: [...applicant.beneficiaryHomePhone, beneficiaryInfo.homePhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryWorkPhone', value: [...applicant.beneficiaryWorkPhone, beneficiaryInfo.workPhone] }));
            dispatch(setApplicantInfo({ attribute: 'beneficiaryCellPhone', value: [...applicant.beneficiaryCellPhone, beneficiaryInfo.cellPhone] }));
        }
    }

    
    const fetchApplcantInfoAnnuitant = async ()=>{
    
        const applicantInfo = await fetchApplicationsAnnuitant(applicationId);
        if(applicantInfo !== undefined){
            if(applicantInfo.firstName !== null){
                dispatch(setApplicantInfo({ attribute: 'designatedSuccessorAnnuitant', value: true }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantFirstName', value: applicantInfo.firstName }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantLastName', value: applicantInfo.lastName }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantDateOfBirth', value: applicantInfo.dateOfBirth }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantGender', value: applicantInfo.gender }));          
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantSIN', value: applicantInfo.sin }));
                dispatch(setApplicantInfo({ attribute: 'successorAnnuitantHomePhone', value: applicantInfo.homePhone }));
                dispatch(setApplicantInfo({ attribute: 'relationToAnnuitant', value: applicantInfo.relationToAnnuitant }));

            }
           
        }
      
    }
    const fetchApplcantInfoSuccessOwner = async ()=>{
    
        const applicantInfo = await fetchApplicationsSuccessorOwner(applicationId);
        if(applicantInfo !== undefined){
            if(applicantInfo.firstName !== null){
                dispatch(setApplicantInfo({ attribute: 'designatedSuccessorOwner', value: true }));
                dispatch(setApplicantInfo({ attribute: 'ownerFirstName', value: applicantInfo.firstName }));
                dispatch(setApplicantInfo({ attribute: 'ownerLastName', value: applicantInfo.lastName }));
                dispatch(setApplicantInfo({ attribute: 'ownerDateOfBirth', value: applicantInfo.dateOfBirth }));
                dispatch(setApplicantInfo({ attribute: 'ownerGender', value: applicantInfo.gender }));          
                dispatch(setApplicantInfo({ attribute: 'ownerSIN', value: applicantInfo.sin }));
                dispatch(setApplicantInfo({ attribute: 'ownerHomePhone', value: applicantInfo.homePhone }));
                dispatch(setApplicantInfo({ attribute: 'relationToOwner', value: applicantInfo.relationToOwner }));

            }
           
        }
      
    }

    const fetchApplcantInfoCredit = async ()=>{
        
        const applicantInfo = await fetchApplicantcreditInfo(applicationId);
       
        if(applicantInfo.length >0){
            
            applicantInfo.map((applicant, index) => {
                let fileURL = [];
                fileURL.push(applicant.proofLink);
                if (applicant.applicantType === 'Applicant') {
                    dispatch(setApplicantInfo({ attribute: 'applicantCreditReportUrls', value: fileURL }));
                
                }else{
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantCreditReportUrls', value: fileURL}));
                }
                fileURL = [];
            });
        }
      
    }


    const fetchApplcantInfoInvestorProfile = async ()=>{
    
        const applicantInfo = await fetchApplicationsInvestorProfile(applicationId);
        if(applicantInfo.length >0){            
            let ques = [];
            let indexCo =0;
            applicantInfo.map((applicant, index) => {

                if(applicant.applicantType === "Applicant"){
                    ques.push(applicant.question);
                    dispatch(setApplicantInfo({ attribute: answerAttributes[index], value: [applicant.optionID, applicant.answer, applicant.score] }));
                    handleMainInvestorScoreChange(index, applicant.score)
                }else if(applicant.applicantType === "Co-Applicant"){
                    handleCoInvestorScoreChange(indexCo, applicant.score)
                    dispatch(setCoapplicantInfo({ attribute: answerAttributes[indexCo], value: [applicant.optionID, applicant.answer, applicant.score] }));
                    indexCo++;
                }
                

            })
            dispatch(setApplicantInfo({ attribute: 'q_ques', value: ques}));
            dispatch(setCoapplicantInfo({ attribute: 'q_ques', value: ques}));


        }
        
       
      
    }

    const fetchApplcantInfoSpecialInfo = async ()=>{
    
        const applicantInfo = await fetchApplicantspecialInfo(applicationId);

        if(applicantInfo !== null){
            dispatch(setApplicantInfo({ attribute: 'howHeard', value: applicantInfo.howHeard }));
            dispatch(setApplicantInfo({ attribute: 'heardDetail', value: applicantInfo.heardDetail }));
            dispatch(setApplicantInfo({ attribute: 'additionalInformation', value: applicantInfo.additionalInfo }));
                    
        }
      
    }
    const extractPath = (url) => {
        const match = url.match(/application\/(.+)/);
        return match ? match[1] : '';
    };

    const extractFileName = (url) => {
        const decodedUrl = decodeURIComponent(url);
        const parts = decodedUrl.split('/');
        return parts[parts.length - 1];
    };
    const sanitizeFileName = (fileName) => {
        return fileName.replace(/[^a-zA-Z0-9_.-]/g, "_");
    };
    
    const handleFileDownload = async () => {
        const fileURLsDownloadApplicant = new Map();
        const applicatnNameid = mainApplicant === '' ? applicant.fullName : mainApplicant;
        const coapplicatnNameid = cpApplicant === '' ? coapplicant.fullName : cpApplicant;
        //all ID files
        if(applicant.applicantID1Urls.length > 0 ){
            const pathSegments = applicant.applicantID1Urls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNameId1 = 'Applicant_' + applicatnNameid + '-Identity_' + 'ID1-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileNameId1, applicant.applicantID1Urls[0]);
        }
        

        
        if(applicant.applicantID2Urls.length > 0 ){
            const pathSegments = applicant.applicantID2Urls[0].split('_');;
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNameid2= 'Applicant_' + applicatnNameid + '-Identity_' + 'ID2-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileNameid2, applicant.applicantID2Urls[0]);
        
        }
        
       
        if(coapplicant.coApplicantID1Urls.length >0){
            const pathSegments = coapplicant.coApplicantID1Urls[0].split('_');
            console.log(pathSegments);
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNamecoId1 = 'CoApplicant_' + coapplicatnNameid + '-Identity_' + 'ID1-'+timestampMatchFormat;;
            fileURLsDownloadApplicant.set(fileNamecoId1, coapplicant.coApplicantID1Urls[0]);
        }
        
       
        if(coapplicant.coApplicantID2Urls.length >0){
            const pathSegments = coapplicant.coApplicantID2Urls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNamecoId2 = 'CoApplicant_' + coapplicatnNameid + '-Identity_' + 'ID2-'+timestampMatchFormat;;
            fileURLsDownloadApplicant.set(fileNamecoId2, coapplicant.coApplicantID2Urls[0]);
        }
    
        //ID verfication
        if(applicant.applicantPersonalID_1Urls.length > 0 ){    
            const pathSegments = applicant.applicantPersonalID_1Urls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNameId1 = 'Applicant_' + applicatnNameid + '-IDVerification-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileNameId1, applicant.applicantPersonalID_1Urls[0]);
        }

        if(coapplicant.coApplicantPersonalID_1Urls.length > 0 ){    
            const pathSegments = coapplicant.coApplicantPersonalID_1Urls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileNameId1 = 'CoApplicant_' + coapplicatnNameid + '-IDVerification-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileNameId1, coapplicant.coApplicantPersonalID_1Urls[0]);
        }
        //Viod Cheue
        if(applicant.voidChequeLink.length > 0 ){
            const pathSegments =  applicant.voidChequeLink[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileName = 'VoidCheque-' + applicatnNameid+ '-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileName, applicant.voidChequeLink[0]);
        }

        //Applicant Credit Report
        if(applicant.applicantCreditReportUrls.length > 0 ){
            const pathSegments =  applicant.applicantCreditReportUrls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileName = 'Applicant_' + applicatnNameid + '-CreditReport-'+timestampMatchFormat;
            fileURLsDownloadApplicant.set(fileName, applicant.applicantCreditReportUrls[0]);
        }
        
         //Co-Applicant Credit Report
         if(coapplicant.coApplicantCreditReportUrls.length > 0 ){
            const pathSegments =  coapplicant.coApplicantCreditReportUrls[0].split('_');
            const fileNameULR = pathSegments[pathSegments.length - 1];
            const timestampMatch = fileNameULR.substring(0, fileNameULR.lastIndexOf('.')).match(/(\d{14})/); // Matches a 14-digit number (yyyyMMddHHmmss)
            let timestampMatchFormat = '';
            if (timestampMatch) {
                timestampMatchFormat= timestampMatch[0]; // The timestamp value
            
            } 
            let fileName = 'CoApplicant_' + coapplicatnNameid + '-CreditReport-'+timestampMatchFormat;;
            fileURLsDownloadApplicant.set(fileName, coapplicant.coApplicantCreditReportUrls[0]);
        }
        
        const zip = new JSZip();
        const folder = zip.folder("downloaded_files_" + applicationId);
        const failedFiles = [];
    
        // Display feedback to the user
        console.log("Starting download of files...");
        console.log(fileURLsDownloadApplicant);
        if(fileURLsDownloadApplicant.size > 0){
            
            await Promise.all(
                Array.from(fileURLsDownloadApplicant.entries()).map(async ([fileName, fileUrl]) => {
                    try {
                        const extractedPath = extractPath(fileUrl); // Assume this function extracts the file path
                        const sanitizedFileName = sanitizeFileName(fileName);
            
                        // Extract the file extension from the URL
                        const fileExtension = fileUrl.substring(fileUrl.lastIndexOf("."));
                        
                        // Construct the full file name with the extension
                        const fullFileName = `${sanitizedFileName}${fileExtension}`;
            
                        // Parse fileName to create subfolder structure
                        const fileParts = sanitizedFileName.split("-");
                        const mainFolder = fileParts[0]; // e.g., "Applicant_michael_fang"
                        const subFolder = fileParts[1] || ""; // e.g., "Identity"
            
                        // Create folders dynamically
                        const subFolderPath = folder.folder(mainFolder)?.folder(subFolder);
            
                        // Fetch the file as a blob
                        const response = await axiosInstance.get(`/application/download`, {
                            params: { fileLocation: extractedPath },
                            responseType: "blob",
                        });
            
                        // Add the file to the appropriate folder
                        subFolderPath.file(fullFileName, response.data);
                        console.log(`File added: ${mainFolder}/${subFolder}/${fullFileName}`);
                    } catch (error) {
                        console.error(`Failed to download: ${fileName}`, error);
                        failedFiles.push(fileName);
                    }
                })
            );
        
            if (Object.keys(folder.files).length === 0) {
                console.error("No files were successfully downloaded.");
                alert("Failed to download all files. Please try again.");
                return;
            }
        
            try {
                console.log("Generating ZIP file...");
                const zipBlob = await zip.generateAsync({ type: "blob" });
        
                // Trigger ZIP download
                const zipUrl = window.URL.createObjectURL(zipBlob);
                const zipLink = document.createElement("a");
                zipLink.href = zipUrl;
                let zipFileName = "files-" + applicationId + ".zip";
                zipLink.setAttribute("download", zipFileName);
                document.body.appendChild(zipLink);
                zipLink.click();
        
                // Clean up
                window.URL.revokeObjectURL(zipUrl);
                document.body.removeChild(zipLink);
        
                console.log("ZIP file downloaded successfully!");
                if (failedFiles.length > 0) {
                    console.warn("Some files failed to download:", failedFiles);
                }
            } catch (zipError) {
                console.error("Error creating ZIP file:", zipError);
                alert("Failed to create ZIP file. Please try again.");
            }
        }
        
    };

    useEffect(() => {
        handleFetchLiabilities();
        handleFetchAssets();
        handleFetchIncomes();
        fetchApplcantInfo();
        fetchApplcantInfoContact();
        fetchApplcantIds();
        fetchApplcantInfoEmployment();
        fetchApplcantInfoSourceofCon();
        handleFetchLiabilities();
        handleFetchAssets();
        handleFetchIncomes();
        fetchApplcantInfoCredit();
        handleFetchBeneficiary();
        fetchApplcantInfoAnnuitant();
        fetchApplcantInfoSuccessOwner();
        fetchApplcantInfoInvestorProfile();
        fetchApplcantInfoSpecialInfo();
        
    }, []);

    const renderFields = (object) => {
        return (
            <div className="mx-4 grid grid-cols-2 gap-2">
                {Object.entries(object).map(([attr, obj]) => {
                    if ((attr === "Liabilities" || attr === "Assets" || attr === "Income" || attr === "Primary Beneficiary") && Array.isArray(obj)) {
                        return obj.map((itemObj, index) => (
                            <div key={index} className="w-full px-4 pb-4 col-span-2 border-b border-dashed border-slate-400">
                                <h3 className="text-xl font-bold mt-4 mb-2 underline underline-offset-2">{`${attr} ${index + 1}`}</h3>
                                <div className="w-full grid grid-cols-2 gap-2">
                                    {Object.entries(itemObj).map(([key, valueObj]) => (
                                        <InfoField
                                            key={key}
                                            description={valueObj.description}
                                            value={valueObj.value}
                                        />
                                    ))}
                                </div>
                            </div>
                        ));
                    } 
                })}
            </div>
        );
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div class="grid grid-cols-1 h-fit m-4">
                <div class="col-span-1 m-4 bg-main-blue h-fit">
                    <h1 class="text-start text-white my-8 ml-8 font-semibold">Application ID - {applicationId}</h1>
                </div>
                <div class="col-span-1 m-4 pb-8 h-fit border-2 border-gray-200">
                    <div className="flex justify-between">
                        <h1 class="text-start m-8 font-bold text-3xl">Summary</h1>
                        {(userTypes.includes("Operator") || userTypes.includes("Advisor")) 
                            && (<Link class="p-2" to= {additionalInfoURL}>
                                
                                <button 
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/10 p-3 m-4 rounded-2xl"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFileDownload();
                                }}
                                 >
                                Download Files
                                </button>

                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/10 p-3 m-4 rounded-2xl"
                                        onClick={handleAdvisorReviewedClick}>
                                    Investment & Additional Info
                                </button>
                               
                            </Link>)}
                    </div>
                    
                    
                    <div>
                        {Object.entries(dataObject).map(([attribute, object]) => (
                            <>
                                <h2 class="text-start m-8 font-bold text-2xl">{attribute}</h2>
                                {Object.entries(object).map(([key, object]) => (
                                    <>  
                                        <h2 class="text-start m-8 font-bold text-xl border-b border-gray-500">{key}</h2>
                                        {renderFields(object)}
                                        {Object.entries(object).map(([key, object]) => {
                                            // Skip rendering for "Liabilities", "Assets", and "Income"
                                            if (!["Liabilities", "Assets", "Income", "Primary Beneficiary"].includes(key)) {
                                                return (
                                                    <><h2 class="text-start m-8 font-bold text-lg">{key}</h2>
                                                    <div className="mx-4 grid grid-cols-2 gap-2" key={key}>

                                                        {Object.entries(object).map(([innerKey, innerObject]) => (
                                                            <InfoField
                                                                key={innerKey}
                                                                description={innerObject.description}
                                                                value={innerObject.value} />
                                                        ))}
                                                    </div></>
                                                );
                                            }
                                            // Return null for excluded keys (no rendering)
                                            return null;
                                        })}
                                    </>
                                ))}
                            </>
                        ))}
                    </div>
                </div>
                {/* <CommentSection /> */}
                <div class="flex justify-center">
                    <button
                        type="submit"
                        class="bg-gray-500 hover:bg-blue-700 text-white font-bold w-1/12 py-4 m-4 rounded-lg"
                        onClick={handleGoBack}
                    >
                        Back
                    </button>
                    {/* <button
                        type="submit"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold w-1/12 py-4 m-4 rounded-lg"
                    >
                        Submit
                    </button> */}
                     
                    {/* <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-8 m-4 rounded">Text</button> */}
                </div>
            </div>
        </Box>
    )
}

export default ApplicationDetailsPage