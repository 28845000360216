import { useNavigate } from "react-router-dom";
import CommentBlock from "./CommentBlock";
import { useState } from "react";
import HistoryBlock from "./HistoryBlock";
import CaseTracker from "./CaseTracker";
/**
 * @param {object} item - an applicationData object that contains id, submitDate, verifiedDate, advisorId, status
 */

const CaseTable = ({cases = [], userInfo, fetchAppCases = () => {}}) => {

    const navigate = useNavigate();
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isCommentOpen, setIsCommentOpen] = useState(false);
    const [selectedCaseID, setSelectedCaseID] = useState('');
    const [selectedContractNo, setSelectedContractNo] = useState('');

    // Handles the click event for the "View" button; Navigates to the details page for the selected case.
    const handleDetailsClick = (applicationID) => {
        console.log("applicationID:", applicationID)
        navigate("/additionalDetails/" + applicationID);
    }

    // Below functions Handles the click event for the "History" and "Comment" buttons; 
    // Open/Close Log History and Comment windows
    const handleHistoryOpenClick = (caseID) => {
        setIsHistoryOpen(true);
        setSelectedCaseID(caseID);
        setSelectedContractNo(cases.find(caseItem=>caseItem.caseID === caseID)?.contractNo || null);

    }

    const handleCommentOpenClick = (caseID) => {
        setIsCommentOpen(true);
        setSelectedCaseID(caseID);
    }

    const closeHistory = () => {
        setIsHistoryOpen(false);
      };

      const closeComment = () => {
        setIsCommentOpen(false);
      };
    return (
        <>
            { cases.length > 0 && (
                <>
                    <tr>
                        <th className="text-base text-gray-400 bg-cyan-50 pl-4">&nbsp;</th>
                        <th className="text-base text-gray-400 bg-cyan-50 pl-4">Case ID</th>
                        <th className="text-base text-gray-400 bg-cyan-50">Contract No.</th>
                        <th className="text-base text-gray-400 bg-cyan-50">Loan Bank</th>
                        <th className="text-base text-gray-400 bg-cyan-50">Fund Company</th>
                        <th className="text-base text-gray-400 bg-cyan-50">Applied Amount</th>
                        <th className="text-base text-gray-400 bg-cyan-50">Case Status</th>
                        <th className="text-base w-1/6 bg-cyan-50"></th>
                        <th className="text-base w-1/6 bg-cyan-50"></th>
                        <th className="text-base w-1/6 bg-cyan-50"></th>
                    </tr>
                    {cases.map((caseItem, index) => (
                        <tr key={`${caseItem.loanInvestmentID}-${index}`} className="hover:bg-cyan-100" >
                            <td className="p-3 text-center text-sky-800">&nbsp;</td>
                            <td className="p-3 text-center text-sky-800" style={{ paddingLeft: '3.75rem' }}>{caseItem.caseID}</td>
                            <td className="p-3 text-center text-sky-800">{caseItem.contractNo}</td>
                            <td className="p-3 text-center text-sky-800">{caseItem.loanFrom}</td>
                            <td className="p-3 text-center text-sky-800">{caseItem.investmentTo}</td>
                            <td className="p-3 text-center text-sky-800">{caseItem.amountApplied}</td>
                            <td className={`py-3 text-center ${caseItem.caseStatus === 'Under Review' ? 'text-orange-300' : caseItem.caseStatus === 'Approved' ? 'text-green-500' : 'text-rose-700'}`}>
                                <CaseTracker 
                                    defaultStatus={caseItem.caseStatus || "Under Review"}
                                    userInfo={userInfo}
                                    caseID = {caseItem.caseID}
                                    fetchAppCases={fetchAppCases}
                                    />
                            </td>
                            <td className="text-center text-sky-800">
                                <button
                                    className="rounded-lg w-1/3 bg-yellow-300"
                                    style={{ minWidth: '3.5rem' }}
                                    onClick={()=>handleHistoryOpenClick(caseItem.caseID)}
                                >
                                    History
                                </button></td>
                            <td className="pr-3 text-center text-sky-800"><button
                                    className="rounded-lg w-1/3 bg-orange-300"
                                    style={{ minWidth: '4.2rem' }}
                                    onClick={() => handleCommentOpenClick(caseItem.caseID)}

                                >
                                    Comment
                                </button></td>
                            <td className="h-12">
                                <button
                                    className="rounded-lg w-1/3 mx-3 bg-orange-500 text-white"
                                    style={{ minWidth: '3.12rem' }}
                                    onClick={()=>handleDetailsClick(caseItem.applicationID)}

                                >
                                    View
                                </button>
                            </td>
                        </tr>
                    ))}
                </>)}

            
            {isHistoryOpen && <HistoryBlock
                caseID={selectedCaseID}
                isOpen={isHistoryOpen}
                onclose={closeHistory}
                contractNo={selectedContractNo}
            />}
            {isCommentOpen && <CommentBlock 
                caseID={selectedCaseID}
                isOpen={isCommentOpen}
                onclose={closeComment}
                userInfo={userInfo}
            />}
            </>
    );
}

export default CaseTable;
