import { Box, Grid } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import DocUploader from "../../../../../components/mediumComponents/uploadDoc/docUploader";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import { verifiedIDOptions, nameAddressOptions, nameDateOfBirthOptions, nameFinancialAccountOptions } from "../../../../../constants/lists";


/**
 * @param {boolean} enable - Enable the main applicant section to be displayed
 * @returns - Applicant verification section  
 */


const AppVerificationSection = ({ enable }) => {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    const { clickNextButton, handleErrorMessage } = useApplication();

    const [currentIDVerifiedOption, setCurrentIDVerifiedOption] = useState([]);
    const [currentIDVerifiedOption_2, setCurrentIDVerifiedOption_2] = useState([]);

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const selectedIDVerified = applicant.applicantPersonalIDInfoVerified;
    const selectedIDVerified_2 = applicant.applicantPersonalIDInfoVerified_2;

    const handleItemExpansionToggle = (event, itemId, isExpanded) => {
        if (isExpanded) {
            handleErrorMessage(pageName, 'remove', `Please expand ${itemId}`);
        }
        else {
            handleErrorMessage(pageName, 'add', `Please expand ${itemId}`);
        }
    };

    useEffect(() => {
        const getIDTypeOptions = () => {
            switch (selectedIDVerified) {
                case 'Name and address':
                    return nameAddressOptions;
                case 'Name and date of birth':
                    return nameDateOfBirthOptions;
                case 'Name and financial account':
                    return nameFinancialAccountOptions;
                default:
                    return [];
            }
        };
        setCurrentIDVerifiedOption(getIDTypeOptions());
    }, [selectedIDVerified]);

    useEffect(() => {
        const getIDTypeOptions_2 = () => {
            switch (selectedIDVerified_2) {
                case 'Name and address':
                    return nameAddressOptions;
                case 'Name and date of birth':
                    return nameDateOfBirthOptions;
                case 'Name and financial account':
                    return nameFinancialAccountOptions;
                default:
                    return [];
            }
        };
        setCurrentIDVerifiedOption_2(getIDTypeOptions_2());
    }, [selectedIDVerified_2]);

    // Since both tree items are open by default, remove the error message of 'Expand' when the component is mounted
    useEffect(() => {
        for (let i = 0; i < treeItemIds.length; i++) {
            handleErrorMessage(pageName, 'remove', `Please expand ${treeItemIds[i]}`);
        }
        // eslint-disable-next-line
    }, []);

    const itemSpace = 4;

    const treeItemIds = ['First Verification Document', 'Second Verification Document'];
    const pageName = 'verification';

    return (
        <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom' display={enable ? 'block' : 'none'} >

            <SimpleTreeView
                defaultExpandedItems={treeItemIds}
                onItemExpansionToggle={handleItemExpansionToggle}
            >

                <TreeItem
                    itemId={treeItemIds[0]}
                    label={
                        <span style={{ fontSize: '20px', width: 'auto', display: 'flex', justifyContent: 'center', padding: 10, color: '#3C3C3C' }}>
                            First Verification Document
                        </span>}
                >
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Upload First Verification Document' />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantPersonalID_1Urls", value)}
                                defaultValue={applicant.applicantPersonalID_1Urls}
                                type={!applicant.verified ? 'mandatory' : 'optional'}
                                name='Applicant First Verification Document'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Verification'
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='First Verification Document Information Verified' />
                            <DropdownList
                                options={verifiedIDOptions}
                                setStateValue={value => handleApplicantChange("applicantPersonalIDInfoVerified", value)}
                                defaultValue={applicant.applicantPersonalIDInfoVerified}
                                type='mandatory'
                                name='Applicant First Verification Document Information Verified'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='First Verification Document Issuing Authority' />
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDIssuingAuthority", value)}
                                defaultValue={applicant.applicantPersonalIDIssuingAuthority}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='First Verification Document Type' />
                            <DropdownList
                                options={currentIDVerifiedOption}
                                setStateValue={value => handleApplicantChange("applicantPersonalIDType", value)}
                                defaultValue={applicant.applicantPersonalIDType}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='First Verification Document Account/Reference number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDAccountReference", value)}
                                defaultValue={applicant.applicantPersonalIDAccountReference}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='First Verification Document Date of information / Expiry date' />
                            <DateField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDExpiryDate", value)}
                                defaultValue={applicant.applicantPersonalIDExpiryDate}
                                minYear={-30}
                                maxYear={0}
                            />
                        </Grid>

                    </Grid>

                </TreeItem>

                <TreeItem
                    itemId={treeItemIds[1]}
                    label={
                        <span style={{ fontSize: '20px', width: 'auto', display: 'flex', justifyContent: 'center', padding: 10, color: '#3C3C3C' }}>
                            Second Verification Document
                        </span>}
                >
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Upload Second Verification Document' />
                            <DocUploader
                                setStateValue={value => handleApplicantChange("applicantPersonalID_2Urls", value)}
                                defaultValue={applicant.applicantPersonalID_2Urls}
                                type={!applicant.verified_2 ? 'mandatory' : 'optional'}
                                name='Applicant Second Verification Document'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                                includeName={true}
                                categoryName='Verification'
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Second Verification Document Information Verified' />
                            <DropdownList
                                options={verifiedIDOptions}
                                setStateValue={value => handleApplicantChange("applicantPersonalIDInfoVerified_2", value)}
                                defaultValue={applicant.applicantPersonalIDInfoVerified_2}
                                type='mandatory'
                                name='Applicant Second Verification Document Information Verified'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Second Verification Document Issuing Authority' />
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDIssuingAuthority_2", value)}
                                defaultValue={applicant.applicantPersonalIDIssuingAuthority_2}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Second Verification Document Type' />
                            <DropdownList
                                options={currentIDVerifiedOption_2}
                                setStateValue={value => handleApplicantChange("applicantPersonalIDType_2", value)}
                                defaultValue={applicant.applicantPersonalIDType_2}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Second Verification Document Account/Reference number' />
                            <TextField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDAccountReference_2", value)}
                                defaultValue={applicant.applicantPersonalIDAccountReference_2}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Second Verification Document Date of information / Expiry date' />
                            <DateField
                                setStateValue={value => handleApplicantChange("applicantPersonalIDExpiryDate_2", value)}
                                defaultValue={applicant.applicantPersonalIDExpiryDate_2}
                                minYear={-30}
                                maxYear={0}
                            />
                        </Grid>

                    </Grid>

                </TreeItem>

            </SimpleTreeView>

        </Box>
    );
};

export default AppVerificationSection;