import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationInfo } from "../../../../reducers/applicationReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import { useEffect, useState } from "react";
import { getDropdownList } from "../../../../services/dropdownService";
import { useApplication } from "../../../../contexts/ApplicationContext";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import './style/SpecialInstruction.css';
import { fetchApplicantspecialInfo } from '../../../../services/fetchInfoService';
/**
 * @returns - Special Instruction main field component for the application page
 */


export default function SpecialInstruction() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const [howHeards, setHowHeards] = useState([]);
    const {clickNextButton} = useApplication();
    const fetchHowHeards = async ()=>{
        const howHeards = await getDropdownList("howHeards");
        setHowHeards(howHeards.map(x=>x['heardType']));
    }
    const application = useSelector((state) => state.applicationInfo);
    const fetchApplcantInfoSpecialInfo = async ()=>{
    
        const applicantInfo = await fetchApplicantspecialInfo(application.applicationID);

        if(applicantInfo !== null){
            dispatch(setApplicantInfo({ attribute: 'howHeard', value: applicantInfo.howHeard }));
            dispatch(setApplicantInfo({ attribute: 'heardDetail', value: applicantInfo.heardDetail }));
            dispatch(setApplicantInfo({ attribute: 'additionalInformation', value: applicantInfo.additionalInfo }));
                    
        }
      
    }


    useEffect(() => {
        fetchHowHeards()
    
    }, []);

    useEffect(() => {
        fetchApplcantInfoSpecialInfo()
    }, []);

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const itemSpace = 6;

    const title = "Special Instructions";
    const pageName = "specialInstructions";

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 special-instructions-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box >
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-4 bg-mainfield-box rounded-2xl shadow-custom'>
                <Grid container spacing={2}>

                    <Grid item xs={itemSpace * 2} >
                        <TextFieldText text='How did you hear about us?' largeBox={true} />
                        {howHeards.length > 0 && (<DropdownList
                            options={howHeards}
                            setStateValue={value => handleApplicantChange("howHeard", value)}
                            defaultValue={applicant.howHeard || ""}
                            type='mandatory'
                            name='Applicant How Heard'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />)}
                    </Grid>
                    <Grid item xs={itemSpace * 2} >
                        <TextFieldText text='Please specify the detail.' largeBox={true} />
                        <Box className="mx-8">
                            <textarea
                                placeholder="Details about how you heard about us. "
                                onChange={(e) => handleApplicantChange("heardDetail", e.target.value)}
                                defaultValue={applicant.heardDetail}
                                className="w-full mr-4 mb-8 h-full px-4 pt-4 pb-24 text-2xl"
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={itemSpace * 2} >
                        <TextFieldText text='Additional Information' largeBox={true} />
                        <Box className="mx-8">
                            <textarea
                                placeholder="Provide any additional information here."
                                onChange={(e) => handleApplicantChange("additionalInformation", e.target.value)}
                                value={applicant.additionalInformation}
                                className="w-full mr-4 mb-8 h-full px-4 pt-4 pb-24 text-2xl"
                            />
                        </Box>
                    </Grid>

                </Grid>
            </Box>

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName}/>
            </Box>

        </Box>
    );
};