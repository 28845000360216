import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    //Co-Applicant Personal Information
    languagePreference: "English",
    firstname: "",
    lastname: "",
    preferredName: "",
    dateOfBirth: "",
    socialInsuranceNumber: "",
    gender: "",
    residentialStatus: "",
    citizenship: "",
    maritalStatus: "",
    countryOfBirth: "",
    provinceOfBirth: "",
    residentOfCanadaSince: "",

    //Co-Applicant’s Residental Address
    sameAddressAsPrimaryApplicant: false, //only for frontend
    streetNumber: "",
    streetName: "",
    unit: "",
    city: "",
    province: "",
    postalCode: "",
    livingSince: "",

    //Co-Applicant’s Previous Residental Address
    prevStreetNumber: "",
    prevStreetName: "",
    prevUnit: "",
    prevCity: "",
    prevProvince: "",
    prevPostalCode: "",
    prevLivingSince: "",

    //Co-Applicant’s Contact Information
    email: "",
    homePhone: "",
    workPhone: "",
    cellPhone: "",

    //Co-Applicant's ID 1
    fullName: "",
    idType: "",
    idNumber: "",
    issueCountry: "",
    issueProvince: "",
    issueAuthority: "",
    issueDate: "",
    expireDate: "",
    verified: null,
    coApplicantID1Urls: [],

    //Co-Applicant's ID 2
    fullName_2: "",
    idType_2: "",
    idNumber_2: "",
    issueCountry_2: "",
    issueProvince_2: "",
    issueAuthority_2: "",
    issueDate_2: "",
    expireDate_2: "",
    verified_2: null,
    coApplicantID2Urls: [],

    //Co-Applicant Tax Status
    residenceOfCanada: true,
    residenceOfUnitedStates: false,
    haveTinUS: null,
    tinUS: "",
    reasonOfNoTinUS: "",
    otherResidence: false,
    countryName: "",
    haveTinOther: null,
    tinOther: "",
    reasonOfNoTinOther: "",

    //Co-Applicant Employment
    employmentStatus: "",
    jobTitle: "",
    natureOfJobResponsibilities: "",
    employmentName: "",
    natureOfEmployersBusiness: "",

    //Co-Applicant Disclosure for Investment Loan
    isPEP_HIO: "No",
    isCoSigner_Guarantor: "No",
    hasSuitsOrJudgements: "No",
    hasBankruptcyOrProposal: "No",
    isLoanBenefittingThirdParty: "No",
    isIncomeTaxOwed: "No",
    isMonthlySupportOrAlimony: "No",
    isSubjectToGarnishment: "No",
    hasPropertySeized: "No",
    isManulifeStaffMember: "No",
    // for own fund
    purposeOfInvestment: "",
    sourceOfFunds: "",

    //Liabilities (could be more than 1)
    liabilityBelongTo: [],
    liabilityType: [],
    liabilityInstitutionName: [],
    liabilityBalance: [],
    annualPropertyTax: [],
    monthlyManagementMaintenanceFee: [],
    monthlyPayment: [],

    //Assets (could be more than 1)
    assetBelongTo: [],
    assetType: [],
    assetMarketValue: [],
    assetInstitutionName: [],

    //Income (could be more than 1)
    incomeBelongTo: [],
    incomeType: [],
    annualIncome: [],
    employName: [],
    employerAddrStreetNumber: [],
    employerAddrStreetName: [],
    employerAddrUnit: [],
    employerAddrCity: [],
    employerAddrProvince: [],
    employerAddrPostalCode: [],
    industry: [],
    occupation: [],
    serviceSince: [],
    previousEmployerName: [],
    previousIndustry: [],
    previousOccupation: [],
    previousServiceSince: [],

    //Investor Profile
    q1_ans: [],
    q2_ans: [],
    q3_ans: [],
    q4_ans: [],
    q5_ans: [],
    q6_ans: [],
    q7_ans: [],
    q8_ans: [],
    q9_ans: [],
    q10_ans: [],
    q11_ans: [],
    q12_ans: [],

    //Investor Profile
    q_ques: [],
    q_quesCate: [],

    //Upload Documents
    //Co-Applicant Income Proofs
    coApplicantUrlsLastYearNOA: [],
    coApplicantUrlsLastYearT1: [],
    coApplicantUrlsLast2YearsT4s: [],
    coApplicantUrlsPayStubsLast2Months: [],
    coApplicantUrlsInvestmentStatement: [],

    //Asset Proofs
    coApplicantUrlsRecentTaxBillResidence: {},
    coApplicantUrlsAccountsStatement: {},

    //Co-Applicant Identity Verification
    coApplicantPersonalID_1Urls: [],
    coApplicantPersonalIDInfoVerified: "",
    coApplicantPersonalIDIssuingAuthority: "",
    coApplicantPersonalIDType: "",
    coApplicantPersonalIDAccountReference: "",
    coApplicantPersonalIDExpiryDate: "",
    coApplicantPersonalID_2Urls: [],
    coApplicantPersonalIDInfoVerified_2: "",
    coApplicantPersonalIDIssuingAuthority_2: "",
    coApplicantPersonalIDType_2: "",
    coApplicantPersonalIDAccountReference_2: "",
    coApplicantPersonalIDExpiryDate_2: "",

    // Credit Reports
    coApplicantCreditReportUrls: [],

    // DB IDs
    LiabilityIDs: [],
    AssetIDs: [],
    IncomeIDs: [],
    CustomerIDs: [],
};

const coapplicantInfoSlice = createSlice({
    name: "coapplicantInfo",
    initialState,
    reducers: {
        setCoapplicantInfo: (state, action) => {
            const { attribute, value } = action.payload
            state[attribute] = value;
        },
        resetCoapplicantInfo: () => initialState,
    },
});

export const { setCoapplicantInfo, resetCoapplicantInfo } = coapplicantInfoSlice.actions;
export default coapplicantInfoSlice.reducer;