import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import DisplayTable from "../../mediumComponents/table/DisplayTable";
import TextFieldText from "../text/textFieldText/TextFieldText";
import { getCaseStatusChangeHistory } from "../../../services/caseStatusService";

/**
 * 
 * @param {boolean} isOpen - For displaying history block
 * @param {boolean} isOpen - For closing the history block
 * @returns HistoryBlock component as part of the additionalTableItem for displaying history of the selection of case status
 */

const HistoryBlock = ({caseID, contractNo, isOpen, onclose }) =>{

    const [history, setHistory] = useState([]);
    const [fundCase, setFundCase] = useState([]);

    const historyName = ['History ID', 'Old Status', 'New Status', 'User Name', 'Time'];

    const fetchHistory = async ()=>{
        try {
            const historyRes = await getCaseStatusChangeHistory(caseID);
            setHistory(historyRes);
        } catch (error) {
            console.error(`Fetch Cases History error: ${error}`);
        }
    } 

    useEffect(()=>{
            fetchHistory();
    },[]);

    return(
        isOpen && (<div className="fixed top-0 left-0 right-0 bottom-0 z-50 bg-opacity-50 bg-gray-900 flex justify-center items-center">
            <div className="bg-white rounded-lg px-6 z-50 mx-auto max-w-screen-xl">
                <div className="text-center">
                <h1 className="text-start my-10 font-bold text-3xl text-sky-600">Status Change History</h1>
                    <div className="flex flex-row gap-8 ml-5">
                        <p className="font-sans text-[20px]"><strong>CaseID:</strong> {caseID} </p> 
                        <p className="font-sans text-[20px]"><strong>Contract No:</strong> {contractNo} </p>
                    </div>
                    <DisplayTable fieldNames={historyName} 
                        fieldValues={history}/>
                    <div className="mt-4">
                        <div className="my-8">
                            <Button variant="outlined" onClick={() => {onclose()}}>Close</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)

    );
}

export default HistoryBlock;