import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useApplication } from "../../../../contexts/ApplicationContext";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import AppVerificationSection from "./section/AppVerificationSection"
import CoappVerificationSection from "./section/CoappVerificationSection"
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";


/**
 * @returns - Identity Verification component for the application page
 */


const Verification = () => {

    const applicant = useSelector((state) => state.applicantInfo);

    const { clickNextButton } = useApplication();

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const title = "Identity Verification";
    const pageName = 'verification';

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24'>

            <Box className='mt-24'>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-8 ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 20 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <AppVerificationSection enable={tabValue === 0} />
            <CoappVerificationSection enable={tabValue === 1} />

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>

        </Box>
    );
};

export default Verification;