/**
 * @param {boolean} needTime - Deytermine if the time should be included in the timestamp 
 * @returns - Returns a timestamp in the format of 'YYYY-MM-DD' or 'YYYY-MM-DD HH:MM:SS:MS' 
 */


export default function CreateTimeStamp({ needTime = false }) {

    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, '0');

    const timeStamp = needTime ?
        `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
        :
        `${year}-${month}-${day}`;

    return timeStamp;
};