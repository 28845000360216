import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_PUBLIC_URL,
});

// Do not expose _token to the outside world.
// Only allows `setAuthToken` and `clearAuthToken` to modify it.
let _token = null;

axiosInstance.interceptors.request.use(
    (config) => {
        if (_token) {
            config.headers.Authorization = `Bearer ${_token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// set the token
const setAuthToken = (token) => {
    _token = token;
};

// clear the token
const clearAuthToken = () => {
    _token = null;
};

export { axiosInstance, setAuthToken, clearAuthToken };