import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../../../components/smallComponents/dropdownList/DropdownList";
import { provinces } from "../../../../../../constants/lists";
import "./style/AddressSubsection.css"
import { provinceSelectorByAbbr, provinceSelectorByFullName } from "../../../../../../services/provinceSelector";
import { useEffect } from "react";


/**
 * @param {string} applicantType - The type of the applicant. It can be 'Applicant' or 'Co-Applicant'
 * @param {string[]} attributes - The attributes of the address subsection. The first element is the applicant type.
 * @param {boolean} enable - A boolean value that determines if the manual input is enabled
 * @returns - An address sub-section for manual input
 * @description - Implement CSS visibility control to keep component redering in the DOM while hidden
 */


export default function AddressSubsection({ applicantType, attributes, enable }) {

    const { clickNextButton } = useApplication();

    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const handleApplicantChange = (attribute, value) => {
        if (applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute, value }))
        }
        else if (applicantType === 'Co-Applicant') {
            dispatch(setCoapplicantInfo({ attribute, value }))
        }
    };

    const handleProvinceChange = (attribute, value) => {
        if (applicantType === 'Applicant') {
            dispatch(setApplicantInfo({ attribute: attribute, value: provinceSelectorByFullName(value) }))
        }
        else if (applicantType === 'Co-Applicant') {
            dispatch(setCoapplicantInfo({ attribute: attribute, value: provinceSelectorByFullName(value) }))
        }
    }

    const isMainApplicant = applicantType === 'Applicant';

    const itemSpace = 4;

    const pageName = 'contact';

    return (
        <Box className="section-addr-box-field" display={enable ? 'block' : 'none'} >

            <Grid container spacing={2}>

                <Grid item xs={itemSpace} >
                    <TextField
                        setStateValue={value => handleApplicantChange(attributes[0], value)}
                        defaultValue={isMainApplicant ? applicant[attributes[0]] : coapplicant[attributes[0]]}
                        type={enable ? 'mandatory' : 'optional'}
                        name={`${applicantType} Street Number`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='Street Number'
                    />
                </Grid>

                <Grid item xs={itemSpace} >
                    <TextField
                        setStateValue={value => handleApplicantChange(attributes[1], value)}
                        defaultValue={isMainApplicant ? applicant[attributes[1]] : coapplicant[attributes[1]]}
                        type={enable ? 'mandatory' : 'optional'}
                        name={`${applicantType} Street Name`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='Street Name'
                    />
                </Grid>

                <Grid item xs={itemSpace} >
                    <TextField
                        setStateValue={value => handleApplicantChange(attributes[2], value)}
                        defaultValue={isMainApplicant ? applicant[attributes[2]] : coapplicant[attributes[2]]}
                        type='optional'
                        name={`${applicantType} Unit`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='Unit'
                    />
                </Grid>

                <Grid item xs={itemSpace} >
                    <TextField
                        setStateValue={value => handleApplicantChange(attributes[3], value)}
                        defaultValue={isMainApplicant ? applicant[attributes[3]] : coapplicant[attributes[3]]}
                        type={enable ? 'mandatory' : 'optional'}
                        name={`${applicantType} City`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='City'
                    />
                </Grid>

                <Grid item xs={itemSpace} >
                    { provinces.length > 0 && (<DropdownList
                        options={provinces}
                        setStateValue={value => handleProvinceChange(attributes[4], value)}
                        defaultValue={isMainApplicant ? provinceSelectorByAbbr(applicant[attributes[4]] || '') : provinceSelectorByAbbr(coapplicant[attributes[4]] || '')}
                        type={enable ? 'mandatory' : 'optional'}
                        name={`${applicantType} Province`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='Province'
                    />) }
                </Grid>

                <Grid item xs={itemSpace} >
                    <TextField
                        setStateValue={value => handleApplicantChange(attributes[5], value?.toUpperCase())}
                        defaultValue={isMainApplicant ? applicant[attributes[5]] : coapplicant[attributes[5]]}
                        type={enable ? 'postcode' : 'optional'}
                        name={`${applicantType} Postal Code`}
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                        label='Postal Code'
                    />
                </Grid>

            </Grid>

        </Box>
    );
};