import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import "./style/CoApplicantEmpSection.css"
import { useEffect } from "react";
import { useApplication } from "../../../../contexts/ApplicationContext";


/**
 * Renders the Co-Applicant Employment Information section.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.EmpStatus - The array of employment status options.
 * @returns {JSX.Element} The rendered component.
 */
export default function EmploymentCoAppInformation({ EmpStatus, enable }) {
    const itemSpace = 3;


    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const { clickNextButton } = useApplication();
    const pageName = 'employment'



    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    }

    function clearField() {
        dispatch(setCoapplicantInfo({ attribute: "jobTitle", value: "" }));
        dispatch(setCoapplicantInfo({ attribute: "natureOfJobResponsibilities", value: "" }));
        dispatch(setCoapplicantInfo({ attribute: "employmentName", value: "" }));
        dispatch(setCoapplicantInfo({ attribute: "natureOfEmployersBusiness", value: "" }));
    }

    //to track the employment status value and trigger the clearField
    useEffect(() => {
        if (coapplicant.employmentStatus === "Student" || coapplicant.employmentStatus === "Retired" || coapplicant.employmentStatus === "") {
            clearField();
        }
        // eslint-disable-next-line
    }, [coapplicant.employmentStatus]);
    return (
        <div style={{ display: enable ? 'block' : 'none' }}>
            <Box className="section-co-box-field">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text='Employment Status' />
                                    <DropdownList
                                        options={EmpStatus}
                                        setStateValue={value => handleCoapplicantChange("employmentStatus", value)}
                                        defaultValue={coapplicant.employmentStatus}
                                        type={applicant.anyCoapplicant ? 'mandatory' : 'optional'} name='Co-Applicant Employment Status' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <TextFieldText
                                        text='Detailed Occupation/Job Title' />
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("jobTitle", value)}
                                        defaultValue={coapplicant.jobTitle}
                                        longBox={true}
                                        disabled={coapplicant.employmentStatus === "Student" || coapplicant.employmentStatus === "Retired" || coapplicant.employmentStatus === ""}
                                        type={coapplicant.employmentStatus && (coapplicant.employmentStatus !== 'Student' && coapplicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Co-Applicant Job Title' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <TextFieldText
                                        text='Nature of Job Responsibilities' />
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("natureOfJobResponsibilities", value)}
                                        defaultValue={coapplicant.natureOfJobResponsibilities}
                                        longBox={true}
                                        disabled={coapplicant.employmentStatus === "Student" || coapplicant.employmentStatus === "Retired" || coapplicant.employmentStatus === ""}
                                        type={coapplicant.employmentStatus && (coapplicant.employmentStatus !== 'Student' && coapplicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Co-Applicant Job Responsibilities' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <TextFieldText
                                        text='Employment Name' />
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("employmentName", value)}
                                        defaultValue={coapplicant.employmentName}
                                        longBox={true}
                                        disabled={coapplicant.employmentStatus === "Student" || coapplicant.employmentStatus === "Retired" || coapplicant.employmentStatus === ""}
                                        type={coapplicant.employmentStatus && (coapplicant.employmentStatus !== 'Student' && coapplicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Co-Applicant Employment Name' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box>
                                    <TextFieldText
                                        text="Nature of Employer's Business" />
                                    <TextField
                                        setStateValue={value => handleCoapplicantChange("natureOfEmployersBusiness", value)}
                                        defaultValue={coapplicant.natureOfEmployersBusiness}
                                        longBox={true}
                                        disabled={coapplicant.employmentStatus === "Student" || coapplicant.employmentStatus === "Retired" || coapplicant.employmentStatus === ""}
                                        type={coapplicant.employmentStatus && (coapplicant.employmentStatus !== 'Student' && coapplicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Co-Applicant Employer Business' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}