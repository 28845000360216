import { Box, FormControl, Select, MenuItem, TextField, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import "./style/DropdownList.css";
import { useApplication } from "../../../contexts/ApplicationContext";
import InputLabel from '@mui/material/InputLabel';


/**
 * @param {string[]} options - The options to be displayed in the dropdown list
 * @param {function} setStateValue - The function to be called when an option is selected
 * @param {string} defaultValue - The default value of the dropdown list
 * @param {boolean} disabled - A boolean value that determines if the dropdown list is disabled
 * @param {boolean} isClear - A boolean value that determines if the selection field needs to be cleared
 * @param {string} type - The type of dropdown list component
 * @param {string} name - The name of the dropdown list component
 * @param {string} pageName - The name of the page the dropdown list component is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @returns - A dropdown list component
 */


export default function DropdownList({ options, setStateValue, defaultValue, disabled, isClear = false, type = 'optional', name = '', pageName = '', buttonCheck = false, label='' }) {

    const { handleErrorMessage } = useApplication();

    const isOther = defaultValue && defaultValue.startsWith('Other');

    const [selectedOption, setSelectedOption] = useState(isOther ? 'Other' : defaultValue);
    const [otherValue, setOtherValue] = useState(isOther ? defaultValue.replace('Other-', '') : '');
    const [error, setError] = useState('');

    const handleOptionChange = (event) => {
        const userSelection = event.target.value;
        setSelectedOption(userSelection);
        setStateValue(userSelection)
        // Provide validation after the user has selected an option
        handleOptionCheck(userSelection);
    };

    const handleOtherValueChange = (event) => {
        const userType = 'Other-' + event.target.value;
        setOtherValue(event.target.value);
        setStateValue(userType);
        // Provide validation after the user has inputted a value
        handleOptionCheck(userType);
    };

    const handleOptionCheck = (selection) => {
        switch (type) {
            case 'mandatory':
                if(!selection){
                    selection = '';
                }
                if (selection.trim() === '') {
                    setError('mandatory');
                    handleErrorMessage(pageName, 'remove', `Please fill out ${name} with Other Specify`);
                    handleErrorMessage(pageName, 'add', `${name} is mandatory`);
                }
                else if (selection.trim() === 'Other' || selection.trim() === 'Other-') {
                    setError('otherEmpty')
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'add', `Please fill out ${name} with Other Specify`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'remove', `Please fill out ${name} with Other Specify`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                handleErrorMessage(pageName, 'remove', `Please fill out ${name} with Other Specify`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if (error === 'mandatory') {
            return 'Select Option Required';
        }
        if (error === 'otherEmpty') {
            return 'Specify Other Required';
        }
    };

    useEffect(() => {
        if (isClear) {
            setSelectedOption('');
            setOtherValue('');
        }
    }, [isClear]);

    useEffect(() => {
        if (defaultValue && defaultValue !== selectedOption) {
            if (defaultValue.startsWith('Other-')) {
                setSelectedOption('Other');
                setOtherValue(defaultValue.replace('Other-', ''));
            }
            else {
                setSelectedOption(defaultValue);
                setOtherValue('');
            }
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleOptionCheck(defaultValue);
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
            handleErrorMessage(pageName, 'remove', `Please fill out ${name} with Other Specify`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className="dropdown-box">
            <FormControl disabled={disabled} error={error !== ''} >
            {label.length > 0 && <InputLabel>{label}</InputLabel>}
                <Select variant='standard' className="dropdown-select" value={selectedOption} onChange={handleOptionChange} label={label}
                    MenuProps={{ style: { maxHeight: 500 } }} 
                    sx={{fontSize: 22}}
                    >
                    {options.map((option, index) => (
                        <MenuItem className="dropdown-select-option text-base" key={index} value={option}>{option}</MenuItem>
                    ))}
                </Select>
                {selectedOption && selectedOption.startsWith('Other') && !disabled &&
                    <TextField label='Please specify' className="dropdown-field bg-white rounded" variant="outlined"
                        value={otherValue} onChange={handleOtherValueChange} />
                }
            </FormControl>
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    )
};