import { Box, Typography } from '@mui/material';
import './style/Footer.css';


/**
 * 
 * @param {string} content - The content to be displayed in the footer
 * @returns - A footer component 
 */


export default function Footer({ content }) {
    return (
        <Box className='footer-box'>
            <Typography className='footer-content'>{content}</Typography>
        </Box>
    )
};