import { Box, TextField, InputAdornment } from '@mui/material';
import { useState, useEffect } from "react";
import { useApplication } from '../../../contexts/ApplicationContext';
import './style/NumberField.css';

export default function NumberFieldMoney({ 
    setStateValue, 
    defaultValue, 
    disabled, 
    minValue = 0, 
    maxValue = 1000000, 
    isClear = false,
    type = 'optional', 
    name = '', 
    pageName = '', 
    buttonCheck = false, 
    onBlur = () => {} 
}) {
    const { handleErrorMessage } = useApplication();
    const [inputValue, setInputValue] = useState(defaultValue === '' ? defaultValue : parseFloat(defaultValue));
    const [error, setError] = useState('');

    const handleNumberFieldChange = (event) => {
        const userTypedValue = event.target.value;
        setInputValue(userTypedValue);
        setStateValue(userTypedValue.toString());
    };

    const handleNumberFieldCheck = () => {
        const number = defaultValue === '' ? defaultValue : parseFloat(defaultValue);
        const validDecimalPattern = /^\d*\.?\d+$/;

        switch (type) {
            case 'number':
                if (number === '' || !validDecimalPattern.test(number)) {
                    setError('mandatory');
                    handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                    handleErrorMessage(pageName, 'add', `${name} is mandatory`);
                } else if (number < minValue || number > maxValue) {
                    setError('range');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'add', `Range for ${name} is ${minValue} to ${maxValue}`);
                } else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                break;
            default:
                break;
        }

        onBlur();
    };

    function checkError(error) {
        if (error === 'mandatory') {
            return 'Numeric Input Required';
        } else if (error === 'range') {
            return 'Out of Range';
        }
    };

    useEffect(() => {
        if (isClear) {
            setInputValue('');
        }
    }, [isClear]);

    useEffect(() => {
        if (parseFloat(defaultValue) !== inputValue) {
            setInputValue(defaultValue === '' ? defaultValue : parseFloat(defaultValue));
        }
    }, [defaultValue]);

    useEffect(() => {
        if (buttonCheck) {
            handleNumberFieldCheck();
        }
        return () => {
            handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
            handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
        };
    }, [buttonCheck, type]);

    return (
        <Box className='number-box'>
            <TextField 
                className='number-field bg-white rounded' 
                type="number"
                inputProps={{
                    min: minValue,
                    max: maxValue,
                    style: { fontSize: '20px' }
                }}
                value={inputValue}
                onChange={handleNumberFieldChange}
                onBlur={handleNumberFieldCheck}
                disabled={disabled}
                required={type !== 'optional'}
                error={error !== ''}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                    ),
                }}
            />
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};
