// ConfirmDialog.js
import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

export default function ConfirmDialog({ open, onClose, onConfirm, message, title }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title || "Notice"}</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{whiteSpace: 'pre-line'}}>{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onClose && <Button onClick={onClose} color="primary">
                    Close
                </Button>}
                {onConfirm && <Button onClick={onConfirm} color="primary" autoFocus>
                    Confirm
                </Button>}
            </DialogActions>
        </Dialog>
    );
}
