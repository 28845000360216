import { Box, List, ListItem, ListItemText } from "@mui/material";
import { Error } from "@mui/icons-material";
import { useApplication } from "../../../contexts/ApplicationContext";


/**
 * @param {string} pageName - Determines the page name to get and display the error messages
 * @param {boolean} enable - Determines if the error box should be displayed 
 * @returns - Error box component to display error messages for specific page 
 */


export default function ErrorBox({ pageName, enable }) {

    const { errorMessages } = useApplication();

    return (
        <div style={{ display: enable ? 'block' : 'none' }}>
            {errorMessages[pageName] && errorMessages[pageName].length > 0 ?
                (
                    <Box className="border border-black rounded-lg p-4">
                        <List className="grid grid-cols-2 gap-4">
                            {errorMessages[pageName].map((message, index) => (
                                <ListItem key={index} >
                                    <Error className="text-red-500 mr-3" sx={{ fontSize: 30 }} />
                                    <ListItemText primary={
                                        <span className="text-xl text-red-400" >{message}</span>
                                    } />
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                )
                :
                null}
        </div>
    );
};