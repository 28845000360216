import { Box, Collapse, Grid, IconButton, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useParams } from 'react-router-dom';
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import DocUploader, { FileLocation } from '../../../../components/mediumComponents/uploadDoc/docUploader';
import "../general/section/style/MainApplicantSection.css";
import DateField from "../../../../components/smallComponents/dateField/DateField";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputFileUpload from "../../../../components/mediumComponents/uploadDoc/FileUploader";
import { useRawFiles } from "../../../../contexts/FileContext";
/**
 * IdentificationInformation component.
 * @param {Object} props - The component props.
 * @param {Array} props.IDType - The array of ID types.
 * @param {Array} props.Countries - The array of countries.
 * @returns {JSX.Element} the form for manually inserting the Identification of Applicant
 */


export default function IdentificationInformation({ IDType, Countries, Authorities, enable }) {

    const { clickNextButton, handleErrorMessage } = useApplication();

    const itemSpace = 3;
    const pageName = 'identification';

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const { saveRawFile } = useRawFiles();

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    useEffect(() => {
        if (applicant.idType.includes('Canadian Citizenship')) {
            handleApplicantChange("expireDate", 'N/A')
        }
        if (applicant.idType_2.includes('Canadian Citizenship')) {
            handleApplicantChange("expireDate_2", 'N/A')
        }
        // eslint-disable-next-line
    }, [applicant.idType, applicant.idType_2])

    //Collapse function section ID 1
    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expanded, setExpanded] = useState(true);

    const [filledSection, setFieldSection] = useState(false)

    const handleExpandClick = () => {
        if (isLoanType) {
            setExpandedID2(expanded)
        }
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (!expanded && !filledSection) {
            handleErrorMessage(pageName, 'add', `All Main Applicant ID 1 Field is mandatory !`);
        }
        else {
            handleErrorMessage(pageName, 'remove', `All Main Applicant ID 1 Field is mandatory !`);
        }
        // eslint-disable-next-line
    }, [expanded, filledSection]);

    useEffect(() => {
        const allFieldsFilled = applicant.fullName.trim() !== "" &&
            applicant.idType.trim() !== "" &&
            applicant.idNumber.trim() !== "" &&
            applicant.issueCountry.trim() !== "" &&
            applicant.issueProvince.trim() !== "" &&
            applicant.issueAuthority.trim() !== "" &&
            applicant.issueDate.trim() !== "" &&
            applicant.expireDate.trim() !== "" &&
            applicant.verified !== null; // Assuming verified is a boolean or has a non-string value
        setFieldSection(allFieldsFilled);
    }, [
        applicant.fullName,
        applicant.idType,
        applicant.idNumber,
        applicant.issueCountry,
        applicant.issueProvince,
        applicant.issueAuthority,
        applicant.issueDate,
        applicant.expireDate,
        applicant.verified
    ]);

    // collapse ID section 2 (need edit)
    const ExpandMoreID2 = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const [expandedID2, setExpandedID2] = useState(false);

    const [filledSectionID2, setFieldSectionID2] = useState(false);

    const handleExpandClickID2 = () => {
        setExpanded(expandedID2)
        setExpandedID2(!expandedID2);
    };

    useEffect(() => {
        if (!expandedID2 && !filledSectionID2 && isLoanType) {
            handleErrorMessage(pageName, 'add', `All Main Applicant ID 2 Field is mandatory !`);
        }
        else {
            handleErrorMessage(pageName, 'remove', `All Main Applicant ID 2 Field is mandatory !`);
        }
        // eslint-disable-next-line
    }, [expandedID2, filledSectionID2]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const allFieldsFilled_2 =
                (applicant.fullName_2 || "").trim() !== "" &&
                (applicant.idType_2 || "").trim() !== "" &&
                (applicant.idNumber_2 || "").trim() !== "" &&
                (applicant.issueCountry_2 || "").trim() !== "" &&
                (applicant.issueProvince_2 || "").trim() !== "" &&
                (applicant.issueAuthority_2 || "").trim() !== "" &&
                (applicant.issueDate_2 || "").trim() !== "" &&
                (applicant.expireDate_2 || "").trim() !== "" &&
                applicant.verified_2 !== null; // Assuming verified_2 is a boolean or has a non-string value
            setFieldSectionID2(allFieldsFilled_2);
        }, 1000); // Delay in milliseconds (1000ms = 1 second)
    
        return () => clearTimeout(timer); // Cleanup the timer
    }, [
        applicant.fullName_2,
        applicant.idType_2,
        applicant.idNumber_2,
        applicant.issueCountry_2,
        applicant.issueProvince_2,
        applicant.issueAuthority_2,
        applicant.issueDate_2,
        applicant.expireDate_2,
        applicant.verified_2,
    ]);
    

    const saveFile = (attribute, value) => {
        saveRawFile( attribute, value )
    }

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-main-box-field">

                <TitleText text="Main Applicant ID 1" />

                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon fontSize="large" />
                    {!expanded && <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>Fill the Applicant ID 1 Field</p>}

                </ExpandMore>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Full Name on ID" />
                                <TextField
                                    setStateValue={value => handleApplicantChange("fullName", value)}
                                    defaultValue={applicant.fullName}
                                    type={expanded ? 'name' : 'optional'} name='Applicant Full Name 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="ID Type" />
                                <DropdownList
                                    options={IDType}
                                    setStateValue={value => handleApplicantChange("idType", value)}
                                    defaultValue={applicant.idType}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant ID Type 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="ID Number" />
                                <TextField
                                    setStateValue={value => handleApplicantChange("idNumber", value)}
                                    defaultValue={applicant.idNumber}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant ID Number 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Issuing Country" />
                                <DropdownList
                                    options={Countries}
                                    setStateValue={value => handleApplicantChange("issueCountry", value)}
                                    defaultValue={applicant.issueCountry}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant Issuing Country 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Issue Province" />
                                <TextField
                                    setStateValue={value => handleApplicantChange("issueProvince", value)}
                                    defaultValue={applicant.issueProvince}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant Issue Province 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Issuing Authority" />
                                <DropdownList
                                    options={Authorities}
                                    setStateValue={value => handleApplicantChange("issueAuthority", value)}
                                    defaultValue={applicant.issueAuthority}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant Issuing Authority 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Issue Date" />
                                <DateField
                                    setStateValue={value => handleApplicantChange("issueDate", value)}
                                    defaultValue={applicant.issueDate}
                                    type={expanded ? 'date' : 'optional'} name='Applicant ID Issue Date 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace}>
                            <Box>
                                <TextFieldText text="Expiry Date" />
                                <DateField
                                    setStateValue={value => handleApplicantChange("expireDate", value)}
                                    defaultValue={applicant.expireDate}
                                    minYear={-50}
                                    disabled={applicant.idType.includes('Canadian Citizenship')}
                                    isExpriyD={true}
                                    type={expanded ? applicant.idType.includes('Canadian Citizenship') ? 'optional' : 'date' : 'optional'} name='Applicant ID Expiry Date 1' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace * 2}>
                            <Box>
                                <TextFieldText text="Has your ID been verified by the advisor physically/face-to-face?" largeBox={true} />
                                <RadioGroupBoolean
                                    setStateValue={value => handleApplicantChange("verified", value)}
                                    defaultValue={applicant.verified}
                                    type={expanded ? 'mandatory' : 'optional'} name='Applicant ID 1 is verified' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={itemSpace * 2} >
                            <TextFieldText text='Upload ID 1' largeBox={true} />
                            <InputFileUpload setUploadedFile={value => saveFile("applicantID1Urls", value)} />
                        </Grid>

                    </Grid>
                </Collapse>

            </Box>


            {isLoanType && (
                <Box className="section-main-box-field">

                    <TitleText text="Main Applicant ID 2" />

                    <ExpandMoreID2
                        expand={expandedID2}
                        onClick={handleExpandClickID2}
                        aria-expanded={expandedID2}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon fontSize="large" />
                        {!expandedID2 && <p style={{ color: 'grey', fontSize: 18, justifyContent: 'center', display: 'flex', fontWeight: 'bold' }}>Fill the Applicant ID 2 Field</p>}

                    </ExpandMoreID2>

                    <Collapse in={expandedID2} timeout="auto" unmountOnExit>
                        <Grid container spacing={2}>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Full Name on ID" />
                                    <TextField
                                        setStateValue={value => handleApplicantChange("fullName_2", value)}
                                        defaultValue={applicant.fullName_2}
                                        type={expandedID2 ? 'name' : 'optional'} name='Applicant Full Name 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="ID Type" />
                                    <DropdownList
                                        options={IDType}
                                        setStateValue={value => handleApplicantChange("idType_2", value)}
                                        defaultValue={applicant.idType_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant ID Type 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="ID Number" />
                                    <TextField
                                        setStateValue={value => handleApplicantChange("idNumber_2", value)}
                                        defaultValue={applicant.idNumber_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant ID Number 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Issuing Country" />
                                    <DropdownList
                                        options={Countries}
                                        setStateValue={value => handleApplicantChange("issueCountry_2", value)}
                                        defaultValue={applicant.issueCountry_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant Issuing Country 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Issue Province" />
                                    <TextField
                                        setStateValue={value => handleApplicantChange("issueProvince_2", value)}
                                        defaultValue={applicant.issueProvince_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant Issue Province 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Issuing Authority" />
                                    <DropdownList
                                        options={Authorities}
                                        setStateValue={value => handleApplicantChange("issueAuthority_2", value)}
                                        defaultValue={applicant.issueAuthority_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant Issuing Authority 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Issue Date" />
                                    <DateField
                                        setStateValue={value => handleApplicantChange("issueDate_2", value)}
                                        defaultValue={applicant.issueDate_2}
                                        type={expandedID2 ? 'date' : 'optional'} name='Applicant ID Issue Date 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace}>
                                <Box>
                                    <TextFieldText text="Expiry Date" />
                                    <DateField
                                        setStateValue={value => handleApplicantChange("expireDate_2", value)}
                                        minYear={-50}
                                        defaultValue={applicant.expireDate_2}
                                        isExpriyD={true}
                                        disabled={applicant.idType_2.includes('Canadian Citizenship')}
                                        type={expandedID2 ? applicant.idType_2.includes('Canadian Citizenship') ? 'optional' : 'date' : 'optional'} name='Applicant ID Expiry Date 2' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace * 2}>
                                <Box>
                                    <TextFieldText text="Has your ID been verified by the advisor physically/face-to-face?" largeBox={true} />
                                    <RadioGroupBoolean
                                        setStateValue={value => handleApplicantChange("verified_2", value)}
                                        defaultValue={applicant.verified_2}
                                        type={expandedID2 ? 'mandatory' : 'optional'} name='Applicant ID 2 is verified' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={itemSpace * 2} >
                                <TextFieldText text='Upload ID 2' largeBox={true} />
                                <InputFileUpload setUploadedFile={value => saveFile("applicantID2Urls", value)} />
                            </Grid>

                        </Grid>
                    </Collapse>

                </Box>
            )}

        </Box>
    );
};