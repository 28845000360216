import { axiosInstance } from '../axios';

export async function fetchCases (applicationID) {
    try{
        const response = await axiosInstance.get(`cases/all/${applicationID}`);
        return response.data 
      }catch(error){
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchCaseOverview (applicationID) {
    try{
        const response = await axiosInstance.get(`/cases/overview/${applicationID}`);
        return response.data?.cases
      }catch(error){
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchCaseDetail(caseID){
    try{
        const response = await axiosInstance.get(`/cases/detail/${caseID}`);
        return response.data
      }catch(error){
          console.error("Failed to fetch info:" , error)
      }
  }

  export async function fetchCaseSummary(applicationID){
    try{
        const response = await axiosInstance.get(`/cases/summary/${applicationID}`);
        return response.data
      }catch(error){
          console.error("Failed to fetch info:" , error)
      }
  }
