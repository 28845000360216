import { useEffect, useCallback } from "react";
import { getMe } from "../services/userService";
import { useDispatch, useSelector } from "react-redux";
import { setUserInfo } from "../reducers/userReducer";

const useMe = (ready) => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.userInfo);

    const fetchMe = useCallback(async () => {
        try {
            const me = await getMe();
            if (me) {
                dispatch(setUserInfo(me));
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
    }, [dispatch]);

    useEffect(() => {
        if(ready) {
            fetchMe();
        } 
    }, [fetchMe, ready]);

    return userInfo;
};

export default useMe;