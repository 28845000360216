import { Box, IconButton } from "@mui/material";
import { Delete } from "@mui/icons-material";


/**
 * @param {string[]} fieldNames - Array of strings that represent the names of the column fields
 * @param {Array[]} fieldValues - Array of arrays that represent the values of the column fields
 * @param {Array[]} fileUrls - Array of arrays that represent the file urls of the column fields
 * @param {Function} handleDelete - Function that handles the deletion of a row
 * @returns - Table component that displays the field names and values 
 */


export default function Table({ fieldNames, fieldValues, fileUrls = null, handleDelete }) {
    return (
        <Box className="overflow-x-auto w-full mt-8 max-h-[300px] overflow-y-auto">

            <table className="min-w-full divide-y divide-gray-300 border border-gray-300">

                <thead className="bg-gray-200">
                    <tr>

                        {fieldNames.map((fieldName, index) => (
                            <th
                                key={index}
                                className="px-4 py-4 text-left text-base font-medium text-gray-500 uppercase tracking-wider"
                            >
                                {fieldName}
                            </th>
                        ))}

                        <th className="px-4 py-4 text-left text-base font-medium text-gray-500 uppercase tracking-wider">
                            Actions
                        </th>

                    </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-300">
                    {fieldValues[0]?.map((_, rowIndex) => (
                        <tr key={rowIndex}>

                            {fieldValues.map((valueArray, colIndex) => (
                                <td
                                    key={colIndex}
                                    className="px-4 py-4 whitespace-nowrap text-base text-gray-500"
                                >
                                    {valueArray[rowIndex]}
                                </td>
                            ))}

                            {fileUrls !== null && (
                                <>

                                    <td className="px-4 py-4 whitespace-nowrap text-base text-gray-500">
                                        {(fileUrls[0][fieldValues[0][rowIndex]] || fileUrls[2][fieldValues[0][rowIndex]]) && (
                                            <a
                                                className='ml-3'
                                                href={fieldValues[1][rowIndex] !== 'Co-Applicant' ? fileUrls[0][fieldValues[0][rowIndex]] : fileUrls[2][fieldValues[0][rowIndex]]}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download
                                            </a>
                                        )}
                                    </td>

                                    <td className="px-4 py-4 whitespace-nowrap text-base text-gray-500">
                                        {(fileUrls[1][fieldValues[0][rowIndex]] || fileUrls[3][fieldValues[0][rowIndex]]) && (
                                            <a
                                                className='ml-3'
                                                href={fieldValues[1][rowIndex] !== 'Co-Applicant' ? fileUrls[1][fieldValues[0][rowIndex]] : fileUrls[3][fieldValues[0][rowIndex]]}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Download
                                            </a>
                                        )}
                                    </td>

                                </>
                            )}

                            <td className="px-4 py-4 whitespace-nowrap text-base font-medium">
                                <IconButton
                                    onClick={() => handleDelete(rowIndex)}
                                >
                                    <Delete />
                                </IconButton>
                            </td>

                        </tr>
                    ))}
                </tbody>

            </table>

        </Box>
    );
};