/**
 * Component for selecting an investment account to open.
 * @returns {JSX.Element} The OwnFund component.
 */
import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCard from '../../../components/smallComponents/preAppCard/whiteCard'
import './OwnFund.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';

function OwnFund() {
    const { setSelectedComponent } = useApplication();
    const shortString = "Maximize this year's contribution or take advantage of contribution room"
    return (
        <Box>
            <HeaderBox text='Which Investment account you would like to open today?' />
            <div className="pre-application-pageOwn">
                {/* 1st part */}
                <div className='small-Box'>
                    <WhiteCard
                        title="TFSA"
                        description={shortString}
                        url="TFSA"
                        size="small"
                    />
                    <WhiteCard
                        title="RESP"
                        description={shortString}
                        url="RESP"
                        size="small"
                    />
                    <WhiteCard
                        title="Non-Registered"
                        description={shortString}
                        url="Non-Registered"
                        size="small"
                    />
                    <WhiteCard
                        title="RRIF"
                        description={shortString}
                        url="RRIF"
                        size="small"
                    />
                </div>
                {/* 2nd part */}
                <div className='small-Box'>
                    <WhiteCard
                        title="FHSA"
                        description={shortString}
                        url="FHSA"
                        size="small"
                    />
                    <WhiteCard
                        title="RRSP"
                        description={shortString}
                        url="RRSP"
                        size="small"

                    />
                    <WhiteCard
                        title="LIRA"
                        description={shortString}
                        url="LIRA"
                        size="small"

                    />
                </div>
            </div>
            <div className='button-Area'>
                <NavLink onClick={() => setSelectedComponent('Home')}>
                    <button className="bigButton">Back</button>
                </NavLink>
            </div>

        </Box>
    );
}

export default OwnFund;