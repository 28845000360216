/**
 * Renders the LoanType component.
 * 
 * @returns {JSX.Element} The LoanType component.
 */
import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCard from '../../../components/smallComponents/preAppCard/whiteCard'
import './style/LoanType.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
import { useNavigate } from 'react-router-dom';
function LoanType() {
    const { setSelectedComponent} = useApplication();
    const navigate = useNavigate();
    const description = [
        "An Investment loan is an effective tool to increase your investments' growth potential through use of a leveraging strategy.",
        "We offer two types of invertment loan"
    ]
    const list =[
        'Quick Loan',
        'Regular Loan'
    ]
    const TFSAString = "Grow your investments on a tax-free basis today."
    const shortString = "Maximize this year's contribution or take advantage of contribution room"

    const handleBackyClick = (e) => {
        e.preventDefault();
        setSelectedComponent('LoanTypeBack');
        navigate('loanType');
        
    }

    return (
        <Box>
            <HeaderBox text='Which of types of Loans you want to apply today?' />
            <div className="pre-application-page">
                <WhiteCard
                    title="Regular Loan"
                    description={description}
                    url="Loan"
                    list = {list}
                />
                <div className='small-Box'>
                    <WhiteCard
                        title="RRSP Loan"
                        description={shortString}
                        url="RRSP/TFSA"
                        size="small"
                        isDisabled={true} 
                    />
                    <WhiteCard
                        title="TFSA Loan"
                        description={TFSAString}
                        url="RRSP/TFSA"
                        size="small"
                        isDisabled={true} 

                    />
                </div>
            </div>
            <div className='button-Area'>
            <NavLink 
                onClick={handleBackyClick}
             >
             <button className="bigButton">Back</button>
             </NavLink>
            </div>

        </Box>
    );
}

export default LoanType;