import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    applicationNo: "",
    requestType: "",
    details: ""
}

const otherRequestInfoSlice = createSlice({
    name: "otherRequestInfo",
    initialState,
    reducers: {
        setOtherRequestInfo: (state, action) => {
            const { attribute, value } = action.payload
            state[attribute] = value;
        },
        resetOtherRequestInfo: () => initialState,
    },
});

export const { setOtherRequestInfo, resetOtherRequestInfo } = otherRequestInfoSlice.actions;
export default otherRequestInfoSlice.reducer;