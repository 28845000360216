import { useState, useEffect, useRef } from 'react'
import { useApplication } from "../../../contexts/ApplicationContext";
import { useSelector } from "react-redux";
import { axiosInstance } from '../../../axios';


/**
 * @param {function} setStateValue - A function that sets the state value
 * @param {string} defaultValue - The default value of the doc uploader
 * @param {string} type - The type of the doc uploader
 * @param {string} name - The name of the doc uploader
 * @param {string} pageName - The name of the page the doc uploader is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @param {boolean} includeName - A boolean value that determines if the name of the applicant or coapplicant should be included in the file path
 * @param {string} categoryName - The name of the category the doc uploader is located
 * @param {boolean} isMultiAdd - A boolean value that determines if the doc uploader is for multiple files
 * @param {number} multiNum - The order number of the multiple files 
 * @param {string} fileLocation - The files where are located
 * @returns - a file upload component handles file upload to Azure Blob storage
 */


const DocUploader = ({ setStateValue, defaultValue, type = 'optional', name = '', pageName = '', buttonCheck = false, includeName = false, categoryName = '', isMultiAdd = false, multiNum = 1, fileLocation}) => {

    const { handleErrorMessage } = useApplication();

    const application = useSelector((state) => state.applicationInfo);
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const [files, setFiles] = useState([]);
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [fileCount, setFileCount] = useState(0);
    const [progress, setProgress] = useState(0);

    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleFileUpload = async (e) => {
        e.preventDefault();
        setProgress(0);     // Reset the progress
        if (!files || files.length === 0) {
            setError('Please select a file to upload');
            return;
        }
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        const acceptedTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

        const validFiles = [];
        const errors = [];

        for (const file of files) {
            if (!acceptedTypes.includes(file.type)) {
                errors.push(`${file.name}: Accepted file types are pdf, jpg, jpeg, and png.`);
                continue;
            }

            if (file.size > maxSizeInBytes) {
                errors.push(`${file.name}: File size exceeds the 5MB limit.`);
                continue;
            }
            validFiles.push(file);
        }

        if (errors.length > 0) {
            setError(errors.join(' '));
            return;
        }
        setUploading(true);

        try {
            const formData = new FormData();
            validFiles.forEach((file) => {
                formData.append(`files`, file);
            });

            if(!checkFileLocation(fileLocation)) {
                return;
            }

            formData.append('applicationID', application.applicationID);
            formData.append('applicantFirstName', applicant.firstname);
            formData.append('applicantLastName', applicant.lastname);
            formData.append('coapplicantFirstName', coapplicant.firstname);
            formData.append('coapplicantLastName', coapplicant.lastname);
            formData.append('fileLocation', fileLocation);

            const response = await axiosInstance.post('application/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                }
            });

            if (response.status === 200) {
                const UploadedUrls = response.data;
                const updatedState = isMultiAdd ? { ...defaultValue, [multiNum]: UploadedUrls[0] }
                                                : UploadedUrls;
                setStateValue(updatedState);
                handleFileCheck(updatedState);

                if (fileInputRef.current) fileInputRef.current.value = '';
            } else {
                setError('Upload file failed');
                throw new Error('Upload failed');
            }

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }

            setFileCount(fileCount + 1);
        }
        catch (error) {
            setError('Failed to upload file');
        }
        finally {
            setUploading(false);
        }
    };

    const extractPath = (url) => {
        const match = url.match(/application\/(.+)/);
        return match ? match[1] : '';
    };

    const extractFileName = (url) => {
        const decodedUrl = decodeURIComponent(url);
        const parts = decodedUrl.split('/');
        return parts[parts.length - 1];
    };

    const handleFileDownload = async (fileUrl) => {
        const extractedPath = extractPath(fileUrl);
        const fileName = extractFileName(fileUrl);
        try {
            const response = await axiosInstance.get(`/application/download`, {
                params: { fileLocation: extractedPath },
                responseType: 'blob',
            });

            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);

            const fileLink = document.createElement('a');
            fileLink.href = url;
            fileLink.setAttribute('download', fileName);
            document.body.appendChild(fileLink);
            fileLink.click();

            // Clean
            window.URL.revokeObjectURL(url);
            document.body.removeChild(fileLink);
        } catch (error) {
            console.error('Error downloading file:', error);
            setError('Failed to download file');
        }
    };

    const handleFileCheck = (fileUrls) => {
        switch (type) {
            case 'mandatory':
                if (fileUrls.length === 0) {
                    setError(`${name} is mandatory`);
                    handleErrorMessage(pageName, 'add', `${name} is mandatory`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        // Provide automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleFileCheck(defaultValue)
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <div className="m-4 mt-5 flex flex-col items-start">

            {defaultValue.length !== 0 && !isMultiAdd && (
                defaultValue.map((url, index) =>
                    <div className="ml-3 mb-6" key={index}>
                        <strong>Uploaded:</strong>
                        <button
                            className="ml-3 text-black underline hover:text-custom-link-blue p-0 bg-transparent border-none cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                handleFileDownload(url);
                            }}
                        >
                            View or Download Files
                        </button>
                    </div>
                )
            )}

            {defaultValue.length !== 0 && isMultiAdd && defaultValue[multiNum] && (
                <div className="ml-3 mb-6">
                    <strong>Uploaded For Record {multiNum}:</strong>
                    <button 
                        className="ml-3 text-black underline hover:text-custom-link-blue p-0 bg-transparent border-none cursor-pointer"
                        onClick={(e) => {
                            e.preventDefault();
                            handleFileDownload(defaultValue[multiNum]);
                        }}
                    >
                        View or Download Files
                    </button>
                </div>
            )}

            <input
                type="file"
                onChange={handleFileChange}
                required={type === 'mandatory'}
                accept=".pdf,.jpg,.jpeg,.png"
                className='mb-1 ml-3'
                multiple={true}
                ref={fileInputRef}
            />

            <button
                onClick={handleFileUpload}
                disabled={uploading}
                className="my-2 py-1 ml-3 border-2 border-gray-300 bg-gray-100 rounded-sm max-w-[450px] w-auto"
            >
                {uploading ? 'Uploading...' : 'Upload One or Multiple Files'}
            </button>

            <div>
                {uploading && (
                    <div className='flex items-center ml-3 my-2 max-w-[450px] w-full'>
                        <progress value={progress} max="100" className='w-full mr-2'>
                            {progress}%
                        </progress>
                        <span className='text-gray-700'>
                            {progress}%
                        </span>
                    </div>
                )}
                {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{error}</p>}
            </div>

        </div>
    );
};


const checkFileLocation = (fileLocation) => {
    return Object.values(FileLocation).includes(fileLocation);
}

export const FileLocation = {
    APPLICANT_IDENTITY_ID1: "APPLICANT_IDENTITY_ID1",
    APPLICANT_IDENTITY_ID2: "APPLICANT_IDENTITY_ID2",
    APPLICANT_VERIFICATION: "APPLICANT_VERIFICATION",
    APPLICANT_CREDIT_REPORT: "APPLICANT_CREDIT_REPORT",
    COAPPLICANT_IDENTITY_ID1: "COAPPLICANT_IDENTITY_ID1",
    COAPPLICANT_IDENTITY_ID2: "COAPPLICANT_IDENTITY_ID2",
    COAPPLICANT_VERIFICATION: "COAPPLICANT_VERIFICATION",
    COAPPLICANT_CREDIT_REPORT: "COAPPLICANT_CREDIT_REPORT",
    VOID_CHEQUE: "VOID_CHEQUE"
}


export default DocUploader;