import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextFieldText from "../../../components/smallComponents/text/textFieldText/TextFieldText";
import { useDispatch, useSelector } from "react-redux";
import DateField from "../../../components/smallComponents/dateField/DateField";
import { setAdditionalInfo } from "../../../reducers/additionalInfoReducer";
import { setApplicationInfo } from "../../../reducers/applicationReducer"
import { useApplication } from "../../../contexts/ApplicationContext";
import { saveInfo } from '../../../services/saveInfoService';
import TextFieldComponent from "../../../components/smallComponents/textField/TextField";
/**
 * @description - This component renders a DateField component for inputing the client's ID verification date
 * @param {string} color - Background color 
 * @returns - IdVerification subsection component as part of AddtionalInfoPage
 */

const IdVerification = ({ color, applicationId }) => {
    const {  page } = useApplication();
    const dispatch = useDispatch();
    const additionalDetails = useSelector((state) => state.applicationInfo);
    const additionalDetailsinfo = useSelector((state) => state.additionalInfo);
    const { clickNextButton } = useApplication();
    const [initialVerificationDate, setInitialVerificationDate] = useState(null);
    const [initialVerificationPlace, setInitialVerificationPlace] = useState(null);
    const pageName = 'additional';

    const handleApplicantChange = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
        setInitialVerificationDate(value);
    };

    const handleApplicantChangePlace = (attribute, value) => {
        dispatch(setAdditionalInfo({ attribute, value }))
        setInitialVerificationPlace(value);
    };


    const saveVerificationDateInfo = async (attribute, field) => {
        try {
            if(attribute === 'idVerificatedDate'){
                const payload = {
                    applicationID: applicationId,
                    idVerificatedDate:initialVerificationDate
                };
                await saveInfo(field, payload);
            }else if(attribute === 'idVerificatedPlace'){
                const payload = {
                    applicationID: applicationId,
                    idVerificatedPlace:initialVerificationPlace
                    
                };
                await saveInfo(field, payload);
            }
           
        } catch (error) {
            console.error("Error saving advisor info:", error);
        }
    };


    useEffect(() => {
    
        if (
            additionalDetails.verificationDate !== null && initialVerificationDate !== null
        ) {
            saveVerificationDateInfo('idVerificatedDate', 'additionalVerificationDatePlace');
        }
    }, [additionalDetails.verificationDate, initialVerificationDate,]);

    useEffect(() => {
    
        if (
            additionalDetails.verificationPlace !== null && initialVerificationPlace !== null && initialVerificationPlace.length >= 2
        ) {
            saveVerificationDateInfo('idVerificatedPlace', 'additionalVerificationDatePlace');
        }
    }, [additionalDetails.verificationPlace, initialVerificationPlace]);


    return (
        <div>
            <Box className='mt-10 rounded-xl shadow-lg text-black-950 py-5 flex justify-center' bgcolor={color}>
                <Box className='flex justify-center items-center mt-5 gap-5'>
                    <TextFieldText text="Client ID Verification Date:" />
                    <DateField
                        defaultValue={additionalDetailsinfo.verificationDate}
                        setStateValue={value => handleApplicantChange("verificationDate", value)}
                        name='Client ID Verification Date'
                        type='date'
                        pageName={pageName}
                        buttonCheck={clickNextButton[pageName]}
                    />
                   <TextFieldText text="ID Verification Place" />
                    <TextFieldComponent
                        defaultValue={ additionalDetailsinfo.verificationPlace}
                        setStateValue={value => handleApplicantChangePlace("verificationPlace", value)}
                        type='optional'
                    />
                </Box>
            </Box>
        </div>
    )
}

export default IdVerification;