import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import "../general/section/style/MainApplicantSection.css";
import { useEffect } from "react";
import { useApplication } from "../../../../contexts/ApplicationContext";



/**
 * Renders the Employment Information section of the application page.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.EmpStatus - The array of employment status options.
 * @returns {JSX.Element} The rendered component.
 */
export default function EmploymentInformation({ EmpStatus, enable }) {
    const itemSpace = 3;

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const { clickNextButton } = useApplication();
    const pageName = 'employment'



    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    function clearField() {
        dispatch(setApplicantInfo({ attribute: "jobTitle", value: "" }));
        dispatch(setApplicantInfo({ attribute: "natureOfJobResponsibilities", value: "" }));
        dispatch(setApplicantInfo({ attribute: "employmentName", value: "" }));
        dispatch(setApplicantInfo({ attribute: "natureOfEmployersBusiness", value: "" }));

    }

    //to track the employment status value and trigger the clearField
    useEffect(() => {
        if (applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === "") {
            clearField();
        }
        // eslint-disable-next-line
    }, [applicant.employmentStatus]);


    return (
        <div style={{ display: enable ? 'block' : 'none' }}>
            <Box>
                <Box className="section-main-box-field">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={itemSpace}>
                                    <Box>
                                        <TextFieldText text='Employment Status' />
                                        <DropdownList
                                            options={EmpStatus}
                                            setStateValue={value => handleApplicantChange("employmentStatus", value)}
                                            defaultValue={applicant.employmentStatus}
                                            type='mandatory' name='Applicant Employment Status' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                            />
                                    </Box>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldText
                                            text='Detailed Occupation/Job Title' largeBox={true}/>
                                        <TextField
                                            setStateValue={value => handleApplicantChange("jobTitle", value)}
                                            defaultValue={applicant.jobTitle}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            type={applicant.employmentStatus && (applicant.employmentStatus !== 'Student' && applicant.employmentStatus !== 'Retired')? 'mandatory' : 'optional'} name='Applicant Job Title' pageName={pageName} buttonCheck={clickNextButton[pageName]} />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldText
                                            text='Nature of Job Responsibilities' largeBox={true}/>
                                        <TextField
                                            setStateValue={value => handleApplicantChange("natureOfJobResponsibilities", value)}
                                            longBox = {true}
                                            defaultValue={applicant.natureOfJobResponsibilities}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            type={applicant.employmentStatus && (applicant.employmentStatus !== 'Student' && applicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Applicant Job Responsibilities' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldText
                                            text='Employment Name' />
                                        <TextField
                                            setStateValue={value => handleApplicantChange("employmentName", value)}
                                            defaultValue={applicant.employmentName}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            type={applicant.employmentStatus && (applicant.employmentStatus !== 'Student' && applicant.employmentStatus !== 'Retired') ? 'mandatory' : 'optional'} name='Applicant Employment Name' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box>
                                        <TextFieldText
                                            text="Nature of Employer's Business" largeBox={true}/>
                                        <TextField
                                            setStateValue={value => handleApplicantChange("natureOfEmployersBusiness", value)}
                                            defaultValue={applicant.natureOfEmployersBusiness}
                                            longBox = {true}
                                            disabled={applicant.employmentStatus === "Student" || applicant.employmentStatus === "Retired" || applicant.employmentStatus === ""}
                                            type={applicant.employmentStatus && (applicant.employmentStatus !== 'Student' && applicant.employmentStatus !== 'Retired')? 'mandatory' : 'optional'} name='Applicant Employer Business' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </div>
    )
}