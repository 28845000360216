import { Box, Grid, FormControlLabel } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from '../../../../reducers/coapplicantReducer';
import { useApplication } from "../../../../contexts/ApplicationContext";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import DocUploader, { FileLocation } from '../../../../components/mediumComponents/uploadDoc/docUploader';
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import Checkbox from "../../../../components/smallComponents/checkbox/Checkbox";
import { fetchConfig } from "../../../../services/fetchConfigService";
import './style/CreditReport.css'
import { fetchApplicantcreditInfo } from '../../../../services/fetchInfoService';
import { useRawFiles } from "../../../../contexts/FileContext";
import { uploadToBlob } from '../../../../services/blobService';
import ConfirmDialog from "../../../../components/smallComponents/alert/ConfirmDialog";
import InputFileUpload from '../../../../components/mediumComponents/uploadDoc/FileUploader';



const title = "Credit Report";
const pageName = 'report';

/**
 * @returns - Credit Report main field component for the application page
 */


const CreditReport = () => {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const { rawFiles, saveRawFile, setRawFiles } = useRawFiles(); 
    const { clickNextButton } = useApplication();
    const [minimumScore, setMinimumScore] = useState("");
    const { handleErrorMessage } = useApplication();
    const [applicantConfirmed , setApplicantConfirmed] = useState(false);
    const [coApplicantConfirmed , setCoApplicantConfirmed] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
    const [dialogError, setDialogError] = useState('');

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    }

    useEffect(() => {
        fetchConfig("Por_App_CreditReport", "CreditScoreRequired").then((score) => {
            setMinimumScore(score);
        }); 
    }, []);
    const application = useSelector((state) => state.applicationInfo);

    const creditReportCheckedLabel = `I, the Applicant, confirm my credit score is at least ${minimumScore}.`;
    const coCreditReportCheckedLabel = `I, the Co-Applicant, confirm my credit score is at least ${minimumScore}.`;

    const fetchApplcantInfoCredit = async ()=>{
        
        const applicantInfo = await fetchApplicantcreditInfo(application.applicationID);
       
        if(applicantInfo.length >0){
            setApplicantConfirmed(true);
            setCoApplicantConfirmed(true);
            applicantInfo.map((applicant, index) => {
                let fileURL = [];
                fileURL.push(applicant.proofLink);
                if (applicant.applicantType === 'Applicant') {
                    dispatch(setApplicantInfo({ attribute: 'applicantCreditReportUrls', value: fileURL }));
                
                }else{
                    dispatch(setCoapplicantInfo({ attribute: 'coApplicantCreditReportUrls', value: fileURL}));
                }
                fileURL = [];
            });
        }
      
    }

    useEffect(() => {
        fetchApplcantInfoCredit();
    }, []);

    const hasCoApplicant = applicant.anyCoapplicant;

    useEffect(() => {
        if (minimumScore !== "") {
            if (!applicantConfirmed) {
                handleErrorMessage(pageName, 'add', `Applicant's Credit Score must be higher than ${minimumScore}`);
            } else {
                handleErrorMessage(pageName, 'remove', `Applicant's Credit Score must be higher than ${minimumScore}`);
            }
            if (!coApplicantConfirmed && hasCoApplicant) {
                handleErrorMessage(pageName, 'add', `Co-Applicant's Credit Score must be higher than ${minimumScore}`);
            } else {
                handleErrorMessage(pageName, 'remove', `Co-Applicant's Credit Score must be higher than ${minimumScore}`);
            }
        }
    }, [applicantConfirmed, coApplicantConfirmed, hasCoApplicant, handleErrorMessage, minimumScore]);

    const itemSpace = 6;

    const saveFile = (attribute, value) => {
        saveRawFile(attribute, value);
    }

    const handleClick = async () => {

        setIsLoading(true);
        
        await handleUploadToBlob(application.applicationID, 'Applicant', 'applicantCreditReportUrls');
        if(applicant.anyCoapplicant) {
            await handleUploadToBlob(application.applicationID, 'Co-Applicant', 'coApplicantCreditReportUrls');
        }

        setIsLoading(false);
    }

    const handleUploadToBlob = async (applicationID, applicantType, attribute) => {
        const file = rawFiles[attribute];
        if(!file){
            return;
        }
        const res = await uploadToBlob(applicationID, applicantType, attribute, file);
        if (res.success) {
            if(applicantType === 'Applicant') {
                dispatch(setApplicantInfo({ attribute, value: res.url }));
            } else if (applicantType === 'Co-Applicant') {
                dispatch(setCoapplicantInfo({ attribute, value: res.url }));
            }
            setDialogError('');
        } else {
            setIsErrorDialogOpen(true);
            setDialogError(res);
        }

    }

    return (
        <>
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 credit-report-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-4 bg-mainfield-box rounded-2xl shadow-custom'>
                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <Box className="mt-4 bg-mainfield-box rounded-2xl ml-4">
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <span className="w-[20vw] min-h-[47px] text-left font-sans text-[20px] font-normal text-[#3C3C3C] leading-8">Applicant Credit Report</span>
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        options={[creditReportCheckedLabel]}
                                        setStateValue={(value) => {setApplicantConfirmed(!value.length)}}
                                        defaultValue={[]}
                                        pageName={pageName}
                                    />
                                }
                            />
                        </Box>
                        <InputFileUpload setUploadedFile={value => saveFile("applicantCreditReportUrls", value)} />
                    </Grid>

                    <Grid item xs={itemSpace} display={hasCoApplicant ? 'block' : 'none'} >
                        <Box className="mt-4 bg-mainfield-box rounded-2xl ml-4">
                            <Box display="flex" flexDirection="column" alignItems="flex-start">
                                <span className="w-[20vw] min-h-[47px] text-left font-sans text-[20px] font-normal text-[#3C3C3C] leading-8">Co-Applicant Credit Report</span>
                            </Box>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        options={[coCreditReportCheckedLabel]}
                                        setStateValue={(value) => {setCoApplicantConfirmed(!value.length)}}
                                        defaultValue={[]}
                                        pageName={pageName}
                                    />
                                }
                            />
                        </Box>
                        <InputFileUpload setUploadedFile={value => saveFile("coApplicantCreditReportUrls", value)} />
                    </Grid>

                </Grid>
            </Box>

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} handleClick={handleClick}/>
            </Box>

        </Box>
        <ConfirmDialog
                open={isLoading}
                message={"Uploading files, please wait..."}
            />
        </>
    );
};

export default CreditReport;