/**
 * Renders the Unavailable page component.
 * 
 * @returns {JSX.Element} The Unavailable page component.
 */
import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './Unavailable.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';

function Unavailable() {
    const { selectedComponent, setSelectedComponent } = useApplication();

    const investment = selectedComponent;
    return (
        <Box>
            <HeaderBox text={investment + ' investment account is not available'} />
            <div className="textPage">
                <p>{investment} investment account is not available, please select other investment account options.</p>
            </div>
            <div className='button-Area'>
                    <NavLink onClick={() => setSelectedComponent('OwnFund')}>
                        <button className="bigButton">Back</button>
                    </NavLink>
            </div>

        </Box>
    );
}

export default Unavailable;