import { axiosInstance } from '../axios';
import { useState } from 'react';
import {answerAttributes , discloseQuestions , disclosureVariables } from "../constants/lists";
import  MonthlyRepayment  from '../components/enumTypes/MonthlyRepayment';

const baseUrl = "/save";

export function infoToSave(pageName, info, type, applicationID, applicationType, application) {
  
  

  if(pageName === 'application'){
    return {
      'applicationID': applicationID,
      'fundType': info.fundType,
      'investmentAccountType': info.investmentAccountType,
      'requestType': info.requestType,
      'customerType': "Individual",
      'createdUserID': info.createdUserID,
      'applicationStatus': "Editing",
      'autofill': false,
      'servingAdvisorCode': info.servingAdvisorCode,
      'isSystemAssignAdvisor': info.isSystemAssignAdvisor,
      'hasCoApplicant': info.hasCoApplicant 
    }
  } else if(pageName === 'general'){
    // por_app_cus_individual 
    return {
        'applicationID': applicationID,
        'applicantType': type,
        'preferredLanguage': info.languagePreference,
        'firstName': info.firstname,
        'lastName': info.lastname,
        'preferredName': info.preferredName,
        'dateOfBirth': info.dateOfBirth,
        'sin': info.socialInsuranceNumber,
        'gender': info.gender,
        'residentialStatus': info.residentialStatus,
        'citizenship': info.citizenship,
        'maritalStatus': info.maritalStatus,
        'placeOfBirth': info.placeOfBirth,
        'countryOfBirth': info.countryOfBirth,
        'provinceOfBirth': info.provinceOfBirth,
        'residentOfCanadaSince': info.residentOfCanadaSince

    }
  } else if(pageName === 'contact'){
    // por_app_cus_contactinfo
    return {
      "contact": {
        'applicationID': applicationID,
        'applicantType': type,
        'email': info.email,
        'homePhone': info.homePhone,
        'workPhone': info.workPhone,
        'cellPhone': info.cellPhone
      // por_app_cus_address
      },"address": {
        'applicationID': applicationID,
        'applicantType': type,
        'streetNumberC': info.streetNumber,
        'streetNameC': info.streetName,
        'unitC': info.unit,
        'cityC': info.city,
        'provinceC': info.province,
        'postCodeC': info.postalCode?.toUpperCase(),
        'livingSinceC': info.livingSince,
        'streetNumberP': info.prevStreetNumber,
        'streetNameP': info.prevStreetName,
        'unitP': info.prevUnit,
        'cityP': info.prevCity,
        'provinceP': info.prevProvince,
        'postCodeP': info.prevPostalCode?.toUpperCase(),
        'livingSinceP': info.prevLivingSince
    }}
  } else if (pageName === 'identification') {    
    // por_app_cus_identity
    return {
        'applicationID': applicationID,
        'applicantType': type,
        'applicantID': [
            {
                'customerIdentityID': info.CustomerIDs?.length >0 ? info.CustomerIDs[0] : '',
                'fullName': info.fullName,
                'idType': info.idType,
                'idNumber': info.idNumber,
                'issueCountry': info.issueCountry,
                'issueProvince': info.issueProvince,
                'issueAuthority': info.issueAuthority,
                'issueDate': info.issueDate,
                'expireDate': info.expireDate,
                'verified': info.verified,
                'idUris': type === 'Co-Applicant' ? info.coApplicantID1Urls : info.applicantID1Urls
            },
            {
              'customerIdentityID': info.CustomerIDs?.length >0 ? info.CustomerIDs[1] : '',
              'fullName': info.fullName_2,
              'idType': info.idType_2,
              'idNumber': info.idNumber_2,
              'issueCountry': info.issueCountry_2,
              'issueProvince': info.issueProvince_2,
              'issueAuthority': info.issueAuthority_2,
              'issueDate': info.issueDate_2,
              'expireDate': info.expireDate_2,
              'verified': info.verified_2,
              'idUris': type === 'Co-Applicant' ? info.coApplicantID2Urls : info.applicantID2Urls
            }            
        ],
        'applicantTaxStatus': {
            'applicationID': applicationID,
            'applicantType': type,
            'residenceOfCanada': info.residenceOfCanada,
            'residenceOfUnitedStates': info.residenceOfUnitedStates,
            'haveTinUS': info.haveTinUS,
            'tinUS': info.tinUS,
            'reasonOfNoTinUS': info.reasonOfNoTinUS,
            'otherResidence': info.otherResidence,
            'countryName': info.countryName,
            'haveTinOther': info.haveTinOther,
            'tinOther': info.tinOther,
            'reasonOfNoTinOther': info.reasonOfNoTinOther
        },
        ...( // Add this section conditionally
          !info.verified || !info.verified_2 ? {
              'idIdentification': {
                  'applicationID': applicationID,
                  'applicantType': type,
                  'idUrl': type === 'Co-Applicant' ? info.coApplicantPersonalID_1Urls : info.applicantPersonalID_1Urls,
                  'informationVerified': 'Name and address',
                  'issueAuthority': type === 'Co-Applicant' ? info.coApplicantPersonalIDIssuingAuthority : info.applicantPersonalIDIssuingAuthority,
                  'idType': type === 'Co-Applicant' ? info.coApplicantPersonalIDType : info.applicantPersonalIDType,
                  'accountReference': type === 'Co-Applicant' ? info.coApplicantPersonalIDAccountReference : info.applicantPersonalIDAccountReference,
                  'expiryDate': type === 'Co-Applicant' ? info.coApplicantPersonalIDExpiryDate : info.applicantPersonalIDExpiryDate || null
              }
          } : {}
      )
    };   
  } else if (pageName === 'employment') {
    //por_app_cus_employment
    if (type === 'Applicant') {
      return {
        'applicantEmployment': {
          'applicationID': applicationID,
          'employmentStatus': info.employmentStatus,
          'jobTitle': info.jobTitle,
          'natureOfJobResponsibilities': info.natureOfJobResponsibilities,
          'employmentName': info.employmentName,
          'natureOfEmployerBusiness': info.natureOfEmployersBusiness,
        }
      }
    } else if (type === 'Co-Applicant') {
      return {
        'coApplicantEmployment': {
          'applicationID': applicationID,
          'employmentStatus': info.employmentStatus,
          'jobTitle': info.jobTitle,
          'natureOfJobResponsibilities': info.natureOfJobResponsibilities,
          'employmentName': info.employmentName,
          'natureOfEmployerBusiness': info.natureOfEmployersBusiness,
        }
      }
    }
  }else if(pageName === 'annuitant'){
    //por_app_SuccessorAnnuitant
    return {
      'applicationID': applicationID,
      'languagePreference': info.successorAnnuitantLanguagePreference,
      'firstName': info.successorAnnuitantFirstName,
      'lastName': info.successorAnnuitantLastName,
      'dateOfBirth' : info.successorAnnuitantDateOfBirth,
      'gender' : info.successorAnnuitantGender,
      'sin' : info.successorAnnuitantSIN,
      'homePhone' : info.successorAnnuitantHomePhone,
      'relationToAnnuitant': info.relationToAnnuitant,
      'customerID': null,
      'designatedSuccessorAnnuitant': info.designatedSuccessorAnnuitant
    }
  }else if(pageName === 'owner'){
    return {
      'applicationID': applicationID,
      'languagePreference' : info.ownerLanguagePreference,
      'firstName': info.ownerFirstName,
      'lastName': info.successorAnnuitantLastName,
      'dateOfBirth' : info.ownerDateOfBirth,
      'gender' : info.ownerGender,
      'sin' : info.ownerSIN,
      'homePhone' : info.ownerHomePhone,
      'relationToOwner': info.relationToOwner,
      'customerID': null,
      'designatedSuccessorOwner': info.designatedSuccessorOwner
    }
  } else if(pageName === 'source'){
    return {
      'applicationID': applicationID,
      'amountApplied': info.loanAmountApplied,
      'newLoanorNot': info.newLoan,
      'loanRatio': info.loanRatio,
      // currently the user can't change monthly repayment option.
      'monthlyRepaymentOption': info.monthlyRepaymentOption || MonthlyRepayment.INTERESTONLY,
      'repaymentTerm': info.repaymentTerms,
      'existingLoanAmount': info.existingLoanAmount,
      'amountForCollateral': info.amountForCollateral,
      'existingSegFundInvestmentAccount': info.existingSegFundInvestmentAccount
      // 'loanFromBank': info.loanBank,
      // 'bankAccountNumber': info.bankAccountNumber,
      // 'loanStatementLink': info.loanStatementLink,
      // 'currentInvestmentCompany': info.currentInvestmentCompany,
      // 'currentInvestmentAccountNumber': info.currentInvestmentAccountNumber,
      // 'investmentStatementLink': info.investmentStatementLink,
      // 'currentInvestmentMarketValue': info.currentInvestmentMarketValue,
      // 'currentInvestmentFundName': info.currentInvestmentFundName,
      // 'currentInvestmentFundCode': info.currentInvestmentFundCode,
      // 'sourceOfContribution': info.sourceOfContribution,
      // 'relinquishingInstitution': info.relinquishingInstitution,
      // 'streetNumber': info.streetNumber,
      // 'streetName': info.streetName,
      // 'unit': info.unit,
      // 'city': info.city,
      // 'province': info.province,
      // 'postalCode': info.postalCode,
      // 'accountNumber': info.accountNumber,
      // 'typeOfTransfer': info.typeOfTransfer,
      // 'investmentDescription': info.investmentDescription,
      // 'typeOfAmount': info.typeOfAmount,
      // 'amount': info.amount,
      // 'accountStatementLink': info.accountStatementLink,
      // 'padType': info.padType,
      // 'regularPadAmount': info.regularPadAmount,
      // 'regularPadFrequency': info.regularPadFrequency,
      // 'firstRegularPadDate': info.firstRegularPadDate,
      // 'policyGuaranteeLevel': info.policyGuaranteeLevel,
      // 'accountHolder': info.accountHolder,
      // 'jointAccountHolder': info.jointAccountHolder,
      // 'accountNo': info.accountNo,
      // 'transitNo': info.transitNo,
      // 'bankName': info.bankName,
      // 'bankCode': info.bankCode,
      // 'requireMoreSignature': info.requireMoreSignature,
      // 'jointAccountHolderEmail': info.jointAccountHolderEmail,
      // 'voidChequeLink': info.voidChequeLink,
      // 'respPlanType': info.respPlanType,
      // 'primaryCaregiver': info.primaryCaregiver,
      // 'legalGuardian': info.legalGuardian,
      // 'isSameBeneficiary': info.isSameBeneficiary
    }
  } else if(pageName === 'contribution'){
    console.log(info);
    return {
      'applicationID': applicationID,
      'amountApplied': info.loanAmountApplied,
      'accountHolder': info.accountHolder,
      'jointAccountHolder': info.jointAccountHolder,
      'bankCode': info.institutionCode,
      'bankName': info.institutionName,
      'transitNo': info.transitNumber,
      'accountNo': info.accountNumber,
      'requireMoreSignature': info.requireMultipleSignature || 0,
      'policyGuaranteeLevel': info.guaranteeLevel,
      'jointAccountHolderEmail': info.jointAccountHolderEmail,
      'voidChequeLink': info.voidChequeLink
    }

  }else if (pageName === 'investorProfile') {
    let profileRecord = [];
    info.q_ques.forEach((item, index) => {
  
      const newRecord = {
        applicationID: applicationID, // Auto-incrementing applicationID
        questionID: index+1, // Example questionId
        question: item, // Example question
        questionCategory: info.q_quesCate[index], // Example category
        optionID: info[answerAttributes[index]][0] , // Initial answerId
        answer: info[answerAttributes[index]][1] !== undefined ? info[answerAttributes[index]][1] : 'NULL', // Use answer if undefined
        score: info[answerAttributes[index]][2] !== undefined ? info[answerAttributes[index]][2] : 0,    // Use 0 if undefined
      };
  
      // Update the state with the new record
      profileRecord.push(newRecord)
    });
    
    if (type === 'Applicant') {
      return {
        'applicantProfile': profileRecord  // Send as an array
      };
    } else if (type === 'Co-Applicant') {
      return {
        'coApplicantProfile': profileRecord  // Send as an array
      };

    } 
  } else if (pageName === 'report'){
    return {
      'applicationID': applicationID,
      'applicantType': type,
      'proofLink': type === 'Applicant'? info.applicantCreditReportUrls : info.coApplicantCreditReportUrls,
    }

  } else if(pageName === 'specialInstructions'){
    return {
      'applicationID': applicationID,
      'howHeard' : info.howHeard,
      'heardDetail': info.heardDetail,
      'additionalInfo': info.additionalInformation
    }
  }else if(pageName === 'disclosure'){

    let disclosureRecord = [];
      if(applicationType === 'quick'){

        discloseQuestions.forEach((question, index) => {
          const newRecord = {
            applicationID: applicationID, 
            questionType:  index === 0 ? 'Anti-Money Laundering' : 'Disclosure for Investment Loan', 
            question: question, 
            answer: info[[disclosureVariables[index]]],
          };
          // Update the state with the new record
          disclosureRecord.push(newRecord)
        });
      }
      if (type === 'Applicant') {
        return {
          'applicantDisclosureLoan': disclosureRecord  // Send as an array
        };
      } else if (type === 'Co-Applicant') {
        return {
          'coApplicantDisclosureLoan': disclosureRecord  // Send as an array
        };
  
      } 
  }else if(pageName === 'summary'){
    return {
      'applicationStatus': application.applicationStatus === ''? 'Submitted' : application.applicationStatus, 
        'applicationID': applicationID,
    }
  }else if(pageName === 'caseGeneration'){
    let caseRecord = [];
    const totoalapplyAmount = Number(info.loanAmountApplied);
      if(totoalapplyAmount <= 100000.0){
        const case1ID = applicationID+'01';
        const newCase = {
          caseID: case1ID,
          applicationID: applicationID, 
          caseStatus: 'Under Review',
          loanFrom: 'B2B',
          amountApplied: totoalapplyAmount,
          salesCharge: 'FEL',
          primeRate: application.defaultPrimeRate,
          subInvestmentAccountType: 'Segregated Fund'
        };
        caseRecord.push(newCase)

      }else{
        //need to creat two cases
        const case1ID = applicationID+'01';
        const case2ID = applicationID+'02';
        let amountBalance = Number(totoalapplyAmount) - 100000.0;
        const newCase1 = {
          caseID: case1ID,
          applicationID: applicationID, 
          caseStatus: 'Under Review',
          loanFrom: 'B2B',
          investmentTo: 'CL',
          amountApplied: 100000.0,
          salesCharge: 'FEL',
          primeRate: application.defaultPrimeRate,
          subInvestmentAccountType: 'Segregated Fund'          
        };
        const newCase2 = {
          caseID: case2ID,
          applicationID: applicationID, 
          caseStatus: 'Under Review',
          loanFrom: 'MLB',
          investmentTo: 'ML',
          amountApplied: Number(amountBalance),
          salesCharge: 'FEL',
          primeRate: application.defaultPrimeRate,
          subInvestmentAccountType: 'Segregated Fund'
        };
        caseRecord.push(newCase1);
        caseRecord.push(newCase2)
      }
    return {
      'applicationCaseProfile': caseRecord,
    }
  }else if(pageName === 'newcaseCreate'){
    let caseRecord = [];
    const newCase = {
      applicationID: applicationID, 
      caseStatus: info.caseStatus,
      loanFrom: info.loanBanks,
      investmentTo: info.fundCompanies,
      amountApplied: Number(info.appliedAmount),
      salesCharge: info.salesCharge,
      primeRate: info.primeRate,
      subInvestmentAccountType: info.subAccountType,
      completedAmount: info.completedAmount === null ? 0.0 : info.completedAmount,
      completedDate: info.completedDate,
      loanAccountNo: info.loanAccountNo,
      loanNo: info.loanNo,
      contractNo: info.contractNo,
      contributionAmount: info.contributionAmount,
      felRate: info.felRate,
      felFee: info.felFee,
      fundPortfolioNo: info.portfolioNo,
      policyGuaranteeLevel: info.policyGuaranteeLevel === "" ? '75/75' : info.policyGuaranteeLevel
      
    };
    caseRecord.push(newCase)
    return {
      'applicationCaseProfile': caseRecord,
    }
  }else if(pageName === 'updatecaseCreat'){
    let caseRecord = [];
    const newCase = {
      caseID: info.caseID,
      applicationID: info.applicationID, 
      caseStatus: info.caseStatus,
      loanFrom: info.loanBanks,
      investmentTo: info.fundCompanies,
      amountApplied: Number(info.appliedAmount),
      salesCharge: info.salesCharge,
      primeRate: info.primeRate,
      subInvestmentAccountType: info.subAccountType,
      completedAmount: info.completedAmount === null ? 0.0 : info.completedAmount,
      completedDate: info.completedDate,
      loanAccountNo: info.loanAccountNo,
      loanNo: info.loanNo,
      contractNo: info.contractNo,
      contributionAmount: info.contributionAmount,
      felRate: info.felRate,
      felFee: info.felFee,
      fundPortfolioNo: info.portfolioNo,
      policyGuaranteeLevel: info.policyGuaranteeLevel
      
    };
    caseRecord.push(newCase)
    return {
      'applicationCaseProfile': caseRecord,
    }
  }else if(pageName === 'acquisitionChannel'){
    return {
      'acquisitionChannelID': application.AcquisitionChannelID,
      'applicationID': applicationID,
      'applicantType': type,
      'customerAcquisitionChannel': application.acquisitionChannel,
      'customerAcquisitionChannelDetail': application.acquisitionChannelDetail,
      'referrerFirstName': info.refFirstName,
      'referrerLastName': info.refLastName,
      'referrerPreferredName': info.refPreferredName,
      'referrerCustomerID': info.customerID === "" ? null : info.customerID,
      'referrerDateofBirth': info.refDateOfBirth,
      'referrerGender': info.refGender,
      'referrerHomePhone': info.refHomePhone
  
    }
  }
};


export async function saveInfo (pageName, info) {
  try{
    const response = await axiosInstance.post(`${baseUrl}/${pageName}`, info)
      return {
        success: true, 
        data: response.data}
    }catch(error){
        return {
          success: false,
          error: error.response.data
        }
    }
}

