import { Box, TextField } from '@mui/material';
import { useState, useEffect } from "react";
import { useApplication } from '../../../contexts/ApplicationContext';
import './style/NumberField.css';
import { useLoanAmount } from '../../../contexts/LoanAmountContext';


/**
 * @param {function} setStateValue - A function that sets the state value
 * @param {string} defaultValue - The default value of the number field
 * @param {boolean} disabled - A boolean value that determines if the number field is disabled
 * @param {number} minValue - The minimum value of the number field
 * @param {number} maxValue - The maximum value of the number field
 * @param {boolean} isClear - A boolean value that determines if the number field needs to be cleared
 * @param {string} type - The type of the number field
 * @param {string} name - The name of the number field
 * @param {string} pageName - The name of the page where the number field is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled
 * @returns - A number field component
 * @description - This component restricts the user from entering values less than the minimum value and greater than the maximum value
 */


export default function NumberField({ setStateValue, defaultValue, disabled, minValue = 0, maxValue = 1000000, isClear = false,
    type = 'optional', name = '', pageName = '', buttonCheck = false, onBlur = () => { } }) {

    const { handleErrorMessage } = useApplication();
    const [inputValue, setInputValue] = useState(defaultValue === '' ? defaultValue : parseFloat(defaultValue));
    const [error, setError] = useState('');

    const handleNumberFieldChange = (event) => {
        const userTypedValue = event.target.value;
        setInputValue(userTypedValue);
        setStateValue(userTypedValue.toString());
    };

    const handleNumberFieldCheck = () => {

        const number = defaultValue === '' ? defaultValue : parseFloat(defaultValue);
        const validDecimalPattern = /^\d*\.?\d+$/;

        switch (type) {
            case 'number':
                if (number === '' || !validDecimalPattern.test(number)) {
                    console.log('number doesnt meet valid decimal pattern: ', number);
                    setError('mandatory');
                    handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                    handleErrorMessage(pageName, 'add', `${name} is mandatory`);
                }
                else if (number < minValue || number > maxValue) {
                    setError('range');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'add', `Range for ${name} is ${minValue} to ${maxValue}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                    handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
                handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
                break;
            default:
                break;
        }

        onBlur();
    };

    function checkError(error) {
        if (error === 'mandatory') {
            return 'Numeric Input Required';
        }
        else if (error === 'range') {
            return 'Out of Range';
        }
    };

    useEffect(() => {
        if (isClear) {
            setInputValue('');
        }
    }, [isClear]);

    useEffect(() => {
        if (parseFloat(defaultValue) !== inputValue) {
            setInputValue(defaultValue === '' ? defaultValue : parseFloat(defaultValue));
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleNumberFieldCheck();
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `${name} is mandatory`);
            handleErrorMessage(pageName, 'remove', `Range for ${name} is ${minValue} to ${maxValue}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className='number-box'>
            <TextField className='number-field bg-white rounded' type="number" 
                inputProps={{
                    min: minValue,
                    max: maxValue,
                    style: { fontSize: '20px' }
                }}
                value={inputValue}
                onChange={handleNumberFieldChange}
                onBlur={handleNumberFieldCheck}
                disabled={disabled}
                required={type !== 'optional'}
                error={error !== ''}
            />
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};