

export function isValidSIN(sin){
    if(!process.env.REACT_APP_SIN_VERIFICATION){
        return true;
    }
    if(!/^[0-9]{9}$/.test(sin)){
        return false;
    }

    let chars = sin.split("");
    let sum = 0;

    chars.forEach((char, index) => {
    let num = parseInt(char, 10); // Convert char to number
    if (index % 2 !== 0) {
        // Multiply every second digit (index 1, 3, 5, etc.)
        num = num * 2;
        
        // If the result is a two-digit number, add each digit separately
        if (num > 9) {
        num = Math.floor(num / 10) + (num % 10);
        }
    }
    sum += num; // Add the number to the sum
    });

    if(sum % 10 === 0){
        return true;
    }else{
        return false;
    }



}

export function isTempSIN(sin){
    return sin.startsWith("9");
}