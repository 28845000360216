import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useParams } from 'react-router-dom';
import { discloseQuestions, disclosureVariables } from "../../../../../constants/lists";


/**
 * @param {boolean} enable - Enable the display of the coapplicant disclosure section
 * @returns - Coapplicant disclosure section for the disclosure page
 */


const CoappDisclosureSection = ({ enable }) => {

    const dispatch = useDispatch();
    const coapplicant = useSelector(state => state.coapplicantInfo);

    const applicationType = useParams().applicationType;

    const questionSet = ['regular', 'quick'].includes(applicationType) ? discloseQuestions : [discloseQuestions[0]];

    const handleChange = (index, value) => {
        const attribute = disclosureVariables[index]
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className='mt-12 bg-cofield-box rounded-2xl shadow-custom' >
                {questionSet.map((question, index) => {
                    const isLast = index === questionSet.length - 1;
                    return (
                        <div key={index} className={`flex flex-row m-3 p-1 leading-8 ${isLast ? '' : 'border-b-2 border-gray-400'}`}>
                            <div className="flex basis-11/12 mx-2 my-2 text-2xl">{index + 1}. {question}</div>
                            <div className="flex justify-items-start grid-cols-1 basis-1/12 my-2 h-full place-items-center">
                                <label className="flex items-center font-semibold text-2xl">
                                    <input
                                        type="radio"
                                        name={`coapp_disclosure_${index}`}
                                        value="Yes"
                                        className="mx-2"
                                        checked={coapplicant[disclosureVariables[index]] === 'Yes'}
                                        onChange={() => handleChange(index, 'Yes')}
                                    />
                                    Yes
                                </label>
                                <label className="flex items-center font-semibold text-2xl">
                                    <input
                                        type="radio"
                                        name={`coapp_disclosure_${index}`}
                                        value="No"
                                        className="mx-2"
                                        checked={coapplicant[disclosureVariables[index]] === 'No'}
                                        onChange={() => handleChange(index, 'No')}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                    )
                })}
            </Box>

        </Box>
    );
};

export default CoappDisclosureSection;