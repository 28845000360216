import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import SettingFeatures from "../../components/mediumComponents/account/SettingFeatures";
import AccountMenu from "../../components/mediumComponents/account/AccountMenu";
import ApplicationTable from "../../components/mediumComponents/account/ApplicationTable";
import { useSelector } from "react-redux";
import Footer from "../../components/mediumComponents/footer/Footer";
/**
 * this is the landing page of the Account section
 */

const AccountSettingPage = () => {

    const [displayApplication, setDisplayApplication] = useState(false)
    const [displaySetting, setDisplaySetting] = useState(false)
    const userInfo = useSelector((state) => state.userInfo);

    useEffect(()=> {
        if(userInfo.userID){
            setDisplayApplication(true)
        }
    }, [userInfo])

    const footerProp = "@2024 - 2025 Ai Financial Power Group LTD. All Rights Reserved.";

    return (
        <Box sx={{ flexGrow: 1 }}>
            <div className="grid grid-cols-4 w-full">
                <div className="col-span-3 m-8">
                    {displayApplication? <ApplicationTable /> : null}
                </div>
            </div>
        </Box>
    )
}

export default AccountSettingPage
