import { createContext, useContext, useState, } from "react";


/**
 * @description - ApplicationContext is used to store functional global states that will not been saved to the database 
 */


const ApplicationContext = createContext();

// Initial state values
const initialState = {
    selectedComponent: 'Home',
    progress: 0,
    page: 0,
    mainManualInputCurrCheck: [],
    mainManualInputPrevCheck: [],
    mainIncomeManualInputCurrCheck: [],
    mainIncomeManualInputPrevCheck: [],
    coManualInputCurrCheck: [],
    coManualInputPrevCheck: [],
    mainInvestorScore: [],
    coInvestorScore: [],
    mainInvestorScoreCalc: 0,
    coInvestorScoreCalc: 0,
    tdsr: 0,
    income:0,
    worth:false,
    beneShare:0,
    errorMessages: {
        general: [],
        contact: [],
        identification: [],
        employment: [],
        disclosure: [],
        product: [],
        source: [],
        option: [],
        financial: [],
        beneficiary: [],
        annuitant: [],
        owner: [],
        profile: [],
        verification: [],
        report: [],
        special: [],
        additional: []
    },
    clickNextButton: {
        general: false,
        contact: false,
        identification: false,
        employment: false,
        disclosure: false,
        product: false,
        source: false,
        option: false,
        financial: false,
        beneficiary: false,
        annuitant: false,
        owner: false,
        profile: false,
        verification: false,
        report: false,
        special: false,
        additional: false
    },
    assetOrderNumber: 1
};

export const ApplicationProvider = ({ children }) => {

    // Used to track the application type that the user is currently on on pre-application page
    const [selectedComponent, setSelectedComponent] = useState(initialState.selectedComponent);

    // Used to track the progress of the application
    const [progress, setProgress] = useState(initialState.progress);

    // The page state is used to track the current page of the application
    const [page, setPage] = useState(initialState.page);

    // Used by checkbox on contact page to indicate if user needs to manually input the address

    const [cantFindCurrAddr, setCantFindCurrAddr] = useState(false);
    const [cantFindPrevAddr, setCantFindPrevAddr] = useState(false);
    const [cantFindCoCurrAddr, setCantFindCoCurrAddr] = useState(false);
    const [cantFindCoPrevAddr, setCantFindCoPrevAddr] = useState(false);
    const [mainManualInputCurrCheck, setMainManualInputCurrCheck] = useState(initialState.mainManualInputCurrCheck);
    const [mainIncomeManualInputCurrCheck, setMainIncomeManualInputCurrCheck] = useState(initialState.mainIncomeManualInputCurrCheck);
    const [mainManualInputPrevCheck, setMainManualInputPrevCheck] = useState(initialState.mainManualInputPrevCheck);
    const [mainIncomeManualInputPrevCheck, setMainIncomeManualInputPrevCheck] = useState(initialState.mainIncomeManualInputPrevCheck);
    const [coManualInputCurrCheck, setCoManualInputCurrCheck] = useState(initialState.coManualInputCurrCheck);
    const [coManualInputPrevCheck, setCoManualInputPrevCheck] = useState(initialState.coManualInputPrevCheck);

    // Used to store the individual score of the investor profile questions
    const [mainInvestorScore, setMainInvestorScore] = useState(initialState.mainInvestorScore);
    const [coInvestorScore, setCoInvestorScore] = useState(initialState.coInvestorScore);

    // Used to store the total score of the investor profile questions
    const [mainInvestorScoreCalc, setMainInvestorScoreCalc] = useState(initialState.mainInvestorScoreCalc);
    const [coInvestorScoreCalc, setCoInvestorScoreCalc] = useState(initialState.coInvestorScoreCalc);

    const [tdsr, setTdsr] = useState(initialState.tdsr);
    const [income, setIncome] = useState(initialState.income);
    const [worth, setWorth] = useState(initialState.worth);
    const [beneShare, setBeneShare] = useState(initialState.beneShare);

    // This state is used to store the error messages from different pages after validation
    const [errorMessages, setErrorMessages] = useState(initialState.errorMessages);

    // This state is used to trigger the automatic error validation when the next button is clicked on specific pages
    // The state will be set to true, which enables the automatic validation via useEffect on each component of the page
    // The automatic validation will only be triggered once per page for the purpose of final check before moving to the next page
    // The field input validation will still be triggered when the user interacts with the input fields regardless of this state
    const [clickNextButton, setClickNextButton] = useState(initialState.clickNextButton);

    // Used to store the order number of the assets users are adding to the application
    const [assetOrderNumber, setAssetOrderNumber] = useState(initialState.assetOrderNumber);

    const handleMainInvestorScoreChange = (index, value) => {
        const temp = mainInvestorScore;
        temp[index] = value;
        setMainInvestorScore(temp);
        setMainInvestorScoreCalc(temp.reduce((accumulator, current) => accumulator + current, 0));
    };

    const handleCoInvestorScoreChange = (index, value) => {
        const temp = coInvestorScore;
        temp[index] = value;
        setCoInvestorScore(temp);
        setCoInvestorScoreCalc(temp.reduce((accumulator, current) => accumulator + current, 0));
    };

    const handleErrorMessage = (pageName, action, message) => {
        setErrorMessages(prevState => {
            const currentMessages = prevState[pageName] || [];

            if (action === 'add') {
                if (!currentMessages.includes(message)) {
                    return {
                        ...prevState,
                        [pageName]: [...currentMessages, message]
                    };
                }
            }
            else if (action === 'remove') {
                if (currentMessages.includes(message)) {
                    return {
                        ...prevState,
                        [pageName]: currentMessages.filter((msg) => msg !== message)
                    };
                }
            }
            // Return the unchanged state if no action is taken
            return prevState;
        });
    };

    const resetContextStates = () => {
        setSelectedComponent(initialState.selectedComponent);
        setProgress(initialState.progress);
        setPage(initialState.page);
        setMainManualInputCurrCheck(initialState.mainManualInputCurrCheck);
        setMainManualInputPrevCheck(initialState.mainManualInputPrevCheck);
        setMainIncomeManualInputPrevCheck(initialState.mainIncomeManualInputPrevCheck);
        setMainIncomeManualInputCurrCheck(income.mainIncomeManualInputCurrCheck);
        setCoManualInputCurrCheck(initialState.coManualInputCurrCheck);
        setCoManualInputPrevCheck(initialState.coManualInputPrevCheck);
        setMainInvestorScore(initialState.mainInvestorScore);
        setCoInvestorScore(initialState.coInvestorScore);
        setMainInvestorScoreCalc(initialState.mainInvestorScoreCalc);
        setCoInvestorScoreCalc(initialState.coInvestorScoreCalc);
        setTdsr(initialState.tdsr);
        setIncome(initialState.income);
        setErrorMessages(initialState.errorMessages);
        setClickNextButton(initialState.clickNextButton);
        setAssetOrderNumber(initialState.assetOrderNumber);
    };

    return (
        <ApplicationContext.Provider value=
            {{
                selectedComponent, setSelectedComponent,
                progress, setProgress,
                page, setPage,
                mainManualInputCurrCheck, setMainManualInputCurrCheck,
                mainManualInputPrevCheck, setMainManualInputPrevCheck,
                mainIncomeManualInputPrevCheck, setMainIncomeManualInputPrevCheck,
                mainIncomeManualInputCurrCheck,setMainIncomeManualInputCurrCheck,
                coManualInputCurrCheck, setCoManualInputCurrCheck,
                coManualInputPrevCheck, setCoManualInputPrevCheck,
                cantFindCurrAddr, setCantFindCurrAddr,
                cantFindPrevAddr, setCantFindPrevAddr,
                cantFindCoCurrAddr, setCantFindCoCurrAddr,
                cantFindCoPrevAddr, setCantFindCoPrevAddr,
                mainInvestorScoreCalc,
                coInvestorScoreCalc,
                tdsr, setTdsr,
                income, setIncome,
                worth, setWorth,
                beneShare, setBeneShare,
                handleMainInvestorScoreChange,
                handleCoInvestorScoreChange,
                errorMessages, handleErrorMessage,
                clickNextButton, setClickNextButton,
                assetOrderNumber, setAssetOrderNumber,
                resetContextStates
            }}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => useContext(ApplicationContext);