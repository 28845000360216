import { Typography } from "@mui/material";
import "./style/TitleText.css";


/**
 * 
 * @param {string} text - The text to be displayed as a main field title.
 * @returns - A title text component for main field component.
 */


export default function TitleText({ text }) {
    return (
        <Typography className="text-title">{text}</Typography>
    );
};