
export const formatConverter = (backendName) => {
    return backendName
    .replace(/([a-z])([A-Z])/g, '$1 $2')   // Add space before uppercase letters
    .replace(/\bId\b/i, 'ID')               // Replace "Id" or "id" with "ID"
    .replace(/\bAnd\b/g, 'and')             // Change "And" to lowercase "and"
    .replace(/\b\w/g, char => char.toUpperCase()); 
}

export const digitConverter = (num) => {
    if(!num || isNaN(num)){
        return num;
    }

    if (typeof num === "string" && num.match(/^\d+$/)) {
        // Return the original string if it is numeric and only contains digits
        return num;
    }
    return parseFloat(num).toString()
}