import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import AddressAutoComplete from "../../../../../components/smallComponents/addressAutoComplete/addressAutoComplete";
import AddressSubsection from "./subsection/AddressSubsection";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import PhoneField from "../../../../../components/smallComponents/phoneField/PhoneField";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import Checkbox from "../../../../../components/smallComponents/checkbox/Checkbox";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import RadioGroupBoolean from "../../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import { currAddr, prevAddr } from "../../../../../constants/lists";
import "./style/CoAppContactSection.css";
import { usePhoneTypes } from "../../../../../hooks/usePhoneTypes";
import { twoPhoneNumbersVerified } from "../../../../../utilities/twoPhoneNumbersVerification";


/**
 * @param {boolean} enable - Enable the CoAppContactSection component to be displayed
 * @returns - The Co-Applicant contact info section of the contact info field component
 */


export default function CoAppContactSection({ enable }) {

    const livingMonthKey = 24;

    const { coManualInputCurrCheck, setCoManualInputCurrCheck,
        coManualInputPrevCheck, setCoManualInputPrevCheck, handleErrorMessage, clickNextButton } = useApplication();

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const { workPhoneType, cellPhoneType, homePhoneType } = usePhoneTypes(coapplicant);

    const applicationType = useParams().applicationType;

    const hasCoApplicant = applicant.anyCoapplicant;
    const notSameAddress = !coapplicant.sameAddressAsPrimaryApplicant;
    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const lessThanRequiredMonthsCoApp = hasCoApplicant && notSameAddress && coapplicant.livingSince !== '' &&
        dayjs().diff(dayjs(coapplicant.livingSince), 'month') < livingMonthKey;

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const handlePhoneChange = (attribute, value) => {
        let twoDifferentPhones = twoPhoneNumbersVerified(attribute, value, coapplicant);
        if(twoDifferentPhones){
            handleErrorMessage(pageName, 'remove', 'Two different phone numbers are required for co-applicant')
            dispatch(setCoapplicantInfo({ attribute, value }))
        } else {
            handleErrorMessage(pageName, 'add', 'Two different phone numbers are required for co-applicant')
        }
    }

    const handleCoapplicantSameAddress = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))

        // If the same address as main applicant is checked, copy the main applicant's address to the co-applicant's address
        if (value) {
            handleErrorMessage(pageName, 'remove', `Co-Applicant Current Address is mandatory`)
            handleErrorMessage(pageName, 'remove', `Co-Applicant Previous Address is mandatory`)

            for (let i = 0; i < currAddr.length; i++) {
                handleCoapplicantChange(currAddr[i], applicant[currAddr[i]])
                handleCoapplicantChange(prevAddr[i], applicant[prevAddr[i]])
            }
            handleCoapplicantChange("livingSince", applicant.livingSince);
            handleCoapplicantChange("prevLivingSince", applicant.prevLivingSince);
        }
        // If the same address as main applicant is unchecked, clear the co-applicant's address
        else if (!value) {
            for (let i = 0; i < currAddr.length; i++) {
                handleCoapplicantChange(currAddr[i], "")
                handleCoapplicantChange(prevAddr[i], "")
            }
            handleCoapplicantChange("livingSince", "");
            handleCoapplicantChange("prevLivingSince", "");
            handleErrorMessage(pageName, 'add', `Co-Applicant Current Address is mandatory`)
        }
    };

    function clearCurrAddrOnChkBox(value) {
        setCoManualInputCurrCheck(value);
        handleAutoAddress();
        if (coManualInputCurrCheck[0] === "Can't find your address?") {
            handleErrorMessage(pageName, 'add', `Co-Applicant Current Address is mandatory`)
        }
    };

    function clearPrevAddrOnChkBox(value) {
        setCoManualInputPrevCheck(value);
        handleAutoAddressPrev();
        if (coManualInputPrevCheck[0] === "Can't find your address?") {
            handleErrorMessage(pageName, 'add', `Co-Applicant Previous Address is mandatory`)
        }
    };

    const handleAutoAddress = (addressObj) => {
        if (addressObj) {
            for (let key in addressObj) {
                dispatch(setCoapplicantInfo({ attribute: key, value: addressObj[key] }));
            }
            setCoManualInputCurrCheck([]);
            handleErrorMessage(pageName, 'remove', `Co-Applicant Current Address is mandatory`)
        }
        else {
            handleErrorMessage(pageName, 'remove', "Co-Applicant Current Address couldn't be retrieved please fill the address field manually")
            dispatch(setCoapplicantInfo({ attribute: 'streetNumber', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'streetName', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'city', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'province', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'postalCode', value: '' }));
            handleErrorMessage(pageName, 'remove', `Co-Applicant Current Address is mandatory`)
        }
    };

    const handleAutoAddressPrev = (addressObj) => {
        if (addressObj) {
            for (let key in addressObj) {
                let keyPrev = "prev" + key.charAt(0).toUpperCase() + key.slice(1);
                dispatch(setCoapplicantInfo({ attribute: keyPrev, value: addressObj[key] }));
            }
            setCoManualInputPrevCheck([]);
            handleErrorMessage(pageName, 'remove', `Co-Applicant Previous Address is mandatory`)
        }
        else {
            handleErrorMessage(pageName, 'remove', "Co-Applicant Previous Address couldn't be retrieved please fill the address field manually")
            dispatch(setCoapplicantInfo({ attribute: 'prevStreetNumber', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'prevStreetName', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'prevCity', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'prevProvince', value: '' }));
            dispatch(setCoapplicantInfo({ attribute: 'prevPostalCode', value: '' }));
            handleErrorMessage(pageName, 'remove', `Co-Applicant Previous Address is mandatory`)
        }
    };

    const handleCoapplicantCurrLivingChange = (attribute, value) => {
        // If the co-applicant has lived at the current address for more than 24 months, clear the previous address
        if (dayjs().diff(dayjs(value), 'month') > livingMonthKey) {
            for (let i = 0; i < prevAddr.length; i++) {
                handleCoapplicantChange(prevAddr[i], "");
            }
            handleCoapplicantChange("prevLivingSince", "");
            handleErrorMessage(pageName, 'remove', `Co-Applicant Previous Address is mandatory`)

        }
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const currAddress = `${coapplicant.streetNumber} ${coapplicant.streetName}, ${coapplicant.city} ${coapplicant.province} ${coapplicant.postalCode}` === ' ,   '? '': `${coapplicant.streetNumber} ${coapplicant.streetName}, ${coapplicant.city} ${coapplicant.province} ${coapplicant.postalCode}`;
    const prevAddress = `${coapplicant.prevStreetNumber} ${coapplicant.prevStreetName}, ${coapplicant.prevCity} ${coapplicant.prevProvince} ${coapplicant.prevPostalCode}` === ' ,   '? '': `${coapplicant.prevStreetNumber} ${coapplicant.prevStreetName}, ${coapplicant.prevCity} ${coapplicant.prevProvince} ${coapplicant.prevPostalCode}`;

    const itemSpace = 6;
    const specialItemSpace = 12;

    const manualInputQuestion = "Can't find your address?";
    const applicantType = 'Co-Applicant';
    const pageName = 'contact';

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-co-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Email' />
                        <TextField
                            setStateValue={value => handleCoapplicantChange("email", value)}
                            defaultValue={coapplicant.email}
                            type={hasCoApplicant ? 'email' : 'optional'}
                            name='Co-Applicant Email'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            largeBox={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Home Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("homePhone", value)}
                            defaultValue={coapplicant.homePhone}
                            type={hasCoApplicant ? 'phone' : 'optional'}
                            name='Co-Applicant Home Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Work Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("workPhone", value)}
                            defaultValue={coapplicant.workPhone}
                            type={hasCoApplicant ? 'phone' : 'optional'}
                            name='Co-Applicant Work Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Cell Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("cellPhone", value)}
                            defaultValue={coapplicant.cellPhone}
                            type={hasCoApplicant ? 'optional' : 'optional'}
                            name='Co-Applicant Cell Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="section-co-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={specialItemSpace} >
                        <TextFieldText text='Same address as Main Applicant?' largeBox={true} />
                        <RadioGroupBoolean
                            setStateValue={value => handleCoapplicantSameAddress("sameAddressAsPrimaryApplicant", value)}
                            defaultValue={coapplicant.sameAddressAsPrimaryApplicant}
                            type={hasCoApplicant ? 'mandatory' : 'optional'}
                            name='Co-Applicant Same Address'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Box display={notSameAddress ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Current Address' />
                            <AddressAutoComplete
                                setStateValue={value => handleAutoAddress(value)}
                                defaultValue={currAddress}
                                name='Co-Applicant Current'
                                mandatory={hasCoApplicant && notSameAddress}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <Checkbox
                                options={[manualInputQuestion]}
                                setStateValue={value => clearCurrAddrOnChkBox(value)}
                                defaultValue={coManualInputCurrCheck}
                                pageName={pageName}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <AddressSubsection
                                applicantType={applicantType}
                                attributes={currAddr}
                                enable={coManualInputCurrCheck.includes(manualInputQuestion)}
                            />
                        </Grid>

                        {isLoanType && (
                            <Grid item xs={specialItemSpace} >
                                <TextFieldText text='Current Living Since' />
                                <DateField
                                    setStateValue={value => handleCoapplicantCurrLivingChange("livingSince", value)}
                                    defaultValue={coapplicant.livingSince}
                                    type={hasCoApplicant && notSameAddress ? 'date' : 'optional'}
                                    name='Co-Applicant Current Living Since'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>
                        )}

                    </Box>

                </Grid>

            </Box>

            <Box className="section-co-box-field">

                {isLoanType && (
                    <Grid container spacing={2} display={lessThanRequiredMonthsCoApp ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Address' />
                            <AddressAutoComplete
                                setStateValue={value => handleAutoAddressPrev(value)}
                                defaultValue={prevAddress}
                                name='Co-Applicant Previous'
                                mandatory={lessThanRequiredMonthsCoApp}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <Checkbox
                                options={[manualInputQuestion]}
                                setStateValue={value => clearPrevAddrOnChkBox(value)}
                                defaultValue={coManualInputPrevCheck}
                                pageName={pageName}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <AddressSubsection
                                applicantType={applicantType}
                                attributes={prevAddr}
                                enable={coManualInputPrevCheck.includes(manualInputQuestion)}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Living Since' />
                            <DateField
                                setStateValue={value => handleCoapplicantChange("prevLivingSince", value)}
                                defaultValue={coapplicant.prevLivingSince}
                                type={lessThanRequiredMonthsCoApp ? 'date' : 'optional'}
                                name='Co-Applicant Previous Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

        </Box>
    );
};