import { axiosInstance } from "../axios"

export const addCaseStatusChangeHistory = async (caseStatusChangeInfo) => {
    try {
      await axiosInstance.post(`case/status/history/add`, caseStatusChangeInfo);
    } catch (error) {
      console.error(`Add case status history request failed, ${error}`)
    }
  }

export const getCaseStatusChangeHistory = async (caseID) => {
    try {
      const response = await axiosInstance.get(`case/status/history/get/${caseID}`);
      return response.data;
    } catch (error) {
      console.error(`GET case history ${caseID} request failed, ${error}`);
    }
  }

export const updateCaseStatus = async (caseID, status) => {
  try {
    const response = await axiosInstance.patch(`cases/status/update/${caseID}`, {status: status});
    return {
      success: true,
      data: response.data
    }
  } catch (error) {
    console.log(error)
    return {
      success: false,
      error: error.response.data
    }
  }
}