import { Co2Sharp } from "@mui/icons-material";
import { useSelector } from "react-redux"
import { useParams } from 'react-router-dom';
import { useApplication } from "../contexts/ApplicationContext";


export const ClientInfoSummary = () => {
    const applicantInfo = useSelector(state => state.applicantInfo)
    const { mainInvestorScoreCalc,
        coInvestorScoreCalc} = useApplication();
    const coapplicantInfo = useSelector(state => state.coapplicantInfo)
    const hasCoApplicant = applicantInfo.anyCoapplicant;
    const hasSecBene = applicantInfo.secBeneficiaryFirstName.length >0 ? true : false;
    const applicationType = useParams().applicationType;
    const isLoan = applicationType === 'quick'? true : false;
    const hasAnnuitant = applicantInfo.designatedSuccessorAnnuitant? true : false;
    const hasOwner =applicantInfo.designatedSuccessorOwner? true : false;
    return {
       
        "Applicant Information": {
            "General": {
                "Applicant Personal Information": {
                    languagePreference: {
                        description: "Language Preference",
                        value: applicantInfo.languagePreference
                    },
                    firstname: {
                        description: "Firstname",
                        value: applicantInfo.firstname
                    },
                    lastname: {
                        description: "Lastname",
                        value: applicantInfo.lastname
                    },
                    preferredName: {
                        description: "Preferred Name",
                        value: applicantInfo.preferredName
                    },
                    dateOfBirth: {
                        description: "Date of Birth",
                        value: applicantInfo.dateOfBirth
                    },
                    socialInsuranceNumber: {
                        description: "Social Insurance Number (SIN)",
                        value: applicantInfo.socialInsuranceNumber
                    },
                    gender: {
                        description: "Gender",
                        value: applicantInfo.gender
                    },
                    residentialStatus: {
                        description: "Residential Status",
                        value: applicantInfo.residentialStatus
                    },
                    citizenship: {
                        description: "Citizenship",
                        value: applicantInfo.citizenship
                    },
                    maritalStatus: {
                        description: "Marital Status",
                        value: applicantInfo.maritalStatus
                    },
                    countryOfBirth: {
                        description: "Place of Birth (Country)",
                        value: applicantInfo.countryOfBirth
                    },
                    provinceOfBirth: {
                        description: "Place of Birth (Province)",
                        value: applicantInfo.provinceOfBirth
                    },
                    residentOfCanadaSince: {
                        description: "Resident of Canada Since",
                        value: applicantInfo.residentOfCanadaSince
                    },
                    anyCoapplicant: {
                        description: "Is there an co-applicant in this application?",
                        value: applicantInfo.anyCoapplicant
                    }
                },
                ...(hasCoApplicant && {
                    "Co-Applicant Personal Information": {
                    languagePreference: {
                        description: "Language Preference",
                        value: coapplicantInfo.languagePreference
                    },
                    firstname: {
                        description: "Firstname",
                        value: coapplicantInfo.firstname
                    },
                    lastname: {
                        description: "Lastname",
                        value: coapplicantInfo.lastname
                    },
                    preferredName: {
                        description: "Preferred Name",
                        value: coapplicantInfo.preferredName
                    },
                    dateOfBirth: {
                        description: "Date of Birth",
                        value: coapplicantInfo.dateOfBirth
                    },
                    socialInsuranceNumber: {
                        description: "Social Insurance Number (SIN)",
                        value: coapplicantInfo.socialInsuranceNumber
                    },
                    gender: {
                        description: "Gender",
                        value: coapplicantInfo.gender
                    },
                    residentialStatus: {
                        description: "Residential Status",
                        value: coapplicantInfo.residentialStatus
                    },
                    citizenship: {
                        description: "Citizenship",
                        value: coapplicantInfo.citizenship
                    },
                    maritalStatus: {
                        description: "Marital Status",
                        value: coapplicantInfo.maritalStatus
                    },
                    countryOfBirth: {
                        description: "Place of Birth (Country)",
                        value: coapplicantInfo.countryOfBirth
                    },
                    provinceOfBirth: {
                        description: "Place of Birth (Province)",
                        value: coapplicantInfo.provinceOfBirth
                    },
                    residentOfCanadaSince: {
                        description: "Resident of Canada Since",
                        value: coapplicantInfo.residentOfCanadaSince
                    }
                }
                    
                })
                
            },
            "Contact Information": {
                "Applicant’s Residental Address": {
                    streetNumber: {
                        description: "Street Number",
                        value: applicantInfo.streetNumber
                    },
                    streetName: {
                        description: "Street Name",
                        value: applicantInfo.streetName
                    },
                    unit: {
                        description: "Unit",
                        value: applicantInfo.unit
                    },
                    city: {
                        description: "City",
                        value: applicantInfo.city
                    },
                    province: {
                        description: "Province",
                        value: applicantInfo.province
                    },
                    postalCode: {
                        description: "Postal Code",
                        value: applicantInfo.postalCode
                    },
                    livingSince: {
                        description: "Living Since",
                        value: applicantInfo.livingSince
                    }
                },
                "Applicant’s Previous Residental Address": {
                    prevStreetNumber: {
                        description: "Street Number",
                        value: applicantInfo.prevStreetNumber
                    },
                    prevStreetName: {
                        description: "Street Name",
                        value: applicantInfo.prevStreetName
                    },
                    prevUnit: {
                        description: "Unit",
                        value: applicantInfo.prevUnit
                    },
                    prevCity: {
                        description: "City",
                        value: applicantInfo.prevCity
                    },
                    prevProvince: {
                        description: "Province",
                        value: applicantInfo.prevProvince
                    },
                    prevPostalCode: {
                        description: "Postal Code",
                        value: applicantInfo.prevPostalCode
                    },
                    prevLivingSince: {
                        description: "Living Since",
                        value: applicantInfo.prevLivingSince
                    }
                },
                "Applicant’s Contact Information": {
                    email: {
                        description: "Email",
                        value: applicantInfo.email
                    },
                    homePhone: {
                        description: "Home Phone",
                        value: applicantInfo.homePhone
                    },
                    workPhone: {
                        description: "Work Phone",
                        value: applicantInfo.workPhone
                    },
                    cellPhone: {
                        description: "Cell Phone",
                        value: applicantInfo.cellPhone
                    }
                },
                ...(hasCoApplicant && { 

                    "Co-Applicant’s Residental Address": {
                        sameAddressAsPrimaryApplicant: {
                            description: "Same address as Primary Applicant",
                            value: coapplicantInfo.sameAddressAsPrimaryApplicant
                        },
                        streetNumber: {
                            description: "Street Number",
                            value: coapplicantInfo.streetNumber
                        },
                        streetName: {
                            description: "Street Name",
                            value: coapplicantInfo.streetName
                        },
                        unit: {
                            description: "Unit",
                            value: coapplicantInfo.unit
                        },
                        city: {
                            description: "City",
                            value: coapplicantInfo.city
                        },
                        province: {
                            description: "Province",
                            value: coapplicantInfo.province
                        },
                        postalCode: {
                            description: "Postal Code",
                            value: coapplicantInfo.postalCode
                        },
                        livingSince: {
                            description: "Living Since",
                            value: coapplicantInfo.livingSince
                        }
                    },
                    "Co-Applicant’s Previous Residental Address": {
                        prevStreetNumber: {
                            description: "Street Number",
                            value: coapplicantInfo.prevStreetNumber
                        },
                        prevStreetName: {
                            description: "Street Name",
                            value: coapplicantInfo.prevStreetName
                        },
                        prevUnit: {
                            description: "Unit",
                            value: coapplicantInfo.prevUnit
                        },
                        prevCity: {
                            description: "City",
                            value: coapplicantInfo.prevCity
                        },
                        prevProvince: {
                            description: "Province",
                            value: coapplicantInfo.prevProvince
                        },
                        prevPostalCode: {
                            description: "Postal Code",
                            value: coapplicantInfo.prevPostalCode
                        },
                        prevLivingSince: {
                            description: "Living Since",
                            value: coapplicantInfo.prevLivingSince
                        }
                    },
                    "Co-Applicant’s Contact Information": {
                        email: {
                            description: "Email",
                            value: coapplicantInfo.email
                        },
                        homePhone: {
                            description: "Home Phone",
                            value: coapplicantInfo.homePhone
                        },
                        workPhone: {
                            description: "Work Phone",
                            value: coapplicantInfo.workPhone
                        },
                        cellPhone: {
                            description: "Cell Phone",
                            value: coapplicantInfo.cellPhone
                        }
                     }
                })
                
                
            },
            "Identification": {
                "Applicant's ID 1": {
                    fullName: {
                        description: "Full Name On ID",
                        value: applicantInfo.fullName
                    },
                    idType: {
                        description: "ID Type",
                        value: applicantInfo.idType
                    },
                    idNumber: {
                        description: "ID Number",
                        value: applicantInfo.idNumber
                    },
                    issueCountry: {
                        description: "Issue Country (Jurisdiction)",
                        value: applicantInfo.issueCountry
                    },
                    issueProvince: {
                        description: "Issue Province (Jurisdiction)",
                        value: applicantInfo.issueProvince
                    },
                    issueAuthority: {
                        description: "Issue Authority",
                        value: applicantInfo.issueAuthority
                    },
                    issueDate: {
                        description: "Issue Date",
                        value: applicantInfo.issueDate
                    },
                    expireDate: {
                        description: "Expire Date",
                        value: applicantInfo.expireDate
                    },
                    verified: {
                        description: "Does your ID verify by the Advisor physically / Face-to-Face?",
                        value: String(applicantInfo.verified).toUpperCase()
                    }
                },
                "Applicant's ID 2": {
                    fullName_2: {
                        description: "Full Name On ID",
                        value: applicantInfo.fullName_2
                    },
                    idType_2: {
                        description: "ID Type",
                        value: applicantInfo.idType_2
                    },
                    idNumber_2: {
                        description: "ID Number",
                        value: applicantInfo.idNumber_2
                    },
                    issueCountry_2: {
                        description: "Issue Country (Jurisdiction)",
                        value: applicantInfo.issueCountry_2
                    },
                    issueProvince_2: {
                        description: "Issue Province (Jurisdiction)",
                        value: applicantInfo.issueProvince_2
                    },
                    issueAuthority_2: {
                        description: "Issue Authority",
                        value: applicantInfo.issueAuthority_2
                    },
                    issueDate_2: {
                        description: "Issue Date",
                        value: applicantInfo.issueDate_2
                    },
                    expireDate_2: {
                        description: "Expire Date",
                        value: applicantInfo.expireDate_2
                    },
                    verified_2: {
                        description: "Does your ID verify by the Advisor physically / Face-to-Face?",
                        value: String(applicantInfo.verified_2).toUpperCase()
                    }
                },
                ...(hasCoApplicant && {
                    "Co-Applicant's ID 1": {
                    fullName: {
                        description: "Full Name On ID",
                        value: coapplicantInfo.fullName
                    },
                    idType: {
                        description: "ID Type",
                        value: coapplicantInfo.idType
                    },
                    idNumber: {
                        description: "ID Number",
                        value: coapplicantInfo.idNumber
                    },
                    issueCountry: {
                        description: "Issue Country (Jurisdiction)",
                        value: coapplicantInfo.issueCountry
                    },
                    issueProvince: {
                        description: "Issue Province (Jurisdiction)",
                        value: coapplicantInfo.issueProvince
                    },
                    issueAuthority: {
                        description: "Issue Authority",
                        value: coapplicantInfo.issueAuthority
                    },
                    issueDate: {
                        description: "Issue Date",
                        value: coapplicantInfo.issueDate
                    },
                    expireDate: {
                        description: "Expire Date",
                        value: coapplicantInfo.expireDate
                    },
                    verified: {
                        description: "Does your ID verify by the Advisor physically / Face-to-Face?",
                        value: String(coapplicantInfo.verified).toUpperCase()
                    }
                    },
                    "Co-Applicant's ID 2": {
                    fullName_2: {
                        description: "Full Name On ID",
                        value: coapplicantInfo.fullName_2
                    },
                    idType_2: {
                        description: "ID Type",
                        value: coapplicantInfo.idType_2
                    },
                    idNumber_2: {
                        description: "ID Number",
                        value: coapplicantInfo.idNumber_2
                    },
                    issueCountry_2: {
                        description: "Issue Country (Jurisdiction)",
                        value: coapplicantInfo.issueCountry_2
                    },
                    issueProvince_2: {
                        description: "Issue Province (Jurisdiction)",
                        value: coapplicantInfo.issueProvince_2
                    },
                    issueAuthority_2: {
                        description: "Issue Authority",
                        value: coapplicantInfo.issueAuthority_2
                    },
                    issueDate_2: {
                        description: "Issue Date",
                        value: coapplicantInfo.issueDate_2
                    },
                    expireDate_2: {
                        description: "Expire Date",
                        value: coapplicantInfo.expireDate_2
                    },
                    verified_2: {
                        description: "Does your ID verify by the Advisor physically / Face-to-Face?",
                        value: String(coapplicantInfo.verified_2).toUpperCase()
                    }
                    }
                }),

                "Applicant Tax Status": {
                    ...(isLoan && {
                        residenceOfCanada: {
                            description: "Tax residence of Canada",
                            value: String(applicantInfo.residenceOfCanada).toUpperCase()
                        }
                    }),
                    
                    
                    ...(isLoan && {
                        residenceOfUnitedStates: {
                            description: "Tax residence of the United States",
                            value: String(applicantInfo.residenceOfUnitedStates).toUpperCase()
                        },
                    }),

                    ...(!isLoan && {
                           haveTinUS: {
                        description: "Do you have Taxpayer identification number (TIN) from the U.S.?",
                        value: applicantInfo.haveTinUS
                        },
                    }),

                    ...(!isLoan && {
                        tinUS: {
                            description: "Taxpayer identification number (TIN)",
                            value: applicantInfo.tinUS
                        },
                    }),

                    ...(!isLoan && {
                        reasonOfNoTinUS: {
                            description: "Explain reason why you don't have TIN",
                            value: applicantInfo.reasonOfNoTinUS
                        },
                    }),
                   
                   
                    ...(isLoan && {
                        otherResidence: {
                            description: "Tax residence of a jurisdiction other than Canada or the United States?",
                            value: String( applicantInfo.otherResidence).toUpperCase()
                        },
                    }),
                    
                    ...(!isLoan && {
                        countryName: {
                            description: "Provide the name of the country",
                            value: applicantInfo.countryName
                        },
                    }),
                    
                    ...(!isLoan && {
                        haveTinOther: {
                            description: "Do you have Taxpayer identification number (TIN) from that country?",
                            value: applicantInfo.haveTinOther
                        },
                    }),
                    
                   
                    ...(!isLoan && {
                        tinOther: {
                            description: "Taxpayer identification number (TIN)",
                            value: applicantInfo.tinOther
                        },
                    }),
                    
                    ...(!isLoan && {
                        reasonOfNoTinOther: {
                            description: "Explain reason why you don't have TIN",
                            value: applicantInfo.reasonOfNoTinOther
                        }
                    }),
                   
                },
                ...(hasCoApplicant && {
                    "Co-Applicant Tax Status": {
                        ...(isLoan && {
                            residenceOfCanada: {
                                description: "Tax residence of Canada",
                                value: String(coapplicantInfo.residenceOfCanada).toUpperCase()
                            }
                        }),
                        
                        
                        ...(isLoan && {
                            residenceOfUnitedStates: {
                                description: "Tax residence of the United States",
                                value: String(coapplicantInfo.residenceOfUnitedStates).toUpperCase()
                            },
                        }),
    
                        ...(!isLoan && {
                               haveTinUS: {
                            description: "Do you have Taxpayer identification number (TIN) from the U.S.?",
                            value: coapplicantInfo.haveTinUS
                            },
                        }),
    
                        ...(!isLoan && {
                            tinUS: {
                                description: "Taxpayer identification number (TIN)",
                                value: coapplicantInfo.tinUS
                            },
                        }),
    
                        ...(!isLoan && {
                            reasonOfNoTinUS: {
                                description: "Explain reason why you don't have TIN",
                                value: coapplicantInfo.reasonOfNoTinUS
                            },
                        }),
                       
                       
                        ...(isLoan && {
                            otherResidence: {
                                description: "Tax residence of a jurisdiction other than Canada or the United States?",
                                value: String( coapplicantInfo.otherResidence).toUpperCase()
                            },
                        }),
                        
                        ...(!isLoan && {
                            countryName: {
                                description: "Provide the name of the country",
                                value: coapplicantInfo.countryName
                            },
                        }),
                        
                        ...(!isLoan && {
                            haveTinOther: {
                                description: "Do you have Taxpayer identification number (TIN) from that country?",
                                value: coapplicantInfo.haveTinOther
                            },
                        }),
                        
                       
                        ...(!isLoan && {
                            tinOther: {
                                description: "Taxpayer identification number (TIN)",
                                value: coapplicantInfo.tinOther
                            },
                        }),
                        
                        ...(!isLoan && {
                            reasonOfNoTinOther: {
                                description: "Explain reason why you don't have TIN",
                                value: coapplicantInfo.reasonOfNoTinOther
                            }
                        })
                       
                    }
                })
                
                    
            },
            "Employment Information": {
                "Applicant Employment": {
                    employmentStatus: {
                        description: "Employment Status",
                        value: applicantInfo.employmentStatus
                    },
                    jobTitle: {
                        description: "Detailed Occupation / Job Title",
                        value: applicantInfo.jobTitle
                    },
                    natureOfJobResponsibilities: {
                        description: "Nature of Job Responsibilities",
                        value: applicantInfo.natureOfJobResponsibilities
                    },
                    employmentName: {
                        description: "Employment Name",
                        value: applicantInfo.employmentName
                    },
                    natureOfEmployersBusiness: {
                        description: "Nature of Employer’s Business",
                        value: applicantInfo.natureOfEmployersBusiness
                    }
                },

                ...(hasCoApplicant && {
                    "Co-Applicant Employment": {
                    employmentStatus: {
                        description: "Employment Status",
                        value: coapplicantInfo.employmentStatus
                    },
                    detailedOccupationJobTitle: {
                        description: "Detailed Occupation / Job Title",
                        value: coapplicantInfo.detailedOccupationJobTitle
                    },
                    natureOfJobResponsibilities: {
                        description: "Nature of Job Responsibilities",
                        value: coapplicantInfo.natureOfJobResponsibilities
                    },
                    employmentName: {
                        description: "Employment Name",
                        value: coapplicantInfo.employmentName
                    },
                    natureOfEmployersBusiness: {
                        description: "Nature of Employer’s Business",
                        value: coapplicantInfo.natureOfEmployersBusiness
                    }
                }
                })
   
            },
            "Disclosure Information": {
                "Anti-Money Laundering - Applicant": {
                    isPEP_HIO: {
                        description: "Was or is the applicant, or any family member, and/or close associate, and/or a person on whose behalf the applicant is making a payment, a Politically Exposed Person (PEP) and/or a Head of an International Organization (HIO), or have they held such a position within the past five (5) years?",
                        value: applicantInfo.isPEP_HIO
                    }
                },
                "Applicant Disclosure for Investment Loan": {
                    isCoSigner_Guarantor: {
                        description: "Is this applicant liable as a co-signer or guarantor?",
                        value: applicantInfo.isCoSigner_Guarantor
                    },
                    hasSuitsOrJudgements: {
                        description: "Are there any suits or judgements against this applicant?",
                        value: applicantInfo.hasSuitsOrJudgements
                    },
                    hasBankruptcyOrProposal: {
                        description: "Has this applicant ever declared bankruptcy or filed a consumer proposal within past 6 years?",
                        value: applicantInfo.hasBankruptcyOrProposal
                    },
                    isLoanBenefittingThirdParty: {
                        description: "Will this loan benefit a third party?",
                        value: applicantInfo.isLoanBenefittingThirdParty
                    },
                    isIncomeTaxOwed: {
                        description: "Is Income tax owed, for the most recent year?",
                        value: applicantInfo.isIncomeTaxOwed
                    },
                    isMonthlySupportOrAlimony: {
                        description: "Is the applicant required to make a monthly support or alimony payment?",
                        value: applicantInfo.isMonthlySupportOrAlimony
                    },
                    isSubjectToGarnishment: {
                        description: "Have you ever been subject to a garnishment?",
                        value: applicantInfo.isSubjectToGarnishment
                    },
                    hasPropertySeized: {
                        description: "Have you ever had property seized?",
                        value: applicantInfo.hasPropertySeized
                    },
                    isManulifeStaffMember: {
                        description: "Is the applicant a Manulife Financial VP (or higher), or a Manulife Bank staff employee?",
                        value: applicantInfo.isManulifeStaffMember
                    }
                },
                ...(hasCoApplicant &&{

                    "Anti-Money Laundering - Co-Applicant": {
                        isPEP_HIO: {
                            description: "Was or is the applicant, or any family member, and/or close associate, and/or a person on whose behalf the applicant is making a payment, a Politically Exposed Person (PEP) and/or a Head of an International Organization (HIO), or have they held such a position within the past five (5) years?",
                            value: coapplicantInfo.isPEP_HIO
                        }
                    },
                    "Co-Applicant Disclosure for Investment Loan": {
                        isCoSigner_Guarantor: {
                            description: "Is this applicant liable as a co-signer or guarantor?",
                            value: coapplicantInfo.isCoSigner_Guarantor
                        },
                        hasSuitsOrJudgements: {
                            description: "Are there any suits or judgements against this applicant?",
                            value: coapplicantInfo.hasSuitsOrJudgements
                        },
                        hasBankruptcyOrProposal: {
                            description: "Has this applicant ever declared bankruptcy or filed a consumer proposal within past 6 years?",
                            value: coapplicantInfo.hasBankruptcyOrProposal
                        },
                        isLoanBenefittingThirdParty: {
                            description: "Will this loan benefit a third party?",
                            value: coapplicantInfo.isLoanBenefittingThirdParty
                        },
                        isIncomeTaxOwed: {
                            description: "Is Income tax owed, for the most recent year?",
                            value: coapplicantInfo.isIncomeTaxOwed
                        },
                        isMonthlySupportOrAlimony: {
                            description: "Is the applicant required to make a monthly support or alimony payment?",
                            value: coapplicantInfo.isMonthlySupportOrAlimony
                        },
                        isSubjectToGarnishment: {
                            description: "Have you ever been subject to a garnishment?",
                            value: coapplicantInfo.isSubjectToGarnishment
                        },
                        hasPropertySeized: {
                            description: "Have you ever had property seized?",
                            value: coapplicantInfo.hasPropertySeized
                        },
                        isManulifeStaffMember: {
                            description: "Is the Co-applicant a Manulife Financial VP (or higher), or a Manulife Bank staff employee?",
                            value: coapplicantInfo.isManulifeStaffMember
                        }
                    }
                })

            }
        },
        "Financial Details": {
            "Source of Contribution": {
                "Loan Details": {
                    loanAmountApplied: {
                        description: "Loan Amount Applied",
                        value: applicantInfo.loanAmountApplied
                    },
                    newLoan: {
                        description: "New Loan",
                        value: String(applicantInfo.newLoan).toUpperCase()
                    },
                    loanRatio: {
                        description: "Loan ratio",
                        value: applicantInfo.loanRatio
                    },
                    monthlyRepaymentOption: {
                        description: "Monthly Repayment Option",
                        value: applicantInfo.monthlyRepaymentOption
                    },
                    repaymentTerms: {
                        description: "Repayment Terms",
                        value: applicantInfo.repaymentTerms
                    },
                    existingLoadAmount: {
                        description: "Existing Loan Amount",
                        value: applicantInfo.existingLoadAmount
                    },
                    existingSegFundInvestmentAccount: {
                        description: "Existing Seg Fund Investment Account",
                        value: applicantInfo.existingSegFundInvestmentAccount
                    },
                    amountForCollateral: {
                        description: "Amount for Collateral",
                        value: applicantInfo.amountForCollateral
                    }
                }
            },
            "Contribution Option": {
                "Guarantee Level": {
                    guaranteeLevel: {
                        description: "Policy guarantee level",
                        value: applicantInfo.guaranteeLevel
                    }
                },
                "Void Cheque": {
                    accountHolder: {
                        description: "Account Holder",
                        value: applicantInfo.accountHolder
                    },
                    jointAccountHolder: {
                        description: "Joint Account Holder",
                        value: applicantInfo.jointAccountHolder
                    },
                    institutionCode: {
                        description: "Bank / Institution Code",
                        value: applicantInfo.institutionCode
                    },
                    institutionName: {
                        description: "Bank / Institution Name",
                        value: applicantInfo.institutionName
                    },
                    transitNumber: {
                        description: "Transit Number",
                        value: applicantInfo.transitNumber
                    },
                    accountNumber: {
                        description: "Account Number",
                        value: applicantInfo.accountNumber
                    },
                    requireMultipleSignature: {
                        description: "This account requires more than 1 signature to process transactions.",
                        value: String(applicantInfo.requireMultipleSignature).toUpperCase()
                        
                    },
                    ...(applicantInfo.requireMultipleSignature && {
                        jointAccountHolderEmail: {
                            description: "Joint Account Holder Email",
                            value: applicantInfo.jointAccountHolderEmail
                        }
                    }),
                   
                }
            },
            "Financial Analysis": {
                "Liabilities": Array.isArray(applicantInfo.LiabilityIDs)
                    ? applicantInfo.LiabilityIDs
                        .map((amount, index) => {
                            return {
                                liabilityBelongTo: {
                                    description: "Owner",
                                    value: applicantInfo.liabilityBelongTo[index]
                                },
                                liabilityType: {
                                    description: "Liability Type",
                                    value: applicantInfo.liabilityType[index]
                                },
                                liabilityInstitutionName: {
                                    description: "Financial Institution Name",
                                    value: applicantInfo.liabilityInstitutionName[index]
                                },
                                liabilityBalance: {
                                    description: "Liability Balance",
                                    value: applicantInfo.liabilityBalance[index]
                                },
                                annualPropertyTax: {
                                    description: "Annual Property Tax",
                                    value: applicantInfo.annualPropertyTax[index]
                                },
                                monthlyManagementMaintenanceFee: {
                                    description: "Monthly Management Maintenance Fee",
                                    value: applicantInfo.monthlyManagementMaintenanceFee[index]
                                },
                                monthlyPayment: {
                                    description: "Monthly Payment",
                                    value: applicantInfo.monthlyPayment[index]
                                }
                            }; 
                        })

                : [],
                ...(hasCoApplicant &&  { "Liabilities": Array.isArray(coapplicantInfo.LiabilityIDs)
                    ? coapplicantInfo.LiabilityIDs 
                        .map((amount, index) => {
                            return {
                                liabilityBelongTo: {
                                    description: "Owner",
                                    value: coapplicantInfo.liabilityBelongTo[index]
                                },
                                liabilityType: {
                                    description: "Liability Type",
                                    value: coapplicantInfo.liabilityType[index]
                                },
                                liabilityInstitutionName: {
                                    description: "Financial Institution Name",
                                    value: coapplicantInfo.liabilityInstitutionName[index]
                                },
                                liabilityBalance: {
                                    description: "Liability Balance",
                                    value: coapplicantInfo.liabilityBalance[index]
                                },
                                annualPropertyTax: {
                                    description: "Annual Property Tax",
                                    value: coapplicantInfo.annualPropertyTax[index]
                                },
                                monthlyManagementMaintenanceFee: {
                                    description: "Monthly Management Maintenance Fee",
                                    value: coapplicantInfo.monthlyManagementMaintenanceFee[index]
                                },
                                monthlyPayment: {
                                    description: "Monthly Payment",
                                    value: coapplicantInfo.monthlyPayment[index]
                                }
                            }; // Return null if caseID does not match
                        })
    
                    : []}),
                    "Assets": Array.isArray(applicantInfo.AssetIDs)
                    ? applicantInfo.AssetIDs
                        .map((amount, index) => {
                            return {
                                assetBelongTo: {
                                    description: "Belong to",
                                    value: applicantInfo.assetBelongTo[index]
                                },
                                assetType: {
                                    description: "Asset Type",
                                    value: applicantInfo.assetType[index]
                                },
                                assetMarketValue: {
                                    description: "Asset Market Value",
                                    value: applicantInfo.assetMarketValue[index]
                                },
                                assetInstitutionName: {
                                    description: "Financial Institution Name",
                                    value: applicantInfo.assetInstitutionName[index]
                                }
                            }; // Return null if caseID does not match
                        })
        
                    : [],    
                    ...(hasCoApplicant &&  {  "Assets": Array.isArray(coapplicantInfo.AssetIDs)
                        ? coapplicantInfo.AssetIDs 
                            .map((amount, index) => {
                                return {
                                    assetBelongTo: {
                                        description: "Belong to",
                                        value: coapplicantInfo.assetBelongTo[index]
                                    },
                                    assetType: {
                                        description: "Asset Type",
                                        value: coapplicantInfo.assetType[index]
                                    },
                                    assetMarketValue: {
                                        description: "Asset Market Value",
                                        value: coapplicantInfo.assetMarketValue[index]
                                    },
                                    assetInstitutionName: {
                                        description: "Financial Institution Name",
                                        value: coapplicantInfo.assetInstitutionName[index]
                                    }
                                }; // Return null if caseID does not match
                            })
        
                        : []}),
                    "Income": Array.isArray(applicantInfo.IncomeIDs)
                        ? applicantInfo.IncomeIDs
                            .map((amount, index) => {
                                return {
                                    incomeBelongTo: {
                                        description: "Owner",
                                        value: applicantInfo.incomeBelongTo[index]
                                    },
                                    incomeType: {
                                        description: "Income Type",
                                        value: applicantInfo.incomeType[index]
                                    },
                                    annualIncome: {
                                        description: "Annual Income",
                                        value: applicantInfo.annualIncome[index]
                                    },
                                    employName: {
                                        description: "Employer Name",
                                        value: applicantInfo.employName[index]
                                    },
                                    employerAddrStreetNumber: {
                                        description: "Employer Address - Street Number",
                                        value: applicantInfo.employerAddrStreetNumber[index]
                                    },
                                    employerAddrStreetName: {
                                        description: "Employer Address - Street Name",
                                        value: applicantInfo.employerAddrStreetName[index]
                                    },
                                    employerAddrCity: {
                                        description: "Employer Address - City",
                                        value: applicantInfo.employerAddrCity[index]
                                    },
                                    employerAddrProvince: {
                                        description: "Employer Address - Province",
                                        value: applicantInfo.employerAddrProvince[index]
                                    },
                                    employerAddrPostalCode: {
                                        description: "Employer Address - Postal Code",
                                        value: applicantInfo.employerAddrPostalCode[index]
                                    },
                                    industry: {
                                        description: "Industry",
                                        value: applicantInfo.industry[index]
                                    },
                                    occupation: {
                                        description: "Occupation",
                                        value: applicantInfo.occupation[index]
                                    },
                                    serviceSince: {
                                        description: "Service Since",
                                        value: applicantInfo.serviceSince[index]
                                    },
                                    previousEmployerName: {
                                        description: "Previous Employer Name",
                                        value: applicantInfo.previousEmployerName[index]
                                    },
                                    previousIndustry: {
                                        description: "Previous Industry",
                                        value: applicantInfo.previousIndustry[index]
                                    },
                                    previousOccupation: {
                                        description: "Previous Occupation",
                                        value: applicantInfo.previousOccupation[index]
                                    },
                                    previousServiceSince: {
                                        description: "Previous Employer Serving Since",
                                        value: applicantInfo.previousServiceSince[index]
                                    }
                                }; // Return null if caseID does not match
                            })
        
                        : [],
                    ...(hasCoApplicant &&  {  "Income": Array.isArray(coapplicantInfo.IncomeIDs)
                        ? coapplicantInfo.IncomeIDs
                            .map((amount, index) => {
                                return {
                                    incomeBelongTo: {
                                        description: "Belong to",
                                        value: coapplicantInfo.incomeBelongTo[index]
                                    },
                                    incomeType: {
                                        description: "Income Type",
                                        value: coapplicantInfo.incomeType[index]
                                    },
                                    annualIncome: {
                                        description: "Annual Income",
                                        value: coapplicantInfo.annualIncome[index]
                                    },
                                    employName: {
                                        description: "Employer Name",
                                        value: coapplicantInfo.employName[index]
                                    },
                                    employerAddrStreetNumber: {
                                        description: "Employer Address - Street Number",
                                        value: coapplicantInfo.employerAddrStreetNumber[index]
                                    },
                                    employerAddrStreetName: {
                                        description: "Employer Address - Street Name",
                                        value: coapplicantInfo.employerAddrStreetName[index]
                                    },
                                    employerAddrCity: {
                                        description: "Employer Address - City",
                                        value: coapplicantInfo.employerAddrCity[index]
                                    },
                                    employerAddrProvince: {
                                        description: "Employer Address - Province",
                                        value: coapplicantInfo.employerAddrProvince[index]
                                    },
                                    employerAddrPostalCode: {
                                        description: "Employer Address - Postal Code",
                                        value: coapplicantInfo.employerAddrPostalCode[index]
                                    },
                                    industry: {
                                        description: "Industry",
                                        value: coapplicantInfo.industry[index]
                                    },
                                    occupation: {
                                        description: "Occupation",
                                        value: coapplicantInfo.occupation[index]
                                    },
                                    serviceSince: {
                                        description: "Service Since",
                                        value: coapplicantInfo.serviceSince[index]
                                    },
                                    previousEmployerName: {
                                        description: "Previous Employer Name",
                                        value: coapplicantInfo.previousEmployerName[index]
                                    },
                                    previousIndustry: {
                                        description: "Previous Industry",
                                        value: coapplicantInfo.previousIndustry[index]
                                    },
                                    previousOccupation: {
                                        description: "Previous Occupation",
                                        value: coapplicantInfo.previousOccupation[index]
                                    },
                                    previousServiceSince: {
                                        description: "Previous Employer Serving Since",
                                        value: coapplicantInfo.previousServiceSince[index]
                                    }
                                }; // Return null if caseID does not match
                            })
        
                        : []}),
            },
        },
        "Additional People": {
            "Beneficiary": {
                "Primary Beneficiary": Array.isArray(applicantInfo.PrimBeneficiaryIDs)
                ? applicantInfo.PrimBeneficiaryIDs
                    .map((amount, index) => {
                        return {
                            beneficiaryFirstName: {
                                description: "Firstname",
                                value: applicantInfo.beneficiaryFirstName[index]
                            },
                            beneficiaryLastName: {
                                description: "Lastname",
                                value: applicantInfo.beneficiaryLastName[index]
                            },
                            priRelationToAnnuitant: {
                                description: "Relation to Annuitant",
                                value: applicantInfo.priRelationToAnnuitant[index]
                            },
                            beneficiaryGender: {
                                description: "Gender",
                                value: applicantInfo.beneficiaryGender[index]
                            },
                            beneficiaryType: {
                                description: "Beneficiary Type",
                                value: applicantInfo.beneficiaryType[index]
                            },
                            beneficiaryDateOfBirth: {
                                description: "Date of Birth",
                                value: applicantInfo.beneficiaryDateOfBirth[index]
                            },
                            beneficiaryShare: {
                                description: "Share",
                                value: applicantInfo.beneficiaryShare[index]
                            },
                            trusteeFirstName: {
                                description: "Trustee - Firstname",
                                value: applicantInfo.trusteeFirstName[index]
                            },
                            trusteeLastName: {
                                description: "Trustee - Lastname",
                                value: applicantInfo.trusteeLastName[index]
                            },
                            trusteeRelationToBeneficiary: {
                                description: "Trustee - Relation to Beneficiary",
                                value: applicantInfo.trusteeRelationToBeneficiary[index]
                            }
                        }; // Return null if caseID does not match
                    })

                : [],
                ...(hasSecBene && {
                    "Secondary Beneficiary": {
                        secBeneficiaryFirstName: {
                            description: "Firstname",
                            value: applicantInfo.secBeneficiaryFirstName
                        },
                        secBeneficiaryLastName: {
                            description: "Lastname",
                            value: applicantInfo.secBeneficiaryLastName
                        },
                        secRelationToAnnuitant: {
                            description: "Relation to Annuitant",
                            value: applicantInfo.secRelationToAnnuitant
                        },
                        secBeneficiaryGender: {
                            description: "Gender",
                            value: applicantInfo.secBeneficiaryGender
                        },
                        secBeneficiaryType: {
                            description: "Beneficiary Type",
                            value: applicantInfo.secBeneficiaryType
                        },
                        secBeneficiaryDateOfBirth: {
                            description: "Date of Birth",
                            value: applicantInfo.secBeneficiaryDateOfBirth
                        },
                        secBeneficiaryShare: {
                            description: "Share",
                            value: applicantInfo.secBeneficiaryShare
                        },
                        secTrusteeFirstName: {
                            description: "Trustee - Firstname",
                            value: applicantInfo.secTrusteeFirstName
                        },
                        secTrusteeLastName: {
                            description: "Trustee - Lastname",
                            value: applicantInfo.secTrusteeLastName
                        },
                        secTrusteeRelationToBeneficiary: {
                            description: "Trustee - Relation to Beneficiary",
                            value: applicantInfo.secTrusteeRelationToBeneficiary
                        }
                    }
                })

               
            },
            "Annuitant": {
                "Successor Annuitant": {
                    designatedSuccessorAnnuitant: {
                        description: "Do you want to designate a successor Annuitant?",
                        value: applicantInfo.designatedSuccessorAnnuitant? "YES" : "NO"
                    },
                    ...(hasAnnuitant && {
                        annuitantLanguagePreference: {
                            description: "Language Preference",
                            value: applicantInfo.annuitantLanguagePreference
                        },
                        annuitantFirstName: {
                            description: "Firstname",
                            value: applicantInfo.annuitantFirstName
                        },
                        annuitantLastName: {
                            description: "Lastname",
                            value: applicantInfo.annuitantLastName
                        },
                        annuitantDateOfBirth: {
                            description: "Date of Birth",
                            value: applicantInfo.annuitantDateOfBirth
                        },
                        annuitantGender: {
                            description: "Gender",
                            value: applicantInfo.annuitantGender
                        },
                        annuitantSIN: {
                            description: "Social Insurance Number (SIN)",
                            value: applicantInfo.annuitantSIN
                        },
                        annuitantHomePhone: {
                            description: "Home Phone",
                            value: applicantInfo.annuitantHomePhone
                        },
                        relationToAnnuitant: {
                            description: "Relation to Annuitant",
                            value: applicantInfo.relationToAnnuitant
                        }
                    })
                    
                }
            },
            "Success Owner": {
                "Success Owner": {
                    designatedSuccessorOwner: {
                        description: "Do you want to designate a successor Owner?",
                        value: applicantInfo.designatedSuccessorOwner? "YES" : "NO"
                    },
                    ...(hasOwner && {
                        ownerLanguagePreference: {
                            description: "Language Preference",
                            value: applicantInfo.ownerLanguagePreference
                        },
                        ownerFirstName: {
                            description: "Firstname",
                            value: applicantInfo.ownerFirstName
                        },
                        ownerLastName: {
                            description: "Lastname",
                            value: applicantInfo.ownerLastName
                        },
                        ownerDateOfBirth: {
                            description: "Date of Birth",
                            value: applicantInfo.ownerDateOfBirth
                        },
                        ownerGender: {
                            description: "Gender",
                            value: applicantInfo.ownerGender
                        },
                        ownerSIN: {
                            description: "Social Insurance Number (SIN)",
                            value: applicantInfo.ownerSIN
                        },
                        ownerHomePhone: {
                            description: "Home Phone",
                            value: applicantInfo.ownerHomePhone
                        },
                        relationToOwner: {
                            description: "Relation to Owner",
                            value: applicantInfo.relationToOwner
                        }
                    })
                  
                }
            }
        },
        "Investor Profile": {
            "Investor Profile - Applicant": {
                "Total Score":{
                    mainInvestorScoreCalc:{
                        description:"Applicant Total Score: " ,
                        value: mainInvestorScoreCalc
                    }
                   
                },
                "Personal Information": {
                    q1_ans: {
                        description: "Q1: " + applicantInfo.q_ques[0],
                        value: "Answer: "+ applicantInfo.q1_ans[1]
                    },
                    q2_ans: {
                        description:"Q2: " + applicantInfo.q_ques[1],
                        value: "Answer: "+  applicantInfo.q2_ans[1]
                    },
                    q3_ans: {
                        description:"Q3: " + applicantInfo.q_ques[2],
                        value:"Answer: "+  applicantInfo.q3_ans[1]
                    }
                },
                "Investment Objective": {
                    q4_ans: {
                        description:"Q4: " + applicantInfo.q_ques[3],
                        value:"Answer: "+  applicantInfo.q4_ans[1]
                    },
                    q5_ans: {
                        description:"Q5: " + applicantInfo.q_ques[4],
                        value: "Answer: "+ applicantInfo.q5_ans[1]
                    }
                },
                "Investment Time Horizon": {
                    q6_ans: {
                        description:"Q6: " + applicantInfo.q_ques[5],
                        value: "Answer: "+ applicantInfo.q6_ans[1]
                    },
                    q7_ans: {
                        description:"Q7: " + applicantInfo.q_ques[6],
                        value:"Answer: "+  applicantInfo.q7_ans[1]
                    }
                },
                "Risk Tolerance": {
                    q8_ans: {
                        description: "Q8: " +applicantInfo.q_ques[7],
                        value: "Answer: "+ applicantInfo.q8_ans[1]
                    },
                    q9_ans: {
                        description: "Q9: " +applicantInfo.q_ques[8],
                        value:"Answer: "+  applicantInfo.q9_ans[1]
                    },
                    q10_ans: {
                        description: "Q10:" +applicantInfo.q_ques[9],
                        value: "Answer: "+ applicantInfo.q10_ans[1]
                    },
                    q11_ans: {
                        description: "Q11: " +applicantInfo.q_ques[10],
                        value: "Answer: "+ applicantInfo.q11_ans[1]
                    }
                },
                "Investment Knowledge": {
                    q12_ans: {
                        description: "Q12: "+ applicantInfo.q_ques[11],
                        value:"Answer: "+  applicantInfo.q12_ans[1]
                    }
                }
            },
            ...(hasCoApplicant && {
                "Investor Profile - Co-Applicant": {
                "Total Score":{
                    coInvestorScoreCalc:{
                        description:"Co-Applicant Total Score: " ,
                        value: coInvestorScoreCalc
                    }
                },
                "Personal Information": {
                    q1_ans: {
                        description: "Q1: " + coapplicantInfo.q_ques[0],
                        value: "Answer: "+ coapplicantInfo.q1_ans[1]
                    },
                    q2_ans: {
                        description:"Q2: " + coapplicantInfo.q_ques[1],
                        value: "Answer: "+  coapplicantInfo.q2_ans[1]
                    },
                    q3_ans: {
                        description:"Q3: " + coapplicantInfo.q_ques[2],
                        value:"Answer: "+  coapplicantInfo.q3_ans[1]
                    }
                },
                "Investment Objective": {
                    q4_ans: {
                        description:"Q4: " + coapplicantInfo.q_ques[3],
                        value:"Answer: "+  coapplicantInfo.q4_ans[1]
                    },
                    q5_ans: {
                        description:"Q5: " + coapplicantInfo.q_ques[4],
                        value: "Answer: "+ coapplicantInfo.q5_ans[1]
                    }
                },
                "Investment Time Horizon": {
                    q6_ans: {
                        description:"Q6: " + coapplicantInfo.q_ques[5],
                        value: "Answer: "+ coapplicantInfo.q6_ans[1]
                    },
                    q7_ans: {
                        description:"Q7: " + coapplicantInfo.q_ques[6],
                        value:"Answer: "+  coapplicantInfo.q7_ans[1]
                    }
                },
                "Risk Tolerance": {
                    q8_ans: {
                        description: "Q8: " +coapplicantInfo.q_ques[7],
                        value: "Answer: "+ coapplicantInfo.q8_ans[1]
                    },
                    q9_ans: {
                        description: "Q9: " +coapplicantInfo.q_ques[8],
                        value:"Answer: "+  coapplicantInfo.q9_ans[1]
                    },
                    q10_ans: {
                        description: "Q10:" +coapplicantInfo.q_ques[9],
                        value: "Answer: "+ coapplicantInfo.q10_ans[1]
                    },
                    q11_ans: {
                        description: "Q11: " +coapplicantInfo.q_ques[10],
                        value: "Answer: "+ coapplicantInfo.q11_ans[1]
                    }
                },
                "Investment Knowledge": {
                    q12_ans: {
                        description: "Q12: "+ coapplicantInfo.q_ques[11],
                        value:"Answer: "+  coapplicantInfo.q12_ans[1]
                    }
                }

            }
            })

        },
        "Special Instructions": {
           
            "Special Instructions": {
                "Anything else?": {
                    acquisitionChannel: {
                        description: "How did you hear about us?",
                        value: applicantInfo.howHeard
                    },
                    additionalInformation: {
                        description: "Additional Information",
                        value: applicantInfo.additionalInformation
                    }
                }
            }
        }
    }
}