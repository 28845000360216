import { Box, Tab, Tabs } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useSelector, useDispatch } from "react-redux";
import "../general/style/General.css";
import EmploymentInformation from "./EmploymentInformation";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import EmploymentCoAppInformation from "./EmploymentCoAppInformation";
import { useState, useEffect } from "react";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import { fetchApplicantemploymentInfo } from '../../../../services/fetchInfoService';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";

export default function Employment() {
    const EmpStatus = ['Employed', 'Self-Employed', 'Student', 'Retired', 'Other']
    const title = "Employment Information";
    const dispatch = useDispatch();


    const applicant = useSelector((state) => state.applicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const [tabValue, setTabValue] = useState(0);
    const pageName = 'employment'
    const { clickNextButton } = useApplication();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);

    };

    const fetchApplcantInfoEmployment = async ()=>{
    
        const applicantInfo = await fetchApplicantemploymentInfo(application.applicationID);
        if(applicantInfo.length >0){
            applicantInfo.map((applicant, index) => {
                if(applicant.applicantType === 'Applicant'){
                    dispatch(setApplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setApplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setApplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setApplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC }));
                    dispatch(setApplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                
                }else if(applicant.applicantType === 'Co-Applicant'){
                    dispatch(setCoapplicantInfo({ attribute: 'employmentStatus', value: applicant.employmentStatusC }));
                    dispatch(setCoapplicantInfo({ attribute: 'jobTitle', value: applicant.occupationJobTitleC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfJobResponsibilities', value: applicant.jobResponsibilitiesC }));
                    dispatch(setCoapplicantInfo({ attribute: 'employmentName', value: applicant.employerNameC }));
                    dispatch(setCoapplicantInfo({ attribute: 'natureOfEmployersBusiness', value: applicant.natureOfBusinessC }));
                }
              
            });
        }
      
    }

    useEffect(() => {
        fetchApplcantInfoEmployment();
    }, []);

    return (
        <Box className="general-box-overall">

            <Box>
                <TitleText text={title} />
            </Box>

            <Box>
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 20 }} label="Co-Applicant" value={1} />
                    )}
                </Tabs>
            </Box>

            <EmploymentInformation EmpStatus={EmpStatus} enable={tabValue === 0} />
            <EmploymentCoAppInformation EmpStatus={EmpStatus} enable={tabValue === 1} />

            <Box className="general-box-button">
                <PrevButton />
                <NextButton pageName={pageName} checkEmp={true} />
            </Box>

        </Box>
    );
};