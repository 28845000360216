import { Box, Grid, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import RadioGroup from "../../component/investProfileRadioGroup";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TabPanel from "../../../../../../components/smallComponents/tabPanel/TabPanel";
import {answerAttributes } from "../../../../../../constants/lists";
import { getDropdownList } from "../../../../../../services/dropdownService";
import { fetchApplicationsInvestorProfile} from '../../../../../../services/fetchInfoService';

/**
 * @param {String} applicantType - Determines if the applicant is the 'main or 'co' applicant
 * @returns - Investor questionaire subsection
 */


export default function InvestorQuestionSubsection({ applicantType }) {
    
    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const applicationType = useParams().applicationType;

    const hasFinancialAnalysis = ['regular', 'quick'].includes(applicationType);

    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange,
        mainInvestorScoreCalc, coInvestorScoreCalc } = useApplication();

    const [TabPanelValue, setTabPanelValue] = useState(0);
    const [disableBackButton, setDisableBackButton] = useState(true);
    const [disableForwardButton, setDisableForwardButton] = useState(false);
    const [investorOptions, setInvestorOptions] = useState(new Map());

    const [investorQuestions, setInvestorQuestions] = useState([]);

    const [investorQuestionsCategory, setInvestorQuestionsCategory] = useState([]);
    const updatedQues = [...applicant.q_ques];
    const updatedQuesCate = [...applicant.q_quesCate];
    const updatedCOQues = [...coapplicant.q_ques];
    const updatedCOQuesCate = [...coapplicant.q_quesCate];
    const fetchInvestorOptions = async ()=>{
        const investorOptionsList = await getDropdownList("aiFKYCQuestionOption");
         // Create a map to store options grouped by question
        const optionsMap = new Map();

        investorOptionsList.forEach(item => {
            const questionId = item['questionID'];
            const option = item['option'];
            if (!optionsMap.has(questionId)) {
                optionsMap.set(questionId, []);
              }
        
              // Add the option to the corresponding question's array
              optionsMap.get(questionId).push(option);
        });

            // Update the state with the populated map
        setInvestorOptions(optionsMap);
        

    }

    const fetchAnswerAttributes = async ()=>{
        const investorQuestionsList = await getDropdownList("aiFKYCQuestion");
        setInvestorQuestions(investorQuestionsList.map(x=>x['question']));
        setInvestorQuestionsCategory(investorQuestionsList.map(x=>x['category']));
        
    }

    const handleBackIconButtonChange = () => {
        if (TabPanelValue === 1) {
            setDisableBackButton(true);
        }
        if (disableForwardButton) {
            setDisableForwardButton(false);
        }
        setTabPanelValue(TabPanelValue - 1);
    };

    const handleForwardIconButtonChange = () => {
        if (TabPanelValue === ((investorQuestions).length - 2)) {
            setDisableForwardButton(true);
        }
        if (disableBackButton) {
            setDisableBackButton(false);
        }
      
        setTabPanelValue(TabPanelValue + 1);
        
    };

  

    const handleRadioChange = (index, value, ansIndex) => {
        let score = 0;
        switch (ansIndex) {
            case 0:
                score = 1;
                break;
            case 1:
                score = 2;
                break;
            case 2:
                score = 5;
                break;
            case 3:
                score = 10;
                break;
            case 4:
                score = 20;
                break;
            default:
                break;
        }
        const updatedValues = [...selectedValues];
        updatedValues[index] = { value, ansIndex };  // Store both value and ansIndex
        setSelectedValues(updatedValues);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }));
            handleMainInvestorScoreChange(index, score)
        }
        else {
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }))
            handleCoInvestorScoreChange(index, score)
        }

    };

    const handleRadioChangeFetch = (index, value, ansIndex, applicantTypeinput) => {
       
        let score = 0;
        switch (ansIndex) {
            case 0:
                score = 1;
                break;
            case 1:
                score = 2;
                break;
            case 2:
                score = 5;
                break;
            case 3:
                score = 10;
                break;
            case 4:
                score = 20;
                break;
            default:
                break;
        }
        const updatedValues = [...selectedValues];
        updatedValues[index] = { value, ansIndex };  // Store both value and ansIndex
        setSelectedValues(updatedValues);

        if (applicantTypeinput === "Applicant" && applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: answerAttributes[index], value: [ansIndex+1, value, score] }));
            handleMainInvestorScoreChange(index, score)
        }
        else if(applicantTypeinput === "Co-Applicant"){
            if(applicantType === 'co'){
                const coindex = index -12;
                handleCoInvestorScoreChange(coindex, score)
                dispatch(setCoapplicantInfo({ attribute: answerAttributes[coindex], value: [ansIndex+1, value, score] }))
            }
            
        }
    };

    const scoreBoardSpace = 12;
    const arrowButtonSpace = 1;
    const questionSpace = 10;
    const disableQuestionIndex = hasFinancialAnalysis ? [0, 1, 2] : [0];

   
    const fetchApplcantInfoInvestorProfile = async ()=>{
    
        const applicantInfo = await fetchApplicationsInvestorProfile(application.applicationID);
        if(applicantInfo.length >0){            
            let ques = [];
            let indexCo =0;
            applicantInfo.map((applicant, index) => {
                const questionId = applicant.questionID;
                const answerIndex = parseInt(applicant.optionID, 10)-1;
                const optionValues = applicant.answer;
                const question = applicant.question;
                const questionCar = applicant.questionCategory;
                if (questionId >3 && optionValues && optionValues.length > 0) { 
                    // Calculate based on the default option
                    handleRadioChangeFetch(index, optionValues, answerIndex, applicant.applicantType);
                    
                }
                
                // Update arrays for Redux state
                if (!updatedQues.includes(question)) {  
                    updatedQues.push(question); 
                    updatedQuesCate.push(questionCar);
                }
        
                if (applicant.applicantType !== 'Application' && !updatedCOQues.includes(question)) {
                    updatedCOQues.push(question); 
                    updatedCOQuesCate.push(questionCar);
                }
                
                setSelectedValues(
                investorQuestions.map((question, index) => ({
                    value: applicant.applicantType === 'Application' 
                        ? applicant[answerAttributes[index]][1] 
                        : coapplicant[answerAttributes[index]][1],
                    ansIndex: applicant.applicantType === 'Application' 
                        ? applicant[answerAttributes[index]][0] 
                        : coapplicant[answerAttributes[index]][0], // Default ansIndex, adjust as needed
                }))
            );
            })

            
            // Dispatch Redux updates
            if (applicantType === 'main') {
                dispatch(setApplicantInfo({ attribute: 'q_ques', value: updatedQues }));
                dispatch(setApplicantInfo({ attribute: 'q_quesCate', value: updatedQuesCate }));
            } else {
                dispatch(setCoapplicantInfo({ attribute: 'q_ques', value: updatedCOQues }));
                dispatch(setCoapplicantInfo({ attribute: 'q_quesCate', value: updatedCOQuesCate }));
            }
        
            // Update selected values
            setSelectedValues(
                investorQuestions.map((question, index) => ({
                    value: applicantType === 'main' 
                        ? applicant[answerAttributes[index]][1] 
                        : coapplicant[answerAttributes[index]][1],
                    ansIndex: applicantType === 'main' 
                        ? applicant[answerAttributes[index]][0] 
                        : coapplicant[answerAttributes[index]][0], // Default ansIndex, adjust as needed
                }))
            );
        
        }else{
            handleDefaultOpton();
        }
        
    }

    const handleDefaultOpton = () => {
        investorQuestions.forEach((_, index) => {
            const optionKey = index + 1; // Assuming investorOptions are keyed by questionID starting from 1
            const optionValues = investorOptions.get(optionKey); 
    
            if (index > 2 && optionValues && optionValues.length > 0) { 
                // Identify the default option and its index
                const defaultOption = optionValues[optionValues.length - 1];
                const defaultAnsIndex = optionValues.length - 1; // Assuming ansIndex is the position
                
                // Calculate based on the default option
                handleRadioChange(index, defaultOption, defaultAnsIndex);
 
            }
    
            // Update arrays for Redux state
            if (!updatedQues.includes(investorQuestions[index])) {  
                updatedQues.push(investorQuestions[index]); 
                updatedQuesCate.push(investorQuestionsCategory[index]);
            }
    
            if (applicantType !== 'main' && !updatedCOQues.includes(investorQuestions[index])) {
                updatedCOQues.push(investorQuestions[index]); 
                updatedCOQuesCate.push(investorQuestionsCategory[index]);
            }
        });
    
        // Dispatch Redux updates
        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'q_ques', value: updatedQues }));
            dispatch(setApplicantInfo({ attribute: 'q_quesCate', value: updatedQuesCate }));
        } else {
            dispatch(setCoapplicantInfo({ attribute: 'q_ques', value: updatedCOQues }));
            dispatch(setCoapplicantInfo({ attribute: 'q_quesCate', value: updatedCOQuesCate }));
        }
    
        // Update selected values
        setSelectedValues(
            investorQuestions.map((question, index) => ({
                value: applicantType === 'main' 
                    ? applicant[answerAttributes[index]][1] 
                    : coapplicant[answerAttributes[index]][1],
                ansIndex: 4, // Default ansIndex, adjust as needed
            }))
        );
    };
    

    const [selectedValues, setSelectedValues] = useState(
        investorQuestions.map((question, index) => ({
            value: applicantType === 'main' 
                ? applicant[answerAttributes[index]][1] 
                : coapplicant[answerAttributes[index]][1],
            ansIndex: index, // Initial ansIndex (you can customize this as needed)
        }))
    );
    
   
    useEffect(() => {
        
        fetchAnswerAttributes();
        fetchInvestorOptions();
        
     }, []);
     
  
     useEffect(() => {
        fetchApplcantInfoInvestorProfile();
     }, [investorOptions]);

    return (
        <Box className="ml-7 mr-7 mt-5 mb-5" >
            <Grid container spacing={2}>

                <Grid item xs={scoreBoardSpace} >
                    {applicantType === 'main' ?
                        <Box className="mt-5 mb-5 flex justify-center items-center border-4 border-gray-400 p-3 rounded-xl">
                            <span className="text-2xl font-semibold mr-10">
                                Main Applicant Score:
                            </span>
                            <span className="text-2xl font-bold text-indigo-600">
                                {mainInvestorScoreCalc}
                            </span>
                        </Box>
                        :
                        <Box className="mt-5 mb-5 flex justify-center items-center border-4 border-gray-400 p-3 rounded-xl">
                            <span className="text-2xl font-semibold mr-10">
                                Co-Applicant Score:
                            </span>
                            <span className="text-2xl font-bold text-indigo-600">
                                {coInvestorScoreCalc}
                            </span>
                        </Box>
                    }
                </Grid>

                <Grid item xs={arrowButtonSpace} display="flex" alignItems="center" justifyContent="center">
                    <IconButton onClick={handleBackIconButtonChange} disabled={disableBackButton} >
                        <ArrowBack sx={{ fontSize: 45 }} />
                    </IconButton>
                </Grid>
                  <Grid item xs={questionSpace}>
                    {investorQuestions.length > 0 && investorQuestions.map((question, index) => (
                        <TabPanel value={TabPanelValue} index={index} key={index} className="ml-5">
                            <TextFieldText text={`${index + 1}. ${question}`} largeBox={true} />
                            <RadioGroup 
                                options={investorOptions.get(index+1) || []} // Make sure to safely access options
                                setStateValue={(value, ansIndex) => handleRadioChange(index, value, ansIndex)}
                                defaultValue={applicantType === 'main' ?
                                    applicant[answerAttributes[index]][1] :
                                    coapplicant[answerAttributes[index]][1]}
                                needIndex={true}
                                disabled={disableQuestionIndex.includes(index)}
                            />
                        </TabPanel>
                    ))}
                </Grid>

                <Grid item xs={arrowButtonSpace} display="flex" alignItems="center" justifyContent="center">
                <IconButton onClick={handleForwardIconButtonChange} disabled={disableForwardButton} >
                        <ArrowForward sx={{ fontSize: 45 }} />
                    </IconButton>
                </Grid>

            </Grid>
        </Box>
    );
};