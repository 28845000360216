import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useApplication } from "../../../contexts/ApplicationContext";
import dayjs from "dayjs";
import "./style/DateField.css";


/**
 * @param {function} setStateValue - A function that sets the state value
 * @param {string} defaultValue - The default value of the date field
 * @param {boolean} isClear - A boolean value that determines if the date field needs to be cleared
 * @param {number} minYear - The minimum year of the date field
 * @param {number} maxYear - The maximum year of the date field
 * @param {number} minDay - The minimum day of the date field
 * @param {number} maxDay - The maximum day of the date field
 * @param {string} rangeType - The type of range for the date field
 * @param {boolean} disabled - A boolean value that determines if the date field is disabled
 * @param {string} type - The type of the date field
 * @param {string} name - The name of the date field
 * @param {string} pageName - The name of the page the date field is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled 
 * @returns - A date field component 
 */


export default function DateField({ setStateValue, defaultValue, isClear = false,
    minYear = 0, maxYear = 100, minDay = 0, maxDay = 365, rangeType = 'year',
    disabled, type = 'optional', name = '', pageName = '', buttonCheck = false, isDOB=false, isExpriyD= false }) {

    const { handleErrorMessage } = useApplication();
    const today = dayjs().startOf('day');
    const [selectedDate, setSelectedDate] = useState(defaultValue === '' ? null : dayjs(defaultValue));
    const [error, setError] = useState('');

    // const minRange = rangeType === 'year' ? dayjs().subtract(maxYear, 'year') : dayjs().subtract(maxDay, 'day').startOf('day');
     const minRange = isExpriyD 
    ? today // If `isExpiryD` is true, set `minRange` to today's date
    : rangeType === 'year' 
        ? dayjs().subtract(maxYear, 'year') 
        : dayjs().subtract(maxDay, 'day').startOf('day');
        
    const maxRange = rangeType === 'year' ? dayjs().subtract(minYear, 'year') : dayjs().subtract(minDay, 'day').startOf('day');

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setStateValue(date === null ? '' : date.format("YYYY-MM-DD"));
        // Provide validation after the user has selected a date
        handleDateCheck(date);
    };

    const handleDateCheck = (date) => {
        switch (type) {
            case 'date':
                if (date === null || !date.isValid()) {
                    setError('date');
                    handleErrorMessage(pageName, 'remove', `Please validate date range for ${name}`);
                    handleErrorMessage(pageName, 'add', `Please validate date format for ${name}`);
                }
                else if (date.isAfter(maxRange) || date.isBefore(minRange)) {
                    setError('range');
                    if(pageName === 'general' && isDOB){
                        handleErrorMessage(pageName, 'remove', `Please validate date format for ${name}`);
                        handleErrorMessage(pageName, 'add', `Age does not meet the requirement for ${name}`);
                    }else{
                        handleErrorMessage(pageName, 'remove', `Please validate date format for ${name}`);
                        handleErrorMessage(pageName, 'add', `Please validate date range for ${name}`);
                    }
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please validate date format for ${name}`);
                    handleErrorMessage(pageName, 'remove', `Please validate date range for ${name}`);
                    handleErrorMessage(pageName, 'remove', `Age does not meet the requirement for ${name}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `Please validate date format for ${name}`);
                handleErrorMessage(pageName, 'remove', `Please validate date range for ${name}`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if (error === 'date') {
            return 'Valid Date Required';
        }
        else if (error === 'range') {
            if(pageName === 'general' && isDOB){
                return 'Age ineligible';
            }
            return 'Out of Range';
        }
    };

    useEffect(() => {
        if (isClear) {
            setSelectedDate(null);
        }
    }, [isClear]);

    useEffect(() => {
        if (defaultValue !== selectedDate?.format("YYYY-MM-DD")) {
            setSelectedDate(defaultValue === '' ? null : dayjs(defaultValue));
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleDateCheck(defaultValue === '' ? null : dayjs(defaultValue))
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `Please validate date format for ${name}`);
            handleErrorMessage(pageName, 'remove', `Please validate date range for ${name}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className="date-box">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker
                    className="date-picker"
                    value={selectedDate}
                    minDate={minRange}
                    maxDate={maxRange}
                    onChange={handleDateChange}
                    disabled={disabled}
                />
            </LocalizationProvider>
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'center', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};