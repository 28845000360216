import { List } from '@mui/material';
import { useEffect } from 'react';
import MainOption from '../../../components/smallComponents/sidebarParts/MainOption';
import './style/Sidebar.css';


/**
 * @param {Array<{ mainOption: string, subOptions: Array<{ subOptionName: string, subOptionValue: number }> }>} sidebarOptions - Sidebar options with main option and related sub options
 * @param {boolean} isHovered - Sidebar hover state
 * @param {Function} setIsHovered - Set sidebar hover state
 * @returns - Sidebar container with main options and sub options
 * @description - Sidebar has hover display feature 
 */


export default function Sidebar({ sidebarOptions, isHovered, setIsHovered }) {

    useEffect(() => {
        const handleMouseEnter = () => setIsHovered(true);
        const handleMouseLeave = () => setIsHovered(false);

        const hoverArea = document.getElementById('hover-area');
        const sidebar = document.getElementById('sidebar-container');

        hoverArea.addEventListener('mouseenter', handleMouseEnter);
        sidebar.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            hoverArea.removeEventListener('mouseenter', handleMouseEnter);
            sidebar.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, [setIsHovered]);

    return (
        <>
            <div id="hover-area"></div>
            <List id="sidebar-container" className={`sidebar-container ${isHovered ? 'open' : ''}`}>
                {sidebarOptions.map((sidebarOption, index) => (
                    <MainOption key={index} mainOption={sidebarOption.mainOption} subOptions={sidebarOption.subOptions} />
                ))}
            </List>
        </>
    )
};