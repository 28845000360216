import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useApplication } from "../../../contexts/ApplicationContext";
import "./style/RadioGroup.css";
import { useLoanAmount } from "../../../contexts/LoanAmountContext";


/**
 * @param {string[]} options - The options to be displayed in the radio group
 * @param {function} setStateValue - The function to be called when an option is selected
 * @param {string} defaultValue - The default value of the radio group
 * @param {boolean} disabled - A boolean value that determines if the radio group is disabled
 * @param {boolean} isClear - A boolean value that triggers the radio group to clear the selected value
 * @param {boolean} needIndex - A boolean value that determines if the index of the selected option is needed
 * @param {string} type - A string value that determines the type of radio group
 * @param {string} name - The name of the radio group
 * @param {string} pageName - The name of the page the radio group is located
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled 
 * @returns - A radio group component that only allows one option to be selected at a time 
 */


export default function RadioGroupComponent({ options, setStateValue, defaultValue, disabled, isClear = false,
    needIndex = false, type = 'optional', name = '', pageName = '', buttonCheck = false }) {

    const { handleErrorMessage } = useApplication();
    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const [error, setError] = useState('');

    const handleRadioChange = (event) => {
        const userSelectedValue = event.target.value;
        setSelectedValue(userSelectedValue);

        if (needIndex) {
            const userSelectedIndex = options.indexOf(userSelectedValue);
            setStateValue(userSelectedValue, userSelectedIndex);
        }
        else {
            setStateValue(userSelectedValue);
        }
        // Provide validation after the user has selected an option
        handleRadioCheck(userSelectedValue);
    };

    const handleRadioCheck = (selection) => {
        switch (type) {
            case 'mandatory':
                if (selection === '') {
                    setError('mandatory');
                    handleErrorMessage(pageName, 'add', `Please select an option for ${name}`);
                }
                else {
                    setError('');
                    handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
                }
                break;
            case 'optional':
                setError('');
                handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
                break;
            default:
                break;
        }
    };

    function checkError(error) {
        if (error === 'mandatory') {
            return 'Select Option Required';
        }
    };

    useEffect(() => {
        if (isClear) {
            setSelectedValue('')
        }
    }, [isClear]);

    useEffect(() => {
        if (defaultValue !== selectedValue) {
            setSelectedValue(defaultValue);
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    useEffect(() => {
        // Proivde automatic validation when the buttonCheck is enabled
        if (buttonCheck) {
            handleRadioCheck(defaultValue);
        }
        // Automatically remove the error message when the component is unmounted
        return () => {
            handleErrorMessage(pageName, 'remove', `Please select an option for ${name}`);
        }
        // eslint-disable-next-line
    }, [buttonCheck, type]);

    return (
        <Box className="radio-box">
            <FormControl>
                <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                    {
                        options.map((option, index) => (
                            <FormControlLabel key={index} value={option} 
                                control={<Radio />}
                                label={option}
                                disabled={disabled}
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
            {error && <p style={{ color: 'red', fontSize: 22, justifyContent: 'left', display: 'flex' }}>{checkError(error)}</p>}
        </Box>
    );
};