import { Box, TextField, List, ListItem, Snackbar, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import './style/addressAutoComplete.css'
import { useApplication } from '../../../contexts/ApplicationContext';
import { useAddressAutoFailInfo } from '../../../hooks/useAddressAutoFailInfo';

/**
 * @param {function} setStateValue - function to set the state value of the address
 * @param {string} name - name of address
 * @param {string} defaultValue - default value of the address
 * @param {boolean} mandatory - if the address is mandatory
 * @param {string} pageName - name of the page where the address is used
 * @param {boolean} buttonCheck - A boolean value that determines if the automatic validation on NextButton has been enabled 
 * @returns - AddressAutoComplete component 
 */


export default function AddressAutoComplete({ setStateValue, name, defaultValue, mandatory, pageName = '', buttonCheck = false, isIncome = false , setIncome, income }) {
    const { handleErrorMessage } = useApplication()
    const [input, setInput] = useState(defaultValue || '');
    const [suggestions, setSuggestions] = useState([]);
    const [inputLength, setInputLength] = useState(0);
    const { handleFail } = useAddressAutoFailInfo();


    async function handleInputChange(event) {
        setInput(event.target.value);
        if (event.target.value.length >= 2 && event.target.value.trim() !== "") {
            const response = await axios.get(
                `https://atlas.microsoft.com/search/address/json?api-version=1.0&subscription-key=${process.env.REACT_APP_ADDRESS_KEY}&query=${event.target.value}&countrySet=CA`
            );
            setSuggestions(response.data.results);
            setInputLength(event.target.value.length);
        }
        else {
            setSuggestions([])
            setInputLength(0);
        }
    };

    useEffect(()=>{
        if(input.trim() !== ''){
            addressSeperator(input);
        }
    }, [input]);


    const addressType = name.toLowerCase().includes("curr")? 'curr': name.toLowerCase().includes("prev")?'prev': 'other';
    const applicantType = name.toLowerCase().includes("co-applicant")?'Co-Applicant': name.toLowerCase().includes("applicant")? 'Applicant': 'other';
    function addressSeperator(suggestion) {
        const addressPatternDoublePostal = /^(\d+)\s+([a-zA-ZÀ-ÿ\s-\d]+),\s([a-zA-ZÀ-ÿ\s-]+)\s([A-Z]{2})\s([A-Za-z]\d[A-Za-z]\s?\d[A-Za-z]\d)$/
        let match = suggestion.address?suggestion.address.freeformAddress.match(addressPatternDoublePostal): suggestion.match(addressPatternDoublePostal);
        //when address is incorrect or using different format
        if (!match) {
            
            if(suggestion.address){
                handleFail(addressType, applicantType, suggestion.address.streetNumber, suggestion.address.streetName, suggestion.address.municipality, suggestion.address.countrySubdivisionCode, suggestion.address.postalCode, isIncome, setIncome, income)
            }
            handleErrorMessage(pageName, 'add', `${name} Address couldn't be retrieved please fill the address field manually`)
            handleErrorMessage(pageName, 'remove', `${name} Address is mandatory`)
            setOpen(true)
        }
        else {
            // eslint-disable-next-line no-unused-vars
            let [_, streetNumber, streetName, city, province, postalCode] = match;
            let address = {
                streetNumber,
                streetName,
                city,
                province,
                postalCode
            };
            //ensure the address isnt only a street name that start with numeric values 
            if (((streetName.startsWith("Avenue") && streetName.length <= 8) || (streetName.startsWith("Street") && streetName.length <= 8))) {
                handleErrorMessage(pageName, 'add', `${name} Address couldn't be retrieved please fill the address field manually`)
                handleErrorMessage(pageName, 'remove', `${name} Address is mandatory`)
                setOpen(true)
            }
            else {
                setStateValue(address)
                setOpenSuccess(true)
                setOpen(false)
                handleErrorMessage(pageName, 'remove', `${name} Address is mandatory`)
                handleErrorMessage(pageName, 'remove', `${name} Address couldn't be retrieved please fill the address field manually`)
            }
        }
    };

    function handleSuggestionClick(suggestion) {
        const addr = suggestion.address.freeformAddress;
        setInput(addr);
        setSuggestions([]);
        setInputLength(addr.length);
        addressSeperator(suggestion)
    };

    const defaultWidth = 350
    const inputWidth = inputLength < 15 ? defaultWidth : Math.max(100, Math.min(10 * inputLength, 500));

    //Functionality for Toaster when address found/not found
    const [open, setOpen] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    function handleClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpenSuccess(false)
    };

    //ensuring if user need to input address into the field, if not input manually
    useEffect(() => {
        if (buttonCheck) {
            if (mandatory && input.trim() === '' && defaultValue?.trim() === '') {
                handleErrorMessage(pageName, 'add', `${name} Address is mandatory`)
            }
            else if (mandatory && input.trim() === defaultValue?.trim()) {
                handleErrorMessage(pageName, 'remove', `${name} Address is mandatory`)
            }
            else if (!mandatory) {
                handleErrorMessage(pageName, 'remove', `${name} Address is mandatory`)
            }
        }
        // eslint-disable-next-line
    }, [buttonCheck, mandatory]);

    useEffect(() => {
        if (defaultValue !== input) {
            setInput(defaultValue)
        }
        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <Box className="input-Address">
            <TextField className=" bg-white rounded" style={{ width: `${inputWidth}px` }} label="Address" onChange={handleInputChange} value={input}/>
            <List className='optionParent'>
                {suggestions.map((suggestion) => (
                    <ListItem className="optionsAddress" key={suggestion.id} onClick={() => handleSuggestionClick(suggestion)}>
                        {suggestion.address.freeformAddress}
                    </ListItem>
                ))}
            </List>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Address Not Found or PostCode Incomplete, please fill the address field manually
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Address Used Successfully
                </Alert>
            </Snackbar>

        </Box>
    );
};