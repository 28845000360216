import { axiosInstance } from '../axios.js';

/**
 * MultiAddObjectService is a service layer on the frontend that interacts with the backend APIs
 * for the Multiple Add Objects including assets, liabilities, and incomes.
 */


// Base URL
const baseUrl = "/api/multiAddObject";

// Get all items
export const getAllItems = async (itemType, applicationID) => {
    try {
        const response = await axiosInstance.get(`${baseUrl}/${itemType}/${applicationID}`);
        return response.data;
    }
    catch (error) {
        console.error('Error: ', error);
    }
};

// Add a new item
export const addItem = async (itemType, item) => {
    try {
        const response = await axiosInstance.post(`${baseUrl}/${itemType}`, item);
        return response.data;
    }
    catch (error) {
        console.error('Error: ', error);
    }
};

// Update a existing item
export const updateItem = async (itemType, item) => {
    try {
        const editItemTpye = itemType+'edit';
        const response = await axiosInstance.post(`${baseUrl}/${editItemTpye}`, item);
        return response.data;
    }
    catch (error) {
        console.error('Error: ', error);
    }
};

// Delete an item by ID
export const deleteItemByID = async (itemType, itemID) => {
    try {
        console.log(itemType, itemID)
        const response = await axiosInstance.delete(`${baseUrl}/${itemType}/${itemID}`);
        return response.data;
    }
    catch (error) {
        console.error('Error: ', error);
    }
};