import { Button, Snackbar, Alert } from "@mui/material";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useState, useEffect } from "react";
import { useSelector,useDispatch  } from "react-redux"; 
import "./style/NextButton.css"
import { saveInfo, infoToSave } from "../../../../services/saveInfoService";
import { useParams } from 'react-router-dom';
import { setApplicationInfo } from '../../../../reducers/applicationReducer';
import { resetCoapplicantInfo, setCoapplicantInfo } from "../../../../reducers/coapplicantReducer";
import { useNavigate } from "react-router-dom";
/**
 * @param {boolean} checkScore - A boolean that determines if the button should check the user's investor profile score
 * @param {boolean} hasCoApplicant - A boolean that determines if the user has a co-applicant
 * @param {string} pageName - The name of the page that the button is on
 * @returns - A button that when clicked will call the handleNextButtonClick function from the ApplicationContext
 * @description - The button has two different validations based on the checkScore prop and will display a toast if the user fails the validations
 */


export default function NextButton({ checkScore = false, checkWorth = false, checkIncome = false, checkTdsr = false, checkEmp = false, checkbeneShare = false,pageName = '' , submit = false, addtionalsubmit = false, handleClick = async () => {} }) {

    const { page, progress, setPage, setProgress, mainInvestorScoreCalc, coInvestorScoreCalc,
        errorMessages, clickNextButton, setClickNextButton, tdsr, income, worth, beneShare } = useApplication();
    const [localCheck, setLocalCheck] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [incomeFailed, setIncomeFailed] = useState(false);
    const [beneShareFailed, setBeneShareFailed] = useState(false);
    const [tdsrFailed, setTdsrFailed] = useState(false);
    const [sendFailed, setSendFailed] = useState(false);
    const [resErrorMessage, setResErrorMessage] = useState("");
    const [empErrorMessage, setEmpErrorMessage] = useState("");
    const application = useSelector((state) => state.applicationInfo);
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const hasCoApplicant = applicant.anyCoapplicant;
    const { residentialStatus, assetType, employmentStatus } = applicant;
    const applicationType = useParams().applicationType;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const applicationStatus = useSelector((state) => state.applicationInfo.applicationStatus);

    const handleNextButtonClick = () => {
        // Set the clickNextButton state to true so that the automatic validation will be triggered on the page 
        // after the user clicks the next button for the first time
        if (!clickNextButton[pageName]) {
            setClickNextButton(prev => ({
                ...prev,
                [pageName]: true,
            }));
        }

        // Set a timeout to allow the automatic validation to finish before checking if there are any error messages
        setTimeout(() => {
            setLocalCheck(true);
        }, 200);
    };

    const handleNextButtonErrorCheck = () => {
        // Check if there are any error messages on the page
        // If there are error messages, display a toast and return
        if (errorMessages[pageName] && errorMessages[pageName].length > 0) {
            setOpenMessage(true);
            return;
        }

        // Call the page change function if the user passes the error message check
        handlePageChange();
    };

    const handleSubmit = () => {
        //send POST request
        dispatch(setApplicationInfo({ attribute: 'applicationStatus', value: 'Submitted' }));
        handlePageChange();
        //case auto generation after application submitted and push into Por_App_LoanInvestmentAndCasedetail
        handleCaseAutoGeneration(application);
        //route back to my-account
         navigate("/home/myAccount")
    };

    const handleApplicationReview = () => {
       
         navigate("/home/myAccount")
    };


    const handleCaseAutoGeneration = async (application) => {
            const info = infoToSave('caseGeneration', applicant, 'Applicant', application.applicationID, applicationType,application);
            // /push into Por_App_LoanInvestmentAndCasedetail
            const reponse = await saveInfo('caseGeneration', info);

    }

    const handleNextButtonInvestorProfileClick = () => {
        // If has co-applicant, check if both main and co-applicant scores are greater than or equal to 180
        // If not, display a toast and return
        
        if (hasCoApplicant) {
            if (mainInvestorScoreCalc < 180 || coInvestorScoreCalc < 180) {
                setOpenMessage(true);
                return;
            }
        }
        // If there is no co-applicant, check if the main applicant score is greater than or equal to 180
        // If not, display a toast and return
        else {
            if (mainInvestorScoreCalc < 180) {
                setOpenMessage(true);
                return;
            }
        }

        // Call the page change function if the user passes the investor profile score check
        handlePageChange();
    };

    const handleNextButtonEmpClick = () => {
        const currentPath = window.location.pathname;

        // Check if quick loan
        if (currentPath.startsWith("/application/quick")) {
            if (!(employmentStatus === "Employed" || employmentStatus === "Self-Employed")) {
                setEmpErrorMessage("You must be Employed or Self-Employed to apply for loan.");
                setOpenMessage(true);
                return;
            }
        }
        
        if (!clickNextButton[pageName]) {
            setClickNextButton(prev => ({
                ...prev,
                [pageName]: true,
            }));
        }

        // Set a timeout to allow the automatic validation to finish before checking if there are any error messages
        setTimeout(() => {
            setLocalCheck(true);
        }, 200);
    };
    const handleNextButtonbeneShareClick = () => {
        if(beneShare !== 0.0){
            setBeneShareFailed(true);
            setOpenMessage(true);
            return;
        }
        handlePageChange();
    }

    const handleNextButtonTdsrClick = () => {
        if(checkIncome){
            handleNextButtonIncomeClick();
        }
        if(tdsr > 35.0){
            setTdsrFailed(true);
            setOpenMessage(true);
            return;
        }
        if ((Number(income) < 40000.0 ) && (tdsr > 35.0) && worth) {
            setOpenMessage(true);
            return; // All three conditions are true, so exit early
        }
        if ((Number(income) < 40000.0 )) {
            setOpenMessage(true);
            return; // Exit if only incomeFailed is true
        }
        
        if (tdsr > 35.0) {
            setOpenMessage(true);
            return; // Exit if only tdsrFailed is true
        }
        
        if (worth) {
            setOpenMessage(true);
            return; // Exit if only worthFailed is true
        }
        // Perform validation on Residential Status
        if (pageName === 'financial') {
            if (assetType && Array.isArray(assetType)) {
                const hasPrincipalResidence = assetType.includes('Principal Residence');
                if (hasPrincipalResidence && residentialStatus !== 'Own') {
                    setResErrorMessage("If you have a 'Principal Residence' asset, your residential status must be 'Own'.");
                    setOpenMessage(true);
                    return;
                }
            }
        }
        // If none of the above conditions are true, handle page change
        handlePageChange();
    };

    const handleNextButtonIncomeClick = () => {
        
        if(Number(income) < 40000.0 ){
            setIncomeFailed(true);
            setOpenMessage(true);
            return;
        }

    };

   

    const handlePageChange = async () => {
        if(pageName !== 'financial' && pageName !== 'beneficiary'){
            const info = infoToSave(pageName, applicant, 'Applicant', application.applicationID, applicationType,application);
            let needToSaveCoInfo = true;
            const reponse = await saveInfo(pageName, info);
            if(pageName === 'identification'){
                let customerID = [];
                if(reponse !== undefined && reponse.length >0 ){
                    
                    reponse.map((idRepsone, index) => {
                        customerID.push(idRepsone.customerIdentityID);
                    });
                    dispatch(setApplicationInfo({ attribute: 'CustomerIDs', value: customerID}));
                }

            }
            if(['specialInstructions', 'option'].includes(pageName)){
                needToSaveCoInfo = false;
            }
            if(hasCoApplicant && needToSaveCoInfo){
                const coInfo = infoToSave(pageName, coapplicant, 'Co-Applicant', application.applicationID, applicationType,application);
                const coreponse = await saveInfo(pageName, coInfo);
                if(pageName === 'identification'){
                    let customerID = [];
                    if(coreponse !== undefined && coreponse.length >0 ){
                        
                        coreponse.map((idRepsoneco, index) => {
                            customerID.push(idRepsoneco.customerIdentityID);
                        });
                        dispatch(setCoapplicantInfo({ attribute: 'CustomerIDs', value: customerID}));
                    }
    
                }
            }
        }
        

        // Increment the progress and page states only if the page is greater than or equal to the saved progress
        // Otherwise, increment the page state only
        if(pageName === 'summary'){
            return;
        }

        if(pageName === 'applicationDetail'){
            return;
        }
        if (page >= progress) {
            setProgress(progress + 1);
            setPage(page + 1);
        } else {
            setPage(page + 1);
        }
    };

    useEffect(() => {
        if (localCheck) {
            handleNextButtonErrorCheck(pageName);
            setLocalCheck(false);
        }


        // eslint-disable-next-line
    }, [localCheck]);


    useEffect(() => {

    }, [incomeFailed]);

    
    
    useEffect(() => {

    }, [tdsrFailed]);




    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenMessage(false);
    };

    return (
        <>
            <Button 
                variant="contained" 
                className="button-next"
                onClick={ async () => {
                    await handleClick();

                    if (checkScore) {
                        handleNextButtonInvestorProfileClick();
                    } else if (checkTdsr) {
                        handleNextButtonTdsrClick();
                    }else if(checkbeneShare){
                        handleNextButtonbeneShareClick()
                    } else if (checkEmp) {
                        handleNextButtonEmpClick();
                    } else if(submit){
                        handleSubmit();
                    } else if(addtionalsubmit){
                        handleApplicationReview();
                    }
                    else {
                        handleNextButtonClick();
                    }                 
                }}
            >
                {/* {submit ? "Submit" : "Next"} */}
                { submit ? "Submit" : addtionalsubmit ? "Reviewed" :"Next"}
            </Button>

            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openMessage}  onClose={handleClose}>
                <div>                 
                {/* First Alert for  checkTdsr */}
                {(checkTdsr && (tdsr > 35.0 ) ) && (
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20, mb: 2 }} // Add margin bottom for spacing
                >
                    { (tdsr > 35.0)
                    ? 'You do not meet the Total Debt Servicing Ratio (TDSR) requirement, Please update with either higher income or lower liability'
                    : null
                    }
                </Alert>
                )}

                {/* Second Alert for checkIncome */}
                {(checkIncome && (income < 40000.0)) && (
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {(income < 40000.0) 
                    ? 'You do not meet the Income requirement, Please update with higher income'
                    : null
                    }
                </Alert>
                )}
                {/* Thrid Alert for  checkworth */}
                {(checkWorth && worth) && (
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {worth
                    ? 'You do not meet the Net Worth requirement, Please update with higher Net Worth'
                    : null
                    }
                </Alert>
                )}

                {(checkbeneShare && beneShareFailed) && (
                <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {checkScore 
                    ? 'You do not meet the investor risk level requirement' 
                    : 'Please update Share field as the Total of Shares must be 100'
                    }
                </Alert>
                )}

                {/* Alert for asset residential status error message */}
                {resErrorMessage && (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        {resErrorMessage}
                    </Alert>
                )}   
                {/* Alert for employment status error message */}
                {empErrorMessage && (
                    <Alert
                        onClose={handleClose}
                        severity="error"
                        variant="filled"
                        sx={{ width: '100%', fontSize: 20 }}
                    >
                        {empErrorMessage}
                    </Alert>
                )}   
                 {/* Alert for check invest Score */}
                 {checkScore && (
                    <Alert
                    onClose={handleClose}
                    severity="error"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                    >
                    {checkScore 
                    ? 'You do not Meet the Investor Risk Ambitious Level, Ambitious Score >= 180' 
                    : ''
                    }
                    </Alert>
                )}   
                               
            </div>
            
            </Snackbar>
            
        </>
    );
};