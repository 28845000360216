import { Box } from "@mui/material";
import InvestorQuestionSubsection from "./subsection/InvestorQuestionSubsection";


/**
 * @returns - Main Applicant Investor Profile Section
 */


export default function MainAppInvestorSection() {
    return (
        <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>
            <InvestorQuestionSubsection applicantType='main' />
        </Box>
    );
};