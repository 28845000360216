import { useSelector } from "react-redux";
import { axiosInstance } from "../axios";

const checkFileLocation = (fileLocation) => {
    return Object.values(FileLocation).includes(fileLocation);
}

const FileLocation = {
    applicantID1Urls: "APPLICANT_IDENTITY_ID1",
    applicantID2Urls: "APPLICANT_IDENTITY_ID2",
    applicantPersonalID_1Urls: "APPLICANT_VERIFICATION",
    applicantCreditReportUrls: "APPLICANT_CREDIT_REPORT",
    coApplicantID1Urls: "COAPPLICANT_IDENTITY_ID1",
    coApplicantID2Urls: "COAPPLICANT_IDENTITY_ID2",
    coApplicantPersonalID_1Urls: "COAPPLICANT_VERIFICATION",
    coApplicantCreditReportUrls: "COAPPLICANT_CREDIT_REPORT",
    voidChequeLink: "VOID_CHEQUE"
}


// upload a SINGLE file to the blob storage
export async function uploadToBlob(applicationID, applicantType, attribute, file){
        console.log(file)
        const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
        const acceptedTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];
        const fileLocation = FileLocation[attribute];
        let isValidFile = false;
        const error = [];

        if (!acceptedTypes.includes(file.type)) {
            error.push(`${file.name}: Accepted file types are pdf, jpg, jpeg, and png.`);
        } else if (file.size > maxSizeInBytes) {
            error.push(`${file.name}: File size exceeds the 5MB limit.`);
        } else {
            isValidFile = true;
        }
        
        if (!isValidFile) {
            return error.join(' ');
        }

        try {
            const formData = new FormData();

            formData.append(`files`, file);

            if(!checkFileLocation(fileLocation)) {
                return "Invalid file location";
            }

            formData.append('applicationID', applicationID);
            formData.append('applicantType', applicantType);
            formData.append('fileLocation', fileLocation);

            const response = await axiosInstance.post('application/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });

            if (response.status === 200) {
                const uploadedUrl = response.data;
                                
                return {
                    success: true,
                    url: uploadedUrl
                };

            } else {
                throw new Error('Upload failed');
            }
        }
        catch (error) {
            return 'Failed to upload file';
        }
    };