import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    applicationID: "",
    customerType: "Individual",
    applicationStatus: "",
    fundType: "",
    investmentAccountType: "",
    loanType: "",
    requestType: "",
    servingAdvisorCode: "",
    isSystemAssignAdvisor: true,
    servingAdvisorID: "",
    supportingAdvisorID: "",
    createdUserID: "",
    createdDate: "",
    latestEditedDate: "",
    firstSubmittedDate: "",
    latestSubmittedDate: "",
    approvedDate: "",
    physicallyVerified: "",
    IDVerificatedDate: "",
    howHeard: "",
    additionalInfo_anythingElse: "",
    applicantCustomerID: "",
    applicantCustomerUserID: "",
    coApplicantCustomerID: "",
    hasCoApplicant: false,
    coApplicantCustomerUserID: "",
    annuitantCustomerID: "",
    applicationType: "",
    contractNo: "",
    comments: "",
    timeStamp: "",
    defaultPrimeRate: ""
}

const applicationInfoSlice = createSlice({
    name: "applicationInfo",
    initialState,
    reducers: {
        setApplicationInfo: (state, action) => {
            const { attribute, value } = action.payload
            state[attribute] = value;
        },
        resetApplicationInfo: () => initialState,
    },
});

export const { setApplicationInfo, resetApplicationInfo } = applicationInfoSlice.actions;
export default applicationInfoSlice.reducer;