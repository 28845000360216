import { Box } from "@mui/material";

/**
 * @param {string} text - The text to be displayed above a text field.
 * @param {boolean} largeBox - A boolean value to determine if the text field is a large box or not.
 * @returns - A text field text component for main field component. 
 */


export default function TextFieldText({ text, largeBox = false }) {

    const boxStyle = largeBox ?
        'w-fit min-h-[50px] bg-transparent mt-[5px] mb-[10px] mx-[30px]' :
        'max-w-[240px] w-fit min-h-[10px] bg-transparent mt-[5px] mb-[20px] mx-[30px]';

    const textStyle = largeBox ?
        'w-[20vw] min-h-[47px] text-left font-sans text-2xl font-normal text-[#3C3C3C] leading-8' :
        'max-w-[230px] w-[10vw] min-h-[47px] text-left font-sans text-2xl font-normal text-[#3C3C3C] leading-8';

    return (
        <Box className={boxStyle}>
            <span className={textStyle}>
                {text}
            </span>
        </Box>
    );
};