import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import { useApplication } from "../../../../contexts/ApplicationContext";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import RadioGroup from "../../../../components/smallComponents/radioGroup/RadioGroup";
import ErrorBox from "../../../../components/smallComponents/errorBox/ErrorBox";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";


/**
 * @returns - ProductDetail component for RESP application 
 */


export default function ProductDetail() {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    const { clickNextButton } = useApplication();

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const itemSpace = 4;

    const title = "Product Details";
    const pageName = 'product';
    const planTypes = ['Family Plan', 'Individual Plan'];
    const applicants = applicant.anyCoapplicant ? ['Applicant', 'Co-Applicant'] : ['Applicant'];

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24'>

            <Box className='mt-24'>
                <TitleText text={title} />
            </Box>

            <Box className="mt-10">
                <ErrorBox pageName={pageName} enable={clickNextButton[pageName]} />
            </Box>

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='RESP Plan Type' />
                        <RadioGroup
                            options={planTypes}
                            setStateValue={value => handleApplicantChange("planTypeRESP", value)}
                            defaultValue={applicant.planTypeRESP}
                            type='mandatory'
                            name='RESP Plan Type'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Primary Caregiver' />
                        <RadioGroup
                            options={applicants}
                            setStateValue={value => handleApplicantChange("primaryCaregiverRESP", value)}
                            defaultValue={applicant.primaryCaregiverRESP}
                            type='mandatory'
                            name='Primary Caregiver'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Custodial Parent / Legal Guardian' />
                        <RadioGroup
                            options={applicants}
                            setStateValue={value => handleApplicantChange("parentOrGuardianRESP", value)}
                            defaultValue={applicant.parentOrGuardianRESP}
                            type='mandatory'
                            name='Parent or Guardian'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton pageName={pageName} />
            </Box>

        </Box>
    );
};