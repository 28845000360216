/**
 * Component for the TFSA_RRSP page.
 * Displays information and required documents for RRSP loan application.
 * @returns {JSX.Element} TFSA_RRSP component.
 */
import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import './TFSA_RRSP.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';

function TFSA_RRSP() {
    const { setSelectedComponent } = useApplication();
    return (
        <Box>
            <HeaderBox text='RRSP loans cannot be applied for online at the moment.
            Please call 647-323-3231 to speak with our investment advisor' />
            <div className="textPage">
                <p>Please see the list of required documents for the RRSP loan application.</p>
                <ol className='olTFSA'>
                    <li>Personal and employment information</li>
                    <li>All RSP loans required the following support documentation to be submitted:</li>
                    <li>Void cheque from a personal account imprinted with the applicants name.</li>
                    <ul className='ulTFSA'>
                        <li>if no cheque is avalaible, a pre-authorized debit slip may be used instead (must be dated within 3 months and stamped by bank teller).</li>
                        <li>Proof of assets (upon request).</li>
                        <li>Letter of Direction (if playing out another financial institution)</li>
                    </ul>
                    <li>Include details from two pieces of valid identification for each applicant</li>
                </ol>
                
            </div>
            <div className='button-Area'>
                <NavLink onClick={() => setSelectedComponent('LoanType')}>
                    <button className="bigButton">Back</button>
                </NavLink>
            </div>

        </Box>
    );
}

export default TFSA_RRSP;