import { Grid, Box, IconButton } from "@mui/material"
import "./style/IdentificationTaxStatus.css"
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../reducers/applicantReducer";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText"
import TextFieldText from "../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../components/smallComponents/textField/TextField";
import DropdownList from "../../../../components/smallComponents/dropdownList/DropdownList";
import RadioGroupBoolean from "../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import { useApplication } from "../../../../contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { ArrowBack, ArrowForward } from "@mui/icons-material";


/**
 * IdentificationTaxStatus component displays the tax status information of the main applicant.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.reasons - The list of reasons for not having a Taxpayer Identification Number (TIN).
 * @returns {JSX.Element} The rendered component.
 */


export default function IdentificationTaxStatus({ reasons, enable }) {

    const { clickNextButton, handleErrorMessage } = useApplication()

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);

    // const itemSpace = 3;
    const pageName = 'identification'

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantTaxResidenceChangeUS = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))

        //making sure the value is being passed to the global variable
        //as the defaultValue wont change the global state variable
        if (value === true) {
            handleApplicantTinUS("haveTinUS", true)
        }
        else {
            handleApplicantTinUS("haveTinUS", null)
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantTinUS = (attribute, value) => {
        if (value) {
            handleApplicantChange("reasonOfNoTinUS", '')
        }
        else {
            handleApplicantChange("tinUS", '')
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantOther = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))

        //making sure the value is being passed to the global variable
        //as the defaultValue wont change the global state variable
        if (value === true) {
            handleApplicantOutside("haveTinOther", true)
        }
        else {
            handleApplicantOutside("haveTinOther", null)
        }
    };

    const handleApplicantOutside = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))

        //setting the global value to default or empty if user changed certain field
        if (value === false) {
            handleApplicantChange("tinOther", '')
            handleApplicantChange("countryName", '')
        }
        else {
            handleApplicantChange("reasonOfNoTinOther", '')
        }
    };

    const residenceOfUS = applicant.residenceOfUnitedStates;

    const residenceOfOutside = applicant.otherResidence;

    //creating the tab display for tax status
    const questions = [
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box>
                        <TextFieldText text='Tax Residence of Canada' />
                        <RadioGroupBoolean
                            setStateValue={value => handleApplicantChange("residenceOfCanada", value)}
                            defaultValue={applicant.residenceOfCanada}
                            type='mandatory' name='Applicant Tax Residence of Canada' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        ,
        <Grid item xs={12}>
            <Grid container spacing={2}>
                <Grid item xs={residenceOfUS ? 4 : 12}>
                    <Box>
                        <TextFieldText text='Tax Residence of United States' />
                        <RadioGroupBoolean
                            setStateValue={value => handleApplicantTaxResidenceChangeUS("residenceOfUnitedStates", value)}
                            defaultValue={applicant.residenceOfUnitedStates}
                            type='mandatory' name='Applicant Tax Residence of United States' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text='Do you have Taxpayer identification number (TIN) from the U.S. ?' />
                        <RadioGroupBoolean
                            setStateValue={value => handleApplicantTinUS("haveTinUS", value)}
                            type={applicant.residenceOfUnitedStates ? 'mandatory' : 'optional'} name='Applicant Has US TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            defaultValue={applicant.haveTinUS}
                            disabled={!applicant.residenceOfUnitedStates}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS && applicant.haveTinUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text='TaxPayer Identification Number (TIN) ' />
                        <TextField
                            setStateValue={value => handleApplicantChange("tinUS", value)}
                            defaultValue={applicant.tinUS}
                            disabled={!applicant.residenceOfUnitedStates || !applicant.haveTinUS}
                            type={applicant.residenceOfUnitedStates && applicant.haveTinUS ? 'mandatory' : 'optional'} name='Applicant TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        />
                    </Box>
                </Grid>
                <Grid item xs={4} display={residenceOfUS && !applicant.haveTinUS ? 'block' : 'none'}>
                    <Box>
                        <TextFieldText text="Explain why you don't have TIN" />
                        <DropdownList
                            options={reasons}
                            setStateValue={value => handleApplicantChange("reasonOfNoTinUS", value)}
                            defaultValue={applicant.reasonOfNoTinUS}
                            disabled={!applicant.residenceOfUnitedStates || applicant.haveTinUS}
                            type={applicant.residenceOfUnitedStates && !applicant.haveTinUS ? 'mandatory' : 'optional'} name='Applicant Reason for No TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                        ></DropdownList>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        ,
        <Box>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={residenceOfOutside ? 6 : 12}>
                        <Box>
                            <TextFieldText text='Tax residence of a jurisdiction other than Canada or the United States?' />
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantOther("otherResidence", value)}
                                defaultValue={applicant.otherResidence}
                                type='mandatory' name='Applicant Tax Residence of Other Jurisdiction' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={applicant.otherResidence && applicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text='If yes, what is the country name of the Taxpayer Identification Number (TIN) that you have? '
                            />
                            <TextField
                                disabled={!applicant.otherResidence || !applicant.haveTinOther}
                                setStateValue={value => handleApplicantChange("countryName", value)} defaultValue={applicant.countryName}
                                type={applicant.otherResidence && applicant.haveTinOther ? 'mandatory' : 'optional'} name='Applicant TIN Country' pageName={pageName} buttonCheck={clickNextButton[pageName]}>
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={applicant.otherResidence && !applicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text="Explain why you don't have TIN" />
                            <DropdownList
                                options={reasons}
                                disabled={!applicant.otherResidence || applicant.haveTinOther}
                                setStateValue={value => handleApplicantChange("reasonOfNoTinOther", value)}
                                defaultValue={applicant.reasonOfNoTinOther}
                                type={applicant.otherResidence && !applicant.haveTinOther ? 'mandatory' : 'optional'} name='Applicant Reason for No TIN Other' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            ></DropdownList>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} display={applicant.otherResidence ? 'block' : 'none'}>
                <Grid container spacing={2}>

                    <Grid item xs={6} >
                        <Box>
                            <TextFieldText text='Do you have Taxpayer identification number (TIN) outside the U.S. or Canada?' />
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantOutside("haveTinOther", value)}
                                defaultValue={applicant.haveTinOther}
                                disabled={!applicant.otherResidence}
                                type={applicant.otherResidence ? 'mandatory' : 'optional'} name='Applicant Has Other TIN' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} display={applicant.otherResidence && applicant.haveTinOther ? 'block' : 'none'}>
                        <Box>
                            <TextFieldText text='TaxPayer Identification Number (TIN) ' />
                            <TextField
                                disabled={!applicant.otherResidence || !applicant.haveTinOther}
                                setStateValue={value => handleApplicantChange("tinOther", value)} defaultValue={applicant.tinOther}
                                type={applicant.otherResidence && applicant.haveTinOther ? 'mandatory' : 'optional'} name='Applicant TIN Other' pageName={pageName} buttonCheck={clickNextButton[pageName]}
                            ></TextField>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
        ,
    ];

    // questions tab prev and next function
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const goToPreviousQuestion = () => {
        setCurrentTabIndex(currentTabIndex - 1);
    };

    const goToNextQuestion = () => {
        setCurrentTabIndex(currentTabIndex + 1);
    };

    return (
        <Box className="section-main-box-field-Tax" display={enable ? 'block' : 'none'} >

            <TitleText text="Main Applicant Tax Status" />

            <Box sx={{ display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center' }}>
                <IconButton onClick={goToPreviousQuestion} disabled={currentTabIndex === 0} sx={{position: 'absolute', left :'0', marginLeft: '20px'}}>
                    <ArrowBack sx={{ fontSize: 45 }} />
                </IconButton>
                <Box>
                    {questions[currentTabIndex]}
                </Box>
                <IconButton onClick={goToNextQuestion} disabled={currentTabIndex === questions.length - 1}sx={{position: 'absolute', right :'0', marginRight: '20px'}}>
                    <ArrowForward sx={{ fontSize: 45 }} />
                </IconButton>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                {currentTabIndex < 2 ? 'please review until the end of the Tab' : ''}
            </Box>

        </Box>
    );
};