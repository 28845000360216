import { useSelector } from "react-redux";

export const AdditionalInfoSummary = (caseId) => {
    const additionalInfo = useSelector(state => state.additionalInfo);
    return {
        "Bank & Investment Company": {
            "Bank and Fund": Array.isArray(additionalInfo.appliedAmount)
                ? additionalInfo.appliedAmount
                    .map((amount, index) => {
                        // Filter items based on matching caseID
                        if (additionalInfo.caseID[index] === caseId) {
                            return {
                                InvestmentLoanBank: {
                                    description: "Investment Loan Bank",
                                    value: additionalInfo.InvestmentLoanBank[index]
                                },
                                InvestmentFundCompany: {
                                    description: "Investment Fund Company",
                                    value: additionalInfo.InvestmentFundCompany[index]
                                },
                                appliedAmount: {
                                    description: "Applied Amount",
                                    value: additionalInfo.appliedAmount[index]
                                },
                                subInvestmentAccountType: {
                                    description: "Sub-Investment Acct. Type",
                                    value: additionalInfo.subInvestmentAccountType[index]
                                },
                                SalesCharge: {
                                    description: "Sales Charge",
                                    value: additionalInfo.SalesCharge[index]
                                },
                                FundPortfolio: {
                                    description: "Fund Portfolio",
                                    value: additionalInfo.FundPortfolio[index]
                                },
                                felRate: {
                                    description: "FEL Rate",
                                    value: additionalInfo.felRate[index]
                                },
                                felFee: {
                                    description: "FEL Fee",
                                    value: additionalInfo.felFee[index]
                                },
                                caseStatus: {
                                    description: "Case Status",
                                    value: additionalInfo.caseStatus[index]
                                },
                                completedAmount: {
                                    description: "Completed Amount",
                                    value: additionalInfo.completedAmount[index]
                                },
                                contributionAmount: {
                                    description: "Contribution Amount",
                                    value: additionalInfo.contributionAmount[index]
                                },
                                completedDate: {
                                    description: "Completed Date",
                                    value: additionalInfo.completedDate[index]
                                },
                                loanAccountNo: {
                                    description: "Loan Account No.",
                                    value: additionalInfo.loanAccountNo[index]
                                },
                                loanNo: {
                                    description: "Loan No.",
                                    value: additionalInfo.loanNo[index]
                                },
                                contractNo: {
                                    description: "Contract No.",
                                    value: additionalInfo.contractNo[index]
                                },
                                primeRate: {
                                    description: "Prime Rate",
                                    value: additionalInfo.primeRate[index]
                                }
                            };
                        }
                        return null; // Return null if caseID does not match
                    })
                    .filter(item => item !== null) // Remove null items from map
                : [] // Default to empty array if appliedAmount is not an array
        },
        "Advisor ID": {
            supportingAdvisorID: {
                description: "Supporting Advisor ID",
                value: additionalInfo.supportingAdvisorID
            },
            servingAdvisorID: {
                description: "Serving Advisor ID",
                value: additionalInfo.servingAdvisorID
            }
        },
        "ID Verification": {
            verificationDate: {
                description: "Client ID Verification Date",
                value: additionalInfo.verificationDate
            }
        },
        "Acquisition Channel": {
            acquisitionChannel: {
                description: "Client Acquisition Channel",
                value: additionalInfo.acquisitionChannel
            },
            existingClient: {
                description: "Existing Client",
                value: additionalInfo.existingClient
            },
            "Referrers": Array.isArray(additionalInfo.referrerLastName)
                ? additionalInfo.referrerLastName.map((_, index) => ({
                    referrerFirstName: {
                        description: "Referrer - Firstname",
                        value: additionalInfo.referrerFirstName[index]
                    },
                    referrerLastName: {
                        description: "Referrer - Lastname",
                        value: additionalInfo.referrerLastName[index]
                    },
                    referrerPreferredName: {
                        description: "Referrer - Preferred Name",
                        value: additionalInfo.referrerPreferredName[index]
                    },
                    referrerDateOfBirth: {
                        description: "Referrer - Date of Birth",
                        value: additionalInfo.referrerDateOfBirth[index]
                    },
                    referrerGender: {
                        description: "Referrer - Gender",
                        value: additionalInfo.referrerGender[index]
                    },
                    referrerHomePhone: {
                        description: "Referrer - Home Phone",
                        value: additionalInfo.referrerHomePhone[index]
                    }
                }))
                : [] // Default to empty array if referrerLastName is not an array
        }
    };
};
