import React, { useEffect } from 'react';
import { Box, Grid, IconButton, Tabs, Tab, Snackbar, Alert } from "@mui/material";
import { AddBox, SearchOutlined } from "@mui/icons-material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../../contexts/ApplicationContext";
import { setApplicantInfo } from "../../../../../../reducers/applicantReducer";
import { setCoapplicantInfo } from "../../../../../../reducers/coapplicantReducer";
import { useParams } from "react-router-dom";
import TextFieldText from "../../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import NumberField from "../../../../../../components/smallComponents/numberField/NumberField";
import NumberFieldMoney from "../../../../../../components/smallComponents/numberField/NumberFieldMoney";
import DropdownList from "../../../../../../components/smallComponents/dropdownList/DropdownList";
import Table from "../../../../../../components/mediumComponents/table/Table";
import DocUploader from "../../../../../../components/mediumComponents/uploadDoc/docUploader";
import { assetType , answerAttributes, investorOptions } from "../../../../../../constants/lists";
import { getAllItems, addItem, deleteItemByID, updateItem } from "../../../../../../services/multiAddObjectService";
import CreateTimeStamp from "../../../../../../utilities/createTimeStamp";
import Big from 'big.js';
import bigInt from 'big-integer';
import "./style/AssetSubsection.css";
import ModifyTable from "../../../../../../components/mediumComponents/table/ModifyTable";
import { getDropdownList } from "../../../../../../services/dropdownService";

/**
 * @description - This component imports a table component to display the stored assets and a field box to add an new asset
 * @param {string} applicantType - Type of applicant (main or co)
 * @param {string} color - Background color of the asset field
 * @returns - Asset Subsection component for the Financial Analysis field component
 * @todo - Remove hard-coded application ID and replace with global state value 
 */


export default function AssetSubsection({ applicantType, color }) {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const dispatch = useDispatch();

    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);
    const application = useSelector((state) => state.applicationInfo);

    const applicationType = useParams().applicationType;

    const { handleMainInvestorScoreChange, handleCoInvestorScoreChange, clickNextButton, assetOrderNumber, setAssetOrderNumber } = useApplication();

    const [asset, setAsset] = useState(
        {
            owner: '',
            type: '',
            value: '',
            institution: '',
            taxBillLink: '',
            accountStatementLink: ''
        }
    );
    const [isUpdate, setIsUpdate] = useState(false);
    const [rowIndex, setRowIndex] = useState(-1);
    // State to trigger the clearing of the input fields in child components
    const [isClear, setIsClear] = useState(false);

    // Control the display of snackbar messages
    const [openError, setOpenError] = useState(false);
    const [openSuccess, setOpenSuccess] = useState(false);
    const [updateAssetIndex, setUpdateAssetIndex] = useState(0);
    const [institutions, setInstitutions] = useState([]);
    const [assetType, setAssetType] = useState([]);
    const itemSpace = 3;
    const tabSpace = 12;
    const docUploaderSpace = 6;

    const pageName = 'financial';
    const itemType = 'assets';
    
    // Question index and score range for the pre-fill question
    const questionIndex = 2;
    const questionScore = [1, 2, 5, 10, 20];

    const hasCoApplicant = applicant.anyCoapplicant;

    const applicantName = applicant.firstname + ' ' + applicant.lastname;
    const coApplicantName = hasCoApplicant ? coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const bothApplicantsName = hasCoApplicant ? applicant.firstname + ' ' + applicant.lastname + ' & ' + coapplicant.firstname + ' ' + coapplicant.lastname : '';
    const owners = hasCoApplicant ? [applicantName, coApplicantName, bothApplicantsName] : [applicantName];

    // Array of strings that represent the names of the column fields in table component
    const names = ['Record No.', 'Owner', 'Type', 'Value', 'Institution', 'Tax Bill', 'Account Statement'];

    // Array of arrays that represent the values of the column fields in table component
    // Each individual array (global state) contains the specific attribute value from every asset
    const values = applicantType === 'main' ?
        [
            applicant.assetOrderNumbers,
            applicant.assetBelongTo,
            applicant.assetType,
            applicant.assetMarketValue,
            applicant.assetInstitutionName,
            applicant.applicantUrlsRecentTaxBillResidence,
            applicant.applicantUrlsAccountsStatement
        ]
        :
        [
            coapplicant.assetBelongTo,
            coapplicant.assetType,
            coapplicant.assetMarketValue,
            coapplicant.assetInstitutionName
        ];

    const urls =
        [
            applicant.applicantUrlsRecentTaxBillResidence,
            applicant.applicantUrlsAccountsStatement,
            coapplicant.coApplicantUrlsRecentTaxBillResidence,
            coapplicant.coApplicantUrlsAccountsStatement
        ];

    const fetchFinancials = async ()=>{
        const financials = await getDropdownList("financialInstitutions");
        setInstitutions(financials.map(x=>x['financialInstitution']));
    }

    const fetchAssetType = async ()=>{
        const assetsList = await getDropdownList("assetTypes");
        setAssetType(assetsList.map(x=>x['assetType']));
    }
    // Pre-fill the net worth question and calculate investor profile score based on the calculated net worth
    const calculateNetWorth = (assetChange) => {

        const netWorth = assetChange === '' ? 0 : parseFloat(assetChange)
            +
            applicant.assetMarketValue
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            +
            coapplicant.assetMarketValue
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            -
            applicant.liabilityBalance
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0)
            -
            coapplicant.liabilityBalance
                .map(value => value === '' ? 0 : parseFloat(value))
                .reduce((accumulator, current) => accumulator + current, 0);

        if (netWorth <= 25000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [1, investorOptions[2][0], 1] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [1, investorOptions[2][0], 1] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[0]);
            handleCoInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (netWorth <= 50000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [2, investorOptions[2][1], 2] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [2, investorOptions[2][1], 2] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[1]);
            handleCoInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (netWorth <= 100000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [3, investorOptions[2][2], 5] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [3, investorOptions[2][2], 5] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[2]);
            handleCoInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (netWorth <= 200000) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [4, investorOptions[2][3], 10] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [4, investorOptions[2][3], 10] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[3]);
            handleCoInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else {
            dispatch(setApplicantInfo({ attribute: answerAttributes[2], value: [5, investorOptions[2][4], 20] }));
            dispatch(setCoapplicantInfo({ attribute: answerAttributes[2], value: [5, investorOptions[2][4], 20] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[4]);
            handleCoInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleCoapplicantChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const clearInputs = ()=>{
        setIsClear(true);
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);
        // Reset the local state to empty strings after adding a new income
        setAsset({
        owner: '',
        type: '',
        value: '',
        institution: '',
        taxBillLink: '',
        accountStatementLink: ''
        });

    }


    const handleAddAsset = async () => {

        // Calculate the net worth when adding a new asset
        calculateNetWorth(asset.value);

        const index = owners.indexOf(asset.owner);

        let ownerName = asset.owner;
        switch (index) {
            case 0:
                ownerName = 'Applicant';
                break;
            case 1:
                ownerName = 'Co-Applicant';
                break;
            case 2:
                ownerName = 'Both Applicants';
                break;
            default:
                break;
        };

        // Get the tax bill and account statement URLs for the asset
        const taxBillUrl = ownerName !== 'Co-Applicant' ?
            applicant.applicantUrlsRecentTaxBillResidence[assetOrderNumber] :
            coapplicant.coApplicantUrlsRecentTaxBillResidence[assetOrderNumber];

        const accountStatementUrl = ownerName !== 'Co-Applicant' ?
            applicant.applicantUrlsAccountsStatement[assetOrderNumber] :
            coapplicant.coApplicantUrlsAccountsStatement[assetOrderNumber];

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: [...applicant.assetOrderNumbers, assetOrderNumber] }));
            dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: [...applicant.assetBelongTo, asset.owner] }));
            dispatch(setApplicantInfo({ attribute: 'assetType', value: [...applicant.assetType, asset.type] }));
            dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: [...applicant.assetMarketValue, asset.value] }));
            dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: [...applicant.assetInstitutionName, asset.institution] }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'assetBelongTo', value: [...coapplicant.assetBelongTo, asset.owner] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetType', value: [...coapplicant.assetType, asset.type] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetMarketValue', value: [...coapplicant.assetMarketValue, asset.value] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetInstitutionName', value: [...coapplicant.assetInstitutionName, asset.institution] }));
        }

        // Create a new asset object to be added to the database
        const newAsset = {
            applicationID: application.applicationID,
            owner: asset.owner, 
            orderNum: assetOrderNumber,
            assetType: asset.type,
            assetMarketValue: new Big(asset.value),
            financialInstitution: asset.institution,
            taxBillLink: taxBillUrl || '',
            accountStatementLink: accountStatementUrl || '',
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

       
        
        // Call the service to add a new asset to the database
        const addedAsset = await addItem(itemType, newAsset);

        // Push the DB generated assetId from API response to the global state
        dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: [...applicant.AssetIDs, addedAsset.assetID] }));

        // Trigger the clearing of the input fields in child components after adding a new asset
        // Reset the state to false after 0.1 seconds to resume input action in the child components

        clearInputs();
        setIsClear(true);
        // Increment the asset order number after adding a new asset
        setAssetOrderNumber(assetOrderNumber + 1);


    };

    // Provide input field validation before adding a new asset
    const handleAddAssetCheck = () => {
        if (asset.owner === '' || asset.type === '' || asset.value === '' ||  asset.assetMarketValue === '') {
            setOpenError(true);
        }
        else if (parseFloat(asset.value) < 0) {
            setOpenError(true);
        }
        else {
            handleAddAsset();
            clearInputs();
            setOpenSuccess(true);
        }
    };

    
    const handleDeleteAsset = async (index) => {

        // Calculate the net worth when deleting an asset
        calculateNetWorth(applicantType === 'main' ? -applicant.assetMarketValue[index] : -coapplicant.assetMarketValue[index]);

        // Delete the asset from the database
        const assetId = bigInt(applicant.AssetIDs[index]);
        const deletedAsset = await deleteItemByID(itemType, assetId);
        console.log('Deleted Asset', deletedAsset);

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: applicant.AssetIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: applicant.assetOrderNumbers.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: applicant.assetBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetType', value: applicant.assetType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: applicant.assetMarketValue.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: applicant.assetInstitutionName.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'assetBelongTo', value: coapplicant.assetBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetType', value: coapplicant.assetType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetMarketValue', value: coapplicant.assetMarketValue.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetInstitutionName', value: coapplicant.assetInstitutionName.filter((_, i) => i !== index) }));
        }
    };

    const handleEditAssert = (index) =>{
        setAsset({
            assetID: applicant.AssetIDs[index],
            owner: applicant.assetBelongTo[index],
            type: applicant.assetType[index],
            orderNum: applicant.assetOrderNumbers[index],
            assetType: applicant.assetType[index],
            value: applicant.assetMarketValue[index],
            institution: applicant.assetInstitutionName[index]

        });
         
         handleDeleteAssetNoDB(index);
         setIsUpdate(prevState => !prevState);
         setUpdateAssetIndex(applicant.AssetIDs[index]);
         
     }

     
     const sendEditAsset = async () => {

        // Calculate the net worth when adding a new asset
        calculateNetWorth(asset.value);

        const index = owners.indexOf(asset.owner);

        let ownerName;
        switch (index) {
            case 0:
                ownerName = 'Applicant';
                break;
            case 1:
                ownerName = 'Co-Applicant';
                break;
            case 2:
                ownerName = 'Both Applicants';
                break;
            default:
                break;
        };

        // Get the tax bill and account statement URLs for the asset
        const taxBillUrl = ownerName !== 'Co-Applicant' ?
            applicant.applicantUrlsRecentTaxBillResidence[assetOrderNumber] :
            coapplicant.coApplicantUrlsRecentTaxBillResidence[assetOrderNumber];

        const accountStatementUrl = ownerName !== 'Co-Applicant' ?
            applicant.applicantUrlsAccountsStatement[assetOrderNumber] :
            coapplicant.coApplicantUrlsAccountsStatement[assetOrderNumber];

        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'AssetIDs', value:[updateAssetIndex]}));
            dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: [...applicant.assetOrderNumbers, assetOrderNumber] }));
            dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: [...applicant.assetBelongTo, asset.owner] }));
            dispatch(setApplicantInfo({ attribute: 'assetType', value: [...applicant.assetType, asset.type] }));
            dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: [...applicant.assetMarketValue, asset.value] }));
            dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: [...applicant.assetInstitutionName, asset.institution] }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'assetBelongTo', value: [...coapplicant.assetBelongTo, asset.owner] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetType', value: [...coapplicant.assetType, asset.type] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetMarketValue', value: [...coapplicant.assetMarketValue, asset.value] }));
            dispatch(setCoapplicantInfo({ attribute: 'assetInstitutionName', value: [...coapplicant.assetInstitutionName, asset.institution] }));
        }

        // Create a new asset object to be added to the database
        const newAsset = {
            assetID: updateAssetIndex,
            applicationID: application.applicationID,
            owner: asset.owner,
            orderNum: assetOrderNumber,
            assetType: asset.type,
            assetMarketValue: new Big(asset.value),
            financialInstitution: asset.institution,
            taxBillLink: taxBillUrl || '',
            accountStatementLink: accountStatementUrl || '',
            timeStamp: CreateTimeStamp({ needTime: true }),
            deleteFlag: false
        };

        // Call the service to add a new asset to the database
        const addedAsset = await updateItem(itemType, newAsset);

        // Push the DB generated assetId from API response to the global state
        //dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: [...applicant.AssetIDs, addedAsset.assetID] }));

        // Trigger the clearing of the input fields in child components after adding a new asset
        // Reset the state to false after 0.1 seconds to resume input action in the child components
        setIsClear(true);
        setIsClear(true);
        setTimeout(() => setIsClear(false), 0.1);

        clearInputs();
        setIsClear(true);
        setIsClear(true);
        setIsUpdate(prevState => !prevState);
        // Increment the asset order number after adding a new asset
        setAssetOrderNumber(assetOrderNumber + 1);
    };

     const handleDeleteAssetNoDB = (index) => {

        // Calculate the net worth when deleting an asset
        calculateNetWorth(applicantType === 'main' ? -applicant.assetMarketValue[index] : -coapplicant.assetMarketValue[index]);


        if (applicantType === 'main') {
            dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: applicant.AssetIDs.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: applicant.assetOrderNumbers.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: applicant.assetBelongTo.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetType', value: applicant.assetType.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: applicant.assetMarketValue.filter((_, i) => i !== index) }));
            dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: applicant.assetInstitutionName.filter((_, i) => i !== index) }));
        }
        else if (applicantType === 'co') {
            dispatch(setCoapplicantInfo({ attribute: 'assetBelongTo', value: coapplicant.assetBelongTo.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetType', value: coapplicant.assetType.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetMarketValue', value: coapplicant.assetMarketValue.filter((_, i) => i !== index) }));
            dispatch(setCoapplicantInfo({ attribute: 'assetInstitutionName', value: coapplicant.assetInstitutionName.filter((_, i) => i !== index) }));
        }
    };
    
    
    const handleFetchAssets = async () => {

        const assets = await getAllItems(itemType, application.applicationID);

        // Temporary variables to store fetched assets info
        let updatedAssetIDs = [];
        let updatedAssetOrderNumbers = [];
        let updatedAssetBelongTo = [];
        let updatedAssetType = [];
        let updatedAssetMarketValue = [];
        let updatedAssetInstitutionName = [];
        let updatedApplicantUrlsRecentTaxBillResidence = {};
        let updatedApplicantUrlsAccountsStatement = {};
        let updatedCoApplicantUrlsRecentTaxBillResidence = {};
        let updatedCoApplicantUrlsAccountsStatement = {};
        let lastOrderNum = 0;
        let totalAsset = 0;
        for (const asset of assets) {
            updatedAssetIDs.push(asset['assetID']);
            updatedAssetOrderNumbers.push(asset['orderNum']);
            updatedAssetBelongTo.push(asset['owner']);
            updatedAssetType.push(asset['assetType']);
            updatedAssetMarketValue.push(asset['assetMarketValue']);
            updatedAssetInstitutionName.push(asset['financialInstitution']);
            totalAsset += asset['assetMarketValue'];
            lastOrderNum = asset['orderNum'];

            if (asset['applicantType'] !== 'Co-Applicant') {
                if (asset['taxBillLink'] !== '') {
                    updatedApplicantUrlsRecentTaxBillResidence[asset['orderNum']] = asset['taxBillLink'];
                }
                if (asset['accountStatementLink'] !== '') {
                    updatedApplicantUrlsAccountsStatement[asset['orderNum']] = asset['accountStatementLink'];
                }
            }
            else {
                if (asset['taxBillLink'] !== '') {
                    updatedCoApplicantUrlsRecentTaxBillResidence[asset['orderNum']] = asset['taxBillLink'];
                }
                if (asset['accountStatementLink'] !== '') {
                    updatedCoApplicantUrlsAccountsStatement[asset['orderNum']] = asset['accountStatementLink'];
                }
            }
        }

        // Dispatch all updates at once
        dispatch(setApplicantInfo({ attribute: 'AssetIDs', value: updatedAssetIDs }));
        dispatch(setApplicantInfo({ attribute: 'assetOrderNumbers', value: updatedAssetOrderNumbers }));
        dispatch(setApplicantInfo({ attribute: 'assetBelongTo', value: updatedAssetBelongTo }));
        dispatch(setApplicantInfo({ attribute: 'assetType', value: updatedAssetType }));
        dispatch(setApplicantInfo({ attribute: 'assetMarketValue', value: updatedAssetMarketValue }));
        dispatch(setApplicantInfo({ attribute: 'assetInstitutionName', value: updatedAssetInstitutionName }));
        dispatch(setApplicantInfo({ attribute: 'applicantUrlsRecentTaxBillResidence', value: updatedApplicantUrlsRecentTaxBillResidence }));
        dispatch(setApplicantInfo({ attribute: 'applicantUrlsAccountsStatement', value: updatedApplicantUrlsAccountsStatement }));
        dispatch(setCoapplicantInfo({ attribute: 'coApplicantUrlsRecentTaxBillResidence', value: updatedCoApplicantUrlsRecentTaxBillResidence }));
        dispatch(setCoapplicantInfo({ attribute: 'coApplicantUrlsAccountsStatement', value: updatedCoApplicantUrlsAccountsStatement }));
        calculateNetWorth(totalAsset);
        totalAsset=0;
        setAssetOrderNumber(lastOrderNum + 1);
    };

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenError(false);
    };

    const handleSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
    };

    useEffect(() => {
        handleFetchAssets();
        fetchAssetType();
        fetchFinancials();
    }, []); 



    return (
        <Box className="subsection-asse-box-overall">

            <Box>
            <ModifyTable 
                    fieldNames={names} 
                    fieldValues={values} 
                    handleDelete={colIndex => handleDeleteAsset(colIndex)} 
                    handleEdit={colIndex => handleEditAssert(colIndex)}  
                    setRowIndex={setRowIndex}
                    disableEdit={false}  />
            </Box>

            <Box className="subsection-asse-box-field" bgcolor={color}>

                <Grid container spacing={2}>

                    {['regular'].includes(applicationType) && (
                        <>

                            {applicant.anyCoapplicant && (
                                <Grid item xs={tabSpace}>
                                    <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} className="ml-5 mr-5" >
                                        <Tab style={{ fontSize: 20 }} label="Main Applicant" value={0} />
                                        <Tab style={{ fontSize: 20 }} label="Co-Applicant Only" value={1} />
                                    </Tabs>
                                </Grid>
                            )}

                            <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                                <TextFieldText text='Recent Tax bill for the Residence' largeBox={true} />
                                <DocUploader
                                    setStateValue={value => handleApplicantChange("applicantUrlsRecentTaxBillResidence", value)}
                                    defaultValue={applicant.applicantUrlsRecentTaxBillResidence}
                                    type='optional'
                                    name='Applicant Tax Bill'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                    includeName={true}
                                    categoryName='Asset'
                                    isMultiAdd={true}
                                    multiNum={assetOrderNumber}
                                />
                            </Grid>

                            <Grid item xs={docUploaderSpace} display={tabValue === 0 ? 'block' : 'none'} >
                                <TextFieldText text='Accounts Statement' largeBox={true} />
                                <DocUploader
                                    setStateValue={value => handleApplicantChange("applicantUrlsAccountsStatement", value)}
                                    defaultValue={applicant.applicantUrlsAccountsStatement}
                                    type='optional'
                                    name='Applicant Accounts Statement'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                    includeName={true}
                                    categoryName='Asset'
                                    isMultiAdd={true}
                                    multiNum={assetOrderNumber}
                                />
                            </Grid>

                            <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                                <TextFieldText text='Recent Tax bill for the Residence' largeBox={true} />
                                <DocUploader
                                    setStateValue={value => handleCoapplicantChange("coApplicantUrlsRecentTaxBillResidence", value)}
                                    defaultValue={coapplicant.coApplicantUrlsRecentTaxBillResidence}
                                    type='optional'
                                    name='Co-Applicant Tax Bill'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                    includeName={true}
                                    categoryName='Asset'
                                    isMultiAdd={true}
                                    multiNum={assetOrderNumber}
                                />
                            </Grid>

                            <Grid item xs={docUploaderSpace} display={tabValue === 1 ? 'block' : 'none'} >
                                <TextFieldText text='Accounts Statement' largeBox={true} />
                                <DocUploader
                                    setStateValue={value => handleCoapplicantChange("coApplicantUrlsAccountsStatement", value)}
                                    defaultValue={coapplicant.coApplicantUrlsAccountsStatement}
                                    type='optional'
                                    name='Co-Applicant Accounts Statement'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                    includeName={true}
                                    categoryName='Asset'
                                    isMultiAdd={true}
                                    multiNum={assetOrderNumber}
                                />
                            </Grid>

                        </>
                    )}

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Owner" />
                        <DropdownList
                            options={owners}
                            defaultValue={asset.owner}
                            setStateValue={value => setAsset({ ...asset, owner: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Asset Type" />
                        <DropdownList
                            options={assetType}
                            defaultValue={asset.type}
                            setStateValue={value => setAsset({ ...asset, type: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace}>
                        <TextFieldText text="Asset Market Value" />
                        <NumberFieldMoney
                            defaultValue={asset.value}
                            setStateValue={value => setAsset({ ...asset, value: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} style={{ display: (asset.type === 'Principal Residence' || asset.type === 'Canadian Real Estate') ? 'none' : 'block' }}>
                        <TextFieldText text="Financial Institution Name" />
                        <DropdownList
                            options={institutions}
                            defaultValue={asset.institution}
                            setStateValue={value => setAsset({ ...asset, institution: value })}
                            isClear={isClear}
                        />
                    </Grid>

                    <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                        
                        {!isUpdate && ( 
                            <IconButton onClick={handleAddAssetCheck} >
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Add Asset</span>
                            </IconButton>
                        )}
                       
                        {isUpdate && ( 
                            <IconButton onClick={sendEditAsset} >
                                <AddBox sx={{ fontSize: 45 }} />
                                <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Update Asset</span>
                            </IconButton>
                        )}
                        
                    </Grid>

                    {/* <Grid item xs={itemSpace * 4} className="pb-7" display="flex" alignItems="center" justifyContent="center">
                        <IconButton onClick={handleFetchAssets} >
                            <SearchOutlined sx={{ fontSize: 45 }} />
                            <span className="font-sans text-[20px] font-normal text-[#3C3C3C]" >Fetch Assets</span>
                        </IconButton>
                    </Grid> */}

                </Grid>

            </Box>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openError} autoHideDuration={4000} onClose={handleErrorClose}>
                <Alert
                    onClose={handleErrorClose}
                    severity="warning"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Please fill all required fields before adding
                </Alert>
            </Snackbar>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={openSuccess} autoHideDuration={4000} onClose={handleSuccessClose}>
                <Alert
                    onClose={handleSuccessClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    Added Asset Successfully
                </Alert>
            </Snackbar>

        </Box>
    );
};