import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCard from '../../../components/smallComponents/preAppCard/whiteCard'
import './Loan.css'
import { Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useApplication } from '../../../contexts/ApplicationContext';
import { useNavigate } from 'react-router-dom';
/**
 * Renders the Loan component.
 * 
 * @returns {JSX.Element} The rendered Loan component.
 */
function Loan() {

    const { setSelectedComponent } = useApplication();
    const navigate = useNavigate();
    const list = [
        'No need to provide asset or income verification',
        'Up to $200,000 CAD available ($100,000 each from two banks, B2B Bank and Manulife Bank)',
        'Fast Approval process'
    ];

    const loanString = 'Loan Transfer Application';
    const shortString = "Best for investors with ample cash flow, each applicant can apply for up to $800,000 CAD";

    const handleApply = (url) => {
        const newTab = window.open(`/application/${url}/general`, '_blank');
        if (newTab) newTab.focus(); // Focus the new tab
        setSelectedComponent('Home'); // Reset the selected component in the original tab
    };
    const handleBackyClick = (e) => {
        e.preventDefault();
        setSelectedComponent('LoanBacks');
        navigate('loan');
        
    }
    return (
        <Box>
            <HeaderBox text='Which type of Loans do you want to apply for today?' />
            <div className="pre-application-page1">
                <WhiteCard
                    title="Quick Loan"
                    url="Quick"
                    list={list}
                    banner={true}
                    openInNewTab={true}
                    onApply={handleApply}
                />
                <div className='small-Box'>
                    <WhiteCard
                        title="Regular Loan"
                        description={shortString}
                        url="BeforeAppRegular"
                        size="small"
                        openInNewTab={true}
                        onApply={handleApply}
                        isDisabled={true} 
                    />
                    <WhiteCard
                        title="Loan Transfer"
                        description={loanString}
                        url="LoanTransfer"
                        size="small"
                        openInNewTab={true}
                        onApply={handleApply}
                        isDisabled={true} 
                        
                    />
                </div>
            </div>
            <div className='button-Area'>
            <NavLink 
                onClick={handleBackyClick}
             >
             <button className="bigButton">Back</button>
             </NavLink>
            </div>
        </Box>
    );
}
export default Loan;