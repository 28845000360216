import { Box, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import { discloseQuestions, disclosureVariables } from "../../../../../constants/lists";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import { purposeOfInvestment, sourceOfFunds } from "../../../../../constants/lists";
import ConfirmDialog from "../../../../../components/smallComponents/alert/ConfirmDialog";
import { useState } from "react";
/**
 * @param {boolean} enable - Enable the display of the applicant disclosure section
 * @returns - Applicant disclosure section for the disclosure page
 */


const AppDisclosureSection = ({ enable }) => {

    const dispatch = useDispatch();
    const applicant = useSelector(state => state.applicantInfo);

    const { clickNextButton } = useApplication();

    const applicationType = useParams().applicationType;

    const questionSet = ['regular', 'quick'].includes(applicationType) ? discloseQuestions : [discloseQuestions[0]];
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleChange = (index, value) => {
        if(value === 'Yes') {
            setIsDialogOpen(true);
        } else {
            const attribute = disclosureVariables[index]
            dispatch(setApplicantInfo({ attribute, value }))
        }
    };

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const itemSpace = 6;
    const pageName = 'disclosure';

    return (
        <>
        <Box display={enable ? 'block' : 'none'} >

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom' >
                {questionSet.map((question, index) => {
                    const isLast = index === questionSet.length - 1;
                    return (
                        <div key={index} className={`flex flex-row items-center m-3 p-1 leading-8 ${isLast ? '' : 'border-b-2 border-gray-400'}`}>
                            <div className="flex basis-11/12 mx-2 my-2 text-2xl">{index + 1}. {question}</div>
                            <div className="flex justify-items-start grid-cols-1 basis-1/12 my-2 h-full place-items-center">
                                <label className="flex items-center font-semibold text-2xl">
                                    <input
                                        type="radio"
                                        name={`app_disclosure_${index}`}
                                        value="Yes"
                                        className="mx-2"
                                        checked={applicant[disclosureVariables[index]] === 'Yes'}
                                        onChange={() => handleChange(index, 'Yes')}
                                    />
                                    Yes
                                </label>
                                <label className="flex items-center font-semibold text-2xl">
                                    <input
                                        type="radio"
                                        name={`app_disclosure_${index}`}
                                        value="No"
                                        className="mx-2"
                                        checked={applicant[disclosureVariables[index]] === 'No'}
                                        onChange={() => handleChange(index, 'No')}
                                    />
                                    No
                                </label>
                            </div>
                        </div>
                    )
                })}
            </Box>

            <Box className='mt-12 bg-mainfield-box rounded-2xl shadow-custom'>

                {['nonreg', 'rrsp', 'tfsa'].includes(applicationType) && (
                    <Grid container spacing={2}>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='What is the purpose of investment?' largeBox={true} />
                            <DropdownList
                                options={purposeOfInvestment}
                                setStateValue={value => handleApplicantChange("purposeOfInvestment", value)}
                                defaultValue={applicant.purposeOfInvestment}
                                type='mandatory'
                                name='Purpose of Investment'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={itemSpace} >
                            <TextFieldText text='Describe the source of funds used for this application' largeBox={true} />
                            <DropdownList
                                options={sourceOfFunds}
                                setStateValue={value => handleApplicantChange("sourceOfFunds", value)}
                                defaultValue={applicant.sourceOfFunds}
                                type='mandatory'
                                name='Source of Funds'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

        </Box>
        <ConfirmDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            message={"Application cannot be proceeded with this answer. Please review the question."}
            title="Notice" 
        />
        </>
    );
};

export default AppDisclosureSection;