import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';


/**
 * @returns - Redirects user to the logout endpoint
 */


export default function LogoutPage() {

    const { instance } = useMsal();

    useEffect(() => {
        async function Logout() {
            try {
                window.localStorage.clear();
                await instance.logoutRedirect();
            } catch (error) {
                console.error(error);
            }
        };

        Logout();
    }, []);

    return (
        <span style={{ fontSize: 24, display: 'flex', justifyContent: "center" }} >Logging out...</span>
    );
};