import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { allAppStatuses } from '../../../constants/lists';
import { useEffect } from 'react';
import { useEffectDebugger } from '../../../utilities/useEffectDebugger';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function ApplicationFilter({onStatusChange}) {
  const theme = useTheme();
  const [selectedStatus, setSelectedStatus] = React.useState([
    'Editing',
    'Submitted',
    'Advisor Reviewed',
    'Operator Processing']);

       // Trigger initial status change when the component mounts
   useEffect(() => {
        onStatusChange(selectedStatus); // Send the default status to the parent
    }, [selectedStatus, onStatusChange]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    onStatusChange(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Application Status</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          multiple
          value={selectedStatus}
          onChange={handleChange}
          input={<OutlinedInput label="Application Status" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
        {allAppStatuses.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={selectedStatus.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
