import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { setApplicationInfo } from "../../../../../reducers/applicationReducer";
import { resetCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import { useParams } from 'react-router-dom';
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../../components/smallComponents/radioGroup/RadioGroup";
import RadioGroupBoolean from "../../../../../components/smallComponents/radioGroupBoolean/RadioGroupBoolean";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import dayjs from "dayjs";
import Gender from "../../../../../components/enumTypes/Gender";
import { languages, ResidentialStatus, maritalStatus, answerAttributes, investorOptions } from "../../../../../constants/lists";
import "./style/MainApplicantSection.css";
import React from "react";
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from "@mui/material/Checkbox";
import FormGroup from '@mui/material/FormGroup';
import { updateApp } from "../../../../../services/appFieldUpdateService";



/**
 * @param {boolean} enable - Enable the main applicant section to be displayed
 * @returns - MainApplicantSection component for General main field component
 */


export default function MainApplicantSection({ enable, countries }) {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const application = useSelector((state) => state.applicationInfo);
    const { handleMainInvestorScoreChange, clickNextButton } = useApplication();

    const applicationType = useParams().applicationType;
    const [helperText, setHelperText] = React.useState('');
    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const hasCoApplicantOption = ['regular', 'quick', 'transfer', 'nonreg', 'resp'].includes(applicationType);
    const hasSpouseOption = ['rrsp'].includes(applicationType);

    const sinRequirement = isLoanType ? 'sinforloan' : 'sin';

    // Question index and score range for the pre-fill question
    const questionIndex = 0;
    const questionScore = [1, 2, 5, 10, 20];

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantDOBChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
        // Pre-fill the age group question and calculate investor profile score based on the main applicant's age
        const mainApplicantAge = dayjs().diff(dayjs(value), 'year');
        if (mainApplicantAge > 71) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [1, investorOptions[0][0], 1] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[0]);
        }
        else if (mainApplicantAge >= 65) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [2, investorOptions[0][1], 2] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[1]);
        }
        else if (mainApplicantAge >= 55) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [3, investorOptions[0][2], 5] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[2]);
        }
        else if (mainApplicantAge >= 41) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [4, investorOptions[0][3], 10] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[3]);
        }
        else if (mainApplicantAge >= 18) {
            dispatch(setApplicantInfo({ attribute: answerAttributes[0], value: [5, investorOptions[0][4], 20] }));
            handleMainInvestorScoreChange(questionIndex, questionScore[4]);
        }
    };

    const handleApplicantCountryChange = (attribute, value) => {
        // Clear resident of Canada since if main applicant selects Canada as country of birth
        if (value === "Canada") {
            dispatch(setApplicantInfo({ attribute: 'residentOfCanadaSince', value: "" }))
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const handleApplicantHasCoAppOrSpouseChange = async(attribute, value) => {
        console.log(value)
        // Clear co-applicant information if main applicant selects no for co-applicant question or spouse question
        if (!value) {
            dispatch(resetCoapplicantInfo());
            setHelperText('');
        } else {
            setHelperText('Additional information is required in co-applicant section.');
        }
        dispatch(setApplicantInfo({ attribute, value }))
        dispatch(setApplicationInfo({ attribute: 'hasCoApplicant', value: value }));
        updateApp(application.applicationID, { 'hasCoApplicant': value });
    };

    const itemSpace = 3;

    const genders = [Gender.MALE, Gender.FEMALE];
    const pageName = 'general';

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-main-box-field">

                <Grid container alignItems={"flex-start"}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='First Name' />
                        <TextField
                            setStateValue={value => handleApplicantChange("firstname", value)}
                            defaultValue={applicant.firstname}
                            type='name'
                            name='Applicant First Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Last Name' />
                        <TextField
                            setStateValue={value => handleApplicantChange("lastname", value)}
                            defaultValue={applicant.lastname}
                            type='name'
                            name='Applicant Last Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Preferred Name' />
                        <TextField
                            setStateValue={value => handleApplicantChange("preferredName", value)}
                            defaultValue={applicant.preferredName}
                            type='optional'
                            name='Applicant Preferred Name'
                            pageName={pageName}
                            placeholder="Optional"
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Gender' />
                        <DropdownList
                            options={genders}
                            setStateValue={value => handleApplicantChange("gender", value)}
                            defaultValue={applicant.gender}
                            type='mandatory'
                            name='Applicant Gender'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Language Preference' />
                        <DropdownList
                            options={languages}
                            setStateValue={value => handleApplicantChange("languagePreference", value)}
                            defaultValue={applicant.languagePreference}
                            type='mandatory'
                            name='Applicant Language Preference'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Social Insurance Number' />
                        <TextField
                            setStateValue={value => handleApplicantChange("socialInsuranceNumber", value)}
                            defaultValue={applicant.socialInsuranceNumber}
                            type={sinRequirement}
                            name='Applicant SIN'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Date of Birth' />
                        <DateField
                            setStateValue={value => handleApplicantDOBChange("dateOfBirth", value)}
                            defaultValue={applicant.dateOfBirth}
                            minYear={isLoanType ? 24 : 18}
                            type='date'
                            name='Applicant DOB'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    {isLoanType && (
                        <>
                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Residential Status' />
                                <DropdownList
                                    options={ResidentialStatus}
                                    setStateValue={value => handleApplicantChange("residentialStatus", value)}
                                    defaultValue={applicant.residentialStatus}
                                    type='mandatory'
                                    name='Applicant Residential Status'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Citizenship' />
                                {countries?.length > 0 && <DropdownList
                                    options={countries}
                                    setStateValue={value => handleApplicantChange("citizenship", value)}
                                    defaultValue={applicant.citizenship}
                                    type='mandatory'
                                    name='Applicant Citizenship'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />}
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Marital Status' />
                                <DropdownList
                                    options={maritalStatus}
                                    setStateValue={value => handleApplicantChange("maritalStatus", value)}
                                    defaultValue={applicant.maritalStatus}
                                    type='mandatory'
                                    name='Applicant Marital Status'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Place of Birth (Country)' />
                                { countries?.length > 0 && <DropdownList
                                    options={countries}
                                    setStateValue={value => handleApplicantCountryChange("countryOfBirth", value)}
                                    defaultValue={applicant.countryOfBirth}
                                    type='mandatory'
                                    name='Applicant Place of Birth (Country)'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />}
                            </Grid>

                            <Grid item xs={itemSpace} >
                                <TextFieldText text='Place of Birth (Province)' />
                                <TextField
                                    setStateValue={value => handleApplicantChange("provinceOfBirth", value)}
                                    defaultValue={applicant.provinceOfBirth}
                                    type='mandatory'
                                    name='Applicant Place of Birth (Province)'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>

                            <Grid item xs={itemSpace} display={applicant.countryOfBirth !== "Canada" ? 'block' : 'none'} >
                                <TextFieldText text='Resident of Canada Since' />
                                <DateField
                                    setStateValue={value => handleApplicantChange("residentOfCanadaSince", value)}
                                    defaultValue={applicant.residentOfCanadaSince}
                                    type={applicant.countryOfBirth !== "Canada" ? 'date' : 'optional'}
                                    name='Applicant Resident of Canada Since'
                                    pageName={pageName}
                                    buttonCheck={clickNextButton[pageName]}
                                />
                            </Grid>
                        </>
                    )}

                    {hasCoApplicantOption && (
                        <Grid item size={itemSpace * 3} display="flex" justifyContent="center" alignItems="center">
                            <FormGroup>
                                <FormControlLabel
                                    className="form-label"
                                    label= <Typography variant="h5">Is there a co-applicant in this application?</Typography>
                                    control={<Checkbox checked={applicant.anyCoapplicant }/>}
                                    onChange={e => handleApplicantHasCoAppOrSpouseChange("anyCoapplicant", e.target.checked)}
                                />
                                <FormHelperText className="form-helper"><Typography variant="h6">{helperText}</Typography></FormHelperText>
                            </FormGroup>
                        </Grid>
                    )}

                    {hasSpouseOption && (
                        <Grid item size={itemSpace * 3} >
                            <TextFieldText text='Is this a Spousal Retirement Savings Plan (Spousal RSP)?' largeBox={true} />
                            <RadioGroupBoolean
                                setStateValue={value => handleApplicantHasCoAppOrSpouseChange("spousalRSP", value)}
                                defaultValue={applicant.spousalRSP}
                                type='mandatory'
                                name='Applicant Has Spouse'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>
                    )}

                </Grid>

            </Box>

        </Box>
    );
};