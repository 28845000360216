import { Box, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import "./style/RadioGroup.css";


/**
 * @param {string[]} options - The options to be displayed in the radio group
 * @param {function} setStateValue - The function to be called when an option is selected
 * @param {string} defaultValue - The default value of the radio group
 * @param {boolean} disabled - A boolean value that determines if the radio group is disabled
 * @returns - A radio group component that only allows one option to be selected at a time 
 */


export default function RadioGroupComponent({ options, setStateValue, defaultValue, disabled, isClear = false, needIndex = false }) {

    const [selectedValue, setSelectedValue] = useState(defaultValue);

    useEffect(() => {
        if (isClear) {
            setSelectedValue('')
        }
    }, [isClear]);

    const handleRadioChange = (event) => {
        const userSelectedValue = event.target.value;
        setSelectedValue(userSelectedValue);

        if (needIndex) {
            const userSelectedIndex = options.indexOf(userSelectedValue);
            setStateValue(userSelectedValue, userSelectedIndex);
        }
        else {
            setStateValue(userSelectedValue);
        }
    };

    const appendStr = [
        ' [ +1 ]',
        ' [ +2 ]',
        ' [ +5 ]',
        ' [ +10 ]',
        ' [ +20 ]',
    ]

    return (
        <Box className="radio-box">
            <FormControl>
                <RadioGroup value={selectedValue} onChange={handleRadioChange}>
                    {
                        options.map((option, index) => (
                            <FormControlLabel className="radio-option" key={index} value={option}
                                control={<Radio className="radio-option-button" />}
                                label={<Typography className="radio-option-text" sx={{fontSize: 22}}>{option}{appendStr[index]}</Typography>}
                                disabled={disabled}
                            />
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </Box>
    );
};