import { Box, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import './style/Topbar.css';
import { useSelector } from "react-redux";
import { useApplication } from '../../../contexts/ApplicationContext';

/**
 * @param {object[]} titles - list of titles for the topbar
 * @param {object[]} menuOptions - list of menu options to be displayed under the menu icon
 * @returns - A topbar component 
 */


export default function Topbar({ titles, menuOptions }) {
    const userName = useSelector(state => state.userInfo.userName);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const { setSelectedComponent } = useApplication();
    const handleClick = (event) => {
       
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (menuOption) => {
        console.log("Selected Menu Option:", menuOption); // Here you can access the clicked menu option
        if(menuOption.name === 'Home'){
            setSelectedComponent('myAccountBack');
        }
        setSelectedMenu(menuOption);  // Store the selected option if needed
        handleClose();  // Close the menu after selection
    };

    return (
        <Box className='topbar-box'>
            <Box className="logo-container">
                <img className="logo" src='/AiF_Logo.png' alt="Logo" />
            </Box>

            <Box className="title-container">
                {titles.map((title, index) => (
                    <Typography className="title" key={index} component={NavLink} to={title.link}>{title.name}</Typography>))}
            </Box>

            <Box className="username-container">
                <Typography className="username">Hi, {userName}</Typography>
            </Box>

            <Box className="menu-container">
                <IconButton
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon className="icon" />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {menuOptions.map((menuOption, index) => (
                        <MenuItem 
                            key={index} 
                            component={NavLink} 
                            to={menuOption.link}
                            onClick={() => handleMenuItemClick(menuOption)}  // Trigger when menu item is clicked
                            >
                                {menuOption.name}
                        </MenuItem>))}
                </Menu>
            </Box>
        </Box>
    )
};