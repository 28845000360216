
import { Box } from "@mui/material";
import "./style/headerBox.css";
/**
 * HeaderBox component displays a box with a header text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.text - The header text to be displayed.
 * @returns {JSX.Element} The rendered HeaderBox component.
 */
export default function HeaderBox({ text }) {
    return (
        <Box  className = "box-Header">
            <div>{text}</div>
        </Box>
    );
}