import { ListItemButton, ListItemText, Snackbar, Alert } from "@mui/material";
import { useApplication } from "../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import { useState } from "react";


/**
 * @param {string} subOptionName - Name of the sub option
 * @param {number} subOptionValue - Value of the sub option
 * @returns - Sub option component of the sidebar
 * @description - The background color of the sub option component changes based on the current page
 */


export default function SubOption({ subOptionName, subOptionValue }) {

    const applicationType = useParams().applicationType;

    const pagesConfig = {
        regular: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'financial',
            'beneficiary',
            'annuitant',
            'owner',
            'profile',
            'verification',
            'report',
            'special'
        ],
        quick: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'financial',
            'beneficiary',
            'annuitant',
            'owner',
            'profile',
            'report',
            'special'
        ],
        transfer: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'beneficiary',
            'annuitant',
            'owner',
            'profile',
            'verification',
            'special'
        ],
        nonreg: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'beneficiary',
            'annuitant',
            'owner',
            'profile',
            'verification',
            'special'
        ],
        resp: [
            'general',
            'contact',
            'identification',
            'employment',
            'product',
            'source',
            'option',
            'beneficiary',
            'annuitant',
            'profile',
            'verification',
            'special'
        ],
        rrsp: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'beneficiary',
            'profile',
            'special'
        ],
        tfsa: [
            'general',
            'contact',
            'identification',
            'employment',
            'disclosure',
            'source',
            'option',
            'beneficiary',
            'annuitant',
            'profile',
            'verification',
            'special'
        ],
        // Define other types
    };

    const pageNames = pagesConfig[applicationType];

    const [open, setOpen] = useState(false);
    const [hasError, setHasError] = useState(false);

    const { progress, page, setPage, errorMessages } = useApplication();

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleClick = () => {

        // Check error messages on the cuurent page if user tries to navigate to future pages
        // Senario 1: User had been to the future page, but there are errors on the current page
        if (subOptionValue > page && errorMessages[pageNames[page]].length > 0) {
            setHasError(true);
            setOpen(true);
            return;
        }

        // Prevent user from jumping to future pages that has not been went through by button navigation
        // Senario 2: User had never been to the future page
        if (subOptionValue > progress) {
            setHasError(false);
            setOpen(true);
            return;
        }

        setPage(subOptionValue);
    };

    return (
        <>
            <ListItemButton style={{ padding: 0, marginTop: 0 }} defaultValue={subOptionValue} onClick={handleClick}>
                <ListItemText
                    className={
                        page === subOptionValue
                            ? "bg-cofield-box w-[425.08px] h-[77.88px] text-center hover:bg-main-light"
                            : "bg-suboption-text w-[425.08px] h-[77.88px] text-center hover:bg-main-light"
                    }
                    primary={
                        <span className="text-black font-sans font-normal text-2xl leading-[77.88px]">
                            {subOptionName}
                        </span>
                    }
                />
            </ListItemButton>

            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={open} autoHideDuration={4000} onClose={handleClose}>
                <Alert
                    onClose={handleClose}
                    severity={hasError ? "error" : "warning"}
                    variant="filled"
                    sx={{ width: '100%', fontSize: 20 }}
                >
                    {hasError ? 'Please check error messages' : 'You must complete the previous sections before proceeding to this section'}
                </Alert>
            </Snackbar>
        </>
    );
};