import React from 'react';
import Badge from '@mui/material/Badge';
import './Badge.css'
import { useApplication } from '../../../contexts/ApplicationContext';

const BadgeComponent = ({ badgeContent, color, children }) => {
    const { selectedComponent } = useApplication();
    return (
        <Badge badgeContent={badgeContent} className='badge-style' >
            {children}
        </Badge>

    );
};

export default BadgeComponent;