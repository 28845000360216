import { useEffect, useState } from 'react'
import ApplicationTableItem from '../../smallComponents/account/ApplicationTableItem';
import { useSelector } from 'react-redux';
import { fetchApplications } from '../../../services/fetchInfoService';
import ApplicationFilter from '../../smallComponents/account/ApplicationFilter';
import { useCallback } from 'react';
import { updateApp } from '../../../services/appFieldUpdateService';
import { USER_ROLE } from '../../../constants/UserRoleMap';

/**
 * @param {object} data - a data object that contains id, firstSubmittedDate, approvedDate, supportingAdvisorName, applicationStatus
 */

const ApplicationTable = () => {    
    const [applications, setApplications] = useState([]);
    const [newApplicationsFetched, setNewApplicationsFetched] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const userInfo = useSelector((state) => state.userInfo);
    const itemsPerPage = 50;
    const [filterStatus, setFilterStatus] = useState("");


    const handleStatusChange = useCallback((newStatus) => {
            setFilterStatus(newStatus);
            setPage(0);
    }, [filterStatus]);

    const fetchApps = async () => {
        try {
            const appsRes = await fetchApplications(userInfo.userID, filterStatus, page);
            setNewApplicationsFetched(appsRes);
            setHasMore(appsRes.length === itemsPerPage);
            // Clear applications if page is reset to 1 (new filter applied)
            
            setApplications(prevApps => 
                page === 0 
                  ? appsRes 
                  : [
                      ...prevApps,
                      ...appsRes.filter(app => !prevApps.some(prevApp => prevApp.applicationID === app.applicationID))
                    ]
              );

        } catch (error) {
            console.error(`Fetch Applications error: ${error}`);
        }
    };

            
    const checkAppStatus = (applicationID, allCompleted) => { 
        if(allCompleted){
            updateApp(applicationID, {'applicationStatus': 'Done'});
            applications.find(app => app.applicationID === applicationID).applicationStatus = "Done";
        } else{
            const item = applications.find(app => app.applicationID === applicationID);
            // If application status is already Done, when case status changes back, change applicationStatus back to 'Operator Processing'
            if(item.applicationStatus === 'Done'){
                updateApp(applicationID, {'applicationStatus': 'Operator Processing'});
                item.applicationStatus = 'Operator Processing';
                setApplications([...applications]);
            }
        } 
    }

    useEffect(() => {
        // Only fetch data if filterStatus is defined
        if (filterStatus) {
            fetchApps();
        }
    }, [page, filterStatus]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
        setHasMore(newApplicationsFetched.length === itemsPerPage);
      };    

    const userIsOperator = userInfo.userInfos.some(({userType}) => userType === USER_ROLE.OPERATOR);
    return (
        <>
            <div>
                <ApplicationFilter onStatusChange={handleStatusChange}/>
            </div>
            <div>
                <table className="w-full">
                    <thead className="border-b-2 border-bg-gray-300 my-4 h-16">
                        <tr >
                            <th className="text-sm text-gray-600" style={{ width: '2%' }}>Row</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Application ID</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Request Type</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Fund Type</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Customer Name</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Serving Advisor</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Supporting Advisor</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Submit Date</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Approved Date</th>
                            <th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Status</th>
                            {userIsOperator && (<th className="text-sm text-gray-600" style={{ width: '12.5%' }}>Days Since Apply</th>)}
                            <th className="w-1/6" style={{ width: '12.5%' }}></th>
                        </tr>
                    </thead>
                    <tbody className="text-sm">
                        {applications.length>0? (applications.map((item, index) => (
                            <ApplicationTableItem key={item.applicationID} userInfo={userInfo} item={item} index={index} checkAppStatus={checkAppStatus}/>
                        ))): (
                            <tr>
                            <td colSpan="9" style={{"textAlign": "center"}}>No application found under current search criteria.</td>
                        </tr>
                        )}
                    </tbody>
                </table>

                {hasMore && (
                    <div className="flex justify-center">
                        <button className="bg-slate-200 p-2 mt-4 rounded-lg" onClick={loadMore}>Load More...</button>
                    </div>
                )}
            </div>
        </>
    );
}

export default ApplicationTable