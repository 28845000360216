export default class LoanRatio {

    static ONEHUNDRED = "100%";
    static ONEFORONE = "1 for 1";
    static TWOFORONE = "2 for 1";
    static THREEFORONE = "3 for 1";

    constructor(ratioString) {
        this.ratioString = ratioString;
    }

    toString() {
        return this.ratioString;
    }
};