import React from 'react';
import HeaderBox from '../../../components/smallComponents/headerBox/headerBox';
import WhiteCardHome from '../../../components/smallComponents/preAppCard/whiteCardHome'
import './Home.css'
import { Box } from '@mui/material';
import { useApplication } from '../../../contexts/ApplicationContext';
import { NavLink, useNavigate } from 'react-router-dom';
/**
 * Renders the Home component.
 *
 * @returns {JSX.Element} The rendered Home component.
 */
function RequestOpt() {
    const { setSelectedComponent } = useApplication();
    const navigate = useNavigate();
    const handleBackyClick = (e) => {
        e.preventDefault();
        setSelectedComponent('RequestOptBack');
        navigate('requestOpt');
        
    }

    return (
        <Box>
            <HeaderBox text='Please Select Your Request Option' />
            <div className="pre-application-page">
                <WhiteCardHome
                    size='small'
                    title="New Application"
                    url="NewApplicationHome"
                    buttonName="New Application"
                />
                
                <WhiteCardHome
                    size='small'
                    title="Other Requests"
                    url="OwnFund"
                    buttonName="Other Requests"
                    isDisabled={true} 
                />
            </div>
            <div className='button-Area'>
            <NavLink 
                onClick={handleBackyClick}
             >
             <button className="bigButton">Back</button>
             </NavLink>
            </div>

        </Box>
    );
}

export default RequestOpt;