import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { setCoapplicantInfo } from "../../../../../reducers/coapplicantReducer";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import RadioGroup from "../../../../../components/smallComponents/radioGroup/RadioGroup";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import DropdownList from "../../../../../components/smallComponents/dropdownList/DropdownList";
import Gender from "../../../../../components/enumTypes/Gender";
import { languages } from "../../../../../constants/lists";
import "./style/CoApplicantSection.css";


/**
 * @param {boolean} enable - Enable the SpouseSection component to be displayed
 * @returns - SpouseSection component for General main field component
 */


export default function SpouseSection({ enable }) {

    const dispatch = useDispatch();
    const applicant = useSelector((state) => state.applicantInfo);
    const coapplicant = useSelector((state) => state.coapplicantInfo);

    const { clickNextButton } = useApplication();

    const handleSpouseChange = (attribute, value) => {
        dispatch(setCoapplicantInfo({ attribute, value }))
    };

    const itemSpace = 3;

    const genders = [Gender.MALE, Gender.FEMALE];
    const pageName = 'general';

    const hasSpouse = applicant.spousalRSP;

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-co-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='First Name' />
                        <TextField
                            setStateValue={value => handleSpouseChange("firstname", value)}
                            defaultValue={coapplicant.firstname}
                            type={hasSpouse ? 'name' : 'optional'}
                            name='Spouse First Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Last Name' />
                        <TextField
                            setStateValue={value => handleSpouseChange("lastname", value)}
                            defaultValue={coapplicant.lastname}
                            type={hasSpouse ? 'name' : 'optional'}
                            name='Spouse Last Name'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Preferred Name' />
                        <TextField
                            setStateValue={value => handleSpouseChange("preferredName", value)}
                            defaultValue={coapplicant.preferredName}
                            type='optional'
                            name='Spouse Preferred Name'
                            pageName={pageName}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Gender' />
                        <RadioGroup
                            options={genders}
                            setStateValue={value => handleSpouseChange("gender", value)}
                            defaultValue={coapplicant.gender}
                            type={hasSpouse ? 'mandatory' : 'optional'}
                            name='Spouse Gender'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Language Preference' />
                        <DropdownList
                            options={languages}
                            setStateValue={value => handleSpouseChange("languagePreference", value)}
                            defaultValue={coapplicant.languagePreference}
                            type={hasSpouse ? 'mandatory' : 'optional'}
                            name='Spouse Language Preference'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Social Insurance Number' />
                        <TextField
                            setStateValue={value => handleSpouseChange("socialInsuranceNumber", value)}
                            defaultValue={coapplicant.socialInsuranceNumber}
                            type={hasSpouse ? 'sin' : 'optional'}
                            name='Spouse SIN'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Date of Birth' />
                        <DateField
                            setStateValue={value => handleSpouseChange("dateOfBirth", value)}
                            defaultValue={coapplicant.dateOfBirth}
                            minYear={18}
                            type={hasSpouse ? 'date' : 'optional'}
                            name='Spouse DOB'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

        </Box>
    );
};