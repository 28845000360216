import { Box, FormControlLabel, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setApplicantInfo } from "../../../../../reducers/applicantReducer";
import { useApplication } from "../../../../../contexts/ApplicationContext";
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import AddressAutoComplete from "../../../../../components/smallComponents/addressAutoComplete/addressAutoComplete";
import AddressSubsection from "./subsection/AddressSubsection";
import TextFieldText from "../../../../../components/smallComponents/text/textFieldText/TextFieldText";
import PhoneField from "../../../../../components/smallComponents/phoneField/PhoneField";
import TextField from "../../../../../components/smallComponents/textField/TextField";
import Checkbox from "@mui/material/Checkbox";
import DateField from "../../../../../components/smallComponents/dateField/DateField";
import { currAddr, prevAddr } from "../../../../../constants/lists";
import "./style/MainAppContactSection.css"
import { twoPhoneNumbersVerified } from "../../../../../utilities/twoPhoneNumbersVerification";
import { usePhoneTypes } from "../../../../../hooks/usePhoneTypes";
import { useState } from "react";
/**
 * @param {boolean} enable - Enable the MainAppContactSection component to be displayed
 * @returns - The main applicant contact info section of the contact info field component
 */
export default function MainAppContactSection({ enable }) {

    const applicant = useSelector((state) => state.applicantInfo);
    const { workPhoneType, cellPhoneType, homePhoneType } = usePhoneTypes(applicant);

    const livingMonthKey = 24;

    const {
        cantFindCurrAddr, setCantFindCurrAddr,
        cantFindPrevAddr, setCantFindPrevAddr,
        handleErrorMessage, clickNextButton } = useApplication();

    const dispatch = useDispatch();

    const applicationType = useParams().applicationType;

    const isLoanType = ['regular', 'quick', 'transfer'].includes(applicationType);
    const lessThanRequiredMonths = applicant.livingSince !== '' && dayjs().diff(dayjs(applicant.livingSince), 'month') < livingMonthKey;

    const handlePhoneChange = (attribute, value) => {
        let twoDifferentPhones = twoPhoneNumbersVerified(attribute, value, applicant);
        if(twoDifferentPhones){
            handleErrorMessage(pageName, 'remove', 'Two different phone numbers are required for applicant')
            dispatch(setApplicantInfo({ attribute, value }))
        } else {
            handleErrorMessage(pageName, 'add', 'Two different phone numbers are required for applicant')
        }
    }

    const handleApplicantChange = (attribute, value) => {
        dispatch(setApplicantInfo({ attribute, value }))
    };

    function handleCantFindCurrAddrChange(value) {
        setCantFindCurrAddr(value);
        handleAutoAddress();
        if (value) {
            handleErrorMessage(pageName, 'add', `Applicant Current Address is mandatory`)
        }
    };

    function handleCantFindPrevAddrChange(value) {
        setCantFindPrevAddr(value);
        handleAutoAddressPrev();
        if (value) {
            handleErrorMessage(pageName, 'add', `Applicant Previous Address is mandatory`)
        }
    };

    const handleAutoAddress = (addressObj) => {
        if (addressObj) {
            for (let key in addressObj) {
                dispatch(setApplicantInfo({ attribute: key, value: addressObj[key] }));
            }
            setCantFindCurrAddr(false);
            handleErrorMessage(pageName, 'remove', `Applicant Current Address is mandatory`)
        } else {
            handleErrorMessage(pageName, 'remove', "Applicant Current Address couldn't be retrieved please fill the address field manually")
            dispatch(setApplicantInfo({ attribute: 'streetNumber', value: applicant.streetNumber }));
            dispatch(setApplicantInfo({ attribute: 'streetName', value: applicant.streetName }));
            dispatch(setApplicantInfo({ attribute: 'city', value: applicant.city }));
            dispatch(setApplicantInfo({ attribute: 'province', value: applicant.province }));
            dispatch(setApplicantInfo({ attribute: 'postalCode', value: applicant.postalCode }));
            dispatch(setApplicantInfo({ attribute: 'unit', value: applicant.unit === null? "" :  applicant.unit}));
            handleErrorMessage(pageName, 'remove', `Applicant Current Address is mandatory`)
        }
    };

    const handleAutoAddressPrev = (addressObj) => {
        if (addressObj) {
            for (let key in addressObj) {
                let keyPrev = "prev" + key.charAt(0).toUpperCase() + key.slice(1);
                dispatch(setApplicantInfo({ attribute: keyPrev, value: addressObj[key] }));
            }
            setCantFindPrevAddr(false);
            handleErrorMessage(pageName, 'remove', `Applicant Previous Address is mandatory`)
        }
        else {
            handleErrorMessage(pageName, 'remove', "Applicant Previous Address couldn't be retrieved please fill the address field manually")
            dispatch(setApplicantInfo({ attribute: 'prevStreetNumber', value: applicant.prevStreetNumber }));
            dispatch(setApplicantInfo({ attribute: 'prevStreetName', value: applicant.prevStreetName }));
            dispatch(setApplicantInfo({ attribute: 'prevCity', value: applicant.prevCity }));
            dispatch(setApplicantInfo({ attribute: 'prevProvince', value: applicant.prevProvince }));
            dispatch(setApplicantInfo({ attribute: 'prevPostalCode', value: applicant.prevPostalCode }));
            handleErrorMessage(pageName, 'remove', `Applicant Previous Address is mandatory`)
        }
    };

    const handleApplicantCurrLivingChange = (attribute, value) => {
        // If the main applicant has lived at the current address for more than 24 months, clear the previous address
        if (dayjs().diff(dayjs(value), 'month') > livingMonthKey) {
            for (let i = 0; i < prevAddr.length; i++) {
                handleApplicantChange(prevAddr[i], "");
            }
            handleApplicantChange("prevLivingSince", "");
            handleErrorMessage(pageName, 'remove', `Applicant Previous Address is mandatory`)
        }
        dispatch(setApplicantInfo({ attribute, value }))
    };

    const currAddress = `${applicant.streetNumber} ${applicant.streetName}, ${applicant.city} ${applicant.province} ${applicant.postalCode}` === ' ,   '? '': `${applicant.streetNumber} ${applicant.streetName}, ${applicant.city} ${applicant.province} ${applicant.postalCode}`;
    const prevAddress = `${applicant.prevStreetNumber} ${applicant.prevStreetName}, ${applicant.prevCity} ${applicant.prevProvince} ${applicant.prevPostalCode}` === ' ,   '? '': `${applicant.prevStreetNumber} ${applicant.prevStreetName}, ${applicant.prevCity} ${applicant.prevProvince} ${applicant.prevPostalCode}`;

    const itemSpace = 6;
    const specialItemSpace = 12;

    const manualInputQuestion = "Can't find your address?";
    const applicantType = 'Applicant';
    const pageName = 'contact';

    return (
        <Box display={enable ? 'block' : 'none'} >

            <Box className="section-main-box-field">

                <Grid container spacing={2}>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Email' />
                        <TextField
                            setStateValue={value => handleApplicantChange("email", value)}
                            defaultValue={applicant.email}
                            type='email'
                            name='Applicant Email'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                            largeBox={true}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Home Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("homePhone", value)}
                            defaultValue={applicant.homePhone}
                            type='phone'
                            name='Applicant Home Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Work Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("workPhone", value)}
                            defaultValue={applicant.workPhone}
                            type='phone'
                            name='Applicant Work Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                    <Grid item xs={itemSpace} >
                        <TextFieldText text='Cell Phone' />
                        <PhoneField
                            setStateValue={value => handlePhoneChange("cellPhone", value)}
                            defaultValue={applicant.cellPhone}
                            type='optional'
                            name='Applicant Cell Phone'
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="section-main-box-field">

                <Grid container spacing={2}>
                
                    <Grid xs={specialItemSpace} >
                        <TextFieldText text='Current Address' />
                        <AddressAutoComplete
                            setStateValue={value => handleAutoAddress(value)}
                            defaultValue={currAddress}
                            name='Applicant Current'
                            mandatory={true}
                            pageName={pageName}
                            buttonCheck={clickNextButton[pageName]}
                        />
                        <FormControlLabel
                            sx={{ml: 2}}
                            control={<Checkbox 
                                checked={cantFindCurrAddr} 
                                onChange={(e) => handleCantFindCurrAddrChange(e.target.checked)} />}
                            label=<Typography variant="h5">Can't find your address?</Typography>
                        />
                    </Grid>

                    {isLoanType && (
                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Current Living Since' />
                            <DateField
                                setStateValue={value => handleApplicantCurrLivingChange("livingSince", value)}
                                defaultValue={applicant.livingSince}
                                type='date'
                                name='Applicant Current Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>
                    )}



                    <Grid item xs={specialItemSpace} >
                        <AddressSubsection
                            applicantType={applicantType}
                            attributes={currAddr}
                            enable={cantFindCurrAddr}
                        />
                    </Grid>

                </Grid>

            </Box>

            <Box className="section-main-box-field">

                {isLoanType && (
                    <Grid container spacing={2} display={lessThanRequiredMonths ? 'block' : 'none'} >

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Address' />
                            <AddressAutoComplete
                                setStateValue={value => handleAutoAddressPrev(value)}
                                defaultValue={prevAddress}
                                name='Applicant Previous'
                                mandatory={lessThanRequiredMonths}
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <FormControlLabel
                                control={<Checkbox 
                                    checked={cantFindPrevAddr} 
                                    onChange={(e) => {handleCantFindPrevAddrChange(e.target.checked);}} />}
                                label="Can't find your address?"
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <AddressSubsection
                                applicantType={applicantType}
                                attributes={prevAddr}
                                enable={cantFindPrevAddr}
                            />
                        </Grid>

                        <Grid item xs={specialItemSpace} >
                            <TextFieldText text='Previous Living Since' />
                            <DateField
                                setStateValue={value => handleApplicantChange("prevLivingSince", value)}
                                defaultValue={applicant.prevLivingSince}
                                type={lessThanRequiredMonths ? 'date' : 'optional'}
                                name='Applicant Previous Living Since'
                                pageName={pageName}
                                buttonCheck={clickNextButton[pageName]}
                            />
                        </Grid>

                    </Grid>
                )}

            </Box>

        </Box>
    );
};