import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import TitleText from "../../../../components/smallComponents/text/titleText/TitleText";
import MainAppInvestorSection from "./section/MainAppInvestorSection";
import CoAppInvestorSection from "./section/CoAppInvestorSection";
import ScoreSummarySection from "./section/ScoreSummarySection";
import PrevButton from "../../../../components/smallComponents/button/prevButton/PrevButton";
import NextButton from "../../../../components/smallComponents/button/nextButton/NextButton";
import './component/style/InvestorProfile.css'

/**
 * @returns - Investor profile main field component for the application page
 */

const pageName = 'investorProfile'
export default function InvestorProfile() {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const applicant = useSelector((state) => state.applicantInfo);

    const title = "Investor Profile";

    return (
        <Box className='max-w-screen-xl w-auto h-fit min-h-[80vh] ml-24 mr-24 investor-profile-box-overall'>

            <Box>
                <TitleText text={title} />
            </Box>

            <Box className='ml-2'>
                <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange} >
                    <Tab style={{ fontSize: 16 }} label="Main Applicant" value={0} />
                    {applicant.anyCoapplicant && (
                        <Tab style={{ fontSize: 16 }} label="Co-Applicant" value={1} />
                    )}
                    <Tab style={{ fontSize: 16 }} label="Reference" value={2} />
                </Tabs>
            </Box>

            {tabValue === 0 && (
                <MainAppInvestorSection />
            )}
            {tabValue === 1 && (
                <CoAppInvestorSection />
            )}
            {tabValue === 2 && (
                <ScoreSummarySection />
            )}

            <Box className="mt-12 flex justify-center">
                <PrevButton />
                <NextButton checkScore={true}  pageName={pageName}/>
            </Box>
            
        </Box>
    );
};