export default class GuaranteeLevel {

    static LEVEL7575 = "75/75";
    static LEVEL75100 = "75/100";
    static LEVEL100100 = "100/100";

    constructor(level) {
        this.guaranteeLevel = level;
    }

    toString() {
        return this.guaranteeLevel;
    }
};